
import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import sales from "../../src/assets/images/sales.png";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import { Scrollbars } from 'react-custom-scrollbars';
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import ActivityCard from './separate/activityTab'
import ConnectWallet from './separate/Connect-Wallet';
import { ActivityCall } from 'actions/v1/report'

import { useSelector } from "react-redux";

import config from 'lib/config'
import { Button } from "@material-ui/core";

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #3d2524;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #3d2524;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function Activity(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const Wallet_Details = useSelector(state => state.wallet_connect_context);
  const [CatBasedTokenList, setCatBasedTokenList] = useState({ 'loader': false, 'purchase': { page: 1, list: [], onmore: true } });
  const [CatName, setCatName] = useState('purchase');

  const [expanded, setExpanded] = React.useState('panel1');
  const [Activity_Item, Set_Activity_Item] = useState([])
  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [load_more, Set_load_more] = useState({});
  // useEffect(() => {
  // }, [Wallet_Details.UserAccountAddr, Wallet_Details.UserAccountBal,CatName])
  async function catChange(name) {
      setCatName(name);
  }
  const onLoadMore = () => {
    CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page + 1;
    setCatBasedTokenList(CatBasedTokenList);
    ActivityTab({
      page: CatBasedTokenList[CatName].page + 1
    });
  }

  useEffect(() => {
    
    if (typeof CatBasedTokenList[CatName] === 'undefined') {
      console.log("tested for category", CatBasedTokenList[CatName]);
      CatBasedTokenList[CatName] = { page: 1, list: [], onmore: true };
      
      setCatBasedTokenList(CatBasedTokenList);
      ActivityTab({ CatName: CatName, page: 1 });
    }
  }, [CatName])
  useEffect(() => {
   
      ActivityTab({ CatName: CatName, page: 1 });
  
  }, [])
  const ActivityTab = async (data) => {
   // console.log("sohmhnjmgjhnmci1", data,CatName)
    var name = CatName
    var reqdata = {
      tabName: name,
      currAddr: Wallet_Details.UserAccountAddr,
      limit: 9,
      page: (CatBasedTokenList[name] && CatBasedTokenList[name].page) ? CatBasedTokenList[name].page : 1,
    }
   // console.log("fdbhdgfbhfdgfefr",reqdata)

    var activitys = await ActivityCall(reqdata)
    // console.log("AllActivitiefgjgfnjhs", activitys)
    if (activitys && activitys.data && activitys.data.list && (activitys.data.list).length > 0) {
      // console.log("AllActivities", activitys.data.list)
      CatBasedTokenList[name].list = CatBasedTokenList[name].list.concat(activitys.data.list);
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
      // setCatBasedTokenList(activitys.data.list)
      // Set_load_more(activitys.data)
    }
    else {
      Set_Activity_Item([])
    }

  }



  return (
    <div className="inner_header">
      {/* <ConnectWallet
          Set_UserAccountAddr={Set_UserAccountAddr}
          Set_UserAccountBal={Set_UserAccountBal}
          Set_WalletConnected={Set_WalletConnected}
          Set_AddressUserDetails={Set_AddressUserDetails}
          Set_Accounts={Set_Accounts}
          WalletConnected={WalletConnected}
          set_Service_Fee={set_Service_Fee}
          Service_Fee={Service_Fee}
          AfterWalletConnected={AfterWalletConnected}
          Wen_Bln={Wen_Bln}
          set_Wen_Bln={set_Wen_Bln}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
          set_providers={set_providers}
          providerss={providerss}
        /> */}


      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><Link to="/">
            <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link></div>
          <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
        </>
        }
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader activity"}>
        <div className="bg_red_1">
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="d-flex justify-content-between">
                  <h2 className="inner_title">Activity</h2>
                  {/* <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle filter_btn white_text_filter_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-exchange-alt pr-2"></i>Filter
                </button>
                <div className="dropdown-menu filter_menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                <label className="custom-control-label" htmlFor="customCheck1">Listings</label>
                </div>
                <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck2" />
                <label className="custom-control-label" htmlFor="customCheck2">Purchases</label>
                </div>
                <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck3" />
                <label className="custom-control-label" htmlFor="customCheck3">Sales</label>
                </div>
                <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck4" />
                <label className="custom-control-label" htmlFor="customCheck4">Transfers</label>
                </div>
                </div>
                </div>
               */}
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div className="container">
        <div className="customDrop">

            <CustomDropdown 
                          noLiPadding
                          buttonText={<div className="customDropdown">
                       
                      Filter</div>}
                          dropdownList={[
                            <div className="buernotir">

                              <div style={{ height: 250 }}>
                             
                                <ul className="explorebg btnbuyerss">
                                  <li className="px-3" >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 "onClick={() =>
                                    catChange('All')} >All</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3"  onClick={() =>
                                    catChange('mine')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >My Activity</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('mint')} >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >Mint</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('bid')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >Bid</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('accept')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >Bid Accept</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('purchase')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >Purchase</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li> 
                                  
                                  <li className="px-3"  onClick={() =>
                                    catChange('putonsale')} >
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">Put on sale</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('cancel price')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">Cancel price</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('like')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">Token Like</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('follow')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">Follow</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                </ul>
                               
                              </div>



                            </div>
                          ]}
                        />
                    
                        </div>
               
              {/* {console.log("activityyyyy",CatBasedTokenList)}  */}
              
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                  <div className="proposal_panel_overall">
                    {/* { */}
                      
                    {/* // CatBasedTokenList[CatName].list.length === 0 ? */}
                      {/* <div className="text-center py-5 notfound">
                        <p className="not_found_text">Loading</p>
                        <p className="not_found_text_sub">Please wait a moment</p>
                        <div className="mt-3">
                      

                        </div>
                      </div> */}

                      {/* // : */}
                      <div className="followers_overall py-3">
                        <div className="row">
                          {
                          CatBasedTokenList
                          && CatName
                          && CatBasedTokenList[CatName] && CatBasedTokenList[CatName].list
                          && CatBasedTokenList[CatName].list.length > 0 &&
                          CatBasedTokenList[CatName].list.map((item) => {
                            return (
                              <ActivityCard
                                item={item}
                              />
                            )
                          })}


                        </div>

                      </div>
                      {/* } */}

                  </div>
              

                </div>


              </div>
           
        </div>

        <div className="text-center mt-4 w-100 ">
               
                <div id="spinButs" className="overflow-hidden">
                  <Button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" onClick={onLoadMore}  style={{ display: "flex" }}>
                    Load More <span>
                    
                    </span>

                  </Button>
                </div>
              </div>
      </div>
      <Footer />
    </div>
  );
}







