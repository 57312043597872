import React, { useEffect,useState,useRef } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

// my side
import { CollectiblesList_Follow } from "actions/v1/token";
// import Avatars from "./Avatar";
import TokenCard from '../views/separate/TokenCard'

// import axios from 'axios';
// import config from '../lib/config';
// import {
//   getCurAddr,
//   ParamAccountAddr_Detail_Get,
// } from '../actions/v1/user';


import { CancelOrderRef } from './separate/CancelOrderRef';
import { LikeRef } from './separate/LikeRef';
import { BurnRef } from './separate/BurnRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import ConnectWallet from './separate/Connect-Wallet';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import isEmpty from "lib/isEmpty";
// import { toast } from 'react-toastify';

const Icon = styled(props => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #3d2524;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #3d2524;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Following(props) {
  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var BurnForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  var PlaceABidForwardRef = useRef();

  const classes = useStyles();


  const Wallet_Details = useSelector(state=>state.wallet_connect_context);

  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
// myside
  const [followingList, setFollowingList] = useState([]);
		const [collectibles, setCollectibles] = useState([]);
		const [filter, setFilter] = useState('');
    const [LikedTokenList, setLikedTokenList] = useState([]);
    // const [UserAccountAddr, Set_UserAccountAddr] = useState('');
    // const [UserAccountBal, Set_UserAccountBal] = useState(0);
    const [AddressUserDetails, Set_AddressUserDetails] = useState({});
    // const [Accounts, Set_Accounts] = useState('');
    const [MyItemAccountAddr, Set_MyItemAccountAddr] = useState('');
    const [HitItem, Set_HitItem] = useState({});
    // const [WalletConnected, Set_WalletConnected] = useState('false');
    const [tokenCounts, Set_tokenCounts] = useState(0);
    const [item, Set_item] = useState({});
    const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
    const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
    const [Bids, Set_Bids] = useState([]);
    const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
    const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
    const [NoOfToken, Set_NoOfToken] = useState(1);
    const [ValidateError, Set_ValidateError] = useState({});
    const [TokenBalance, Set_TokenBalance] = useState(0);
    const [YouWillPay, Set_YouWillPay] = useState(0);
    const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
    const [YouWillGet, Set_YouWillGet] = useState(0);
    const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = useState('init');
    const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = useState('init');
    const [AllowedQuantity, Set_AllowedQuantity] = useState({});
    // const [Time_Auction_List, Set_Time_Auction_List] = useState([]);
    // const [ Recent_Nft_List, Set_Recent_Nft_List] = useState([]);
    const [Service_Fee,set_Service_Fee] = useState(0);
    // const[Wen_Bln,set_Wen_Bln]=useState(0);
    const[MyItemAccountAddr_Details,Set_MyItemAccountAddr_Details]=useState({})
    const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
    const [MyTokenDetail, Set_MyTokenDetail] = useState({});
    const [providerss,set_providers]=useState(null)
  
    const [convertVal, setConvertVal] = React.useState(0);
   
    useEffect(() => {
			getfollowing();
		}, [Wallet_Details.UserAccountAddr])
		const getfollowing = async (filterParam) => {
			// //////console..log(filterParam);
			// const addr =  window.web3.eth.defaultAccount;
		if(Wallet_Details.UserAccountAddr!==""){
      var resp = await CollectiblesList_Follow({
				target :'following',
				addr : Wallet_Details.UserAccountAddr,
				// follower : addr,
				// filterParam : filterParam
			  });
			  //////console..log(">>>>Resp", resp);
			  if (resp.data && resp.data.list!==0) {
				setCollectibles(resp.data.list)
			  }
        else{
          setCollectibles([])
        }
		}}
    const AfterWalletConnected=()=>{

    }
    
  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }


  return (
    <div className="inner_header">
       {/* <ConnectWallet
          Set_UserAccountAddr={Set_UserAccountAddr}
          Set_UserAccountBal={Set_UserAccountBal}
          Set_WalletConnected={Set_WalletConnected}
          Set_AddressUserDetails={Set_AddressUserDetails}
          Set_Accounts={Set_Accounts}
          WalletConnected={WalletConnected}
          set_Service_Fee={set_Service_Fee}
          Service_Fee={Service_Fee}
          AfterWalletConnected={AfterWalletConnected}
          Wen_Bln={Wen_Bln}
          set_Wen_Bln={set_Wen_Bln}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
          set_providers={set_providers}
          providerss={providerss}
        /> */}
      
        <PutOnSaleRef
          ref={PutOnSaleForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          // GetUserBal={GetUserBal}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
          // set_providers={set_providers}
          // providerss={Wallet_Details.providerss}
        />
         <BurnRef
          ref={BurnForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          // GetUserBal={GetUserBal}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
          // set_providers={set_providers}
          // providerss={Wallet_Details.providerss}
        />
        <PlaceAndAcceptBidRef
          ref={PlaceABidForwardRef}
          // Set_WalletConnected={Set_WalletConnected}
          // Set_UserAccountAddr={Set_UserAccountAddr}
          // Set_UserAccountBal={Set_UserAccountBal}
          Set_AddressUserDetails={Set_AddressUserDetails}
          // Set_Accounts={Set_Accounts}
          Set_MyItemAccountAddr={Set_MyItemAccountAddr}
          Set_tokenCounts={Set_tokenCounts}
          Set_item={Set_item}
          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
          Set_MyTokenBalance={Set_MyTokenBalance}
          Set_Bids={Set_Bids}
          Set_AccepBidSelect={Set_AccepBidSelect}
          Set_tokenBidAmt={Set_tokenBidAmt}
          Set_NoOfToken={Set_NoOfToken}
          Set_ValidateError={Set_ValidateError}
          Set_TokenBalance={Set_TokenBalance}
          Set_YouWillPay={Set_YouWillPay}
          Set_YouWillPayFee={Set_YouWillPayFee}
          Set_YouWillGet={Set_YouWillGet}
          Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
          Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}

          // WalletConnected={Wallet_Details.WalletConnected}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          AddressUserDetails={Wallet_Details.AddressUserDetails}
          // Accounts={Wallet_Details.Accounts}
          MyItemAccountAddr={MyItemAccountAddr}
          tokenCounts={tokenCounts}
          item={item}
          tokenCounts_Detail={tokenCounts_Detail}
          MyTokenBalance={MyTokenBalance}
          Bids={Bids}
          AccepBidSelect={AccepBidSelect}
          tokenBidAmt={tokenBidAmt}
          NoOfToken={NoOfToken}
          ValidateError={ValidateError}
          TokenBalance={TokenBalance}
          YouWillPay={YouWillPay}
          YouWillPayFee={YouWillPayFee}
          YouWillGet={YouWillGet}
          BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
          BidApply_SignCallStatus={BidApply_SignCallStatus}
          AllowedQuantity={AllowedQuantity}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // set_Wen_Bln={set_Wen_Bln}
          // Wen_Bln={Wallet_Details.Wen_Bln}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
          // set_providers={set_providers}
          // providerss={Wallet_Details.providerss}
        />
        <PurchaseNowRef
          ref={PurchaseNowForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          // GetUserBal={GetUserBal} 
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // set_Wen_Bln={set_Wen_Bln}
          // Wen_Bln={Wallet_Details.Wen_Bln}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
          // set_providers={set_providers}
          // providerss={Wallet_Details.providerss}
        />
        <WalletRef
          ref={WalletForwardRef}
          // Set_UserAccountAddr={Set_UserAccountAddr}
          // Set_WalletConnected={Set_WalletConnected}
          // Set_UserAccountBal={Set_UserAccountBal}
          // set_providers={set_providers}
          // providerss={Wallet_Details.providerss}
        />
        <LikeRef
        
        // UserAccountAddr={Wallet_Details.UserAccountAddr}
        //  WalletConnected={Wallet_Details.WalletConnected}
        //  set_providers={set_providers}
        //  providerss={Wallet_Details.providerss}
          ref={LikeForwardRef}
          setLikedTokenList={setLikedTokenList}
          MyItemAccountAddr={MyItemAccountAddr}
        />
        <CancelOrderRef
          ref={CancelOrderForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          // WalletConnected={Wallet_Details.WalletConnected}
     
          // GetUserBal={GetUserBal}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
          // set_providers={set_providers}
          // providerss={Wallet_Details.providerss}
        />
        <ReportNowRef
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          ref={ReportForwardRef}
        />
        <ShareNowRef
          ref={ShareForwardRef}
        />

      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><Link to="/">
            <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/></Link></div>
            <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid"/></Link></div>
            </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className="bg_red_1">
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <h2 className="inner_title">Following</h2>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        </div>
        <div className="container-fluid container-theme mt-3">
        <div className=" masonry myitemcards m-0 ma_no_gap">
        {
						collectibles && collectibles.map((item) =>{
              return(
        


                <div className=" ">
                    <TokenCard
                              item={item}
                          Set_item={Set_item}
                          LikedTokenList={LikedTokenList}
                        hitLike={LikeForwardRef.current&&LikeForwardRef.current.hitLike}  
                        // UserAccountAddr={Wallet_Details.UserAccountAddr}
                        //   UserAccountBal={Wallet_Details.UserAccountBal}
                          PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                          PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                          PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                          Set_Bids={Set_Bids}
                          Bids = {item.myBid}
                          Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                          Set_MyTokenBalance = {Set_MyTokenBalance}
                          Set_MyTokenDetail={Set_MyTokenDetail}
                          Set_AllowedQuantity={Set_AllowedQuantity}
                          Set_YouWillPay = {Set_YouWillPay}
                          Set_YouWillPayFee = {Set_YouWillPayFee}
                          Set_YouWillGet = {Set_YouWillGet}
                          // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                          Burn_Click={BurnForwardRef.current.Burn_Click}
                          CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                          // WalletConnected={Wallet_Details.WalletConnected}
                          SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                          ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                          setConvertVal={setConvertVal}
                          convertVal={convertVal}
                        
                          />
                          
                </div>
            
                )
              })					
            }
                </div>

        </div>
      </div>
      <Footer/>
         </div>
  );
}
