import React from 'react';

// import Avatar from 'react-string-avatar';
import Profile from '../assets/images/no_profile2.png'
export default function Avatars(props){
// ////console.log('props',props);
var { item } = props;
return(
        // <div className="img_avatar_space">
        <div>
                {/* <Avatar initials={item!==undefined&&(item!=""&&item.substring(2, 4).toUpperCase())} roundShape="true" bgColor="#a30726" textColor="white" /> */}
                <img src={Profile} className={item} alt="test" />        
        </div>

)}