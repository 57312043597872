import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function ConnectWallet(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><Link to="/">
            <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/></Link></div>
            <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid"/></Link></div>
            </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <Link to="/">
                    <i className="fas fa-arrow-left mr-3 arrow_back"></i>
                </Link>
                <h3 className="section-head mb-0 sec_he_sm">Connect your wallet</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-4">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <p className="create_para">Connect with one of available wallet providers or create a new wallet.</p>
              <div className="create_box_panel row connect_row connect_row_conn_wal text-center">
                  <div className="col-12 col-sm-6 col-lg-3 mb-3 ">
                <Link to="/create-single" className="create_box">
                  <h2>Metamask</h2>
                  <img src={require("../assets/images/wallet_01.png")} alt="logo" className="img-fluid mt-3"/>
                  <p className="mb-0 text-white text-center mt-3">One of the most secure wallets with great flexibility</p>
                </Link>
                </div>
                
                 {/* <div className="col-12 col-sm-6 col-lg-3 mb-3">
                <Link to="/create-multiple" className="create_box">
                  <h2>Binance Chain Wallet</h2>
                  <img src={require("../assets/images/wallet_02.png")} alt="logo" className="img-fluid mt-3"/>
                  <p className="mb-0 text-white text-center mt-3">Connect via app on your phone</p>

                </Link>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 mb-3">
                <Link to="/create-multiple" className="create_box">
                  <h2>WalletConnect</h2>
                  <img src={require("../assets/images/wallet_03.png")} alt="logo" className="img-fluid mt-3 mr-2"/>
                
                  <p className="mb-0 text-white text-center mt-3">Connect with Rainbow, Trust, Argent and more</p>

                </Link>
                </div>
               
                <div className="col-12 col-sm-6 col-lg-3 mb-3">
                <Link to="/create-multiple" className="create_box">
                  <h2>TrustWallet</h2>
                  <img src={require("../assets/images/wallet_05.png")} alt="logo" className="img-fluid mt-3"/>
                  <p className="mb-0 text-white text-center mt-3">Connect via app on your phone</p>

                </Link>
                </div> */}
              </div>
              <p className="create_para mt-3">We do not own your private keys and cannot access your funds without your confirmation.</p>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
