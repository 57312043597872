// import Web3 from 'web3';
// import config from '../../lib/config'


export const getReceipt=async(web3,approveCall)=>{
        console.log("hdsjkhgfkjsdhkfgdf",approveCall);
        // var receipt = null
        var receipt =  await web3.eth.getTransactionReceipt(approveCall)
        ////console.log("receipt",receipt)
        console.log("receipt 1",receipt)
     
        return receipt
//       })
      
       
}