import React, {
    useEffect,
    forwardRef,
    useState,
    useImperativeHandle
} from 'react';
import {
    useHistory, useLocation
} from "react-router-dom";
import { Button } from '@material-ui/core';
import video from 'video.js';
// import $ from 'jquery';
import Web3 from 'web3';
import '@metamask/legacy-web3'

// import EXCHANGE from '../../ABI/EXCHANGE.json'
// import Audios from 'assets/images/audio.png'
import config from '../../lib/config';
import Select from 'react-select';
import isEmpty from 'lib/isEmpty';
import Trade from '../../ABI/Trade.json'
import {
    TokenPriceChange_update_Action,
    checkOtherPlatformDetais1155
} from '../../actions/v1/token';
import SINGLE from '../../ABI/SINGLE.json';
import MULTIPLE from '../../ABI/MULTIPLE.json'
// import {
//     getCurAddr,
// } from '../../actions/v1/user';
import { getReceipt } from 'actions/v1/getReceiptFunc';
import Convert1 from '../separate/Convert1'
// import ReactPlayer from 'react-player';
import Convert from '../separate/Convert'
import { toast } from 'react-toastify';

import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

import { useSelector } from "react-redux";

toast.configure();
let toasterOption = config.toasterOption;

const exchangeAddress = config.exchangeAddress;

export const PutOnSaleRef = forwardRef((props, ref) => {

    const history = useHistory();



    const Wallet_Details = useSelector(state => state.wallet_connect_context);

    var pathVal = '';
    const location = useLocation();
    if (location.pathname) {
        if (location.pathname.split('/').length >= 2) {
            pathVal = location.pathname.split('/')[1];
        }
    }
    const [location_pathname, Set_location_pathname] = useState(pathVal);
    var CollectibleType_val = (location_pathname === 'create-single') ? config.singleType : config.multipleType;
    // const [BuyerName, Set_BuyerName] = React.useState('');
    // const [blns, Set_blns] = React.useState('');
    // const [dethBln, Set_dethBln] = React.useState('');
    // const [bidProfile1, Set_bidProfile1] = React.useState([]);

    const [FormSubmitLoading, Set_FormSubmitLoading] = useState('init');
    const [CoinName, setCoinNames] = useState('');
    const [CoinName_Initial, set_CoinName_Initial] = useState('');
    const [Items, Set_Items] = useState({});

    const [YouWillGet, Set_YouWillGet] = useState(0);
    const [owner_Detail, set_owner_Detail] = useState({})
    ////////console.log('props.Items.tokenPrice', props.item);

    const [TokenPrice, Set_TokenPrice] = useState(0);
    const [TokenPrice_Initial, Set_TokenPrice_Initial] = React.useState(0);
    // console.log("TokenPrice_Initial",TokenPrice_Initial);
    const [token_onwers_info, set_token_onwers_info] = useState({})
    const [ApproveCallStatus, setApproveCallStatus] = React.useState('init');
    const [MintCallStatus, setMintCallStatus] = React.useState('init');
    // const [approvedata, setApprovedata] = React.useState(false);

    const [PutOnSale, setPutOnSale] = useState(true);
    const [PutOnSaleType, setPutOnSaleType] = useState('');

    const [TokenBid, setTokenBid] = useState(true);


    // const [UnLockcontent, Set_UnLockcontent] = useState("");
    const [Unlockoncepurchased, Set_Unlockoncepurchased] = useState(false);


    // const [TokenQuantity, Set_TokenQuantity] = useState(1);

    const [MinimumBid, Set_MinimumBid] = useState(0);
    const [Clocktime, set_Clocktime] = useState('');
    const [EndClocktime, set_EndClocktime] = useState("");

    // const [convertVal, setConvertVal] = React.useState(0);

    const [StartDate, Set_StartDate] = useState('Select Start Date');
    const [EndDate, Set_EndDate] = useState('Select End Date');

    const [quantyputonsale, Setquantyputonsale] = useState(1)
    const [stateforOwnerdetails, SetstateforOwnerdetails] = useState({})
  
// {console.log("dfbgdfbhgdfbdgfb",quantyputonsale,stateforOwnerdetails)}
    //   const [ValidateError, setValidateError] = useState({});
    const [ValidateError, Set_ValidateError] = useState({ TokenPrice: '' });



    // const [CollectibleType, Set_CollectibleType] = useState(CollectibleType_val);

    const priceoption = [
        { value: config.currencySymbol, label: config.currencySymbol },
        { value: config.tokenSymbol, label: config.tokenSymbol },
    ];

    const priceoptionfunc = (e) => {
        set_CoinName_Initial(e.value)
        ItemValidation({ CoinName: e.value })
        Set_FormSubmitLoading('start')
    };
    const inputChange = (e) => {
        if (e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
            Set_FormSubmitLoading('start')
            var value = e.target.value;
            // console.log("jfdvnkjdnvk",e.target.value);
            switch (e.target.name) {
                case 'TokenPrice':
                    // console.log("bfhjkdbhfk" ,value);
                    Set_TokenPrice(value);
                    ItemValidation({ TokenPrice: value });
                    if (value !== '' && isNaN(value) === false && value > 0) {
                        var weii = value * config.decimalvalues;
                        var per = (weii * Wallet_Details.Service_Fee_seller) / 1e20;
                        var royal = weii * item.tokenRoyality * config.decimalvalues / 1e20
                        // if ((String(CoinName_Initial).toLowerCase()) == (String(config.tokenSymbol).toLowerCase())) {
                        // Set_YouWillGet(parseFloat((weii - (per+royal)) / config.decimalvalues).toFixed(2));}
                        Set_YouWillGet(parseFloat((weii - (per)) / config.decimalvalues).toFixed(4));
                        // }
                        // else {
                        //     // Set_YouWillGet(parseFloat((weii - (per+royal)) / config.decimalvalues).toFixed(4));
                        //     Set_YouWillGet(parseFloat((weii - (per)) / config.decimalvalues).toFixed(4));
                        // }

                        // var weii = window.web3.toWei(value);
                        // var per = (Number(weii) * config.sellerfee)/ 1e20;
                        // var royal = Number(weii) * Number(window.web3.toWei(item.tokenRoyality))/1e20
                        // if((String(CoinName_Initial).toLowerCase())==(String(config.tokenSymbol).toLowerCase())){
                        //     var getValue=window.web3.fromWei((Number(weii) - (Number(per)+Number(royal))))
                        // Set_YouWillGet(parseFloat(Number(getValue)).toFixed(2));}
                        // else{
                        //     var getValues=window.web3.fromWei((Number(weii) - (Number(per)+Number(royal))))
                        //     Set_YouWillGet(parseFloat(Number(getValues)).toFixed(2));      
                        //       }
                        //console.log("Set_YouWillGet",weii,per,royal)

                    }
                    break;
                case 'MinimumBid':
                    // console.log("bfhjkdbhfk" ,value);
                    Set_MinimumBid(value);
                    if (value !== '' && isNaN(value) === false && value > 0) {
                        var weii = value * config.decimalvalues;
                        var per = (weii * Wallet_Details.Service_Fee_seller) / 1e20;
                        var royal = weii * item.tokenRoyality * config.decimalvalues / 1e20
                        if ((String(CoinName_Initial).toLowerCase()) === (String(config.tokenSymbol).toLowerCase())) {
                            // Set_YouWillGet(parseFloat((weii - (per+royal)) / config.decimalvalues).toFixed(2));}
                            Set_YouWillGet(parseFloat((weii - (per)) / config.decimalvalues).toFixed(2));
                        }
                        else {
                            // Set_YouWillGet(parseFloat((weii - (per+royal)) / config.decimalvalues).toFixed(4));
                            Set_YouWillGet(parseFloat((weii - (per)) / config.decimalvalues).toFixed(4));
                        }

                        // var weii = window.web3.toWei(value);
                        // var per = (Number(weii) * config.sellerfee)/ 1e20;
                        // var royal = Number(weii) * Number(window.web3.toWei(item.tokenRoyality))/1e20
                        // if((String(CoinName_Initial).toLowerCase())==(String(config.tokenSymbol).toLowerCase())){
                        //     var getValue=window.web3.fromWei((Number(weii) - (Number(per)+Number(royal))))
                        // Set_YouWillGet(parseFloat(Number(getValue)).toFixed(2));}
                        // else{
                        //     var getValues=window.web3.fromWei((Number(weii) - (Number(per)+Number(royal))))
                        //     Set_YouWillGet(parseFloat(Number(getValues)).toFixed(2));      
                        //       }
                        //console.log("Set_YouWillGet",weii,per,royal)

                    }
                    ItemValidation({ TokenPrice: value })
                    break;
                default:
                // code block
            }
        }
    }

    const ItemValidation = async (data = {}) => {
        var ValidateError = {};

        var Chk_TokenPrice = (typeof data.TokenPrice !== undefined) ? (data.TokenPrice) : TokenPrice;
        var CoinName_Initial = typeof CoinName !== undefined ? data.CoinName : CoinName_Initial
        //  console.log("dfcdswfvs",Chk_TokenPrice,data,TokenPrice);
        if (Chk_TokenPrice === '') {
            ValidateError.TokenPrice = '"Token Price" is not allowed to be empty';
        }
        else if (Chk_TokenPrice === 0) {
            ValidateError.TokenPrice = '"Token Price" must be greater than 0';
        }
        // else if (YouWillGet == 0) {
        //     ValidateError.TokenPrice = 'Only  allowed 2 digits for WENLAMBO and 4 digits for BNB after decimal point';
        // }

        else if (isNaN(Chk_TokenPrice) === true) {
            ValidateError.TokenPrice = '"Token Price" must be a number';
        }
        else if (TokenPrice_Initial > 0 && Chk_TokenPrice >= TokenPrice_Initial) {
            ValidateError.TokenPrice = '"Token Price" must be less than ' + TokenPrice_Initial;
        }
        else if (CoinName_Initial === "") {
            ValidateError.CoinName = '"Currency or Token Symbol" is required';
        }
        else if (CoinName_Initial === "undefined") {
            ValidateError.CoinName = '"Currency or Token Symbol" is required';
        }
        if (YouWillGet === 0) {
            ValidateError.TokenPrice = 'Only  allowed  4 digits after decimal point';
        }
        //console.log("Validation Checking",quantyputonsale,stateforOwnerdetails.balance)
        if (quantyputonsale < 1) {
            ValidateError.quantyputonsale = '"Quantity" is not allowed to be empty';
        }
        
        if (quantyputonsale > stateforOwnerdetails.balance) {
            ValidateError.quantyputonsale = '"Quantity" is should not grater than you own';
        }
        
        else {
            // await props.GetUserBal();
            // if(Chk_TokenPrice > UserAccountBal) {
            //     ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance';
            // }
            // else {
            delete ValidateError.TokenPrice;
            Set_FormSubmitLoading('start')
            // }

        }

        Set_ValidateError(ValidateError);
        return ValidateError;
    }

    async function FormSubmit() {
        Set_FormSubmitLoading('start');
        console.log("vnfckjvdf",PutOnSaleType);
        if (PutOnSaleType === 'FixedPrice') {
            var errors = await ItemValidation();
            var errorsSize = Object.keys(errors).length;
            if (errorsSize !== 0) {
                // console.log("hjsdbfjsdh",errors);
                Set_FormSubmitLoading('error');
                toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
                return false;
            }

            if (Wallet_Details.providerss) {
                // console.log("bfjkbvhcjigvhfji",props.providerss);
                var web3 = new Web3(Wallet_Details.providerss)
                if (
                    web3
                ) {
                    var receipt = null;
                    var handle = null;
                    if (Items.contractAddress === config.singleContract ||
                        Items.contractAddress === config.multipleContract ||
                        Items.contractAddress === config.old_singleContract ||
                        Items.contractAddress === config.old_multipleContract) {

                        if (Items.type === 721) {
                            var CoursetroContract = new web3.eth.Contract(
                                SINGLE,
                                Items.contractAddress
                            );
                        }
                        else {
                            var CoursetroContract = new web3.eth.Contract(
                                MULTIPLE,
                                Items.contractAddress
                            );
                        }
                        try {
                            Set_FormSubmitLoading('processing');
                            // console.log("dhgvfjhsdgjhudvh",Items.tokenCounts, props.Accounts,TokenPrice);
                            // console.log("vfdghuivgfsdkjhbk",web3.utils.toWei(String(TokenPrice)),TokenPrice);
                            await CoursetroContract.methods
                                .orderPlace(
                                    Items.tokenCounts,
                                    web3.utils.toWei(String(TokenPrice)),

                                )
                                .send({ from: Wallet_Details.UserAccountAddr })
                                .on('transactionHash', async (transactionHash) => {
                                    handle = setInterval(async () => {
                                        receipt = await getReceipt(web3, transactionHash)
                                        clr1();
                                    }, 8000)
                                })
                        }

                        catch (error) {
                            Set_FormSubmitLoading('try');
                            // console.log('error : ', error);
                            toast.error('Order not placed', toasterOption)
                        }
                    }
                    else {
                        // console.log("Trade is Working", Items.type)
                        var CoursetroContract = new web3.eth.Contract(
                            Trade,
                            config.trade
                        );
                        try {
                            Set_FormSubmitLoading('processing');

                            await CoursetroContract.methods
                                .orderPlace(
                                    Items.tokenCounts,
                                    web3.utils.toWei(TokenPrice),
                                    Items.contractAddress,
                                    Items.type
                                )
                                .send({ from: Wallet_Details.Accounts })
                                .on('transactionHash', async (transactionHash) => {
                                    handle = setInterval(async () => {
                                        receipt = await getReceipt(web3, transactionHash)
                                        clr1();
                                    }, 8000)
                                })
                        }

                        catch (error) {
                            Set_FormSubmitLoading('try');
                            //console.log('error : ', error);
                            toast.error('Order not placed', toasterOption)
                        }
                    }

                    async function clr1() {

                        if (receipt !== null) {
                            clearInterval(handle);
                            if (receipt.status === true) {
                                Set_FormSubmitLoading('done');
                                ////////console.log('result : ', result);
                                var postData = {
                                    tokenOwner: Wallet_Details.UserAccountAddr,
                                    tokenCounts: Items.tokenCounts,
                                    tokenPrice: TokenPrice,
                                    blockHash: receipt.blockHash,
                                    transactionHash: receipt.transactionHash,
                                    owner: owner_Detail.tokenOwner,
                                    CoinName: CoinName_Initial,
                                    MinimumBid: 0,
                                    Clocktime: null,
                                    EndClocktime: null,
                                    PutOnSaleType: PutOnSaleType,
                                    putonsalequantity: quantyputonsale

                                }
// console.log("sfgdgvdfgvfd",postData);
                                var Resp = await TokenPriceChange_update_Action(postData)
                                if (Resp && Resp.data && Resp.data.RetType && Resp.data.RetType === 'success') {
                                    toast.success("Collectible price changed successfully", toasterOption)
                                    window.$('#PutOnSale_modal').modal('hide');
                                    setTimeout(() => {
                                        history.push("/")
                                        // history.push('/');
                                    }, 8000);
                                }
                            }
                        }
                    }

                }
            }
        }
        else {



            ////////console.log('result : ', result);
            var postData = {
                tokenOwner: Wallet_Details.UserAccountAddr,
                tokenCounts: Items.tokenCounts,
                tokenPrice: TokenPrice,
                owner: owner_Detail.tokenOwner,
                CoinName: CoinName_Initial,
                MinimumBid: MinimumBid,
                Clocktime: Clocktime,
                EndClocktime: EndClocktime,
                PutOnSaleType: PutOnSaleType,

            }


            var Resp = await TokenPriceChange_update_Action(postData)
            if (Resp && Resp.data && Resp.data.RetType && Resp.data.RetType === 'success') {
                toast.success("Collectible price changed successfully", toasterOption)
                window.$('#PutOnSale_modal').modal('hide');
                Set_FormSubmitLoading('done');
                setTimeout(() => {
                    history.push("/")
                    // history.push('/');
                }, 8000);
            }



        }
    }

    var {
        item,
        UserAccountAddr,
        UserAccountBal,
        Service_Fee
    } = props;



    useEffect(() => {
        Set_ValidateError({});
    }, []);

    const changePutOnSaleType = (type) => {
        setPutOnSaleType(type);
        if (type === 'FixedPrice') {
        }
    };


    async function DateChange(from, val) {
        if (from === 'StartDateDropDown') {
            if (val === 'PickStartDate') {
                ModalAction('calendar_modal', 'show');
            }
            else {
                Set_StartDate(val);
                var myDate = new Date();
                if (val === 'RightAfterListing') {
                    var newdat = myDate.setDate(myDate.getDate());
                }
                else {
                    var newdat = myDate.setDate(myDate.getDate() + parseInt(val));
                }
                var newdate = new Date(newdat);
                // console.log("Datefdxscsdvfg",newdate)
                set_Clocktime(newdate);
            }
        }
        else if (from === 'EndDateDropDown') {
            if (val === 'PickEndDate') {
                ModalAction('calendar_modal_expire', 'show');
            }
            else {
                Set_EndDate(val);
                var myDate = new Date();
                var newdat = myDate.setDate(myDate.getDate() + parseInt(val));
                var newdate = new Date(newdat)
                set_EndClocktime(newdate)
            }
        }
    }


    async function ModalAction(id, type) {
        window.$('#' + id).modal(type);
        if (id === 'calendar_modal') {
            if (Clocktime) {
                var dt = new Date(Clocktime);
                var dt1 = dt.toLocaleString();
                Set_StartDate(dt1);
            }
        }
        else if (id === 'calendar_modal_expire') {
            if (EndClocktime) {
                var dt = new Date(EndClocktime);
                var dt1 = dt.toLocaleString();
                Set_EndDate(dt1);
            }
        }
    }
    const CheckedChange = (e) => {
        if (e && e.target && e.target.name) {
            if (e.target.name === 'putonsale') {
                if (PutOnSale === false) {
                    setPutOnSale(true);
                    if (PutOnSaleType === '') {
                        setPutOnSaleType('FixedPrice')
                    }
                }
                else {
                    setPutOnSale(false);
                    setPutOnSaleType('')
                    setTokenBid(true)
                }
            }
            else if (e.target.name === 'unlockoncepurchased') {
                if (Unlockoncepurchased === false) {
                    Set_Unlockoncepurchased(true);
                }
                else {
                    Set_Unlockoncepurchased(false);
                }
            }
        }
    };
    const onChange = (e) => {
        e.preventDefault();
        const { id, value } = e.target;

       let formData = { ...quantyputonsale, ...{ [id]: value } }
       // const price = e.target.value
       console.log("fgnjhfb",formData);
       Setquantyputonsale(formData.putonsalequantity)
      }


    useImperativeHandle(
        ref,
        () => ({
            async PutOnSale_Click(item, tokenOwnerInfo, ownerdetail) {
                // console.log("putonsale",item,ownerdetail,tokenOwnerInfo)
                setPutOnSaleType(ownerdetail.PutOnSaleType)
                SetstateforOwnerdetails(ownerdetail)
                if (Wallet_Details.UserAccountAddr !== "") {
                    var web3 = new Web3(Wallet_Details.providerss)
                    var balance = await checkOtherPlatformDetais1155(item, ownerdetail, item.type, web3);
                    console.log('scbalance>>zdsvx>>>>>sc>>',balance)
                    if (balance === 0) {
                        toast.warning("You won't buy at this moment please refresh you data", toasterOption);
                        setTimeout(() => {
                            // window.location.href="/"
                        }, 1000);
                        return false;
                    }
                    else {

                        if (String(item.contractAddress).toLowerCase() === String(config.singleContract).toLowerCase() ||
                            String(item.contractAddress).toLowerCase() === String(config.multipleContract).toLowerCase() ||
                            String(item.contractAddress).toLowerCase() === String(config.old_singleContract).toLowerCase() ||
                            String(item.contractAddress).toLowerCase() === String(config.old_multipleContract).toLowerCase()) {
                            window.$('#PutOnSale_modal').modal('show');
                        }
                        else {
                            var check = await orderApprovecheck(item, ownerdetail);
                            if (check) {
                                //console.log("Checking status",check)
                                setApproveCallStatus('done')
                            }
                            // console.log("Checking status",check)
                            window.$('#price_item_modal').modal('show');
                        }
                        props.Set_HitItem(item);
                        Set_Items(item)
                        set_owner_Detail(ownerdetail)
                        // Set_TokenPrice(ownerdetail.tokenPrice);
                        Set_TokenPrice_Initial(ownerdetail.tokenPrice);
                        set_CoinName_Initial(ownerdetail.CoinName)
                        setCoinNames(ownerdetail.CoinName)
                        if (!isEmpty(tokenOwnerInfo)) {
                            set_token_onwers_info(tokenOwnerInfo)
                        }
                        else {
                            set_token_onwers_info({})
                        }
                        Set_ValidateError({});
                    }
                }
                else {
                    window.$('#connect_modal').modal('show');
                }
            }
        }),
    )
    async function orderApprovecheck(item, tokenOwnerInfo) {
        if (Wallet_Details.providerss == null) {
            toast.warning("OOPS!..connect Your Wallet", toasterOption);
            return false;
        }
        var web3 = new Web3(Wallet_Details.providerss);
        try {

            //   console.log("OrderApprove Check", item,tokenOwnerInfo);
            var MultiContract = new web3.eth.Contract(
                (item.type === 721 ? SINGLE : MULTIPLE),
                item.contractAddress
            );
            var status = await MultiContract.methods.isApprovedForAll(
                tokenOwnerInfo.tokenOwner,
                config.trade
            ).call();
            return status;
        }
        catch (e) {
            // console.log("OrderApprove Check", e);
            return false

        }
    }
    async function ApproveCall() {
        var receiptt = null;
        var handlee = null;
        if (Wallet_Details.providerss == null) {
            toast.warning("OOPS!..connect Your Wallet", toasterOption);
            return false;
        }
        var web3 = new Web3(Wallet_Details.providerss);
        var currAddr = Wallet_Details.UserAccountAddr

        if (currAddr === "") {
            toast.warning("OOPS!..connect Your Wallet", toasterOption);
            return false;
        }
        setApproveCallStatus('processing');
        try {
            var MultiContract = new web3.eth.Contract(
                (Items.type === 721 ? SINGLE : MULTIPLE),
                Items.contractAddress
            );
            await MultiContract.methods.setApprovalForAll(
                config.trade,
                true
            ).send({
                from: owner_Detail.tokenOwner,
            }).on('transactionHash', async (transactionHash) => {
                if (transactionHash !== "") {
                    handlee = setInterval(async () => {
                        receiptt = await getReceipt(web3, transactionHash)
                        clr();
                    }, 8000)
                }
            })
        }
        catch (error) {
            toast.error("Approve failed", toasterOption);
            setApproveCallStatus('tryagain');
        }
        async function clr() {
            if (receiptt != null) {
                clearInterval(handlee)
                toast.success("Approve Successfully", toasterOption);
                setApproveCallStatus('done');
            }
        }
    }


    async function priceListFunction() {
        //console.log("POPup starting")
        window.$('#price_item_modal').modal('hide');
        window.$('#PutOnSale_modal').modal('show');
    }


    return (
        <>

            <div className="modal fade primary_modal" id="PutOnSale_modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true"   >
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="accept_modalLabel">{(TokenPrice_Initial === 0 || TokenPrice_Initial === null) ? 'Put On Sale' : 'Lower Price'}</h5>
                            <div className="change_price_img_div">
                                {
                                    Items && Items.image &&
                                    ((String(Items.image).split('.').pop() === "mp4") ||
                                        (String(Items.image).split('.').pop() === "webm") ||
                                        (String(Items.image).split('.').pop() === "WEBM") ||
                                        (String(Items.image).split('.').pop() === "ogv") ||
                                        (String(Items.image).split('.').pop() === "OGV"))
                                    &&
                                    <video
                                        alt="video"
                                        id="my-video"
                                        className="img-fluid" muted
                                        autoPlay controls playsInline loop
                                        preload="auto"
                                    >
                                        <source src={Items.additionalImage ? (Items.additionalImage === "" ? `${config.IPFS_IMG}/${Items.ipfsimage}` : `${config.Back_URL}/nftImg/${Items.tokenCreator}/${Items.additionalImage}`) : `${config.IPFS_IMG}/${Items.ipfsimage}`} type="video/mp4" />
                                    </video>
                                }
                                {
                                    Items && Items.image && (String(Items.image).split('.').pop() === "mp3" || String(Items.image).split('.').pop() === "aac" || String(Items.image).split('.').pop() === "AAC" || String(Items.image).split('.').pop() === "FLAC" || String(Items.image).split('.').pop() === "flac") &&
                                    <>
                                        <img src={config.AudioImg} className="img-fluid" />

                                        <audio
                                            muted
                                            className="img-fluid"
                                            autoPlay
                                            controls
                                            playsInline
                                            loop
                                        >
                                            <source src={Items.additionalImage ? (Items.additionalImage === "" ? `${config.IPFS_IMG}/${Items.ipfsimage}` : `${config.Back_URL}/nftImg/${Items.tokenCreator}/${Items.additionalImage}`) : `${config.IPFS_IMG}/${Items.ipfsimage}`} type="audio/mp3" />
                                        </audio>
                                    </>
                                }
                                {Items && Items.image && (String(Items.image).split('.').pop() === "webp" || String(Items.image).split('.').pop() === "WEBP" || String(Items.image).split('.').pop() === "jpg" || String(Items.image).split('.').pop() === "JPG" || String(Items.image).split('.').pop() === "jpeg" || String(Items.image).split('.').pop() === "JPEG" || String(Items.image).split('.').pop() === "png" || String(Items.image).split('.').pop() === "PNG") &&
                                    <img src={Items.additionalImage ? (Items.additionalImage === "" ? `${config.IPFS_IMG}/${Items.ipfsimage}` : `${config.Back_URL}/nftImg/${Items.tokenCreator}/${Items.additionalImage}`) : `${config.IPFS_IMG}/${Items.ipfsimage}`} alt="Collections" className="img-fluid" />
                                }
                            </div>
                            <p className="text-gray font_we_600 font_12">You are about to sale for
                                <span className="buy_desc_sm_bold pl-1 bold_red owner_break">{Items.tokenName} </span>
                                for
                                <span className="buy_desc_sm_bold pl-1 bold_red owner_break" styel={{ fontSize: 10 }}>
                                    {
                                        token_onwers_info
                                        && (token_onwers_info)
                                        && (token_onwers_info.name !== "" ?
                                            <span >{token_onwers_info.name}</span>
                                            :
                                            <span className="word_brak_text_inline_new" title={"Owner : " + owner_Detail.tokenOwner}>{String(owner_Detail.tokenOwner).slice(0, 8).concat('...')} <span>Quantity {owner_Detail.balance}</span></span>)
                                            
                                    }
                                </span>
                            </p>

                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close9">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body px-0 pt-0">
                            <form className="px-4 bid_form">
                                {TokenPrice_Initial > 0 ?
                                    <>
                                        <div className="input-group mb-2 input_grp_style_1">
                                            <input type="text"
                                    
                                                maxLength={config.maxLength}
                                                name="TokenPrice"
                                                id="TokenPrice"
                                                onChange={inputChange}
                                                placeholder="e.g. 10"
                                                autoComplete="off"

                                                className="form-control"
                                                aria-describedby="basic-addon2" />
                                            {(TokenPrice_Initial === 0 || TokenPrice_Initial === null) &&
                                                <div className="input-group-append">
                                                    <Select
                                                        className="yes1 form-control primary_inp select1 selxet_app"
                                                        id="basic-addon2"
                                                        name="coinname"
                                                        onChange={priceoptionfunc}
                                                        options={priceoption}
                                                        label="Select price"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                    />
                                                </div>}



                                        </div>
                                        {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                                        {ValidateError.CoinName && <span className="text-danger">{ValidateError.CoinName}</span>}

                                        {TokenPrice_Initial > 0 && <p className="form_note">Price must be less than the actual price</p>}
                                    </>

                                    :
                                    <>

                                        <div className="d-flex justify-content-between align-items-end">
                                            <div>
                                                <h3 className="my-2 ">
                                                    <span className="text-gray">
                                                        {/* {(PutOnSale == false || (PutOnSale == true && PutOnSaleType == 'FixedPrice' && TokenPrice == 0)) && <span className="mr-1">Not for sale </span>} */}
                                                        {(PutOnSale === true && PutOnSaleType === 'FixedPrice' && TokenPrice > 0) && <span className="mr-1">For Sale</span>}
                                                        {(PutOnSale === true && PutOnSaleType === 'TimedAuction') && <span className="mr-1">Minimum Bid </span>}
                                                        {/* {(PutOnSale == true && PutOnSaleType == 'UnLimitedAuction') && <span>Open for Bids </span>}
                                                {TokenQuantity} of {TokenQuantity} */}
                                                    </span> </h3>

                                                {(PutOnSale === true && PutOnSaleType === 'FixedPrice' && TokenPrice > 0) && <button className="btn btn_purple_sm bg-theme" data-toggle="modal" data-target="#place_bid_multiple_modal"><span>
                                                    <Convert
                                                        item={Number(TokenPrice)}
                                                        coinName={CoinName_Initial}
                                                        convertVal={1}
                                                    /> {CoinName_Initial}</span></button>}
                                                {(PutOnSale === true && PutOnSaleType === 'TimedAuction') && <button className="btn btn_purple_sm bg-theme" data-toggle="modal" data-target="#place_bid_multiple_modal"> <span>{MinimumBid === '' ? 0 :
                                                    <Convert
                                                        item={Number(MinimumBid)}
                                                        coinName={'WENLAMBO'}
                                                        convertVal={1}
                                                    />} {config.tokenSymbol} </span></button>}
                                                {/* {(PutOnSaleType!='TimedAuction') && <span>Place a bid</span>} */}

                                            </div>
                                        </div>







                                        <div className="form-row">
                                            <div className="form-group col-md-12">
                                                <div className="d-flex justify-content-between align-items-start grid_toggle">
                                                    <div>
                                                        <label className="primary_label" htmlFor="inputEmail4">Put on Sale</label>
                                                        <p className="form_note">You’ll receive bids on this item</p>
                                                    </div>
                                                    <label className="switch toggle_model">
                                                        <input type="checkbox"
                                                            id="putonsale"
                                                            name="putonsale"
                                                            onChange={CheckedChange}
                                                            checked={PutOnSale}
                                                        />
                                                        <span className="slider"></span>
                                                    </label>
                                                </div>
                                                {
                                                    (PutOnSale === false) ? ('') : (
                                                        <>
                                                            <div className="row connect_row putonsale_sec justify-content-center">
                                                                <div className="col-12 col-sm-4 col-md-12 col-lg-5">
                                                                    <div className={"create_box create_sing_bx" + ((PutOnSaleType === 'FixedPrice') ? 'active' : 'inactive')} id="fixedprice" onClick={() => { changePutOnSaleType('FixedPrice') }} >
                                                                        <img src={require("../../assets/images/price_svg.svg")} alt="Fixed Price" className="img-fluid" />
                                                                        <p>Fixed Price</p>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    (item.type === 721) &&
                                                                    <div className="col-12 col-sm-4 col-md-12 col-lg-5">
                                                                        <div className={"create_box create_sing_bx" + ((PutOnSaleType === 'TimedAuction') ? 'active' : 'inactive')} onClick={() => { changePutOnSaleType('TimedAuction') }}>
                                                                            <img src={require("../../assets/images/timed_svg.svg")} alt="Timed Auction" className="img-fluid" />
                                                                            <p>Timed Auction</p>
                                                                        </div>
                                                                    </div>}
                                                                {/* <div className="col-12 col-sm-4 col-md-12 col-lg-5 mb-3" onClick={() => { changePutOnSaleType('UnLimitedAuction') }}>
                                                            <div className={"create_box create_sing_bx" + ((PutOnSaleType == 'UnLimitedAuction') ? 'active' : 'inactive')}>
                                                                <img src={require("../../assets/images/unlimited_svg.svg")} alt="Unlimited Auction" className="img-fluid" />

                                                                <p>Unlimited Auction</p>
                                                            </div>
                                                        </div> */}
                                                            </div>

                                                        </>)}
                                                {(PutOnSale === true && PutOnSaleType === 'FixedPrice') &&
                                                
                                                    <div className="row mx-0 mt-3 fixed_price_sec">
                                                        {(item.type === 1155) &&
                                                        <>
                                                        <label className="primary_label" htmlFor="price_new">Quantity</label>
                                                        
                                                          <div className="quantity_wirh">
                                                                    
                                                                       <input type="text"
                                                                       // value={collectionName}
                                                                       className="form-control primary_inp"
                                                                       id="putonsalequantity"
                                                                    //    value ={putonsalequantity}
                                                                       placeholder="Enter your quantity"
                                                                       onChange={onChange} />
                                                                    
                                                                     {ValidateError.quantyputonsale && <span className="text-danger">{ValidateError.quantyputonsale}</span>}
                                                                    </div></>
                                                                }
                                                        <label className="primary_label" htmlFor="price_new">Price</label>

                                                        <div className="form-row w-100">
                                                      
                                                            <div className="form-group col-md-12 col-lg-12">
                                                         
                                                                <div className="input-group input_grp_style_1 bordbot">
                                                               
                                                                    <input
                                                                        type="text"
                                                                        className="form-control selct_form_input_h"
                                                                        placeholder="Enter minimum bid"
                                                                        aria-label="Recipient's username"
                                                                        aria-describedby="basic-addon3"
                                                                        name="TokenPrice"
                                                                        id="TokenPrice"
                                                                        maxLength={config.maxLength}
                                                                        onChange={(e) => inputChange(e)}
                                                                        autoComplete="off"
                                                                    />

                                                                    <div className="input-group-append">
                                                                        <Select
                                                                            className="yes1 form-control primary_inp select1 selxet_app"
                                                                            id="basic-addon2"
                                                                            name="coinname"
                                                                            onChange={priceoptionfunc}
                                                                            options={priceoption}
                                                                            label="Select price"
                                                                            formControlProps={{
                                                                                fullWidth: true
                                                                            }}
                                                                        />
                                                                        {/* <span className="input-group-text pl-0 min_h_45_px" id="basic-addon2">{config.currencySymbol}</span> */}
                                                                    </div>
                                                                </div>
                                                                {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                                                            </div>
                                                        </div>
                                                        <p className="form_note">Service fee
                                                            <span className="font_we_700_note_txt">{Wallet_Details.Service_Fee_seller / 1e18}% </span><br />
                                                            You will receive <span className="font_we_700_note_txt">
                                                                {/* {YouWillGet} */}
                                                                <Convert
                                                                    item={Number(YouWillGet)}

                                                                    coinName={CoinName_Initial}
                                                                    convertVal={1}
                                                                />
                                                                {CoinName_Initial}</span>
                                                            <span className="font_we_700_note_txt">
                                                                <br />
                                                                {/* ( $ <Convert1
                                                            item={Number(YouWillGet)}
                                                            convertVal={(String(CoinName).toLowerCase() === (config.tokenSymbol).toLowerCase()) ?
                                                                config.WenlamboConvertVal :
                                                                Wallet_Details.currency_convertion}
                                                        /> ) */}
                                                                ($ {CoinName_Initial == "BLKZ" ? Wallet_Details.tokenAddress.BiddingToken_USD * TokenPrice : Wallet_Details.tokenAddress.XDC_Currency_USD * TokenPrice})
                                                                {/* {console.log("gvdgfdfvdsf", Wallet_Details.tokenAddress.BiddingToken_USD,Wallet_Details.tokenAddress.XDC_Currency_USD)} */}
                                                            </span>
                                                        </p>
                                                    </div>
                                                }
                                                {(PutOnSale === true && PutOnSaleType === 'TimedAuction') &&
                                                    <div className="row mt-3 timed_sec">
                                                        <div className="col-12 mb-3">
                                                            <label className="primary_label" htmlFor="price_new">Minimum bid</label>
                                                            <div className="input-group mb-1 align-items-baseline">

                                                                <input
                                                                    type="text"
                                                                    className="form-control selct_form_input_h"
                                                                    // placeholder="0"
                                                                    aria-label="Recipient's username"
                                                                    aria-describedby="basic-addon2"
                                                                    name="MinimumBid"
                                                                    id="MinimumBid"
                                                                    step="0.01"
                                                                    maxLength={15}
                                                                    onChange={(e) => inputChange(e)}
                                                                    placeholder="e.g. 10"
                                                                    autoComplete="off"
                                                                />



                                                                {ValidateError.MinimumBid && <span className="text-danger">{ValidateError.MinimumBid}</span>}
                                                                <div className="input-group-append">
                                                                    <p className="yes1 form-control primary_inp select1 selxet_app mb-0" >{config.tokenSymbol}</p>
                                                                </div>
                                                            </div>
                                                            <p className="form_note">   Bids below this amount won't be allowed. If you not enter any amount we will consider as 0</p>
                                                        </div>
                                                        <div className="col-12 col-lg-6  mb-3">
                                                            <div className="single_dd_1">
                                                                <label className="primary_label" htmlFor="start_date">Starting Date</label>
                                                                <div className="dropdown">
                                                                    <button className="btn btn-secondary dropdown-toggle filter_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                                                        {StartDate}<i className="fas fa-chevron-down"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                                                                        <div >Select Date</div>
                                                                        <div id="RightAfterListing" onClick={() => { DateChange('StartDateDropDown', 'RightAfterListing') }}>Right after listing</div>
                                                                        <div id="PickStart" onClick={() => { DateChange('StartDateDropDown', 'PickStartDate') }} >Pick specific date</div>
                                                                    </div>
                                                                </div>
                                                                {ValidateError.Clocktime && <span className="text-danger">{ValidateError.Clocktime}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-6 mb-3">
                                                            <div className="single_dd_1">
                                                                <label className="primary_label" htmlFor="start_date">Expiration Date</label>
                                                                <div className="dropdown">
                                                                    <button className="btn btn-secondary dropdown-toggle filter_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        {EndDate}<i className="fas fa-chevron-down"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                                                                        <div>Select Date</div>
                                                                        <div onClick={() => { DateChange('EndDateDropDown', '1 Day') }}>1 day</div>
                                                                        <div onClick={() => { DateChange('EndDateDropDown', '3 Day') }}>3 days</div>
                                                                        <div onClick={() => { DateChange('EndDateDropDown', 'PickEndDate') }}>Pick specific date</div>

                                                                    </div>
                                                                </div>
                                                                {ValidateError.EndClocktime && <span className="text-danger">{ValidateError.EndClocktime}</span>}

                                                            </div>

                                                        </div>
                                                        <div className="col-12">
                                                            <p className="form_note_link_boild" data-toggle="modal" data-target="#learn_modal">Learn more how timed auctions work</p>

                                                        </div>

                                                    </div>


                                                }</div>
                                            {/* <div className="form-group col-md-12">
                                        <div className="d-flex justify-content-between align-items-start grid_toggle">
                                            <div>
                                                <label className="primary_label" htmlFor="inputEmail4">Unlock Once Purchased</label>
                                                <p className="form_note">Content will be unlocked after successful transaction</p>
                                            </div>
                                            <label className="switch toggle_custom">
                                                <input
                                                    type="checkbox"
                                                    id="unlockoncepurchased"
                                                    name="unlockoncepurchased"
                                                    onChange={CheckedChange}
                                                />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                        {
                                            (Unlockoncepurchased) &&
                                            <div className="form-group unlock_sec">
                                                <input type="text"
                                                    className="form-control primary_inp mb-1"
                                                    name="UnLockcontent"
                                                    id="UnLockcontent"
                                                    onChange={inputChange}
                                                    placeholder="Digital key, code to redeem or link to a file.." />
                                                <p className="form_note">Tip: Markdown syntax is supported</p>
                                            </div>

                                        }
                                        {ValidateError.UnLockcontent && <span className="text-danger">{ValidateError.UnLockcontent}</span>}

                                    </div> */}
                                        </div>


                                    </>
                                }



                                <div className="row pb-3">
                                    <div className="col-12 col-sm-6">
                                        <p className="buy_desc_sm">Service fee</p>
                                    </div>
                                    <div className="col-12 col-sm-6 text-sm-right">
                                        <p className="buy_desc_sm_bold">{Wallet_Details.Service_Fee_seller / config.decimalvalues}% <span>{TokenPrice_Initial > 0 && CoinName_Initial}{(TokenPrice_Initial === 0 || TokenPrice_Initial === null) && (CoinName_Initial && CoinName_Initial)}</span></p>
                                    </div>
                                </div>
                                {/* <div className="row pb-3">
                                <div className="col-12 col-sm-6">
                                    <p className="buy_desc_sm">Royalty fee</p>
                                </div>
                                <div className="col-12 col-sm-6 text-sm-right">
                                    <p className="buy_desc_sm_bold">{item.tokenRoyality}% <span>{TokenPrice_Initial>0 && CoinName_Initial}{(TokenPrice_Initial==0||TokenPrice_Initial==null) && (CoinName_Initial&&CoinName_Initial)}</span></p>
                                </div>
                            </div> */}
                                <div className="row pb-3">
                                    <div className="col-12 col-sm-6">
                                        <p className="buy_desc_sm">You will get</p>
                                    </div>
                                    <div className="col-12 col-sm-6 text-sm-right">
                                        <p className="buy_desc_sm_bold">
                                            <Convert
                                                item={YouWillGet}

                                                coinName={CoinName_Initial}
                                                convertVal={1}
                                            />
                                            <span>{TokenPrice_Initial > 0 && CoinName_Initial}{(TokenPrice_Initial === 0 || TokenPrice_Initial === null) && (CoinName_Initial && CoinName_Initial)}</span></p>
                                    </div>
                                </div>

                                <div className="text-center">

                                    <Button
                                        className="create_btn btn-block"
                                        type="button"
                                        onClick={(FormSubmitLoading === 'start' || FormSubmitLoading === 'try') ? (() => FormSubmit()) : ''}
                                        disabled={(FormSubmitLoading === 'processing' || FormSubmitLoading === 'done' || FormSubmitLoading === 'init' || FormSubmitLoading === 'error')}
                                    >
                                        {FormSubmitLoading === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {FormSubmitLoading === 'processing' && 'In-Progress'}
                                        {FormSubmitLoading === 'init' && 'Start'}
                                        {FormSubmitLoading === 'start' && 'Start'}
                                        {FormSubmitLoading === 'done' && 'Done'}
                                        {FormSubmitLoading === 'try' && 'Try-Again'}
                                        {FormSubmitLoading === 'error' && 'Error in Entered Price'}
                                    </Button>
                                    <Button className="btn_outline_red btn-block"
                                        disabled={(FormSubmitLoading === 'processing')}
                                        data-dismiss="modal" aria-label="Close">Cancel</Button>

                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade primary_modal" id="price_item_modal" tabIndex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="create_item_modalLabel">Follow Steps</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="media approve_media">
                                    {ApproveCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                                    {ApproveCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                                    {ApproveCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                                    {ApproveCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}


                                    <div className="media-body">
                                        <p className="mt-0 approve_text">Approve</p>
                                        <p className="mt-0 approve_desc">Checking balance and approving</p>
                                    </div>
                                </div>
                                <div className="text-center my-3">
                                    <Button className={"primary_btn btn-block"}
                                        disabled={(ApproveCallStatus === 'processing' || ApproveCallStatus === 'done')}
                                        onClick={ApproveCall}
                                    >
                                        {ApproveCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {ApproveCallStatus === 'init' && 'Approve'}
                                        {ApproveCallStatus === 'processing' && 'In-progress...'}
                                        {ApproveCallStatus === 'done' && 'Done'}
                                        {ApproveCallStatus === 'tryagain' && 'Try Again'}

                                    </Button>
                                </div>
                                <div className="media approve_media">
                                    {MintCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                                    {MintCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                                    {MintCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                                    {MintCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                                    <div className="media-body">
                                        <p className="mt-0 approve_text">Listing the NFT</p>
                                        <p className="mt-0 approve_desc">Call contract method</p>
                                    </div>
                                </div>
                                <div className="text-center my-3">
                                    <Button className={"primary_btn btn-block"}
                                        disabled={(ApproveCallStatus !== 'done' || MintCallStatus === 'processing' || MintCallStatus === 'done')}
                                        onClick={priceListFunction}>
                                        {MintCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {MintCallStatus === 'init' && 'Start'}
                                        {MintCallStatus === 'processing' && 'In-progress...'}
                                        {MintCallStatus === 'done' && 'Done'}
                                        {MintCallStatus === 'tryagain' && 'Try Again'}
                                    </Button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            {/* calendar Modal */}
            <div className="modal fade primary_modal" id="calendar_modal" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="pb-3">

                                <Datetime
                                    input={false}
                                    value={Clocktime}
                                    onChange={(value) => set_Clocktime(value)} />
                            </div>
                            <div className="text-center pb-3">
                                <Button className="btn btn_blue" id="doneStartDate" onClick={() => ModalAction('calendar_modal', 'hide')}>Done</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* end calendar modal */}
            {/* calendar Modal */}
            <div className="modal fade primary_modal" id="calendar_modal_expire" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="pb-3">
                                <Datetime
                                    input={false}
                                    value={EndClocktime}
                                    onChange={(value) => set_EndClocktime(value)}
                                />
                            </div>
                        </div>
                        <div className="text-center">
                            <Button className="primary_btn" id="doneEndDate" onClick={() => ModalAction('calendar_modal_expire', 'hide')}>Done</Button>
                        </div>
                    </div>
                </div>
            </div>
            {/* end calendar modal */}
        </>
    )
})

