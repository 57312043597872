/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { Notifications, AccountBalanceWallet, WbSunny, Brightness3 } from '@material-ui/icons';
// react components for routing our app without refresh
import { Link, useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import { Scrollbars } from 'react-custom-scrollbars';
import all from "../../assets/images/all.svg";
//myside 
import Audios from '../../assets/images/audio.png'
import CopyToClipboard from "react-copy-to-clipboard";
import config from '../../lib/config';
import { getSearchList } from "actions/v1/user";
import ConnectWallet from '../../views/separate/Connect-Wallet';
import Avatars from "views/Avatar";
import { notifications, notificationStatusChange } from '../../actions/v1/report';
import {
  getCurAddr
} from '../../actions/v1/user';
import {
  GetCategoryAction
} from '../../actions/v1/token';


import Convert1 from '../../views/separate/Convert1'
import isEmpty from '../../lib/isEmpty'
import moment from 'moment'
import Convert from 'views/separate/Convert';
import Modal from 'react-modal';

import { Account_Connect, Account_disConnect } from '../../actions/redux/action'
import { useSelector, useDispatch } from 'react-redux'

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption
const useStyles = makeStyles(styles);
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
export default function HeaderLinks(props) {

  const classes = useStyles();
  const dispatch = useDispatch()

  const toggletheme = () => {

    document.getElementById("root").classList.toggle('dark_theme');
    var usebody = document.getElementsByClassName("mobile_nav");
    for (var j = 0; j < usebody.length; j++) {
      usebody[j].classList.toggle('dark_theme')
    }


  };
  const toggleUsermenu = () => {
    var useclass = document.getElementsByClassName("usemneu_dd");
    for (var i = 0; i < useclass.length; i++) {
      useclass[i].classList.toggle('d-none')
    }
  }

  const [keyword, setKeyword] = useState();
  const [searchItem, setSearchItem] = useState([])
  const [UsersearchItem, setUserSearchItem] = useState([])
  const [notificationss, setnotificationss] = useState([])

  const history = useHistory();
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [WalletConnected, Set_WalletConnected] = React.useState("false");
  const [Accounts, Set_Accounts] = React.useState('');
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Service_Fee, set_Service_Fee] = useState(0);
  const [Wen_Bln, set_Wen_Bln] = useState(0);
  const [providerss, set_providers] = useState(null)
  const [agePop, setAgePop] = useState(false);
  const [closemodel, setclosemodel] = useState(false);
  const [convertVal, setConvertVal] = React.useState(0);
  const [CategoryOption, setCategoryOption] = useState([]);
  const [TokenImages, setTokenImage] = useState({});


  const Wallet_Details = useSelector(state => state.wallet_connect_context);
  var tab = 'activity';
  const toggleSearchmenu = async (event) => {
    var useclass = document.getElementsByClassName("searchmneu_dd");
    //console.log(">>>>>>>>>>>>>>",event.target.value.length);
    if (event.target.value.length == 1) {
      for (var c = 0; c < useclass.length; c++) {
        useclass[c].classList.remove('d-none');

      }
    }
    if (event.target.value.length == 0) {
      for (var c = 0; c < useclass.length; c++) {
        useclass[c].classList.add('d-none');

      }
    }
    let keywordVal = event.target.value;
    setKeyword(keywordVal)
    let postData = {
      limit: 6,
      keyword: keywordVal
    }
    var data = await getSearchList(postData);
    if (data && data.searchlist && data.searchlist.items && data.searchlist.items.list) {
      setSearchItem(data.searchlist.items.list);
    }
    if (data && data.searchlist && data.searchlist.users) {
      setUserSearchItem(data.searchlist.users);
    }

    //console.log(">>>>>>>>>>>>>>", data.searchlist);
  }

  const seachByKeyword = () => {
    history.push({ pathname: '/Search', search: `?search=${keyword}`, key: Math.floor((Math.random() * 100) + 1) })
    if (window.location.pathname === '/Search')
      location.reload();
  }

  const AfterWalletConnected = async () => {
  }


  const copyText = (a, b) => {
    toast.success('copied', toasterOption);

  }




  const statusChangeactivity = async (data) => {
    var reqdata = {
      currAddr: (Wallet_Details.UserAccountAddr).toLowerCase(),
      tokenCounts: data.tokenCounts,
      _id: data._id
    }
    var noti = await notificationStatusChange(reqdata)
    //////console..log("qweiqwueiqwueiouqeuqw", noti)
  }

  async function GetCategoryCall() {
    var resp = await GetCategoryAction()
    if (resp && resp.data && resp.data.list) {
      var CategoryOption = [];
      var ind = null
      resp.data.list.map((item, index) => {
        ind = ((isEmpty(TokenImages) || TokenImages.activate === false) && String(item.name).toLowerCase() === "film") ? index : -1
        CategoryOption.push({
          name: 'TokenCategory',
          value: item._id,
          label: item.name
        })
      })
      if (ind > -1) {
        delete CategoryOption[ind]
      }
      setCategoryOption(CategoryOption)
    }
  }



  useEffect(() => {
// if(!Wallet_Details.modelpopup)
//     window.$("#agree").modal("show")
GetCategoryCall();
    notification(Wallet_Details.UserAccountAddr)

  }, [Wallet_Details.WalletConnected, Wallet_Details.UserAccountAddr, localStorage.walletConnectType, config.providercon]);
  const closefun = async () => {

    window.$("#agree").modal("hide")
    dispatch({
      type: Account_Connect, Account_Detail: {
        modelpopup:true
      }
    })
  }





  const notification = async (currAddr) => {

    var reqdata = {
      currAddr: currAddr,
      limit: 12
    }
    if (currAddr) {
      var noti = await notifications(reqdata)
      //////console..log("notification val", noti)
      if (noti && noti.data && noti.data.data) {
        setnotificationss(noti.data.data)
      }
    }
  }

  const Disconnect = async () => {
    // alert(1)
    dispatch({ type: Account_disConnect })

    if (localStorage.walletconnect != null && localStorage.walletConnectType == 'wc') {
      await (Wallet_Details.providerss).disconnect()
      localStorage.removeItem('walletconnect')
      localStorage.removeItem('walletConnectType');

    }

    localStorage.removeItem('walletConnectType');
    config.providercon = null
    set_providers(null)
    Set_WalletConnected("false")
    Set_UserAccountAddr('')
    Set_UserAccountBal(0)


  }
  // console.log("gvnijfdhbvij",Wallet_Details);
  function datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  return (
    <>



      <List className={classes.list + " main_navbar"}>

        <ListItem className={classes.listItem + " menu_dropdown dropdown_header_ul user_dd_ul ml-0"}>
          <div className="search_inp_group">
            <input type="text" className="search_inp" placeholder="Search XDCNFT" onChange={toggleSearchmenu} />
            <div className="search_inp_group_append">
              <i className="fas fa-search"></i>
            </div>
          </div>
          <div className="noti_parent noti_parnt_user searchmneu_dd searchmneu_dd_1 d-none" id="searchmneu_dd">
            <Scrollbars style={{ height: 350 }} className="nto_scrol_div">
              {searchItem.length != 0 && <p className="font_we_700">Items</p>}
              <ul className="noti_ul_dd">
                {
                  searchItem && searchItem.map((item) =>
                    <li className="px-3">
                      <div className="media" onClick={() => { history.push(`/info/${item.tokenOwner}/${item.contractAddress}/${item.tokenCounts}`) }}>
                        {
                          (item.image != "" && (
                            String(item.image).split('.').pop() == "mp4" ||
                            (String(item.image).split('.').pop() == "webm") ||
                            (String(item.image).split('.').pop() == "WEBM") ||
                            (String(item.image).split('.').pop() == "ogv") ||
                            (String(item.image).split('.').pop() == "OGV")
                          )) &&
                          <video
                            id="my-video"
                            className="img-fluid mr-2 user_ul_new align-self-center"
                            autoPlay playsInline loop muted
                            preload="auto"
                          >
                            <source src={item.additionalImage ? (item.additionalImage == "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`) : `${config.IPFS_IMG}/${item.ipfsimage}`} type="video/mp4" />

                            {/* <source src={item.ipfsimage!=""?`${config.IPFS_IMG}/${item.ipfsimage}`:`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`} type="video/mp4" /> */}
                          </video>}

                        {
                          (item.image != "" &&
                            (String(item.image).split('.').pop() == "mp3" || String(item.image).split('.').pop() == "aac" || String(item.image).split('.').pop() == "AAC" || String(item.image).split('.').pop() == "FLAC" || String(item.image).split('.').pop() == "flac")) &&
                          <>
                            <img src={config.AudioImg} className="img-fluid" />


                            <audio
                              muted
                              className="img-fluid mr-2 user_ul_new align-self-center"
                              playsInline loop 
                              type="audio/mp3"
                              autostart="off"
                              src={item.additionalImage ? (item.additionalImage == "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`) : `${config.IPFS_IMG}/${item.ipfsimage}`}

                            // src={item.ipfsimage!=""?`${config.IPFS_IMG}/${item.ipfsimage}`:`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`} 
                            >

                            </audio>
                          </>
                        }

                        {
                          (item.image != "" && (
                            String(item.image).split('.').pop() == "webp"
                            || String(item.image).split('.').pop() == "WEBP"
                            || String(item.image).split('.').pop() == "gif"
                            || String(item.image).split('.').pop() == "jpg"
                            || String(item.image).split('.').pop() == "GIF"
                            || String(item.image).split('.').pop() == "JPG"
                            || String(item.image).split('.').pop() == "JPEG"
                            || String(item.image).split('.').pop() == "jpeg"
                            || String(item.image).split('.').pop() == "png"
                            || String(item.image).split('.').pop() == "PNG") &&
                            <img
                              src={item.additionalImage ? (item.additionalImage == "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`) : `${config.IPFS_IMG}/${item.ipfsimage}`}

                              //  src={item.ipfsimage!=""? `${config.IPFS_IMG}/${item.ipfsimage}`:`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                              alt="Collections" className="img-fluid mr-2 user_ul_new align-self-center" />
                          )}
                        <div className="media-body flex_body">
                          <div>
                            <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc mr-1">{item.tokenName}</p>
                            <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc mr-1">
                              {(
                                item
                                && item.tokenowners_current
                                && item.tokenowners_current.tokenPrice > 0)
                                && <span>{item.tokenowners_current.tokenPrice} {item.tokenowners_current.coinName}   </span>}
                              {(

                                item
                                && item.clocktime == null
                                && item.endclocktime == null
                                && item.tokenowners_current
                                && (item.tokenowners_current.tokenPrice == 0 || item.tokenowners_current.tokenPrice == null)
                                && <span> Open for Bid </span>)}

                              {
                                item
                                && item.clocktime != null
                                && item.endclocktime != null
                                && item.minimumBid
                                && item.minimumBid != 0
                                && <span>{item.minimumBid} {config.tokenSymbol}   </span>}

                            </p>
                          </div>
                        </div>
                      </div>

                    </li>

                  )}

              </ul>

              {UsersearchItem.length != 0 && <p className="font_we_700">Users</p>}

              <ul className="noti_ul_dd">
                {
                  UsersearchItem && UsersearchItem.map((searchUser) =>

                    <li className="px-3" onClick={() => history.push(searchUser.customurl != "" ? `/${searchUser.customurl}` : `/user/${searchUser.curraddress}`)}>
                      <div className="media">
                        {searchUser && searchUser.image != "" ?
                          <img src={`${config.Back_URL}/images/${searchUser._id}/${searchUser.image}`} alt="User" className="img-fluid mr-2 user_ul_new align-self-center" />
                          :

                          <Avatars item="img-fluid mr-2 user_ul_new align-self-center" />

                        }
                        {/* <img src={require("../../assets/images/collections_02.png")} alt="User" className="img-fluid mr-2 user_ul_new align-self-center" /> */}
                        <div className="media-body flex_body">
                          <div>
                            <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc mr-1">{searchUser.name}</p>
                            {/* <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc">{((searchUser.count!=0)?searchUser.count:0)+'  Followers'} </p> */}
                          </div>

                        </div>
                      </div>

                    </li>
                  )}
              </ul>

            </Scrollbars>
            <div className="text-center">
              <button className="btn new_btn_grad" tabIndex="0" type="button" onClick={() => { seachByKeyword() }}><span><Link to='/search'>Search</Link></span></button>
            </div>
          </div>

        </ListItem>


        <ListItem className={classes.listItem + " menu_dropdown dropdown_header_ul noti_ul exploreLinks"}>
        <CustomDropdown
          noLiPadding
          buttonText={ <div className="noti_online align-items-center">
          Explore </div>}        
          dropdownList={[
            <div className="noti_parent">
           
            <Scrollbars className="nto_scrol_div">
            <ul className="noti_ul_dd">
            <li className="px-3">
            <div>
              
                  <Link to={`/explore/All`}>
                  <span>View All</span>
                </Link>
                </div>
              </li>
            {/* {console.log("fndkeswbfvkhrdwbf",CategoryOption)} */}
                {CategoryOption.length > 0 && CategoryOption.map((itempro) => {
                    return (
              <li className="px-3">
               
              <div className="media">
              {/* <img src={all} alt="..." className="img-fluid mr-2 img_user_noti align-self-center"/> */}
                  {/* <img src={require("../../assets/images/eye.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" /> */}
                
                  <div className="media-body">
                  <Link to={`/explore/${itempro.label}`}>
                    <div>
                   
                    <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">{itempro.label}</p>
              
                    </div>
                    </Link>
                  </div>
                      
                </div>
            
               
              </li>
                 )
                })}
              {/* <li className="px-3">
              <div className="media">
              
                  <Link to={`/explore/All`}>
                  <span>All NFTs</span>
                </Link>
               
              </li>
              <li className="px-3">
              <div className="media">
              
                  <img src={require("../../assets/images/art.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                  <div className="media-body flex_body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Art</p>
                    
                    </div>
                   
                  </div>
                </div>
               
              </li>
              <li className="px-3">
              <div className="media">
              
                  <img src={require("../../assets/images/music.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                  <div className="media-body flex_body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">Music</p>
                    
                    </div>
                    
                  </div>
                </div>
               
              </li>
              <li className="px-3">
              <div className="media">
              
                  <img src={require("../../assets/images/photography.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                  <div className="media-body flex_body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">Photography</p>
                    
                    </div>
                    
                  </div>
                </div>
               
              </li>
              <li className="px-3">
              <div className="media">
              
                  <img src={require("../../assets/images/ticket.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                  <div className="media-body flex_body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">Tickets</p>
                    
                    </div>
                    
                  </div>
                </div>
               
              </li>
              <li className="px-3">
              <div className="media">
              
                  <img src={require("../../assets/images/qr-code-scan.png")} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                  <div className="media-body flex_body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc">E-codes</p>
                    
                    </div>
                    
                  </div>
                </div>
               
              </li> */}
            </ul>
            </Scrollbars>
            <p className="no_noti d-none">No new notifications</p>
          
           
            </div>
          ]}
        />
      </ListItem>
        <ListItem className={classes.listItem}>
          <Link className={classes.navLink} to="/about">About</Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link className={classes.navLink} to="/activity">Activity</Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link className={classes.navLink} to="/how-it-works">FAQ</Link>
        </ListItem>
{/* 
        <ListItem className={classes.listItem}>
          <button onClick={() => SwitchChain()}>ADD RPCURL</button>
        </ListItem> */}

        {/* <ListItem className={classes.listItem}>
        <Link className={classes.navLink} to="/my-items">My items</Link>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Link className={classes.navLink} to="/following">Following</Link>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Link className={classes.navLink} to="/activity">Activity</Link>
      </ListItem> */}



        <ListItem className={classes.listItem}>
          <Link to="/create"> <Button className={classes.navLink + " create_btn"}>
            Create
          </Button></Link>
        </ListItem>
        {
          // config.providercon!=null&&
          // WalletConnected == "true" &&
          Wallet_Details.UserAccountAddr != "" &&
          <ListItem className={classes.listItem + " menu_dropdown dropdown_header_ul noti_ul noti_tb_trans"}>
            <CustomDropdown
              noLiPadding
              buttonText={<div><Notifications className="menu_icons" />
                <span className="icon_txt">Notifications</span>
                {notificationss.map((item) => {
                  return (
                    item.statusOpen == "new" &&
                    <span className="green_circle_dot"></span>
                  )
                })}
              </div>}
              dropdownList={[
                <div className="noti_parent_class">
                  <p className="noti_head">Notifications</p>
                  <Scrollbars style={{ height: 210 }}>
                    <ul className="noti_ul_dd">
                      {
                        notificationss.length != 0 ?
                          notificationss.map((item) => {
                            return (
                              <li className="px-3" onClick={() => statusChangeactivity(item)}>

                                <div className="media">
                                  {item.to &&
                                    <>
                                      {
                                        item
                                        && item.touserField
                                        && item.touserField.image
                                        && item.touserField.image != ""
                                        && <img onClick={() => history.push(item.touserField.customurl != "" ? `/:${item.touserField.customurl}` : `/user/${item.to}`)} src={`${config.Back_URL}/images/${item.touserField._id}/${item.touserField.image}`} alt="User" className="img-fluid mr-2 img_user_noti align-self-center" />
                                      }
                                      {
                                        item.touserField === undefined
                                        || item.touserField.image === undefined
                                        || item.touserField.image == ""
                                        && <Avatars onClick={() => history.push(item.touserField.customurl != "" ? `/:${item.touserField.customurl}` : `/user/${item.to}`)} item="img-fluid mr-2 img_user_noti align-self-center" />
                                      }
                                    </>}
                                  <div className="media-body flex_body">
                                    <div>
                                      <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc not_banner_dessc_new mr-1">

                                        {item.to
                                          && <span title={"User :  " + ((item.touserField && item.touserField.name != "") ? item.touserField.name : String(item.to))} className="user_noti_colr mr-1" onClick={() => history.push(item.touserField && item.touserField.customurl != "" ? `/${item.touserField.customurl}` : `/user/${item.to}`)}>
                                            @{(item.touserField && item.touserField.name != "") ? item.touserField.name : String(item.to).slice(0, 6).concat('...')}</span>
                                        }

                                        <span onClick={() => history.push(`/info/${item.tokenOwner}/${item.contractAddress}/${item.tokenCounts}`)}>{item.tokenField && item.tokenField.tokenName != "" && item.tokenField.tokenName}</span> 	{item.activity}
                                        <span onClick={() => history.push(item.userField && item.userField.customurl != "" ? `/${item.userField.customurl}` : `/user/${item.from}`)} className="user_noti_colr mr-1" title={"Token Owner : " + ((item.userField && item.userField.name != "") ? item.userField.name : String(item.from))}
                                        > @{(item.userField && item.userField.name != "") ? item.userField.name : String(item.from).slice(0, 6).concat('...')}</span></p>
                                      <p className="mt-0 banner_user font_10 mb-0 banner_user_new mr-1">
                                        {moment(item.created).format('MMMM Do YYYY, h:mm a')}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                              </li>
                            )
                          })
                          :
                          <p className="no_noti">No new notifications</p>

                      }

                    </ul>
                  </Scrollbars>


                </div>
              ]}
            />
          </ListItem>}

        {((
          Wallet_Details.UserAccountAddr == ""
          // WalletConnected == "false" 
          // WalletConnected == "false"
          // config.providercon==null
        )

          // && UserAccountAddr == ""
        ) ?
          <ListItem className={classes.listItem}>
            <Button className={classes.navLink + " create_btn headercntbtn"} data-toggle="modal" data-target="#connect_modal">
              <div >Connect Wallet</div>
            </Button>
          </ListItem>
          :
          (<ListItem className={classes.listItem + " menu_dropdown dropdown_header_ul user_dd_ul"} onClick={toggleUsermenu}>
            <Button color="transparent" className={classes.navLink + " pl-cust-wal ml-0"}>
              <AccountBalanceWallet className="menu_icons" />
              <span className="icon_txt">Wallet</span>
            </Button>
            <div className="noti_parent noti_parnt_user usemneu_dd d-none" id="usemneu_dd">
              <p className="noti_head pt-4 mb-0">
                <span title={Wallet_Details.UserAccountAddr && Wallet_Details.UserAccountAddr != "" && (Wallet_Details.UserAccountAddr)}>{Wallet_Details.UserAccountAddr && Wallet_Details.UserAccountAddr != "" && (Wallet_Details.UserAccountAddr).substring(0, 8).concat('.....')}
                  <CopyToClipboard text={Wallet_Details.UserAccountAddr} onCopy={() => copyText('invite link', Wallet_Details.UserAccountAddr)}>

                    <i className="fas fa-sticky-note notes_fa cur_pointer"></i>
                  </CopyToClipboard>
                </span>

              </p>
              <div className="px-2">
                {/* <p className="info_des">oxc4c16ab5ac7d...b21a<i className="fas fa-sticky-note notes_fa cur_pointer"></i></p> */}
                <div className="media header_media pt-3">
                  <img src={require("../../assets/images/BNB.png")} alt="User" className="img-fluid mr-3 coin_header" />
                  <div className="media-body flex_body">
                    <div>
                      <p className="mt-0 media_num">{config.currencySymbol} Balance</p>
                      <p className="balance_txt_header pt-0 mb-0">
                        {/* <span>{Wallet_Details.UserAccountBal

                          && (Wallet_Details.UserAccountBal != "" || Wallet_Details.UserAccountBal != 0) ?
                          <> {Number(Wallet_Details.UserAccountBal).toFixed(config.toFixed)} {config.currencySymbol}
                            ( $ <Convert1
                              item={Wallet_Details.UserAccountBal}
                              convertVal={Wallet_Details.convertVal}
                            />)
                          </>
                          : <>
                           $( {Wallet_Details.tokenAddress.XDC_Currency_USD * Wallet_Details.UserAccountBaltokenPrice})
                          </>
                        }
                        </span> */}
                        <span>
                          <p class="tokendetaiss det">XDC{Wallet_Details.UserAccountBal} (${(Wallet_Details.UserAccountBal * Wallet_Details.tokenAddress.XDC_Currency_USD)})</p>
                        </span>
                      </p>

                    </div>

                  </div>
                </div>
                <div className="media header_media pt-3">
                  <img src={require("../../assets/images/Wen.png")} alt="User" className="img-fluid mr-3 coin_header" />
                  <div className="media-body flex_body">
                    <div>
                      <p className="mt-0 media_num">{config.tokenSymbol} Balance</p>
                      <p className="balance_txt_header pt-0 mb-0">
                        {/* <span>{Wallet_Details.Wen_Bln
                          && (Wallet_Details.Wen_Bln != "" || Wallet_Details.Wen_Bln != 0) ?

                          <>  <Convert
                            item={Number(Wallet_Details.Wen_Bln)}
                            convertVal={1}
                            coinName={'WENLAMBO'}
                          />
                            {config.tokenSymbol}
                            ($ <Convert1
                              item={Number(Wallet_Details.Wen_Bln)}
                              convertVal={Number(config.WenlamboConvertVal)}
                            /> USD)
                          </>
                          : <> 0 WENLAMBO ( $ 0 USD) </>
                        }

                        </span> */}
                        <span>
                          <p class="tokendetaiss det">XDC{Wallet_Details.Wen_Bln} (${(Wallet_Details.Wen_Bln * Wallet_Details.tokenAddress.BiddingToken_USD)})</p>
                        </span>
                      </p>

                    </div>

                  </div>
                </div>
                <ul className="user_prof_ul mt-4">
                  <li>
                    <Link to="/edit-profile"><span><i className="fas fa-user mr-2"></i>My profile</span></Link>
                  </li>
                  <li>
                    <Link to="/my-items"><span><i className="fas fa-file-image mr-2"></i>My items</span></Link>
                  </li>
                  <li>
                <Link to="/addCollections"><span><i className="fas fa-file-image mr-2"></i>Create Collection</span></Link>
                </li>

                  <li>
                    <div onClick={Disconnect}><Link to="/"><span><i className="fas fa-sign-out-alt mr-2"></i>Disconnect</span></Link></div>
                  </li>
                </ul>

              </div>




            </div>

          </ListItem>
          )}
        <ListItem className={classes.listItem + " create_btn mode"}>
          <Button className={classes.navLink} onClick={toggletheme}>
            <p className="light"><Brightness3 /></p>
            <p className="dark"><WbSunny /></p>
          </Button>
        </ListItem>
      </List>

    </>);
}
