import React from "react";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import config from '../../lib/config';
import isEmpty from '../../lib/isEmpty';
import Avatars from "views/Avatar";
import Convert from 'views/separate/Convert'
import Convert1 from '../separate/Convert1'
import Audios from '../../assets/images/masonary_02.png'
import { useSelector } from "react-redux";

import LazyLoad from 'react-lazyload';
import LazyLoader from "../lazyloader";

export default function TokenCard(props) {
    async function showAllwithPro(data) {
    }

    const Wallet_Details = useSelector(state => state.wallet_connect_context);

    var {
        item,
        LikedTokenList,
        hitLike,
        UserAccountAddr,
        PutOnSale_Click,
        PurchaseNow_Click,
        Burn_Click,
        CancelOrder_Click,
        WalletConnected,
        ShareSocial_Click,
        SubmitReport_Click,
    } = props;
    const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span></span>
        } else {
            return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
        }
    };
    const checkerror = (e, item) => {
        e.target.src = `${config.IPFS_IMG}/${item.ipfsimage}`
    }
    return (
        (item.tokenowners_current) ?
            <div className="item itemd_heih_adj">
                <div className="card_inner_item">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex creators_details mb-2">
                            {/* collection */}
                            {!isEmpty(item.usercontract) && item.usercontract.conAddr ?
                                <img src={`${config.Back_URL}/userContract/${item.usercontract.conAddr}/${item.usercontract.imageUser}`} alt="Collection" className="img-fluid align-self-center" />
                                :
                                <img src={config.Lod} alt="Collection" className="img-fluid align-self-center" title={"Collection : " + config.exchangeAddress} />
                            }
                            {/*creator  */}
                            {
                                item
                                && item.tokenCreatorInfo
                                &&
                                <Link to={item.tokenCreatorInfo.customurl !== "" ? `/${item.tokenCreatorInfo.customurl}` : `/user/${item.tokenCreatorInfo.curraddress}`} data-toggle="tooltip" data-placement="top" title={`Creator : ${item.tokenCreatorInfo.name !== "" ? item.tokenCreatorInfo.name : item.tokenCreatorInfo.curraddress}`}>
                                    {item.tokenCreatorInfo.image !== "" ? <img src={`${config.Back_URL}/images/${item.tokenCreatorInfo._id}/${item.tokenCreatorInfo.image}`} alt="Creator" className="img-fluid align-self-center" /> :
                                        <div className="img-fluid align-self-center">
                                            <Avatars item="img-fluid align-self-center" />
                                        </div>}

                                </Link>

                            }
                            {/* <p>dsd{item.tokenCreatorInfo.customurl}</p> */}
                            {/* owner */}
                            {
                                item
                                // && item.type == 1155
                                && item.tokenuser
                                && !isEmpty(item.tokenuser)
                                && item.tokenowners_current_count
                                && item.tokenowners_current_count.count
                                &&
                                (item.tokenuser.image && item.tokenuser.image !== ''
                                    ?
                                    <Link to={item.tokenuser.customurl !== "" ? `/${item.tokenuser.customurl}` : `/${item.tokenuser.curraddress}`} title={`Owner : ${item.tokenuser.name}`}>
                                        <img src={`${config.Back_URL}/images/${item.tokenuser._id}/${item.tokenuser.image}`} alt="Owner" className="img-fluid align-self-center" />
                                    </Link>
                                    :
                                    <Link to={`/user/${item.tokenowners_current.tokenOwner}`} title={`Owner : ${item.tokenowners_current.tokenOwner}`}>
                                        <div className="img-fluid align-self-center">
                                            <Avatars item="img-fluid align-self-center" />
                                        </div>
                                    </Link>)
                            }
                        </div>
                        <div>
                            {item.tokenowners_current.balance > 0 &&
                                <div className="dropdown">
                                    <button className="drop-down" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        ....
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        {(Wallet_Details.WalletConnected === "true"
                                            && item.tokenowners_current
                                            && item.tokenowners_current.tokenOwner
                                            && (item.tokenowners_current.tokenPrice === 0 || item.tokenowners_current.tokenPrice == null)
                                            && item.tokenowners_current.tokenOwner === Wallet_Details.UserAccountAddr)
                                            && (item.tokenowners_current.endclocktime == null || (new Date(item.tokenowners_current.endclocktime) < Date.now())) &&
                                            <div className="dropdown-item" id={"editnotshow" + item.tokenCounts}>
                                                <span onClick={() => PutOnSale_Click(item, item.tokenuser, item.tokenowners_current)}>
                                                    Put on sale</span>
                                            </div>
                                        }
                                        {
                                            (Wallet_Details.WalletConnected === "true"
                                                && item.tokenowners_current
                                                && item.tokenowners_current.tokenOwner
                                                && item.tokenowners_current.tokenOwner === Wallet_Details.UserAccountAddr)
                                            &&
                                            item.tokenowners_current.tokenPrice > 0
                                            &&
                                            // &&(0||0||0)&&
                                            <div className="dropdown-item" id={"editnotshow" + item.tokenCounts}>
                                                <span onClick={() => PutOnSale_Click(item, item.tokenuser, item.tokenowners_current)}>
                                                    Lower Price
                                                </span>
                                            </div>
                                        }
                                        {(Wallet_Details.WalletConnected === "true" && item.tokenowners_current && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner === Wallet_Details.UserAccountAddr) && (item.tokenowners_current.tokenPrice > 0) &&
                                            <div className="menu_itm dropdown-item" id={"cancelorder" + item.tokenCounts}>
                                                <span onClick={() => CancelOrder_Click(item, item.tokenuser, item.tokenowners_current)}>Cancel Order</span>
                                            </div>
                                        }
                                        {(Wallet_Details.WalletConnected === "true" && item.tokenowners_current && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner === Wallet_Details.UserAccountAddr) &&
                                            <div className="menu_itm dropdown-item" id={"BurnCollectible" + item.tokenCounts}>
                                                <span onClick={() => Burn_Click(item, item.tokenowners_current)}>Burn</span>
                                            </div>
                                        }

                                        {(Wallet_Details.WalletConnected === "true" && item.tokenowners_current && (item.tokenowners_current.tokenPrice > 0) && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner !== UserAccountAddr) &&
                                            <div className="menu_itm dropdown-item">
                                                <span onClick={() => PurchaseNow_Click(item, item.tokenowners_current)}>Purchase Now</span>
                                            </div>
                                        }
                                        {(item.tokenowners_current && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner !== Wallet_Details.UserAccountAddr) &&
                                            <div className="menu_itm dropdown-item" data-toggle="modal" data-target="#report_modal" onClick={() => SubmitReport_Click(item, item.tokenowners_current)}>
                                                <span>Report</span>
                                            </div>
                                        }

                                        {(item.tokenowners_current && item.tokenowners_current.tokenOwner) &&
                                            <div className="menu_itm dropdown-item" id={"BurnCollectible" + item.tokenCounts} data-toggle="modal" data-target="#share_modal" onClick={() => ShareSocial_Click(item, item.tokenowners_current)}>
                                                <span>Share</span>
                                            </div>
                                        }

                                    </div>
                                </div>
                            }</div>
                    </div>

                    <div className="remaintime">
                        <div className="item_inner_img">
                            <Link to={"/info/" + item.tokenOwner + '/' + item.contractAddress + '/' + item.tokenCounts}>
                                <LazyLoad height={200} placeholder={<LazyLoader />} offset={[-200, 0]} debounce={500}>
                                    <> {
                                        (item.image !== "" &&
                                            ((String(item.image).split('.').pop() === "mp4") ||
                                                (String(item.image).split('.').pop() === "webm") ||
                                                (String(item.image).split('.').pop() === "WEBM") ||
                                                (String(item.image).split('.').pop() === "ogv") ||
                                                (String(item.image).split('.').pop() === "OGV")
                                            )) &&
                                        <video
                                            id="my-video"
                                            className="img-fluid"
                                            autoPlay playsInline loop muted
                                            preload="auto"
                                            alt="video"
                                        >
                                            <source
                                                src={(item.additionalImage ? (item.additionalImage === "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`) : `${config.IPFS_IMG}/${item.ipfsimage}`)}
                                                type="video/mp4" />
                                        </video>}

                                        {
                                            item.image !== "" && (String(item.image).split('.').pop() === "mp3" || String(item.image).split('.').pop() === "aac" || String(item.image).split('.').pop() === "AAC" || String(item.image).split('.').pop() === "FLAC" || String(item.image).split('.').pop() === "flac") &&
                                            <>
                                                <img src={config.AudioImg} className="img-fluid" alt="text" />
                                                <audio controls controlsList="nodownload"
                                                    poster={'assets/images/audio.png'}
                                                    alt='audio'
                                                    playsInline loop muted
                                                    type="audio/mp3"
                                                    autostart="off"

                                                    src={(item.additionalImage ? (item.additionalImage === "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`) : `${config.IPFS_IMG}/${item.ipfsimage}`)}
                                                >
                                                </audio></>
                                        }

                                        {
                                            item.image !== ""
                                            && (String(item.image).split('.').pop() === "webp" || String(item.image).split('.').pop() === "WEBP" || String(item.image).split('.').pop() === "gif" || String(item.image).split('.').pop() === "jpg" || String(item.image).split('.').pop() === "GIF" || String(item.image).split('.').pop() === "JPG" || String(item.image).split('.').pop() === "JPEG" || String(item.image).split('.').pop() === "jpeg" || String(item.image).split('.').pop() === "png" || String(item.image).split('.').pop() === "PNG")
                                            &&
                                            <img
                                                src={(item.additionalImage ? (item.additionalImage === "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`) : `${config.IPFS_IMG}/${item.ipfsimage}`)}
                                                onError={(e) => checkerror(e, item)}
                                                // src={item.ipfsimage!=""? `${config.IPFS_IMG}/${item.ipfsimage}`:`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`} 
                                                alt="Collections" className="img-fluid " />
                                        }

                                    </>
                                </LazyLoad>
                            </Link>
                        </div>


                        <div className="remaintime">
                            {(item && item.tokenowners_current && item.tokenowners_current.clocktime && item.tokenowners_current.clocktime != null && item.tokenowners_current.endclocktime && item.tokenowners_current.endclocktime != null) ?

                                <>

                                    <badge className="badge badge-dark badge-timer">
                                        {new Date(item.tokenowners_current.endclocktime) > Date.now() ?
                                            <>
                                                <Countdown
                                                    // date={Date.now()+100000000000}
                                                    date={new Date(item.tokenowners_current.endclocktime)}
                                                    autoStart={true}
                                                    onStart={() => new Date(item.tokenowners_current.clocktime)}
                                                    renderer={renderer}
                                                >
                                                </Countdown>
                                                <i className="fas fa-fire ml-2"></i>
                                            </>

                                            : <span>Auction Completed</span>}
                                    </badge>


                                </>

                                : ('')}
                        </div>
                    </div>
                    <Link to={"/info/" + item.tokenOwner + '/' + item.contractAddress + '/' + item.tokenCounts} className="nameimg">
                        <div>
                            <h2>{item.tokenName.slice(0, 20).concat('...')} </h2>
                            <h2 className="highsedbit">In stock : <span> {item && item.tokenowners_current && item.tokenowners_current.balance} </span></h2>
                        </div>
                        {/* <img src={Audios}    className="img-fluid" alt="test"/> */}
                    </Link>
                    {/* {item.tokenowners_current.tokenOwner === Wallet_Details.UserAccountAddr && <h4 className="text-left text_blck_wen">My Collections</h4>} */}
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h3 className="mb-0" >
                                {(

                                    item
                                    && item.tokenowners_current
                                    && item.tokenowners_current.tokenPrice
                                    && (item.tokenowners_current.tokenPrice !== 0 || item.tokenowners_current.tokenPrice != null))
                                    ? <span>
                                        <Convert
                                            item={Number(item.tokenowners_current.tokenPrice)}


                                            coinName={item.tokenowners_current.CoinName}

                                            convertVal={1}
                                        />
                                        {item.tokenowners_current.CoinName }

                                        <>
                                            {/* ($</> <Convert1 
                                    item  ={Number(item &&item.tokenowners_current && item.tokenowners_current.tokenPrice)}
                                    convertVal={
                                        (String(item.tokenowners_current.CoinName).toLowerCase()===String(config.tokenSymbol).toLowerCase())?config.WenlamboConvertVal:Wallet_Details.currency_convertion}
                                    /><>) */}
                                            ($ {item.tokenowners_current.CoinName == "BLKZ" ? (Wallet_Details.tokenAddress.BiddingToken_USD * item.tokenowners_current.tokenPrice).toFixed(6) :(Wallet_Details.tokenAddress.XDC_Currency_USD * item.tokenowners_current.tokenPrice).toFixed(6)})

                                        </>
                                    </span> : null}
                                {
                                    item
                                    && item.tokenowners_current.clocktime != null
                                    && item.tokenowners_current.endclocktime != null
                                    && item.tokenowners_current.minimumBid
                                    && item.tokenowners_current.minimumBid !== 0

                                    && <span>
                                        <Convert
                                            item={Number(item.tokenowners_current.minimumBid)}


                                            coinName={'BLKZ'}
                                            convertVal={1}
                                        />

                                        {config.tokenSymbol }
                                        {/* ($ <Convert1 
                                    item={Number(item.tokenowners_current.minimumBid)}
                                    convertVal={config.WenlamboConvertVal}
                                    />) */}
                                        ($ {Wallet_Details.tokenAddress.BiddingToken_USD * item.tokenowners_current.minimumBid})
                                    </span>}

                                {/* of {item&&item.tokenowners_current&&item.tokenowners_current.quantity} */}

                            </h3>
                        </div>
                        <div className="masonry_likes" onClick={() => hitLike(item)}>

                            {/* <i className="far fa-heart ml-1 liked"></i> */}
                            {
                                (LikedTokenList.findIndex(tokenCounts => (tokenCounts.tokenCounts === item.tokenCounts)) > -1) ?
                                    (<i className="fas fa-heart mr-1 liked"></i>) :
                                    (<i className="far fa-heart mr-1"></i>)
                            }
                            <span className={item.tokenCounts + '-likecount'}>{item.likecount}</span>
                        </div>
                    </div>

                </div></div>



            : ('')
    )
}