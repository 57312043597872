/*eslint-disable*/
import React, { useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FooterLogo from "../../assets/images/footer_logo.png";
import axios from 'axios'
import config from '././../../lib/config'
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import { Account_Connect, Account_disConnect } from '../../actions/redux/action'
import { useSelector, useDispatch } from 'react-redux'
import { subscriberAction } from "actions/v1/token";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import { sociallinksfunction } from '../../actions/v1/report';
import { Link, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';





// mysde
import ConnectWallet from "views/separate/Connect-Wallet";
const useStyles = makeStyles(styles);

export default function Footer(props) {

  const classes = useStyles();


  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  const Wallet_Details = useSelector(state => state.wallet_connect_context);

  const [sociallinks, setsociallinks] = useState({})
  const [Footer_Link, Set_Footer_link] = useState([])
  const [UserAccountAddr, Set_UserAccountAddr] = useState('');
  const [UserAccountBal, Set_UserAccountBal] = useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = useState('');
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = useState('');
  const [Wen_Bln, set_Wen_Bln] = useState(0);
  const [Service_Fee, set_Service_Fee] = useState(0);
  const [WalletConnected, Set_WalletConnected] = useState('false');
  const [providerss, set_providers] = useState(null)
  const [convertVal, setConvertVal] = React.useState(0);



  const [mail, setmail] = useState('');
  // useEffect(() => {
  //   if(!Wallet_Details.modelpopup)
  //       window.$("#agree").modal("show")
    
  //       // notification(Wallet_Details.UserAccountAddr)
    
  //     }, [Wallet_Details.WalletConnected, Wallet_Details.UserAccountAddr, localStorage.walletConnectType, config.providercon]);

  useEffect(() => {
   

    sociallinksfunct()
    CategoryListCall()
  }, [Wallet_Details.WalletConnected, Wallet_Details.UserAccountAddr, localStorage.walletConnectType, config.providercon])


  const AfterWalletConnected = () => {

  }
  // const closefun = async () => {

  //   window.$("#agree_modal111").modal("hide")
  //   dispatch({
  //     type: Account_Connect, Account_Detail: {
  //       modelpopup:true
  //     }
  //   })
  //   localStorage.removeItem('agree_modal111')
  // }

  const mailstate = (e) => {
    //console.log("Mialllll",e.target.value)
    setmail(e.target.value)
  }

  const newmailadd = async () => {
    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;
    if (mail != "") {
     if (!emailRegex.test(mail)) {
       return toast.error("Enter valid email")
     }
   }
    // console.log("mail>>>>",mail);
    var newmailData = await subscriberAction({ email: mail });
    if (newmailData && newmailData.data && newmailData.data && newmailData.data.success && newmailData.data.success == true) {
      toast.success(newmailData.data.message);
      setmail('');
    }
    // console.log(">>>>newmailData",newmailData);
  }




  const sociallinksfunct = async () => {
    var soci = await sociallinksfunction()
    // ////////console.log("soci",soci.data.soci)
    if (soci !== undefined && soci.data.soci !== undefined) {
      setsociallinks(soci.data.soci)
    }
    else {

      setsociallinks({})
    }
  }
  async function CategoryListCall() {
    axios
      .get(`${config.vUrl}/token/category/list`)
      .then(response => {
        ////////console.log('response', response)
        if (response && response.data && response.data.list) {
          Set_Footer_link(response.data.list);
        }
      })
      .catch(e => console.log(e))
  }
  const toggleUsermenu = () => {
    var useclass = document.getElementsByClassName("usemneu_dd");
    for (var i = 0; i < useclass.length; i++) {
      useclass[i].classList.toggle('d-none')
    }
  }

  const tabSelection = (data) => {
    // alert(1)
    // window.$('.nav-tabs a[href="#' + sata + '"]').tab('show');
    window.document.getElementById(data + "tab").click()
  }
  var location = useLocation();
  ////console.log("iowioiewroewirooewiorie",location)
  return (
    <>
      {/* <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        set_Service_Fee={set_Service_Fee}
        Service_Fee={Service_Fee}
        AfterWalletConnected={AfterWalletConnected}
        Wen_Bln={Wen_Bln}
        set_Wen_Bln={set_Wen_Bln}
        setConvertVal={setConvertVal}
        convertVal={convertVal}
        set_providers={set_providers}
        providerss={providerss}
      /> */}

   

      <footer className={footerClasses}>

        <div className="container-fluid container-theme container-lg">
          <img src={FooterLogo} alt="XDCNFT" />
          <div className="footflex">
            <div className={classes.left + " footer_left" + " notowe"}>

              <div>
                <h4 className="text-left">XDCNFT</h4>

                <List className={classes.list + " footer_links"}>
                  <ListItem className={classes.inlineBlock}>
                    <a href="#explore" className={classes.block}><Link to="/explore/All">Get Started</Link></a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a href="#explore" className={classes.block}><Link to="/how-it-works">How it works</Link></a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>

                    <a href="#explore" className={classes.block}><Link to="/community">Community</Link></a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a href="#explore" className={classes.block}><Link to="/privacy-policy">Privacy Policy</Link></a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a href="#explore" className={classes.block}><Link to="/terms-and-conditions">Terms of Service</Link></a>
                  </ListItem>
                </List>

              </div>


              <div className="">
                <h4 className="text-left">Social</h4>

                <List className={classes.list + " footer_links"}>
                  {
                    sociallinks && sociallinks.Twitter && sociallinks.Twitter != "" &&
                    <ListItem className={classes.inlineBlock}>
                      <a href={sociallinks && sociallinks.Twitter && (sociallinks.Twitter != "" ? sociallinks.Twitter : "#")} className={classes.block}>Twitter</a>
                    </ListItem>
                  }
                  {
                    sociallinks && sociallinks.Discord && sociallinks.Discord != "" &&
                    <ListItem className={classes.inlineBlock}>
                      <a href={sociallinks && sociallinks.Discord && (sociallinks.Discord != "" ? sociallinks.Discord : "#")} className={classes.block}>Discord</a>
                    </ListItem>
                  }
                  {
                    sociallinks && sociallinks.Telegram && sociallinks.Telegram != "" &&
                    <ListItem className={classes.inlineBlock}>
                      <a href={sociallinks && sociallinks.Telegram && (sociallinks.Telegram != "" ? sociallinks.Telegram : "#")} className={classes.block}>Telegram</a>
                    </ListItem>
                  }
                  {sociallinks && sociallinks.Reddit && sociallinks.Reddit != "" &&
                    <ListItem className={classes.inlineBlock}>
                      <a href={sociallinks && sociallinks.Reddit && (sociallinks.Reddit != "" ? sociallinks.Reddit : "#")} className={classes.block}>Reddit</a>
                    </ListItem>
                  }
                  {
                    sociallinks && sociallinks.Instagram && sociallinks.Instagram != "" &&
                    <ListItem className={classes.inlineBlock}>
                      <a href={sociallinks && sociallinks.Instagram && (sociallinks.Instagram != "" ? sociallinks.Instagram : "#")} className={classes.block}>Instagram</a>
                    </ListItem>
                  }
                </List>
              </div>

            </div>

            <div className={classes.right + " footer_right new_des_footer pb-0"}>
              <div>
                <h4 className="text-left">Newsletter</h4>
                <p className="text-left my-3">Stay up to date on latest news,drops from XDCNFT Community</p>
                <div className="newsletter_grp">
                  <input type="email" className="newsletter_inp" placeholder="Your Email Address" onChange={mailstate}
                    value={mail} />
                  <div className="newsletter_grp_append">
                    <button className="btn m-0" type="button" id="news_submit" onClick={() => { newmailadd() }}>Subscribe</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bordrline"></div>
          <div className="mt-4">
            <p className="copyright_txt">All rights reserved.Copyright © 2022 by<a href="https://xdcnft.com" target="_blank" className="a_footer_text"> XDCNFT</a></p>
          </div>
        </div>
      </footer>
    </>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
