import React, { useState, useEffect, useRef, useContext, } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import './index.css';

// pages for this product
import Home from "views/Home.js";
import Create from "views/Create.js";
import CreateSingle from "views/Create-single.js";
import CreateMultiple from "views/create-multiple.js";
import About from "views/About.js";

import HowItWorks from "views/how-it-works.js";
import EditProfile from "views/edit-profile.js";
import Proposals from "views/proposals.js";
import Myitems from "views/my-items.js";
import Following from "views/following.js";
import Discussion from "views/Discussion.js";
import Discussionlatest from "views/discussion-latest.js";
import DiscussionDetail from "views/discussion-detail.js";
import Privacypolicy from "views/Privacypolicy.js";
import Terms from "views/Termsofservice.js";
import Explore from "views/explore.js";
import Info from "views/info.js";
import community from "views/communty"

import collectionlist from "views/collectionList.js"

import AddCollection from "../src/views/addcollection"
import UserCollection from "../src/views/usercollections"

import Activity from "views/activity.js";
import Search from "views/search.js";

import ConnectWallet from "views/connect-wallet.js";
import comingsoon from "views/comingsoon.js";

import Faq from "views/faq.js";

// added by me
import '@metamask/legacy-web3'
import 'react-toastify/dist/ReactToastify.css';

import Xdc3 from "xdc3";

import { AddressUserDetails_GetOrSave_Action } from "actions/v1/user";
import userContract721 from './ABI/userContract721'
import MULTIPLE from './ABI/MULTIPLE.json';
import SINGLE from '../src/ABI/SINGLE.json';
import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";
import config from './lib/config';
import DETH_ABI from 'ABI/DETH_ABI.json';
import { useSelector, useDispatch } from 'react-redux';
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import axios from "axios";



import { toast } from 'react-toastify';
import HeaderLinksCommunity from "components/Header/HeaderLinksCommunity";
import Icon1 from "assets/images/icon1.jpg";
import Icon2 from "assets/images/xdc-icon.png";
import Icon3 from "assets/images/wallet_05.png";
import Icon4 from "assets/images/wallet1.png";
import { convertionValue } from "actions/v1/token";

toast.configure();
let toasterOption = config.toasterOption;



export default function Indexs(props) {
    //Redux - kslp
    const birds = useSelector(state => state.wallet_connect_context);
    //console.log("birds",birds)
    const dispatch = useDispatch(); const { ...rest } = props;

    const timerRef = useRef(null);

    const getXDCPrice = () => {
        axios.get(`${config.vUrl}/token/AdminPriceChange`)
            .then((data) => {
                console.log("Token Details", data.data.Price)
                if (data.data && data.data.Success) {
                    dispatch({
                        type: Account_Connect,
                        Account_Detail: {
                            tokenAddress: data.data.Price
                        }
                    })
                }
                else {
                    toast.error("Price Convertion Value Failed")
                }
            })
    }

    const getPrice = async () => {
        const res1 = await axios.get(`${config.vUrl}/token/AdminPriceChange`);
        let tokenAddressData = res1.data.Price;

        const res2 = await axios.get(`https://admin.xdcnft.com/cmc`);
        let data = res2?.data?.data;
        if(data?.length > 0) {
            let dataXDC = data[0].data["XDC"].quote.USD.price;
            tokenAddressData.XDC_Currency_USD = Number(dataXDC);
        }
        
        dispatch({
            type: Account_Connect,
            Account_Detail: {
                tokenAddress: tokenAddressData
            }
        })
    }


    useEffect(() => {
        getPrice();
        // getXDCPrice();
        getServi();
        if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'mt') {
            getInit('mt');
        }
        else if (localStorage.walletConnectType == 'wc' && localStorage.walletconnect != null) {
            getInit('wc')
        }

    }, [localStorage.walletConnectType]);




    async function getServi() {
        // console.log("servicefeeecomming");
        var web3sw = new Web3(config.BNBPROVIDER)
        
        if (web3sw) {
            
            var CoursetroContracti = new web3sw.eth.Contract(
                SINGLE,
                config.singleContract
            );
            try{
            var Singlefee1 = await CoursetroContracti
                .methods
                .getServiceFee()
                .call()
            var tokenAddress = [];
            var buyerFee = Singlefee1[0];
            var sellerFee = Singlefee1[1];
            // console.log("test service fee",Singlefee1,sellerFee,buyerFee);
            dispatch({
                type: Account_Connect,
                Account_Detail: {
                    Service_Fee_buyer: buyerFee,
                    Service_Fee_seller: sellerFee,
                    //tokenAddress: tokenAddress
                }
            })
        }
        catch(e){
console.log("error in servicefee",e);
        }
            var currency = await convertionValue();
            // console.log("dfhjvfudhbvjfhkbvjkfd", currency);
            dispatch({
                type: Account_Connect,
                Account_Detail: {

                    currency_convertion: currency.data.USD
                }
            })

        }
    }



    async function getInit(type) {
        var handle = null;
        var provider = null;

        provider = await connect_Wallet(type);

        if (provider) {
            try {
                await provider.enable()
                    .then(async function () {
                        var xdc3 = new Xdc3(provider)
                        var currAddr = '';
                        var balance = 0, setacc = '';
                        var val
                        var netid = await xdc3.eth.net.getId()
                        // var web3 = new Web3(provider)
                        if ((netid == config.networkVersion)
                            // || (xdc3.currentProvider.chainId == config.networkVersion)
                        ) {
                            localStorage.setItem('walletConnectType', type)
                            if (localStorage.walletConnectType == "wc") {
                                var result = JSON.parse(localStorage.walletconnect).accounts
                                setacc = result[0];
                                val = await xdc3.eth.getBalance(setacc)
                                balance = val / 1000000000000000000;
                                currAddr = String(setacc).toLowerCase();

                            }
                            else if (localStorage.walletConnectType == "mt") {
                                var result = await xdc3.eth.getAccounts()
                                setacc = result[0];
                                await xdc3.eth.getBalance(setacc)
                                    .then(async (val) => {

                                        val = await xdc3.eth.getBalance(setacc)
                                        balance = val / 1000000000000000000;
                                    })
                                currAddr = String(setacc).toLowerCase();
                            }
                            var respval = await AddressUserDetails_GetOrSave_Call(currAddr);
                            var wenbl = await WenlamboValue(currAddr, xdc3)
                            dispatch({
                                type: Account_Connect,
                                Account_Detail: {
                                    UserAccountAddr: currAddr,
                                    providerss: provider,
                                    UserAccountBal: balance,
                                    WalletConnected: "true",
                                    Accounts: setacc,
                                    AddressUserDetails: respval,
                                    Wen_Bln: wenbl
                                }
                            })


                        }
                        else {
                            toast.warn("Connect to XDC Mainnet", toasterOption);
                        }
                    })
                    .catch((e) => {
                    })
            } catch (err) {

            }
        }
        else {

            toast.warning("Please Add Wallet", toasterOption);
        }
    }
    async function connect_Wallet(type) {
        if (type == 'wc') {
            var provider = new WalletConnectProvider({
                rpc: {
                    4: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
                    // 56: "https://bsc-dataseed1.binance.org",
                    // 97:"https://data-seed-prebsc-1-s1.binance.org:8545/"
                },
                network: 'binance',
                chainId: 4,
                // chainId: 97,
            }
            );
            return provider;
        }

        else if (type == 'mt') {
            var provider = window.ethereum;
            return provider;
        }
    }
    async function AddressUserDetails_GetOrSave_Call(currAddr) {
        var ReqData = {
            addr: String(currAddr).toLowerCase()
        }
        var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
        if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
            return Resp.data.data.User
        } else {
            return null
        }
    }


    async function WenlamboValue(currAddr, web3) {
        try {
            var bidvalue = new web3.eth.Contract(
                DETH_ABI, config.tokenAddr[config.tokenSymbol]
            );
            var bidbln = await bidvalue
                .methods
                .balanceOf(currAddr)
                .call();
            var bidbln1 = (bidbln / config.decimalvalues).toFixed(config.toFixed)
            // alert(bidbln1)
            return bidbln1
        }
        catch (e) {
        }
    }
    window.addEventListener('load', async (event) => {
        // alert(1)
        if (localStorage.getItem('walletConnectType') == 'mt') {
            if (window.ethereum) {
                // console.log("tjghjfhio");
                window.ethereum.on('accountsChanged', function (accounts) {
                    if (timerRef.current) {
                        clearTimeout(timerRef.current);
                    }
                    timerRef.current = setTimeout(() => {
                        getInit('mt');
                    }, 1000);
                })

                window.ethereum.on('chainChanged', async function (networkId) {
                    //console.log("chainid",networkId)
                    if (networkId == config.chainId) {
                        if (timerRef.current) {
                            clearTimeout(timerRef.current);
                        }
                        timerRef.current = setTimeout(() => {
                            getInit('mt');
                        }, 1000);
                    }
                    else {
                        dispatch({
                            type: Account_disConnect,
                        })
                    }
                })
            }
        }
        else if (localStorage.walletConnectType == "wc") {
            var provider3 = null
            if (provider3 == null) {
                provider3 = await connect_Wallet("wc");
            }
            else if (provider3 != null) {
                (provider3).on("connect", () => {
                    getInit('wc')
                });
                (provider3).on("disconnect", () => {
                    localStorage.removeItem('walletConnectType')
                });
            }
        }
    })

    const SwitchChain = async () => {
        console.log("Account Changed")
        // alert(1)
        const RPC_URL = []
        RPC_URL.push(config.BNBPROVIDER)
        const CHAIN_ID = config.networkVersion;
        // console.log("Config.chainid",config.chainId)
        // Check if MetaMask is installed
        // MetaMask injects the global API into window.ethereum
        const hexString = CHAIN_ID.toString(16);
        //    console.log("rnijhghrijht",hexString,window.ethereum);
        if (window.ethereum) {
            try {
                const data = await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: '0x32',
                            chainName: 'XDC Mainnet',
                            rpcUrls: RPC_URL,
                            nativeCurrency: {
                                name: 'XDC',
                                symbol: 'XDC',
                                decimals: 18,
                            }
                            // currencySymbol:XDC

                            // name: 'XDC'
                        },
                    ],
                });
                console.log("HexString : ", hexString)
                return true;
            } catch (addError) {
                console.log("Meta Error : ", addError);

            }
        } else {
            console.log('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
            return false;

        }
    }
    return (
        <>
            <BrowserRouter basename="/" >
                <Switch>

                    <Route path="/my-items/:activity?" component={Myitems} />
                    <Route path="/create-single" component={CreateSingle} />
                    <Route path="/create-multiple" component={CreateSingle} />
                    <Route path="/how-it-works" component={Faq} />
                    <Route path="/edit-profile" component={EditProfile} />
                    <Route path="/addCollections" component={AddCollection} />
                    <Route path="/addCollectionss/:name/:form" component={AddCollection} />
                    <Route path="/search" component={Search} />
                    <Route path="/my-items" component={Myitems} />
                    <Route path="/collectionlist" component={collectionlist} />
                    <Route path="/Following" component={Following} />
                    <Route path="/create" component={Create} />
                    <Route path="/privacy-policy" component={Privacypolicy} />
                    <Route path="/about" component={About} />
                    <Route path="/community" component={community} />
                    <Route path="/terms-and-conditions" component={Terms} />
                    <Route path="/activity" component={Activity} />
                    <Route path="/explore/:Param_category" component={Explore} />
                    {/* <Route path="/connect-wallet" component={ConnectWallet} /> */}
                    <Route path="/comingsoon" component={comingsoon} />
                    <Route path="/info/:owneraddress/:collectionaddress/:tokenidval" component={Info} />
                    {/* <Route path="/info/:tokenidval" component={Info} /> */}
                    <Route path="/user/:paramAddress" component={Myitems} />
                    <Route path="/collection/:param/:customurl" component={UserCollection} />
                    <Route path="/:paramUsername" component={Myitems} />
                    <Route path="/" component={Home} />
                    <Route exact path="/*" component={Home}>
                        <Redirect to="/" />
                    </Route>
                    {/* <Route path="/profile-page" component={ProfilePage} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/" component={Components} /> */}
                    {/* <Route path="/voting-proposals" component={Proposals} /> */}

                    {/* <Route path="/FAQ" component={Faq} /> */}

                </Switch>
            </BrowserRouter>




            <div className="modal fade primary_modal" id="connect_modal" role="dialog" aria-labelledby="connect_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false" >
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h5 className="modal-title" id="connect_modalLabel">Connect Your Wallet</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ul className="connect_ul mb-4">
                                {

                                    window.ethereum
                                    && new Web3(window.ethereum)
                                    && new Web3(window.web3.currentProvider)
                                    && (new Web3(window.web3.currentProvider.isMetaMask))
                                    &&

                                    <li>
                                        <div className=" connect_card" onClick={() => {
                                            // localStorage.setItem('walletConnectType','mt')
                                            window.$('.modal').modal('hide')
                                            getInit('mt')
                                            if (localStorage.walletconnect != null) {
                                                localStorage.removeItem('walletconnect')
                                            }
                                        }} >
                                            <div className="card-body-mod">
                                                <div className="media follow_media">
                                                    {/* <img src={require("../../assets/images/connect_img_1.png")} alt="User" className="img-fluid mr-2" /> */}

                                                    <div className="media-body flex_body">
                                                        <div className="w-100">
                                                            <div className="wallet-lists"
                                                            >
                                                                <p className="my-0 media_text"> <img src={Icon1} />Metamask</p>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </li>


                                }



                                {

                                    window.ethereum
                                    && new Web3(window.ethereum)
                                    && new Web3(window.web3.currentProvider)
                                    && (new Web3(window.web3.currentProvider.isMetaMask))
                                    &&

                                    <li>
                                        <div className=" connect_card" onClick={() => {
                                            // localStorage.setItem('walletConnectType','mt')
                                            window.$('.modal').modal('hide')
                                            getInit('mt')
                                            if (localStorage.walletconnect != null) {
                                                localStorage.removeItem('walletconnect')
                                            }
                                        }} >
                                            <div className="card-body-mod">
                                                <div className="media follow_media">
                                                    {/* <img src={require("../../assets/images/connect_img_1.png")} alt="User" className="img-fluid mr-2" /> */}

                                                    <div className="media-body flex_body">
                                                        <div className="w-100">
                                                            <div className="wallet-lists"
                                                            >
                                                                <p className="my-0 media_text"> <img src={Icon2} />XDCPay</p>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </li>

                                }
                                {

                                    // window.ethereum
                                    // && new Web3(window.ethereum)
                                    // && new Web3(window.web3.currentProvider)
                                    // &&

                                    // <li>
                                    //   <div className=" connect_card" onClick={() => {
                                    //     // localStorage.setItem('walletConnectType','mt')
                                    //     window.$('.modal').modal('hide')
                                    //     getInit('mt')
                                    //     if (localStorage.walletconnect != null) {
                                    //       localStorage.removeItem('walletconnect')
                                    //     }
                                    //     props.Set_WalletConnected("true");
                                    //   }} >
                                    //     <div className="card-body-mod">
                                    //       <div className="media follow_media">
                                    //         {/* <img src={require("../../assets/images/connect_img_1.png")} alt="User" className="img-fluid mr-2" /> */}

                                    //         <div className="media-body flex_body">
                                    //           <div className="w-100">
                                    //             <div className="wallet-lists"
                                    //             >
                                    //               <p className="my-0 media_text"> <img src={Coin} />CoinBase</p>

                                    //             </div>

                                    //           </div>

                                    //         </div>

                                    //       </div>

                                    //     </div>
                                    //   </div>
                                    // </li>
                                }
                                <li>
                                    <div className="connect_card">
                                        <div className="card-body-mod">
                                            <div className="media follow_media">
                                                {/* <img src={require("../../assets/images/connect_img_1.png")} alt="User" className="img-fluid mr-2" /> */}

                                                <div className="media-body flex_body">
                                                    <div className="w-100">
                                                        <div className="wallet-lists"
                                                        >
                                                            <p className="my-0 media_text" onClick={() => SwitchChain()}> <img src={Icon1} />   ADD RPC URL IN METAMASK</p>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </li>
                                {/* <li>
                                    <div className="connect_card">
                                        <div className="card-body-mod">
                                            <div className="media follow_media">
                                             

                                                <div className="media-body flex_body">
                                                    <div className="w-100">
                                                        <div className="wallet-lists"
                                                        >
                                                            <p className="my-0 media_text"><img src={Icon2} />ADD RPC URL IN XDCPay</p>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </li> */}
                                {/* {window.ethereum
                                    && new Web3(window.ethereum)
                                    && new Web3(window.web3.currentProvider)
                                    && (new Web3(window.web3.currentProvider.isWalletConnect)) &&

                                    <li>
                                        <div className="connect_card  d-xl-none"
                                            onClick={() => {
                                                localStorage.setItem('walletConnectType','mt')
                                                window.$('.modal').modal('hide')
                                                getInit('mt')
                                                if (localStorage.walletconnect != null) {
                                                    localStorage.removeItem('walletconnect')
                                                }

                                            }}
                                        >
                                            <div className="card-body-mod">
                                                <div className="media follow_media">

                                                    <div className="media-body flex_body">
                                                        <div className="w-100">
                                                            <div className="wallet-lists" >
                                                                <p className="my-0 media_text"> <img src={Icon4} />Trust wallet</p>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>



                                        </div>
                                    </li>} */}

                            </ul>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}



// ReactDOM.render(<Indexs/>,document.getElementById('root')
// );