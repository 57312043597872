import React, { useEffect, useState, useRef } from "react";
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link, useParams ,useLocation} from "react-router-dom";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

import HeaderLinks from "components/Header/HeaderLinks.js";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// mysid
import TokenCard from '../views/separate/TokenCard'
import { useSelector } from "react-redux";
import { CollectionList } from '../actions/v1/token';
import { CancelOrderRef } from './separate/CancelOrderRef';
import { LikeRef } from './separate/LikeRef';
import { BurnRef } from './separate/BurnRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import ConnectWallet from './separate/Connect-Wallet';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { addUserCollection } from '../actions/v1/user'
import '@metamask/legacy-web3'
import { toast } from 'react-toastify';

import { getcmslistinhome, getpromotion } from '../actions/v1/report';
import isEmpty from "lib/isEmpty";
import config from 'lib/config';
toast.configure();
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function Ico(props) {


  const { ...rest } = props;

  const classes = useStyles();
  const location = useLocation();
  const Wallet_Details = useSelector(state => state.wallet_connect_context);


  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var BurnForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  var PlaceABidForwardRef = useRef();
  const [topcreatorsection, settopcreatorsection] = React.useState({});
  const [LikedTokenList, setLikedTokenList] = useState([]);
  // const [UserAccountAddr, Set_UserAccountAddr] = useState('');
  // const [UserAccountBal, Set_UserAccountBal] = useState(0);
  // const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  // const [Accounts, Set_Accounts] = useState('');
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = useState('');
  const [HitItem, Set_HitItem] = useState({});
  const [Categorylist, setCategorylist] = useState([]);
  const [TokenList, setTokenList] = useState([]);
  const [CatName, setCatName] = useState('All');
  const [CatBasedTokenList, setCatBasedTokenList] = useState({ 'loader': false, 'All': { page: 1, list: [], onmore: true } });
  // const [Page, setPage] = useState(1);
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [item, Set_item] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [Bids, Set_Bids] = useState([]);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = useState('init');
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [Time_Auction_List, Set_Time_Auction_List] = useState([]);
  const [Recent_Nft_List, Set_Recent_Nft_List] = useState([]);
  // const [WalletConnected, Set_WalletConnected] = useState('false');
  // const [providerss,set_providers]=useState(null)
  const [Service_Fee, set_Service_Fee] = useState(0);
  const [convertVal, setConvertVal] = React.useState(0);
  // const[Wen_Bln,set_Wen_Bln]=useState(0);
  const [Promotion_List, Set_Promotion_list] = useState({})
  const [Creatorcount, setCreatorcount] = useState(0);
  const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = useState({})
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [getcmslistinhome1, setgetcmslistinhome1] = useState({})
  const [getcmslistinhome12, setgetcmslistinhome12] = useState({})
  const [CollectionListed12, Set_Collection_List] = useState([]);
  const [CollectionInfo, setCollectionInfo] = useState([]);
  const [collectionListed, SetCollectionList] = useState([]);
  const [Time, setTime] = useState(7);
  var { param, customurl } = useParams();

  useEffect(() => {
    Timefun();
    CollectionListed();
  }, [Time])
//   async function getInit() {
//     TokenListCall();
//     CollectionProfile()

//   }

const CollectionListed = async () => {
  var Arg = {
    Timed: Time,
  };
  // console.log("dfvdsvds",Arg);
    var collectiongal = await CollectionList(Arg);
    // console.log("CollectionList what its is comming", collectiongal.data.data.collections)
    SetCollectionList(collectiongal.data.data.collections)
  }
  async function Timefun (timefunn) {
    // console.log("nhkjdcbvdjshb",timefunn);
    if(timefunn > 0 ){
    setTime(timefunn)
    }
      }

//   const onLoadMore = () => {

//   }
//   async function TokenListCall() {
//     var payload = {
//       currAddr: param,
//       customurl: customurl,
//       limit: 6,
//       from: 'collection'
//     }
//     console.log("TokenList call function", payload)
//     var resp = await CollectiblesList_Home(payload);
//     console.log("After NFT", resp.data);
//     if (resp && resp.data && resp.data.from === 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
//       console.log("After NFT", resp.data);
//       Set_Collection_List(resp.data.list)
//     }
//     else {
//       Set_Collection_List([])
//     }
//   }
//   async function CollectionProfile() {
//     var payload = {
//       userAddress: param,
//       customurl: customurl
//     }
//   console.log("fgjnhfgjm",payload);
//     var collectionInfo = await getUserCollectiondata(payload);
//     if (collectionInfo && collectionInfo.data && collectionInfo.data.data && collectionInfo.data.data.collections) {
//       console.log("Collection Informations", collectionInfo);
//       setCollectionInfo(collectionInfo.data.data.collections)
//     }
//   }



  return (
    // WalletConnected=="false"?
    // <Loader/>:
    <div>
      {/* <div id="loader_div"><span className="spin_round"></span><img src="../assets/images/loader.png" className="logo_load" /></div> */}

      <div className="home_header">

        <PutOnSaleRef
          ref={PutOnSaleForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        />
        <BurnRef
          ref={BurnForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={providerss}

        />
        <PlaceAndAcceptBidRef
          ref={PlaceABidForwardRef}
          // Set_WalletConnected={Set_WalletConnected}
          // Set_UserAccountAddr={Set_UserAccountAddr}
          // Set_UserAccountBal={Set_UserAccountBal}
          // Set_AddressUserDetails={Set_AddressUserDetails}
          // Set_Accounts={Set_Accounts}
          Set_MyItemAccountAddr={Set_MyItemAccountAddr}
          Set_tokenCounts={Set_tokenCounts}
          Set_item={Set_item}
          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
          Set_MyTokenBalance={Set_MyTokenBalance}
          Set_Bids={Set_Bids}
          Set_AccepBidSelect={Set_AccepBidSelect}
          Set_tokenBidAmt={Set_tokenBidAmt}
          Set_NoOfToken={Set_NoOfToken}
          Set_ValidateError={Set_ValidateError}
          Set_TokenBalance={Set_TokenBalance}
          Set_YouWillPay={Set_YouWillPay}
          Set_YouWillPayFee={Set_YouWillPayFee}
          Set_YouWillGet={Set_YouWillGet}
          Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
          Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}

          // WalletConnected={WalletConnected}
          // UserAccountAddr={UserAccountAddr}
          // UserAccountBal={UserAccountBal}
          // AddressUserDetails={AddressUserDetails}
          // Accounts={Accounts}
          MyItemAccountAddr={MyItemAccountAddr}
          tokenCounts={tokenCounts}
          item={item}
          tokenCounts_Detail={tokenCounts_Detail}
          MyTokenBalance={MyTokenBalance}
          Bids={Bids}
          AccepBidSelect={AccepBidSelect}
          tokenBidAmt={tokenBidAmt}
          NoOfToken={NoOfToken}
          ValidateError={ValidateError}
          TokenBalance={TokenBalance}
          YouWillPay={YouWillPay}
          YouWillPayFee={YouWillPayFee}
          YouWillGet={YouWillGet}
          BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
          BidApply_SignCallStatus={BidApply_SignCallStatus}
          AllowedQuantity={AllowedQuantity}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // set_Wen_Bln={set_Wen_Bln}
          // Wen_Bln={Wen_Bln}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={providerss}
        />
        <PurchaseNowRef
          ref={PurchaseNowForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          // UserAccountAddr={UserAccountAddr}
          // UserAccountBal={UserAccountBal}
          // Accounts={Accounts}
          // GetUserBal={GetUserBal} 
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // set_Wen_Bln={set_Wen_Bln}
          // Wen_Bln={Wen_Bln}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={providerss}
        />
        <WalletRef
          ref={WalletForwardRef}
        // Set_UserAccountAddr={Set_UserAccountAddr}
        // Set_WalletConnected={Set_WalletConnected}
        // Set_UserAccountBal={Set_UserAccountBal}
        // set_providers={set_providers}
        // providerss={providerss}
        />
        <LikeRef

          // UserAccountAddr={UserAccountAddr}
          ref={LikeForwardRef}
          setLikedTokenList={setLikedTokenList}
          MyItemAccountAddr={MyItemAccountAddr}
        // WalletConnected={WalletConnected}
        // set_providers={set_providers}
        // providerss={providerss}
        />
        <CancelOrderRef
          ref={CancelOrderForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}


          // WalletConnected={WalletConnected}

          // UserAccountAddr={UserAccountAddr}
          // UserAccountBal={UserAccountBal}
          // Accounts={Accounts}
          // GetUserBal={GetUserBal}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={providerss}

        />
        <ReportNowRef
          // UserAccountAddr={UserAccountAddr}
          ref={ReportForwardRef}
        />
        <ShareNowRef
          ref={ShareForwardRef}
        />

        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<Link to="/">
            <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link>}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 20,
            color: "white"
          }}
          {...rest}
        />

<div className={classes.pageHeader + " inner_pageheader"}>
        <div className="bg_red_1 bid_section_section_1 hotbid_section">
          <div className="container-fluid container-theme container-lg">
            
              
              <div className="d-flex justify-content-center customDrop">
<h2 class="section-head">Top Collections</h2>
            <CustomDropdown 
                          noLiPadding
                          buttonText={<div className="align-items-center buyersbtns customDropdown">
                       
                       {Time} Day</div>}
                          dropdownList={[
                            <div className="buernotir">

                              <div style={{ height: 200 }} className="nto_scrol_div">
                             
                                <ul className="explorebg btnbuyerss">
                                  <li className="px-3" id="backgorundd" onClick={() => Timefun(1)}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >1 days</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3"onClick={() => Timefun(7)}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">7 Days</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() => Timefun(30)}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">30 Days</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                </ul>
                               
                              </div>



                            </div>
                          ]}
                        />
                      <div>
                     
                      </div>
                        </div>
            
          </div>
        </div>

        {/* NEW ACTIVITY */}
        <div className="container mt-3 d-none d-lg-block">
          <div className="table-responsive">
            <table class="table table_activity">
              {collectionListed.length > 0 &&
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">#</th>
                    <th scope="col">Collection</th>
                    <th scope="col">Collection Name</th>
                    <th scope="col">Avg Price</th>
                    <th scope="col"></th>

                  </tr>
                </thead>
              } 

<tbody>
                {collectionListed.length === 0 ?

                  <div className="text-center py-5 notfound">
                    <p className="not_found_text">No items found</p>
                    <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                    <div className="mt-3">

                    </div>
                  </div>

                  :
                  collectionListed.map((item, index) => {
                    console.log("hbcgvcvbgvcbcgv", item);
                
                    return (
                      <>
                        <tr>
                          <th scope="row">
                           {/* <img src= {icon}/>  */}
                       
                              {/* <i class="fas fa-shopping-cart"></i> */}
                            {/* <span className="pl-2">{item.action
                            }</span> */}
                            </th>
<td>{index + 1}</td>
                          <td>
                            <div class="xenox_td">

                       
                                {
                            item.imageName === "" &&
                            <img src={require("../assets/images/fish.jpg")} class="img-fluid img-circle" alt="Shape" />
                          }
                          {
                            item.imageName !== "" &&

                            <img src={`${config.Back_URL}/collectionLogo/${item.userAddress}/${item.imageName}`} alt="Shape" className="img-fluid img-circle" />

                          }
                              
                            
                              <div>
                                <p class="my-0 media_num"></p>
                                <p class="mt-0 media_text"></p>
                              </div>
                            </div>

                          </td>
                          <td>
                            <p class="my-0 media_text1">
                            
                            {item.collectionName}
                             </p>
        
                          </td>
                          <td>${Number(item.total).toFixed(5)}</td>
                          <td className="buynowbtnt">
                         <Link to={'/collection/' + item.userAddress + '/' + item.customurl}>
                              <Button>View</Button>
                              </Link>
                          </td>
                         





                        </tr>
                      </>)
                  })
                }
              </tbody>
         
            </table>
          </div>
        </div>
        <div className="container mt-3 d-block d-lg-none table-res">
          <div className="table-responsive">
            <table class="table table_activity">
              {collectionListed.length > 0 &&
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Collection</th>
                    <th scope="col">Collection Name</th>
                    <th scope="col">Avg Price</th>
                   
                  </tr>
                </thead>
              } 

<tbody>
                {collectionListed.length === 0 ?

                  <div className="text-center py-5 notfound">
                    <p className="not_found_text">No items found</p>
                    <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                    <div className="mt-3">

                    </div>
                  </div>

                  :
                  collectionListed.map((item, index) => {
                    console.log("hbcgvcvbgvcbcgv", item);
                
                    return (
                      <>
                        <tr>
                      
<td>{index + 1}</td>
                         <td>
                            <div class="xenox_td">

                       
                                {
                            item.imageName === "" &&
                            <img src={require("../assets/images/fish.jpg")} class="img-fluid img-circle" alt="Shape" />
                          }
                          {
                            item.imageName !== "" &&

                            <img src={`${config.Back_URL}/collectionLogo/${item.userAddress}/${item.imageName}`} alt="Shape" className="img-fluid img-circle" />

                          }
                              
                            
                              <div>
                                <p class="my-0 media_num"></p>
                                <p class="mt-0 media_text"></p>
                              </div>
                            </div>

                          </td>
                         
                          <td>
                            <p class="my-0 media_text1">
                            
                            {item.collectionName}
                             </p>
        
                          </td>
                          <td className="ml-3">${Number(item.total).toFixed(5)}</td>
                          <td className="buynowbtnt">
                           <Link to={'/collection/' + item.userAddress + '/' + item.customurl}>
                              <Button>View</Button>
                              </Link>
                          </td>
                          {/* <td className="buynowbtnt">
                         <Link to={'/collection/' + item.userAddress + '/' + item.customurl}>
                              <Button>Buy Now</Button>
                              </Link>
                          </td> */}
                         





                        </tr>
                      </>)
                  })
                }
              </tbody>
         
            </table>
          </div>
        </div>


      </div>


        <div id="logo_overlay" className="logo_ovelay">

          <Footer />
        </div>
      </div>
    </div>
  );
}
