import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useHistory, useLocation } from "react-router-dom";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
// import masonary1 from 'assets/images/masonary_04.png'
// import Loader from 'assets/images/loader.png';
// myside
import $ from 'jquery';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import Select from 'react-select'
// import {
//   getCurAddr
// } from '../actions/v1/user';
import {
  ipfsImageHashGet,
} from '../actions/v1/token';
// import Audios from '../assets/images/audio.png'
// import Avatars from "./Avatar";
import {
  GetCategoryAction,
  CreateTokenValidationAction,
  TokenAddItemAction,
  TokenAddOwnerAction,
} from '../actions/v1/token';
import { getUserCollection } from '../actions/v1/user'
import Web3Utils from 'web3-utils'
import Convert1 from '../views/separate/Convert1'
import {
  TokenImageCalls
} from '../actions/v1/report';
import config from '../lib/config'
import ConnectWallet from './separate/Connect-Wallet';
// import EXCHANGE from '../ABI/EXCHANGE.json'
import MULTIPLE from '../ABI/MULTIPLE.json';
import SINGLE from '../ABI/SINGLE.json';
import { toast } from "react-toastify";
import isEmpty from "lib/isEmpty";
import Convert from 'views/separate/Convert';
import { getReceipt } from '../actions/v1/getReceiptFunc';




import { useSelector } from "react-redux";
toast.configure();
const dashboardRoutes = [];
let toasterOption = config.toasterOption;
const useStyles = makeStyles(styles);
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function CreateSingle(props) {

  const priceoption = [
    { value: config.currencySymbol, label: config.currencySymbol },
    { value: config.tokenSymbol, label: config.tokenSymbol },
  ];

  const classes = useStyles();



  const Wallet_Details = useSelector(state => state.wallet_connect_context);
  const { ...rest } = props;
  const history = useHistory();
  // wallet related : common state
  // const [WalletConnected, Set_WalletConnected] = useState('false');
  // const [UserAccountAddr, Set_UserAccountAddr] = useState('');
  const [UserAccountBal, Set_UserAccountBal] = useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  // const [Accounts, Set_Accounts] = useState('');
  // const [MyItemAccountAddr, Set_MyItemAccountAddr] = useState('');
  var pathVal = '';
  const location = useLocation();
  if (location.pathname) {
    if (location.pathname.split('/').length >= 2) {
      pathVal = location.pathname.split('/')[1];
    }
  }
  const [location_pathname, Set_location_pathname] = useState(pathVal);
  var CollectibleType_val = (location_pathname === 'create-single') ? config.singleType : config.multipleType;
  var ContractAddressUser_val = (location_pathname === 'create-single') ? config.singleContract : config.multipleContract;

  const [CollectibleType, Set_CollectibleType] = useState(CollectibleType_val);
  const [ContractAddressUser, set_ContractAddressUser] = useState(ContractAddressUser_val);

  const [FormSubmitUserClicked, Set_FormSubmitUserClicked] = useState(false);
  const [fileSizes, setfilesize] = useState(0)
  const [TokenCollection, setTokenCollection] = useState({
    addr: '',
    img: 'site.png',
    name: 'wenlambo'
  });
  const [TokenOwner, setTokenowner] = useState({
    addr: '',
    img: '',
    name: ''
  });
  const [TokenCreater, setTokenCreater] = useState({
    addr: '',
    img: '',
    name: ''
  });

  const [StartDate, Set_StartDate] = useState('Select Start Date');
  const [EndDate, Set_EndDate] = useState('Select End Date');

  const [MintHashVal, Set_MintHashVal] = useState('');
  const [TokenQuantity, Set_TokenQuantity] = useState(1);

  const [TokenBid, setTokenBid] = useState(true);

  const [MinimumBid, Set_MinimumBid] = useState(0);
  const [Clocktime, set_Clocktime] = useState('');
  const [EndClocktime, set_EndClocktime] = useState("");

  const [UnLockcontent, Set_UnLockcontent] = useState("");
  const [Unlockoncepurchased, Set_Unlockoncepurchased] = useState(false);

  const [SingleContractAddressAdmin, set_SingleContractAddressAdmin] = useState(config.singleContract);
  const [MultipleContractAddressAdmin, set_MultipleContractAddressAdmin] = useState(config.multipleContract);

  // const [SingleContractAddressUser, set_SingleContractAddressUser] = useState(config.singleContract);

  const [TokenCount, Set_TokenCount] = useState(20000);

  const [ApproveCallStatus, setApproveCallStatus] = useState('init');
  const [MintCallStatus, setMintCallStatus] = useState('init');
  const [SignCallStatus, setSignCallStatus] = useState('init');
  const [SignLockCallStatus, setSignLockCallStatus] = useState('init');

  // const [TokenType, setTokenType] = useState('Single');

  const [ValidateError, setValidateError] = useState({ TokenPrice: '' });

  const [PutOnSale, setPutOnSale] = useState(true);
  const [PutOnSaleType, setPutOnSaleType] = useState('UnLimitedAuction');
  const [UserCollection, setUserCollection] = useState('XDC');
  const [TokenCategory, setTokenCategory] = useState({ label: '' });
  const [CategoryOption, setCategoryOption] = useState([]);
  const [TokenPrice, setTokenPrice] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [TokenName, setTokenName] = useState('');
  const [TokenDescription, setTokenDescription] = useState('');
  const [TokenRoyalities, setTokenRoyalities] = useState('');
  const [TokenProperties, setTokenProperties] = useState('');
  const [TokenFile, setTokenFile] = useState("");
  const [TokenFilePreReader, setTokenFilePreReader] = useState("");
  const [TokenFilePreUrl, setTokenFilePreUrl] = useState("");
  const [ipfsimg, setIpfsImg] = useState(null)
  const [ipfshash, setIpfsHash] = useState("");
  const [ipfshashurl, setipfshashurl] = useState('');
  const [imgfilename, setimgfilename] = useState('');
  const [additionalImage, setAdditionalImage] = useState('')
  const [CoinName, setCoinNames] = useState('');
  const [TokenImages, setTokenImage] = useState({});
  // const [Service_Fee,set_Service_Fee] = useState(0);
  // const [providerss,set_providers]=useState(null)

  const [convertVal, setConvertVal] = React.useState(0);
  const [Wen_Bln, set_Wen_Bln] = useState(0);

  const [ipfsmetatag, set_ipfsmetatag] = useState('');
  const [CollectionList, setCollectionList] = useState([]);
  const [ipfsmeta, setmetadata] = useState('');

  useEffect(() => {
    getCollection()
  }, [Wallet_Details.UserAccountAddr]);
  useEffect(() => {
    // console.log("consolelog checking")
    // if(CategoryOption.length==0){
    GetCategoryCall();
    // }
    if (Wallet_Details.UserAccountAddr !== "") {
      if (isEmpty(TokenImages)) {
        //  //alert(UserAccountAddr)
        TokenImageCall(Wallet_Details.UserAccountAddr);
      }
    }
    CreateItemValidation(FormSubmitUserClicked);
  }, [
    Wallet_Details.UserAccountAddr,
    FormSubmitUserClicked,
    StartDate,
    EndDate,
    TokenQuantity,
    TokenBid,
    MinimumBid,
    Clocktime,
    EndClocktime,
    UnLockcontent,
    Unlockoncepurchased,
    PutOnSale,
    PutOnSaleType,
    TokenCategory,
    TokenPrice,
    TokenName,
    TokenDescription,
    TokenRoyalities,
    TokenProperties,
    TokenFilePreUrl,
    ipfsimg,
    TokenImages,
  ]);

  async function getCollection() {
    var payload = {
      userAddress: Wallet_Details.UserAccountAddr
    }
    if (location_pathname === 'create-multiple') {
      payload.NFTType = 'MULTIPLE'
    }
    else {
      payload.NFTType = 'SINGLE'
    }
    var userCollectiondata = await getUserCollection(payload)
    // console.log("gmfjdgjuhfbgv",userCollectiondata.data);
    if (userCollectiondata && userCollectiondata.message && userCollectiondata.message.data.collections) {
      var Collectionlist = [];
      // console.log("setUserCollection",userCollectiondata.data);
      userCollectiondata.message.data.collections.map((item, index) => {
        Collectionlist.push({
          name: 'userCollection',
          value: item._id,
          label: item.collectionName,
          // custon:item.customurl
        })
      })
      setCollectionList(Collectionlist)
    }

  }

  async function GetCategoryCall() {
    var resp = await GetCategoryAction()
    if (resp && resp.data && resp.data.list) {
      var CategoryOption = [];
      var ind = null
      resp.data.list.map((item, index) => {
        ind = ((isEmpty(TokenImages) || TokenImages.activate === false) && String(item.name).toLowerCase() === "film") ? index : -1
        CategoryOption.push({
          name: 'TokenCategory',
          value: item._id,
          label: item.name
        })
      })
      if (ind > -1) {
        delete CategoryOption[ind]
      }
      // console.log("Category Options", CategoryOption)
      setCategoryOption(CategoryOption)
    }
  }

  const changePutOnSaleType = (type) => {
    setPutOnSaleType(type);
    if (type === 'FixedPrice') {
      Set_MinimumBid(0)
      set_Clocktime('')
      set_EndClocktime("")
    }
    else if (type == 'TimedAuction') {
      setTokenPrice(0)
    }
    else if (type == 'UnLimitedAuction') {
      Set_MinimumBid(0)
      set_Clocktime('')
      set_EndClocktime("")
      setTokenPrice(0)
    }
  };

  const CheckedChange = (e) => {
    if (e && e.target && e.target.name) {
      if (e.target.name === 'putonsale') {
        if (PutOnSale === false) {
          setPutOnSale(true);
          if (PutOnSaleType === '') {
            setPutOnSaleType('FixedPrice')
          }
        }
        else {
          setPutOnSale(false);
          setPutOnSaleType('')
          setTokenBid(true)
        }
      }
      else if (e.target.name === 'unlockoncepurchased') {
        if (Unlockoncepurchased === false) {
          Set_Unlockoncepurchased(true);
        }
        else {
          Set_Unlockoncepurchased(false);
        }
      }
    }
  };


  const priceoptionfunc = (e) => {

    setCoinNames(e.value)
  };

  const selectFileChange = (e) => {
    var validExtensions = ["png", 'gif', 'webp', 'mp4', 'PNG', 'jpg', 'JPEG', 'jpeg', 'JPG', 'mp3', 'aac', 'AAC', 'flac', 'FLAC', 'WEBM', 'webm', 'ogv', 'OGV']; //array of valid extensions
    if (e.target && e.target.files) {
      var reader = new FileReader()
      var file = e.target.files[0];
      ////////console.log("dakldjasdkasjdjasd", file.name)
      setimgfilename(file.name)
      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
      if ($.inArray(fileNameExt, validExtensions) === -1) {
        toast.error("Only these file types are accepted : " + validExtensions.join(', '), toasterOption);
        return false;
      }
      const fileSize = file.size;
      if (30000000 < fileSize) {
        toast.error("File size exceeds 30 MB", toasterOption);
        return false;
      }
      else {
        setfilesize(fileSize)
        setTokenFile(file);
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          if (reader.result) {
            setTokenFilePreReader(reader.result);
          }
        }.bind(this);
        setTokenFilePreUrl(e.target.value);
      }
    }
  }

  // const selectChange = (e) => {
  //   if (e && e.name && e.label && e.value) {
  //     switch (e.name) {
  //       case 'TokenCategory':
  //         setTokenCategory(e);
  //         break;
  //       default:
  //     }
  //   }
  // }
  const selectChange = (e) => {
    if (e && e.name && e.label && e.value) {
      switch (e.name) {
        case 'TokenCategory':
          setTokenCategory(e);
          break;
        case 'userCollection':
            setUserCollection(e.label)
          default:
      }
    }
  }
  const PriceCalculate = async (data = {}) => {
    var price = (typeof data.price != 'undefined') ? data.price : TokenPrice;
    var weii = price * config.decimalvalues;
    var per = (weii * Wallet_Details.Service_Fee_seller) / 1e20;
    var mulWei = parseFloat(weii - per);
    var getVal = (mulWei / config.decimalvalues);
    // if(CoinName=="BNB"){
    Set_YouWillGet(getVal.toFixed(4));
    // }
    //   if(CoinName=="WENLAMBO"){
    //     Set_YouWillGet(getVal.toFixed(2));}
    //////console.log("qowpoqpwpoqpwop",getVal)
  }
  const inputChange = (e) => {
    if (e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
      var value = e.target.value;
      const re = /^[0-9]+([.][0-9]+)?$/; //with decimal
      const re1 = /^[0-9\b]+$/; // only [0-9]
      switch (e.target.name) {
        case 'TokenPrice':
          if (value !== '' && isNaN(value) === false && value > 0) {
            setTokenPrice(value);
            PriceCalculate({ price: value });
          }
          else {
            setValidateError(ValidateError);
            setTokenPrice('0');
            PriceCalculate({ price: 0 });
          }
          break;
        case 'TokenName':
          setTokenName(value);
          break;
        case 'TokenDescription':
          setTokenDescription(value);
          break;
        case 'TokenRoyalities':
          setTokenRoyalities(value);
          break;
        case 'TokenProperties':
          setTokenProperties(value);
          break;
        case 'UnLockcontent':
          Set_UnLockcontent(value);
          break;
        case 'MinimumBid':
          Set_MinimumBid(value);
          break;
        case 'TokenQuantity':
          Set_TokenQuantity(value);
          break;
        default:
        // code block
      }
    }
  }

  ////// to0ken add function ////////



  // const addToken = async () => {
  //   var web3 = new Web3(Wallet_Details.providerss);
  //   var contractCall = null
  //   if (location_pathname === 'create-multiple') {
  //     var CoursetroContract = new web3.eth.Contract(MULTIPLE, config.multipleContract);
  //     contractCall = await CoursetroContract.methods.addTokenType(
  //       ["BLKZ"],
  //       ["0xECE709d4C931C5f899e34379Ed03D0bfeDA66c0d"]).send({ from: Wallet_Details.Accounts })

  //   }
  //   else {
  //     var CoursetroContract = new web3.eth.Contract(SINGLE, config.singleContract);
  //     contractCall = await CoursetroContract.methods.addTokenType(
  //        ["BLKZ"],
  //       ["0xECE709d4C931C5f899e34379Ed03D0bfeDA66c0d"]).send({ from: Wallet_Details.Accounts })

  //   }
  //   console.log("sdhgfyhsdbfudsghfuyiehg",CoursetroContract)
  // }

  async function ApproveCall() {
    try {
      if (Wallet_Details.UserAccountAddr === "") {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        return false;
      }
      else {
        setApproveCallStatus('processing');
        var senddata = {
          Image: TokenFile,
          name: TokenName,
          // image: config.IPFS_IMG + "/" + ipfsimghashget.data.ipfsval,
          desc: (TokenDescription !== "" ? TokenDescription : 'This Token was Created in MYRIAD NFT platform'),
          Creator: Wallet_Details.UserAccountAddr.toLowerCase()
        }
        //console.log("IPFS send data",senddata)
        var ipfsimghashget = await ipfsImageHashGet(senddata);
        console.log("ipfsimghashget",ipfsimghashget.data.medadata)
        if (ipfsimghashget && ipfsimghashget.data && ipfsimghashget.data.medadata) {
          if (ipfsimghashget.data.medadata.ipfsval !== "") {
            setIpfsHash(ipfsimghashget.data.medadata.ipfsval)
            setipfshashurl(`${config.IPFS_IMG}/${ipfsimghashget.data.medadata.ipfsval}`)
            setAdditionalImage(ipfsimghashget.data.medadata.additionalImage)
            if (ipfsimghashget.data.medadata.ipfsmetadata) {
              var ipfsurls = ipfsimghashget.data.medadata.ipfsmetadata
              setmetadata(ipfsimghashget.data.medadata.ipfsmetadata)
              set_ipfsmetatag(`${config.IPFS_IMG}/${ipfsurls}`)
              toast.success("Approve Successfully", toasterOption);
              setApproveCallStatus('done');
            }
            else {
              setApproveCallStatus('tryagain');
              toast.error("Try Again", config.toasterOption)
            }
          }
          else {
            setApproveCallStatus('tryagain');
            toast.error("Try Again", config.toasterOption)
          }

        }
        else {
          setApproveCallStatus('tryagain');
          toast.error("Try Again", config.toasterOption)
        }
      }
    }
    catch (error) {
      setApproveCallStatus('tryagain');
      toast.error("Try Again", config.toasterOption)
    }
  }

  const MintCall = async () => {
    var web3 = new Web3(Wallet_Details.providerss);
    if (Wallet_Details.UserAccountAddr === "") {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      return false;
    }
    var gas;
    await web3.eth.getGasPrice()
      .then(async (result) => {
        gas = result;
        

      });
    var CoursetroContract = null;
    var contractCall = null;
    var TokenPriceInStr = window.web3.toWei(TokenPrice);
    // var TokenPriceInStr = (await toFixedFunc((Digits) )).toString();
    // console.log("wqywuyquyw")

    // console.log("wqywuyquyw", TokenPriceInStr)
    if (location_pathname === 'create-multiple') {
      CoursetroContract = new web3.eth.Contract(MULTIPLE, config.multipleContract);
      contractCall = await CoursetroContract.methods.mint(
        TokenPriceInStr,
        web3.utils.toWei(String(TokenRoyalities)),
        TokenQuantity,
        ipfsmetatag)

    }
    else {
      CoursetroContract = new web3.eth.Contract(SINGLE, config.singleContract);
      console.log("nkjhkilgkukjnkh",  ipfsmetatag,TokenPriceInStr,TokenQuantity,TokenRoyalities,config.singleContract);
      contractCall = await CoursetroContract.methods.mint(
        ipfsmetatag,
        TokenPriceInStr,
        TokenQuantity,
        web3.utils.toWei(String(TokenRoyalities))
      )

    }
    try {
      var mintCall = null;
      var receipt = null;
      var handle = null;
      console.log("wqywuyquyw",typeof TokenPriceInStr)
      setMintCallStatus('processing')
      if (contractCall != null) {
        await contractCall
          .send({
            from: Wallet_Details.UserAccountAddr
          })
          .on('transactionHash', (transactionHash) => {
            console.log("testing all 3@123", transactionHash)
            mintCall = transactionHash;
            if (mintCall) {
              handle = setInterval(async () => {
                receipt = await getReceipt(web3, transactionHash)
                clr1();
              }, 8000)
            }
          })
      }
    }
    catch (e) {
      console.log("dfsfdsfdsef", e)
      toast.error("Mint not Successfully", toasterOption);
      setMintCallStatus('tryagain');
    }
    async function clr1() {
      if (receipt != null) {
        clearInterval(handle);
        if (receipt.status === true) {
          Set_MintHashVal(mintCall);
          toast.success("Your Token Added Successfully", toasterOption);
          var Status = "true";
          var tokenid = receipt.logs[1].topics[2];
          const someString = Web3Utils.hexToNumber(tokenid);
          var TokenAddItemPayload = {
            Image: additionalImage,
            ipfsimage: ipfshash,
            Name: TokenName,
            Count: someString,
            Description: TokenDescription,
            Price: TokenPrice,
            Royalities: TokenRoyalities,
            Category_label: TokenCategory.label,
            Bid: TokenBid,
            Properties: TokenProperties,
            Owner: Wallet_Details.UserAccountAddr,
            Creator: Wallet_Details.UserAccountAddr,
            CategoryId: TokenCategory.value,
            Quantity: TokenQuantity,
            Balance: TokenQuantity,
            ContractAddress: ContractAddressUser,
            Status: Status,
            HashValue: mintCall,
            Type: CollectibleType,
            MinimumBid: 0,
            Clocktime: '',
            EndClocktime: '',
            UnLockcontent: '',
            PutOnSale: PutOnSale,
            PutOnSaleType: PutOnSaleType,
            CoinName: CoinName,
            additionalImage: additionalImage,
            collection: UserCollection,
            ipfsmeta:ipfsmeta,
          };
          // console.log("lkjhg", TokenAddItemPayload)
          if (Unlockoncepurchased === true) {
            TokenAddItemPayload.UnLockcontent = UnLockcontent;
          }

          if (PutOnSale === true) {
            if (PutOnSaleType === 'FixedPrice') {
              TokenAddItemPayload.Price = TokenPrice;
            }
            else if (PutOnSaleType === 'TimedAuction') {
              TokenAddItemPayload.MinimumBid = MinimumBid;
              TokenAddItemPayload.Clocktime = Clocktime;
              TokenAddItemPayload.EndClocktime = EndClocktime;
              TokenAddItemPayload.CoinName = config.tokenSymbol;
            }
          }
          await TokenAddItemAction(TokenAddItemPayload);
          var TokenAddOwnerPayload = {
            'Count': someString,
            'Price': TokenPrice,
            'Owner': Wallet_Details.UserAccountAddr,
            'Balance': TokenQuantity,
            'Quantity': TokenQuantity,
            'ContractAddress': ContractAddressUser,
            'Type': CollectibleType,
            'tokenCreator': Wallet_Details.UserAccountAddr,
            // 'previousPrice':TokenPrice,
            'HashValue': mintCall,
            'CoinName': CoinName,
            'Status': Status,
            'collection': UserCollection,
            'ipfsmeta':ipfsmeta,
            'PutOnSaleType': PutOnSaleType,
           

          };
          if (PutOnSaleType === 'TimedAuction') {
            TokenAddOwnerPayload.MinimumBid = MinimumBid;
            TokenAddOwnerPayload.Clocktime = Clocktime;
            TokenAddOwnerPayload.EndClocktime = EndClocktime;
            TokenAddOwnerPayload.CoinName = config.tokenSymbol
          }
          await TokenAddOwnerAction(TokenAddOwnerPayload);

          setMintCallStatus('done');
          setTimeout(() => window.$('#create_item_modal').modal('hide'), 1000);
          setTimeout(() => history.push("/my-items"), 1200);

        }
      }

    }
  }

  async function SignCall() {

    var web3 = new Web3(Wallet_Details.providerss);
    if (Wallet_Details.UserAccountAddr === "") {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
    }
    setSignCallStatus('processing');
    try {
      await web3.eth.personal.sign("Sign Sell Order", Wallet_Details.UserAccountAddr)

      toast.success("Sign Successfully", toasterOption);
      setSignCallStatus('done');

    }
    catch (e) {
      toast.error("Sign Failed", toasterOption);
      setSignCallStatus('tryagain');
    }
  }
  async function SignLockCall() {

    var web3 = new Web3(Wallet_Details.providerss);
    if (Wallet_Details.UserAccountAddr === "") {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
    }
    try {
      setSignLockCallStatus('processing');
      await web3.eth.personal.sign("Sign Lock Order", Wallet_Details.UserAccountAddr)
      toast.success("Sign Lock Order Successfully", toasterOption);
      setSignLockCallStatus('done');
      // setTimeout(() => window.$('#create_item_modal').modal('hide'), 1000);
      // setTimeout(() => window.location.href = config.Front_URL + "/my-items", 1200);
    }
    catch (e) {
      toast.error("Sign Failed", toasterOption);
      setSignLockCallStatus('tryagain');
    }
  }
  async function CreateItemValidation(chk) {
    if (chk) {
      var ValidateError = {};
      if (TokenName === '') {
        ValidateError.TokenName = '"Name" is not allowed to be empty';
      }
      if (TokenName !== "") {
        if ((config.nameFormat).test(TokenName)) {
          ValidateError.TokenName = '"Emoji" is not allowed';
        }
      }
      if (TokenRoyalities === '') {
        ValidateError.TokenRoyalities = '"Royalties" is not allowed to be empty';
      }
      else if (isNaN(TokenRoyalities) === true) {
        ValidateError.TokenRoyalities = '"Royalties" must be a number';
      }
      else if (TokenRoyalities > 20) {
        ValidateError.TokenRoyalities = '"Royalties" must be less than or equal to 20';
      }
      if (TokenFilePreUrl === '') {
        ValidateError.photo = '"File" is required';
      }
      if (30000000 < fileSizes) {
        ValidateError.photo = '"File" Must be below 30mb';
      }
      if (typeof TokenCategory.label === 'undefined' || TokenCategory.label === '') {
        ValidateError.TokenCategory = '"Category" is required';
      }

      if (Unlockoncepurchased === true && UnLockcontent === '') {
        ValidateError.UnLockcontent = '"Locked content" is required';
      }
      if (PutOnSale === true && PutOnSaleType === 'FixedPrice') {
        if (TokenPrice === '' || isNaN(TokenPrice) === true && TokenPrice === 0) {
          ValidateError.TokenPrice = '"Price" must be a number';
        }
        else if (TokenPrice === 0) {
          ValidateError.TokenPrice = '"Price" must be greater than zero';
        }
        else if (CoinName === "") {
          ValidateError.TokenPrice = '"Currency or Token" must be required';
        }
        if (YouWillGet === 0) {
          ValidateError.TokenPrice = 'only allowed 4 digits  after decimal point';
        }

      }
      if (PutOnSale === true && PutOnSaleType === 'TimedAuction') {
        if (MinimumBid === '') {
          ValidateError.MinimumBid = '"Bid Price" must be a number';
        }
        if (Clocktime === '') {
          ValidateError.Clocktime = '"Start Clock Time " cant be a number';
        }
        if (EndClocktime === '') {
          ValidateError.EndClocktime = '"End Clock Time " cant be a number';
        }
        if (Clocktime === 'Select Start Date') {
          ValidateError.Clocktime = '"Start Clock Time " cant be a number';
        }
        if (EndClocktime === 'Select End Date') {
          ValidateError.EndClocktime = '"End Clock Time " cant be a number';
        }
        if (Clocktime === 'Invalid Date') {
          ValidateError.Clocktime = '"Start Clock Time " cant be a number';
        }
        if (EndClocktime === 'Invalid Date') {
          ValidateError.EndClocktime = '"End Clock Time " cant be a number';
        }
      }
      if (TokenQuantity === '' || isNaN(TokenQuantity) === true && TokenQuantity === 0) {
        ValidateError.TokenQuantity = '"Number of copies" must be a number';
      }
      setValidateError(ValidateError);
      return ValidateError;
    }
    else {
      return {};
    }
  }
  async function DateChange(from, val) {
    if (from === 'StartDateDropDown') {
      if (val === 'PickStartDate') {
        ModalAction('calendar_modal', 'show');
      }
      else {
        Set_StartDate(val);
        var myDate = new Date();
        if (val === 'RightAfterListing') {
          var newdat = myDate.setDate(myDate.getDate());
        }
        else {
          var newdat = myDate.setDate(myDate.getDate() + parseInt(val));
        }
        var newdate = new Date(newdat);
        ////console.log("Date",newdate)
        set_Clocktime(newdate);
      }
    }
    else if (from === 'EndDateDropDown') {
      if (val === 'PickEndDate') {
        ModalAction('calendar_modal_expire', 'show');
      }
      else {
        Set_EndDate(val);
        var myDate = new Date();
        var newdat = myDate.setDate(myDate.getDate() + parseInt(val));
        var newdate = new Date(newdat)
        set_EndClocktime(newdate)
      }
    }
  }
  async function ModalAction(id, type) {
    window.$('#' + id).modal(type);
    if (id === 'calendar_modal') {
      if (Clocktime) {
        var dt = new Date(Clocktime);
        var dt1 = dt.toLocaleString();
        Set_StartDate(dt1);
      }
    }
    else if (id === 'calendar_modal_expire') {
      if (EndClocktime) {
        var dt = new Date(EndClocktime);
        var dt1 = dt.toLocaleString();
        Set_EndDate(dt1);
      }
    }
  }
  async function CreateItem() {
    Set_FormSubmitUserClicked(true);
    var errors = await CreateItemValidation(true);
    var errorsSize = Object.keys(errors).length;
    if (errorsSize !== 0) {
      toast.error("Form validation error. Fix all mistakes and submit again", toasterOption);
      return false
    }
    else if (Wallet_Details.providerss) {
      if (Wallet_Details.providerss == null) {
        toast.error("Please Connect to XDC Network", toasterOption)
        // Set_WalletConnected("false");
      }
      else {
        // var currAddr = window.web3.eth.defaultAccount;
        // window.web3.eth.getBalance(currAddr, async (errors, balance) => {
        //   var usercurbal = 0;
        if (typeof Wallet_Details.UserAccountBal === 'undefined' || Wallet_Details.UserAccountBal === null || Wallet_Details.UserAccountBal === 0) {
          Set_UserAccountBal(0);
        }
        else {
          // usercurbal = balance / config.decimalvalues;
          Set_UserAccountBal(Wallet_Details.UserAccountBal);
        }
        if (Wallet_Details.UserAccountBal === 0) {
          toast.error("Insufficient balance", toasterOption);
          return false;
        }
        var TokenCategory_label = TokenCategory.label;
        let payload = {
          TokenName,
          TokenRoyalities,
          image: TokenFile,
          TokenCategory_label,
          PutOnSaleType,
          TokenPrice,
        }
        const resp = await CreateTokenValidationAction(payload);
        if (resp && resp.data) {
          if (resp.data.errors) {
            var errors = resp.data.errors;
            var errorsSize = Object.keys(errors).length;
            if (errorsSize !== 0) {
              setValidateError(errors);
              toast.error("Form validation error. Fix all mistakes and submit again", toasterOption);
            }
            else {
              setValidateError({});
              window.$('#create_item_modal').modal('show');
            }
          }
        }
        // })
      }
    }
    else {
      toast.error("Please Connect to XDC Network", toasterOption);
      // Set_WalletConnected(false);
    }
  }
  const AfterWalletConnected = async () => {
  }

  const TokenImageCall = async (datas) => {
    var reqdata = {
      currAddr: datas
    }
    var tokenimages = await TokenImageCalls(reqdata);
    ////////console.log(tokenimages)
    if (tokenimages && tokenimages.data) {
      setTokenImage(tokenimages.data)
    }
  }

  return (
    <div className="inner_header">
      {/* <ConnectWallet
          Set_UserAccountAddr={Set_UserAccountAddr}
          Set_UserAccountBal={Set_UserAccountBal}
          Set_WalletConnected={Set_WalletConnected}
          Set_AddressUserDetails={Set_AddressUserDetails}
          Set_Accounts={Set_Accounts}
          WalletConnected={WalletConnected}
          set_Service_Fee={set_Service_Fee}
          Service_Fee={Service_Fee}
          AfterWalletConnected={AfterWalletConnected}
          Wen_Bln={Wen_Bln}
          set_Wen_Bln={set_Wen_Bln}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
          set_providers={set_providers}
          providerss={providerss}
        /> */}
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><Link to="/">
            <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link></div>
          <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
        </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <Link to="/create">
                  <i className="fas fa-arrow-left mr-3 arrow_back"></i>
                </Link>
                <h3 className="section-head mb-0 sec_he_sm">{location_pathname === "create-single" && 'Create Single Collectible'}{location_pathname === "create-multiple" && 'Create Multiple Collectible'}</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-5">
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              {/* <button onClick={()=>addToken()}>testttt</button> */}
              <label className="primary_label">Preview</label>
              <div className="single_collectible masonry mx-0">
                <div className="item">
                  <div className="card_inner_item">
                    <div className="d-flex justify-content-between">
                      {/* <div className="d-flex creators_details">
                      
                        {(TokenImages)
                          && TokenImages.image != ""
                          ? <a href={TokenImages.customurl != "" ? `${config.Front_URL}/${TokenImages.customurl}` : `${config.Users_URL}/${TokenImages.curraddress}`} title={"Creator : " + TokenImages.name != "" ? TokenImages.name : String(TokenImages.curraddress)}>
                            <img src={`${config.Back_URL}/images/${TokenImages._id}/${TokenImages.image}`} alt="User" className="img-fluid align-self-center" /></a>
                          : <a href={TokenImages.customurl != "" ? `${config.Front_URL}/${TokenImages.customurl}` : `${config.Users_URL}/${TokenImages.curraddress}`} title={"Creator : " + String(TokenImages.curraddress)} >
                           
                              <Avatars item="img-fluid align-self-center" />
                           
                          </a>
                        }
                     
                      
                        {(TokenImages)
                          && TokenImages.image != ""
                          ? <a href={TokenImages.customurl != "" ? `${config.Front_URL}/${TokenImages.customurl}` : `${config.Users_URL}/${TokenImages.curraddress}`} title={"Owner : " + TokenImages.name != "" ? TokenImages.name : String(TokenImages.curraddress)}>
                            <img src={`${config.Back_URL}/images/${TokenImages._id}/${TokenImages.image}`} alt="User" className="img-fluid align-self-center" /></a>
                          : <a href={TokenImages.customurl != "" ? `${config.Front_URL}/${TokenImages.customurl}` : `${config.Users_URL}/${TokenImages.curraddress}`} title={"Owner : " + String(TokenImages.curraddress)} >
                            <div className="img-fluid align-self-center" >
                              <Avatars item={TokenImages.curraddress} />
                            </div>
                          </a>
                        }
                       
                     
                        <a href="#" title={"Collection : " + config.exchangeAddress} ><img src={Loader} alt="User" className="img-fluid align-self-center" /></a>
                      </div>
                      */}
                      <div>

                      </div>
                    </div>
                    <div className="remaintime mt-3">
                      <div className="item_inner_img">
                        {((TokenFilePreUrl.split('.').pop() === 'mp4') ||
                          (TokenFilePreUrl.split('.').pop() === 'webm') ||
                          (TokenFilePreUrl.split('.').pop() === 'WEBM') ||
                          (TokenFilePreUrl.split('.').pop() === 'OGV') ||
                          (TokenFilePreUrl.split('.').pop() === 'ogv'))
                          &&
                          <video
                            id="imgPreview"
                            className="img-fluid"
                            alt="img"
                            autoPlay
                            controls
                            muted
                            playsInline
                            loop
                            src={TokenFilePreReader !== "" ? TokenFilePreReader : require('../assets/images/noimage.png')} type="video/mp4"
                          />

                        }
                        {(TokenFilePreUrl.split('.').pop() === 'mp3')
                          &&
                          <>
                            <img src={config.AudioImg} className="img-fluid" />

                            <audio
                              muted
                              id="imgPreview"
                              className="img-fluid"
                              alt="img"
                              autoPlay
                              controls
                              playsInline
                              loop
                              src={TokenFilePreReader !== "" ? TokenFilePreReader : require('../assets/images/noimage.png')} type="video/mp3"
                            >
                            </audio>
                          </>

                        }

                        {(TokenFilePreUrl.split('.').pop() === "webp"
                          || TokenFilePreUrl.split('.').pop() === "WEBP"
                          || TokenFilePreUrl.split('.').pop() === "gif"
                          || TokenFilePreUrl.split('.').pop() === "jpg"
                          || TokenFilePreUrl.split('.').pop() === "GIF"
                          || TokenFilePreUrl.split('.').pop() === "JPG"
                          || TokenFilePreUrl.split('.').pop() === "JPEG"
                          || TokenFilePreUrl.split('.').pop() === "jpeg"
                          || TokenFilePreUrl.split('.').pop() === "png"
                          || TokenFilePreUrl.split('.').pop() === "PNG")
                          &&
                          <img src={TokenFilePreReader !== "" ? TokenFilePreReader : require('../assets/images/noimage.png')} id="imgPreview" alt="Collections" className="img-fluid" />
                        }
                        {TokenFilePreReader === "" &&
                          <img src={require('../assets/images/noimage.png')} id="imgPreview" alt="Collections" className="img-fluid" />
                        }
                      </div>

                      {/* <div className="timeleft">
                        <span>03h 56mm 06s left</span>
                      </div> */}
                    </div>
                    <h2>{TokenName} </h2>
                    <div className="d-flex justify-content-between align-items-end">
                      <div>
                        <h3 className="my-2 ">
                          <span className="text-gray">
                            {(PutOnSale === false || (PutOnSale === true && PutOnSaleType === 'FixedPrice' && TokenPrice === 0)) && <span className="mr-1">Not for sale </span>}
                            {(PutOnSale === true && PutOnSaleType === 'FixedPrice' && TokenPrice > 0) && <span className="mr-1">For Sale</span>}
                            {(PutOnSale === true && PutOnSaleType === 'TimedAuction') && <span className="mr-1">Minimum Bid </span>}
                            {(PutOnSale === true && PutOnSaleType === 'UnLimitedAuction') && <span>Open for Bids </span>}
                            {TokenQuantity} of {TokenQuantity}
                          </span> </h3>

                        {(PutOnSale === true && PutOnSaleType === 'FixedPrice' && TokenPrice > 0) && <button className="btn btn_purple_sm bg-theme" data-toggle="modal" data-target="#place_bid_multiple_modal"><span>
                          <Convert
                            item={Number(TokenPrice)}
                            coinName={CoinName}
                            convertVal={1}
                          /> {CoinName}</span></button>}
                        {(PutOnSale === true && PutOnSaleType === 'TimedAuction') && <button className="btn btn_purple_sm bg-theme" data-toggle="modal" data-target="#place_bid_multiple_modal"> <span>{MinimumBid === '' ? 0 :
                          <Convert
                            item={Number(MinimumBid)}
                            coinName={'WENLAMBO'}
                            convertVal={1}
                          />} {config.tokenSymbol} </span></button>}
                        {/* {(PutOnSaleType!='TimedAuction') && <span>Place a bid</span>} */}

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </GridItem>
            <GridItem xs={12} sm={8} md={8}>
              <form className="formCls">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Upload file</label>
                        <p className="form_note">JPG, JPEG, PNG, GIF, WEBP, WEPM, OGV, MP3, FLAC, AAC or MP4 . Max 30mb.</p>
                      </div>
                      <div className="file_btn btn primary_btn">Upload
                        <input
                          className="inp_file"
                          type="file"
                          name="file"
                          accept="audio/*,video/*,image/*"
                          onChange={selectFileChange}
                        />
                      </div>
                      {ValidateError.photo && <span className="text-danger img-file">{ValidateError.photo}</span>}
                    </div>
                  </div>
                  {/* <div className="form-group col-md-12">
                    <div className="">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Choose Collection</label>
                      </div>
                      <div className="row connect_row mt-3">
                        <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                          <div className="create_box create_sing_bx" data-toggle="modal" data-target="#choose_collection_modal">

                            <img src={require("../assets/images/price_svg.svg")} alt="Fixed Price" className="img-fluid" />

                            <p>Create ERC-721</p>
                          </div>
                        </div>

                        <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                          <div className="create_box create_sing_bx">
                            <img src={require("../assets/images/loader.png")} alt="Unlimited Auction" className="img-fluid" />

                            <p>WENLAMBO</p>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>
                 */}
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start grid_toggle">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Put on Sale</label>
                        <p className="form_note">You’ll receive bids on this item</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox"
                          id="putonsale"
                          name="putonsale"
                          onChange={CheckedChange}
                          checked={PutOnSale}
                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                    {
                      (PutOnSale === false) ? ('') : (
                        <>
                          <div className="row connect_row mt-3 putonsale_sec">
                            <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                              <div className={"create_box create_sing_bx" + ((PutOnSaleType === 'FixedPrice') ? 'active' : 'inactive')} id="fixedprice" onClick={() => { changePutOnSaleType('FixedPrice') }} >
                                <img src={require("../assets/images/price_svg.svg")} alt="Fixed Price" className="img-fluid" />
                                <p>Fixed Price</p>
                              </div>
                            </div>
                            {(CollectibleType === 721) &&
                              <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                                <div className={"create_box create_sing_bx" + ((PutOnSaleType === 'TimedAuction') ? 'active' : 'inactive')} onClick={() => { changePutOnSaleType('TimedAuction') }}>
                                  <img src={require("../assets/images/timed_svg.svg")} alt="Timed Auction" className="img-fluid" />
                                  <p>Timed Auction</p>
                                </div>
                              </div>}
                            <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3" onClick={() => { changePutOnSaleType('UnLimitedAuction') }}>
                              <div className={"create_box create_sing_bx" + ((PutOnSaleType === 'UnLimitedAuction') ? 'active' : 'inactive')}>
                                <img src={require("../assets/images/unlimited_svg.svg")} alt="Unlimited Auction" className="img-fluid" />

                                <p>Unlimited Auction</p>
                              </div>
                            </div>
                          </div>

                        </>)}
                    {(PutOnSale === true && PutOnSaleType === 'FixedPrice') &&
                      <div className="row mx-0 mt-3 fixed_price_sec">
                        <label className="primary_label" htmlFor="price_new">Price</label>

                        <div className="form-row w-100">
                          <div className="form-group col-md-6">
                            <div className="input-group input_grp_style_1">

                              <input
                                type="text"
                                className="form-control selct_form_input_h"
                                placeholder="0"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                name="TokenPrice"
                                id="TokenPrice"
                                step="0.01"
                                maxLength={15}
                                onChange={inputChange}
                                // placeholder="e.g. 10"
                                autoComplete="off"
                              />
                              <div className="input-group-append">
                                <Select
                                  className="yes1 form-control primary_inp select1 selxet_app"
                                  id="basic-addon2"
                                  name="coinname"
                                  onChange={priceoptionfunc}
                                  options={priceoption}
                                  label="Select price"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                />
                                {/* <span className="input-group-text pl-0 min_h_45_px" id="basic-addon2">{config.currencySymbol}</span> */}
                              </div>
                            </div>
                            {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                          </div>
                        </div>
                        <p className="form_note">Service fee
                          <span className="font_we_700_note_txt">{Wallet_Details.Service_Fee_seller / 1e18}% </span><br />
                          You will receive <span className="font_we_700_note_txt">
                            {/* {YouWillGet} */}
                            <Convert
                              item={Number(YouWillGet)}

                              coinName={CoinName}
                              convertVal={1}
                            />
                            {CoinName}
                          </span>
                          <span className="font_we_700_note_txt">
                            <br />
                            {/* ( $ <Convert1
                              item={Number(YouWillGet)}
                              convertVal={(String(CoinName).toLowerCase() === (config.tokenSymbol).toLowerCase()) ?
                                config.WenlamboConvertVal :
                                Wallet_Details.currency_convertion}
                            /> ) */}

                            $( {CoinName == "BLKZ" ? Wallet_Details.tokenAddress.BiddingToken_USD * TokenPrice : Wallet_Details.tokenAddress.XDC_Currency_USD * TokenPrice})
                          </span>
                        </p>
                      </div>
                    }
                    {(PutOnSale === true && PutOnSaleType === 'TimedAuction') &&
                      <div className="row mt-3 timed_sec">
                        <div className="col-12 mb-3">
                          <label className="primary_label" htmlFor="price_new">Minimum bid</label>
                          <div className="input-group mb-1">
                            <input
                              type="text"
                              className="form-control selct_form_input_h"
                              placeholder="Enter minimum bid"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon3"
                              name="MinimumBid"
                              id="MinimumBid"
                              maxLength={config.maxLength}
                              onChange={inputChange}
                              autoComplete="off"
                            />
                            {ValidateError.MinimumBid && <span className="text-danger">{ValidateError.MinimumBid}</span>}
                            <div className="input-group-append">
                              <p className="yes1 form-control primary_inp select1 selxet_app">{config.tokenSymbol}</p>
                            </div>
                          </div>
                          <p className="form_note">   Bids below this amount won't be allowed. If you not enter any amount we will consider as 0</p>
                        </div>
                        <div className="col-12 col-lg-6  mb-3">
                          <div className="single_dd_1">
                            <label className="primary_label" htmlFor="start_date">Starting Date</label>
                            <div className="dropdown">
                              <button className="btn btn-secondary dropdown-toggle filter_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                {StartDate}<i className="fas fa-chevron-down"></i>
                              </button>
                              <div className="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                                <div >Select Date</div>
                                <div id="RightAfterListing" onClick={() => { DateChange('StartDateDropDown', 'RightAfterListing') }}>Right after listing</div>
                                <div id="PickStart" onClick={() => { DateChange('StartDateDropDown', 'PickStartDate') }} >Pick specific date</div>
                              </div>
                            </div>
                            {ValidateError.Clocktime && <span className="text-danger">{ValidateError.Clocktime}</span>}
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <div className="single_dd_1">
                            <label className="primary_label" htmlFor="start_date">Expiration Date</label>
                            <div className="dropdown">
                              <button className="btn btn-secondary dropdown-toggle filter_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {EndDate}<i className="fas fa-chevron-down"></i>
                              </button>
                              <div className="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                                <div>Select Date</div>
                                <div onClick={() => { DateChange('EndDateDropDown', '1 Day') }}>1 day</div>
                                <div onClick={() => { DateChange('EndDateDropDown', '3 Day') }}>3 days</div>
                                <div onClick={() => { DateChange('EndDateDropDown', 'PickEndDate') }}>Pick specific date</div>

                              </div>
                            </div>
                            {ValidateError.EndClocktime && <span className="text-danger">{ValidateError.EndClocktime}</span>}

                          </div>

                        </div>
                        <div className="col-12">
                          <p className="form_note_link_boild" data-toggle="modal" data-target="#learn_modal">Learn more how timed auctions work</p>

                        </div>

                      </div>


                    }</div>
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start grid_toggle">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Unlock Once Purchased</label>
                        <p className="form_note">Content will be unlocked after successful transaction</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input
                          type="checkbox"
                          id="unlockoncepurchased"
                          name="unlockoncepurchased"
                          onChange={CheckedChange}
                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                    {
                      (Unlockoncepurchased) &&
                      <div className="form-group unlock_sec">
                        <input type="text"
                          className="form-control primary_inp mb-1"
                          name="UnLockcontent"
                          id="UnLockcontent"
                          onChange={inputChange}
                          placeholder="Digital key, code to redeem or link to a file.." />
                        <p className="form_note">Tip: Markdown syntax is supported</p>
                      </div>

                    }
                    {ValidateError.UnLockcontent && <span className="text-danger">{ValidateError.UnLockcontent}</span>}

                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Name</label>
                    <input type="text"
                      className="form-control primary_inp"
                      name="TokenName"
                      id="TokenName"
                      onChange={inputChange}
                      placeholder="e.g. Redeemable" />
                    {ValidateError.TokenName && <span className="text-danger">{ValidateError.TokenName}</span>}
                  </div>

                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Description <span className="text-muted">(Optional)</span></label>
                    <textarea type="text"
                      className="form-control primary_inp"
                      id="desccription"
                      name="TokenDescription"
                      onChange={inputChange}
                      placeholder="Description about your NFTs..."
                      autoComplete="off"
                    ></textarea>

                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="Collection">Select Collection</label>
                    <Select
                      className="yes1 form-control primary_inp select1 selxet_app"
                      id="userCollection"
                      name="userCollection"
                      onChange={selectChange}
                      maxMenuHeight={220}
                      options={CollectionList}
                      label="Select Collection"
                      placeholder="Select Collection"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />


                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="category">Category</label>
                    <Select
                      className="yes1 form-control primary_inp select1 selxet_app"
                      id="tokenCategory"
                      name="tokenCategory"
                      onChange={selectChange}
                      maxMenuHeight={220}
                      options={CategoryOption}
                      label="Select or type name"
                      placeholder="Select or type name"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                    {ValidateError.TokenCategory && <span className="text-danger"><br />{ValidateError.TokenCategory}</span>}

                  </div>
                </div>
                <div className="form-row">
                <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="royalties">Royalties</label>
                    <div className="inp_grp">
                      <input type="text"
                        className="form-control primary_inp"
                        name="TokenRoyalities"
                        onChange={inputChange}
                        id="royalties"
                        placeholder="10%" />
                      <p className="inp_append">%</p>
                      <span>Suggested: 0%,5%, 10%, 20%. Maximum is 20%</span>
                      {ValidateError.TokenRoyalities && <span className="text-danger"><br />{ValidateError.TokenRoyalities}</span>}

                    </div>
                    {/* <p className="error_text">Royalties is required</p> */}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="properties">Properties <span className="text-muted">(Optional)</span></label>
                    <input type="text"
                      className="form-control primary_inp"
                      id="properties"
                      name="TokenProperties"
                      onChange={inputChange}
                      placeholder="e.g. size" />
                    {/* <p className="error_text">Properties is required</p> */}
                  </div>
                  {location_pathname === 'create-multiple' &&
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="desccription">Number of copies</label>
                      <input
                        type="text"
                        className="form-control"
                        id="TokenQuantity"
                        name="TokenQuantity"
                        onChange={inputChange}
                        placeholder="e.g. 1"
                        value={TokenQuantity}
                        maxLength={3}
                        autoComplete="off"
                      />
                      {ValidateError.TokenQuantity && <span className="text-danger"><br />{ValidateError.TokenQuantity}</span>}
                    </div>
                  }

                  

                </div>
                <div>
                  {/* <Button className="create_btn">Create item</Button> */}
                  <Button className="create_btn" onClick={CreateItem}>Create item</Button>

                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
      {/* Choose Collection Modal */}
      <div className="modal fade primary_modal" id="choose_collection_modal" tabIndex="-1" role="dialog" aria-labelledby="choose_collection_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="choose_collection_modalLabel">Collection</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex align-items-center flex_block_mob">
                <div className="hex center">
                  <div className="hex__shape">
                    <div className="hex__shape">
                      <div className="hex__shape">
                        <img src={require("../assets/images/img_01.png")} alt="logo" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="font_14 font_600 line_20 mb-1 text_brown mb-3">We recommend an image of at least 400x400. Gifs work too.</p>
                  <div className="file_btn primary_btn d-inline-block btn_small">Choose File
                    <input className="inp_file" type="file" name="file" />
                  </div>
                </div>
              </div>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="name">Display Name <span className="text-muted">(30 available)</span></label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="Enter token name" />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="desccription">Symbol <span className="text-muted">(required)</span></label>
                    <input type="text" className="form-control primary_inp" id="desccription" placeholder="Enter token symbol" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="name">Description <span className="text-muted">(Optional)</span></label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="Spread some words about token collection" />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="desccription">Short url</label>
                    <input type="text" className="form-control primary_inp" id="desccription" defaultValue="Fayre.io/" />
                  </div>
                </div>
                <div className="text-center">
                  <Button className="create_btn btn-block">Create Collection</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* ens choose collection modal */}
      {/* create_item Modal */}
      <div className="modal fade primary_modal" id="create_item_modal" tabIndex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="create_item_modalLabel">Follow Steps</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="media approve_media">
                  {ApproveCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                  {ApproveCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                  {ApproveCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                  {ApproveCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}

                  {/* <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i> */}
                  {/* <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Upload Meta Data</p>
                    <p className="mt-0 approve_desc">Upload meta contents to ipfs</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="create_btn btn-block"
                    disabled={(ApproveCallStatus === 'processing' || ApproveCallStatus === 'done')}
                    onClick={ApproveCall}
                  >
                    {ApproveCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {ApproveCallStatus === 'init' && 'Approve'}
                    {ApproveCallStatus === 'processing' && 'In-progress...'}
                    {ApproveCallStatus === 'done' && 'Done'}
                    {ApproveCallStatus === 'tryagain' && 'Try Again'}


                  </Button>
                </div>
                <div className="media approve_media">
                  {MintCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                  {MintCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                  {MintCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                  {MintCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}

                  {/* <i className="fas fa-check mr-3" aria-hidden="true"></i> */}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Upload files & Mint token</p>
                    <p className="mt-0 approve_desc">Call contract method</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="create_btn btn-block"
                    disabled={(ApproveCallStatus !== 'done' || MintCallStatus === 'processing' || MintCallStatus === 'done')}
                    onClick={MintCall}
                  >
                    {MintCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {MintCallStatus === 'init' && 'Start'}
                    {MintCallStatus === 'processing' && 'In-progress...'}
                    {MintCallStatus === 'done' && 'Done'}
                    {MintCallStatus === 'tryagain' && 'Try Again'}
                  </Button>
                </div>

                <div className="media approve_media">
                  {/* {SignCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                  {SignCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                  {SignCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                  {SignCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>} */}

                  {/* <i className="fas fa-check mr-3" aria-hidden="true"></i> */}
                  {/* <div className="media-body">
                    <p className="mt-0 approve_text">Sign sell order</p>
                    <p className="mt-0 approve_desc">Sign sell order using to our wallet</p>
                  </div> */}
                </div>
                <div className="text-center my-3">
                  {/* <Button className="create_btn btn-block"
                    disabled={(MintCallStatus !== 'done' || SignCallStatus === 'processing' || SignCallStatus === 'done')}
                    onClick={SignCall}>
                    {SignCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    {SignCallStatus === 'init' && 'Start'}
                    {SignCallStatus === 'processing' && 'In-progress...'}
                    {SignCallStatus === 'done' && 'Done'}
                    {SignCallStatus === 'tryagain' && 'Try Again'}

                  </Button> */}
                </div>

                <div className="media approve_media">
                  {/* {SignLockCallStatus === 'init' && <i className="fas fa-check mr-3 pro_initial" aria-hidden="true"></i>}
                  {SignLockCallStatus === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>}
                  {SignLockCallStatus === 'done' && <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>}
                  {SignLockCallStatus === 'tryagain' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>} */}

                  {/* <i className="fas fa-check mr-3" aria-hidden="true"></i> */}
                  {/* <div className="media-body">
                    <p className="mt-0 approve_text">Sign lock order</p>
                    <p className="mt-0 approve_desc">Sign lock order using to our wallet</p>
                  </div> */}
                </div>
                {/* <div className="text-center mt-3">
                  <Button className="create_btn btn-block"
                    disabled={(SignCallStatus !== 'done' || SignLockCallStatus === 'processing' || SignLockCallStatus === 'done')}
                    onClick={SignLockCall}>
                    {SignLockCallStatus === 'init' && 'Start'}
                    {SignLockCallStatus === 'processing' && 'In-progress...'}
                    {SignLockCallStatus === 'done' && 'Done'}
                    {SignLockCallStatus === 'tryagain' && 'Try Again'}

                  </Button>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end create_item modal */}


      {/* learn Modal */}
      <div className="modal fade primary_modal" id="learn_modal" tabIndex="-1" role="dialog" aria-labelledby="learn_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="learn_modalLabel">How timed auction work</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>When you put your item on timed auction, you choose currency, minimum bid, starting and ending dates of your auction.</p>

              <p>The bidder can only place a bid which satisfies the following conditions:</p>
              <ol>
                <li>
                  It is at least minimum bid set for the auction
                </li>
                <li>
                  It is at least 5% higher than the current highest bid

                </li>
              </ol>

              <p>Note that some bids may disappear with time if the bidder withdraws their balance. At the same time, some bids may reappear if the bidder has topped up their balance again.</p>

              <p>Auction cannot be cancelled after any valid bid was made. Any bid placed in the last 10 minutes extends the auction by 10 minutes.</p>

              <p>In the 48 hours after the auction ends you will only be able to accept the highest available bid placed during the auction. As with regular bids, you will need to pay some gas to accept it.Note that you can always decrease the price of your listing for free, without making a transaction or paying gas. You can view all your items here.</p>


            </div>
          </div>
        </div>
      </div>
      {/* end learn modal */}

      {/* calendar Modal */}
      <div className="modal fade primary_modal" id="calendar_modal" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="pb-3">

                <Datetime
                  input={false}
                  value={Clocktime}
                  onChange={(value) => set_Clocktime(value)} />
              </div>
              <div className="text-center pb-3">
                <Button className="btn btn_blue" id="doneStartDate" onClick={() => ModalAction('calendar_modal', 'hide')}>Done</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end calendar modal */}
      {/* calendar Modal */}
      <div className="modal fade primary_modal" id="calendar_modal_expire" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="pb-3">
                <Datetime
                  input={false}
                  value={EndClocktime}
                  onChange={(value) => set_EndClocktime(value)}
                />
              </div>
            </div>
            <div className="text-center">
              <Button className="primary_btn" id="doneEndDate" onClick={() => ModalAction('calendar_modal_expire', 'hide')}>Done</Button>
            </div>
          </div>
        </div>
      </div>
      {/* end calendar modal */}

    </div>
  );
}
