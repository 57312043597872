import axios from "axios";
import config from '../../lib/config';
import Web3 from 'web3';

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;

export const getCurAddr = async () => {
  var currAddr = '';
  if (window.ethereum) {
    var web3 = new Web3(window.ethereum);
    if(
      web3&&
      window.web3
      && window.web3.eth
      && window.web3.eth.defaultAccount
    ) {
      // var accVal = await web3.eth.getAccounts();
      // if(accVal[0]) {
      //   currAddr = accVal[0];
      // }
      currAddr = window.web3.eth.defaultAccount;
      currAddr = currAddr.toString();
    }
  }
  return currAddr;
}

export const ParamAccountAddr_Detail_Get = async (Payload) => {
  ////////console....log('Payload',Payload);
  try {
    let Resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/user/address/details/getAddress`,
      'params': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const getfaq = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/user/getfaq`,
    });
    ////////console....log("faq_list:",resp.data.data)
    return {
      result: resp.data.data
    }
  }
  catch (err) {
  }
}
export const FollowChange_Action = async (Payload) => {
  ////////console....log('Payload',Payload);
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/user/follow/change`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const PutOnSale_Action = async (Payload) => {
}

export const ToastShow = async (data) => {
  ////////console....log('datadatadatadata',data)
  if (data.toast && data.toast.type && data.toast.msg) {
    if(data.toast.type === 'success') {
      toast.success(data.toast.msg, toasterOption)
    } else {
      toast.error(data.toast.msg, toasterOption)
    }
  }
}

export const UserProfile_Update_Action = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/user/profile/update`,
      'data': Payload
    })
    ToastShow(Resp.data);
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const User_FollowList_Get_Action = async (Payload) => {
  ////////console....log('Payload',Payload);
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/user/follow/list/`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const Collectibles_Get_Action = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/user/collectibles`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const changeReceiptStatus_Action = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/user/changereceiptstatus`,
      'data': Payload
    })
    return {
      data: Resp
    }
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const AddressUserDetails_GetOrSave_Action = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/user/address/details/getorsave`,
      'data': Payload
    })
    return {
      data: Resp
    }
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}


export const editprofile = async (data) => {
  try {
      const bodyFormData = new FormData();
      bodyFormData.append("name", data.name);
      bodyFormData.append("customurl", data.customurl);
      bodyFormData.append("bio", data.bio);
      bodyFormData.append("twitter", data.twitter);
      bodyFormData.append("photo", data.photo);
      bodyFormData.append("currAddr", data.currAddr);
       bodyFormData.append("youtube", data.youtube);
      bodyFormData.append("instagram", data.instagram);
      bodyFormData.append("email", data.email);
      
      bodyFormData.append("facebook", data.facebook);

      bodyFormData.append("facebookcheck", data.facebookcheck);
      bodyFormData.append("instagramcheck", data.instagramcheck);
      bodyFormData.append("youtubecheck", data.youtubecheck);
      bodyFormData.append("twittercheck", data.twittercheck);
      let respData = await axios({
          'method': 'post',
          'url': `${config.vUrl}/user/editprofile/`,
          'headers': {
              'Authorization': localStorage.user_token
          },
          data: bodyFormData
      });
      ////console.log("eweuiwueiuwueiw",respData.data)
      return {
          loading: false,
          userValue: respData.data
      }

  }
  catch (err) {
      return {
          loading: false,
          error: returnErr(err)
      }
  }
}
function returnErr(err) {
  if(err.response && err.response.data && err.response.data.errors) {
      return err.response.data.errors;
  }
  else {
      return '';
  }
}

export const getprofile = async (data, dispatch) => {
  try {
      let respData = await axios({
          'method': 'post',
          'url': `${config.vUrl}/user/getprofile`,
          'headers': {
              'Authorization': localStorage.user_token
          },
          data
      });

      return {
          loading: false,
          userValue: respData.data.userValue
      }
  }
  catch (err) {
      return {
          loading: false,
          error: returnErr(err)
      }
  }
}

export const getSearchList = async(data) => {
  try {
    let respData = await axios({
        'method': 'post',
        'url': `${config.vUrl}/token/getSearchList`,
        'headers': {
            'Authorization': localStorage.user_token
        },
        data
    });
    return {
        loading: false,
        searchlist: respData.data
    }
}
catch (err) {
    return {
        loading: false,
        error: returnErr(err)
    }
}
}
export async function coverimagevalidations(data) {
  ////////console....log("profileAsSasaSadd" + JSON.stringify(data))
  var formData = new FormData();
  // ////////console....log('image',data.file)
  formData.append('currAddr', data.currAddr);
  formData.append('image',data.file)
  try {
          let respData = await axios({
                  'method': 'post',
                  'url': `${config.vUrl}/user/test/coverimagevalidation`,
                  'data':formData
          })
          
          ////////console....log("SADASDasdasd" + JSON.stringify(respData))
          return {
                  loading: false,
                  error: respData.data
          }

  } catch (err) {
          return {
                  loading: false,
                  error: err.response.data
          }
  }
}

export const coverImage = async (data) => {
  ////////console....log("coverImage", data)
  try {
      const bodyFormData = new FormData();
      bodyFormData.append("coverimage", data.file);
      bodyFormData.append("accounts", data.currAddr);


      let respData = await axios({
          'method': 'post',
          'url': `${config.vUrl}/user/test/coverImage/`,
          'headers': {
              'Authorization': localStorage.user_token
          },
          data: bodyFormData
      });

      return {
          loading: false,
          userValue: respData.data
      }

  }
  catch (err) {

      return {
          loading: false,
          error: err.response.data.errors
      }
  }
}

export const pics1 = async (data) => {
  ////////console....log("coverImage", data)
  try {
    

      let respData = await axios({
          'method': 'post',
          'url': `${config.vUrl}/user/test/pics1/`,
          'headers': {
              'Authorization': localStorage.user_token
          },
          data: data
      });
       return {
          // loading: false,
          userValue: respData.data
      }

  }
  catch (err) {

      return {
          loading: false,
          error: err.response.data.errors
      }
  }
}
export const checkFollower = async (data) => {
  try {
    let respData = await axios({
      'method': 'post',
      'url': `${config.vUrl}/user/checkFollower`,
      'headers': {
        'Authorization': localStorage.user_token
      },
      data
    });
    return {
      loading: false,
      follower: respData.data
    }
  }
  catch (err) {
    return {
      loading: false,
      error: returnErr(err)
    }
  }
  }
  export const getFollowers = async (data) => {
    try {
    let respData = await axios({
      'method': 'post',
      'url': `${config.vUrl}/user/getFollowers`,
      'headers': {
        'Authorization': localStorage.user_token
      },
      data
    });
    return {
      loading: false,
      follower: respData.data
    }
  }
  catch (err) {
    return {
      loading: false,
      error: returnErr(err)
    }
  }
  }
  export const followUnfollow = async (data) => {
    try {
      let respData = await axios({
          'method': 'post',
          'url': `${config.vUrl}/user/followUnfollow`,
          'headers': {
              'Authorization': localStorage.user_token
          },
          data
      });
      return {
          loading: false,
          follower: respData.data
      }
  }
  catch (err) {
      return {
          loading: false,
          error: returnErr(err)
      }
  }
  }
export const getMyActivity = async (addr) => {
  ////////console....log("getMyActivity checking"+addr)
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/user/activity/header`,
      data: addr
    });
    ////////console....log("faq_list:",JSON.stringify(resp))
    return {
      result: resp.data.list
    }
  }
  catch (err) {
  }
}

export const burnToken = async (addr) => {
  ////////console....log("getMyActivity checking"+addr)
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/burns/burnToken`,
      data: addr
    });
    ////////console....log("burnToken:",JSON.stringify(resp))
    return {
      result: resp.data
    }
  }
  catch (err) {
  }
}


export const toFixedNumber = (x,l,m)=> {
  
  if (Math.abs(x) < 1.0) {
      let e = parseInt(x.toString().split('e-')[1]);
      if (e) {
          x *= Math.pow(10, e - 1);
          x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
  } else {
      var e = parseInt(x.toString().split('+')[1]);
      if (e > 20) {
          e -= 20;
          x /= Math.pow(10, e);
          x += (new Array(e + 1)).join('0');
      }
  }
    var j;
    var q = String(x)
    if(Number(q) < 0.0001)
    {
    for (var i = 0; i <= q.length; i++){
      if((q.charAt(i) !== '.') && (q.charAt(i) !== '0'))
      {
        j = q.slice(0,i+3)
        i = q.length
      }
    }
  }
  else{
    j = q
  }
  return j;
}

export const CollectionValidationAction = async (payload) => {
  // console.log('payloaddthbgth', payload);
  try {
    let respData = await axios({
      'method': 'post',
      'url': `${config.vUrl}/user/Collectibleval`,
      data: payload
    });
    // console.log("dfgbhgfdbhgrh",respData.data);
    return {
     
      data: respData.data
    }
  }
  catch (err) {
  }
}

// export const addUserCollection = async (payload) => {
//   console.log("gmjiofngijfdn",payload);
//   try{
//       const bodyFormData = new FormData();
//       bodyFormData.append("collectionAddress", payload.collectionAddress);
//       bodyFormData.append("collectionType", payload.collectionType);
//       bodyFormData.append("collectionName", payload.collectionName);
//       bodyFormData.append("customurl", payload.customurl);
//       bodyFormData.append("userAddress", payload.userAddress);
//       bodyFormData.append("Image", payload.imageVal);
//       bodyFormData.append("coverImage", payload.coverval);
//     let respData = await axios({
//       'method': 'post',
//       'url': `${config.v1Url}/user/AddUserCollection`,
//       'data' : bodyFormData,
//   });
//   return {
//       message: respData
//   }
// }
// catch (err) {
//   return { error: err }
// }
// }


export const addUserCollection = async (payload) => {
  // console.log("gmjiofngijfdn",payload.from);
  try{
      const bodyFormData = new FormData();
      if(payload.from !="FindOwner")
      {
        console.log("collection")
      bodyFormData.append("collectionType", payload.formValue.collectionType);
      bodyFormData.append("collectionName", payload.formValue.collectionName);
      bodyFormData.append("customurl", payload.formValue.customurl);
      bodyFormData.append("userAddress", payload.userAddress);
      bodyFormData.append("Image", payload.formValue.profileImg);
      bodyFormData.append("coverImage", payload.formValue.coverImg);
      }
  
// console.log("sAHRgsrtjh",bodyFormData)

      
     
    let respData = await axios({
      'method': 'post',
      'url': `${config.v1Url}/user/AddUserCollection`,
      'data' : bodyFormData,
  });
  return {
      message: respData
  }
}
catch (err) {
  return { error: err }
}
}

export const getUserCollection = async (payload) => {
  // console.log("fnvhfduhivgbf",payload);
  try{
    let respData = await axios({
      'method': 'post',
      'url': `${config.v1Url}/user/getUserCollection`,
      'data' : payload,
  });
  return {
      message: respData
  }
}
catch (err) {
  return { error: err }
}
}