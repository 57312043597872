//kslp
import React, { useEffect, useState, useRef } from "react";
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link, useParams } from "react-router-dom";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import { Scrollbars } from 'react-custom-scrollbars';
import HeaderLinks from "components/Header/HeaderLinks.js";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import range from "../../src/assets/images/dollar.png";
import collect from "../../src/assets/images/category.png";
import sales from "../../src/assets/images/sales.png";
import Collection from "../../src/assets/images/collection.png";
import filter from "../../src/assets/images/sort.png";
// mysid
import TokenCard from './separate/TokenCard'

import axios from 'axios';


import config from '../lib/config';
import {
  ParamAccountAddr_Detail_Get,
} from '../actions/v1/user';
import {
  CollectiblesList_Home,
  topCreatorsApi,
  Explorecollection
} from '../actions/v1/token';
import { CancelOrderRef } from './separate/CancelOrderRef';
import { LikeRef } from './separate/LikeRef';
import { BurnRef } from './separate/BurnRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import ConnectWallet from './separate/Connect-Wallet';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';

import { useSelector } from "react-redux";

import '@metamask/legacy-web3'
import { toast } from 'react-toastify';

import { getcmslistinhome, getpromotion } from '../actions/v1/report'
import isEmpty from "lib/isEmpty";

toast.configure();
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function Explore(props) {

  const classes = useStyles();


  const Wallet_Details = useSelector(state => state.wallet_connect_context);

  const { ...rest } = props;
  const { Param_category } = useParams()
  console.log("dfhuigbihudsb", Param_category);
  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var BurnForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  var PlaceABidForwardRef = useRef();
  const [topcreatorsection, settopcreatorsection] = React.useState({});
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = useState('');
  const [HitItem, Set_HitItem] = useState({});
  const [Categorylist, setCategorylist] = useState([]);
  const [TokenList, setTokenList] = useState([]);
  const [CatName, setCatName] = useState('All');
  const [CatBasedTokenList, setCatBasedTokenList] = useState({ 'loader': false, 'All': { page: 1, list: [], onmore: true } });
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [item, Set_item] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [Bids, Set_Bids] = useState([]);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = useState('init');
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [Time_Auction_List, Set_Time_Auction_List] = useState([]);
  const [Recent_Nft_List, Set_Recent_Nft_List] = useState([]);
  const [Service_Fee, set_Service_Fee] = useState(0);
  const [convertVal, setConvertVal] = React.useState(0);
  const [Promotion_List, Set_Promotion_list] = useState({})
  const [Creatorcount, setCreatorcount] = useState(0);
  const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = useState({})
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [getcmslistinhome1, setgetcmslistinhome1] = useState({})
  const [getcmslistinhome12, setgetcmslistinhome12] = useState({})
  const [LoadDisable, Set_LoadDisable] = useState(false);
  const [typefunfix11, Settypefun] = useState('');
  const [hotcol, Sethotcoll] = useState({});
  const [newcollstate, Setnewcollstate] = useState({});

  useEffect(() => {

    getInit();
    // if(getcmslistinhome1.length==0){
    Hotcoll();
    // }
  }, [])
  useEffect(() => {
    TokenListCall()
  }, [typefunfix11])
  useEffect(() => {
    catChange(Param_category)
  }, [Param_category])

  const getcmslistinhomes = async () => {
    var reqdata = {
      load: 'home'
    }
    var convers = await getcmslistinhome(reqdata);
    if (convers && convers.data) {
      setgetcmslistinhome1(convers.data)
    }
  }
  const getcmslistinhomes1 = async () => {
    var reqdata = {
      load: 'tophome'
    }
    var convers = await getcmslistinhome(reqdata);
    if (convers && convers.data) {
      setgetcmslistinhome12(convers.data)
    }
  }
  const promotionData = async () => {
    var test = await getpromotion();
    //////console.log("Dsajdashdjasgdaskdkagshdghasgkd",test)
    if (test && test.userValue) {
      Set_Promotion_list(test.userValue[0])
    }
  }



  async function getInit() {
    // TokenListCall();
    getcmslistinhomes();
    getcmslistinhomes1();
    promotionData();
    CategoryListCall();
    topCreatorsFUnc();
    // timeAuctionFUnc();
    // recentNftFUnc();
    // ;}

  }

  const topCreatorsFUnc = async () => {
    var topCraete = await topCreatorsApi();
    if (topCraete && topCraete.data) {
      settopcreatorsection(topCraete.data)
    }
  }

  const Get_MyItemAccountAddr_Details = async (payload) => {
    var Resp = await ParamAccountAddr_Detail_Get(payload);
    if (
      Resp
      && Resp.data
      && Resp.data.User
      && Resp.data.User.curraddress
    ) {
      Set_MyItemAccountAddr(Resp.data.User.curraddress);
      if (Resp.data.User) {
        Set_MyItemAccountAddr_Details(Resp.data.User);
      }
    }

  }

  const Hotcoll = async () => {
    var collectiongal = await Explorecollection();
    console.log("explorecomming", collectiongal.data.data.collections)
    Sethotcoll(collectiongal.data.data.collections)
  }

  async function CategoryListCall() {
    axios
      .get(`${config.vUrl}/token/category/list`)
      .then(response => {
        if (response && response.data && response.data.list) {
          console.log("gvjfbgvhbftfg", response.data.list);
          setCategorylist(response.data.list);
        }
      })
      .catch(e => console.log(e))
  }

  const onLoadMore = () => {
    console.log("sdgvfsdgvdsfcgdgvdf", CatBasedTokenList[CatName].page);
    console.log("fghbdfbgfdbgdfb", CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page + 1);
    CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page + 1;
    setCatBasedTokenList(CatBasedTokenList);

    TokenListCall({
      page: CatBasedTokenList[CatName].page + 1
    });
  }

  async function catChange(name, type) {
    // console.log("dgfbggfd", type);
    if (name !== CatName) {
      setCatName(name);
      Setnewcollstate(type)
      // console.log("rfvgevgfdv", name);

      // if (typeof CatBasedTokenList[name] === 'undefined') {
      //   CatBasedTokenList[name] = { page: 1, list: [], onmore: true };
      //   console.log("htrghtrghb",CatBasedTokenList);
      //   setCatBasedTokenList(CatBasedTokenList);
      //   TokenListCall({ CatName: name, page: 1 });
      // }
    }
  }

  useEffect(() => {
    if (typeof CatBasedTokenList[CatName] === 'undefined') {
      CatBasedTokenList[CatName] = { page: 1, list: [], onmore: true };
      // console.log("htrghtrghb", CatBasedTokenList);
      setCatBasedTokenList(CatBasedTokenList);
      TokenListCall({ CatName: CatName, page: 1 });
    }
  }, [CatName])





  async function TokenListCall(data = {}) {
    var currAddr = Wallet_Details.UserAccountAddr
    var name = CatName;
    // console.log("vdscvffsd", CatName);
    if (data.CatName) {
      // console.log("gbifrbirhdb", data.CatName);
      name = data.CatName
    }

    var from = typefunfix11;
    // console.log("vjbdsvchb", typefunfix11);
    if (newcollstate == 'collection') {
      console.log("Other Filters")
      var name = CatName
      var payload = {
        limit: 10,
        page: (CatBasedTokenList[name] && CatBasedTokenList[name].page) ? CatBasedTokenList[name].page : 1,
        currAddr: currAddr,
        customurl: name,
        from: "collection",
        // saleType:from,
      }

    }
    else {
      console.log("Collection Filters")
      var payload = {
        limit: 10,
        page: (CatBasedTokenList[name] && CatBasedTokenList[name].page) ? CatBasedTokenList[name].page : 1,
        currAddr: currAddr,
        CatName: name,
        from: "filter",
        // saleType:from,
      }
    }

    Set_LoadDisable(true)
    // console.log("loadMore>>>", payload, CatBasedTokenList[name], CatBasedTokenList)
    CatBasedTokenList.loader = true;
    setCatBasedTokenList(CatBasedTokenList);
    // console.log("devfkjdfgdhvbihfb", payload);
    var resp = await CollectiblesList_Home(payload);
    // console.log("dbcfhbdsjhvcbjshd", resp);
    CatBasedTokenList.loader = false;
    setCatBasedTokenList(CatBasedTokenList);
    Set_LoadDisable(false)
    if (resp && resp.data && resp.data.from === 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      setTokenList(TokenList.concat(resp.data.list));
      setCreatorcount(resp.data.list.length);
      if (typeof CatBasedTokenList[name] == 'undefined') {
        CatBasedTokenList[name] = { page: 1, list: [] };
      }
      CatBasedTokenList[name].list = CatBasedTokenList[name].list.concat(resp.data.list);
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
    
    else {
      CatBasedTokenList[name].onmore = false;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
  }


  async function AfterWalletConnected() {
    Get_MyItemAccountAddr_Details();
    LikeForwardRef && LikeForwardRef.current && LikeForwardRef.current.getLikesData();
  }


  async function typefun(data) {
    console.log("fgbfdbfd", data);
    Settypefun(data)
    // console.log("devfkjdjhgjhgjhvbihfb", data);
  }




  return (

    <div>

      <div className="home_header">


        <PutOnSaleRef
          ref={PutOnSaleForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}

          setConvertVal={setConvertVal}
          convertVal={convertVal}

        />
        <BurnRef
          ref={BurnForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          setConvertVal={setConvertVal}
          convertVal={convertVal}

        />
        <PlaceAndAcceptBidRef
          ref={PlaceABidForwardRef}
          Set_AddressUserDetails={Set_AddressUserDetails}
          Set_MyItemAccountAddr={Set_MyItemAccountAddr}
          Set_tokenCounts={Set_tokenCounts}
          Set_item={Set_item}
          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
          Set_MyTokenBalance={Set_MyTokenBalance}
          Set_Bids={Set_Bids}
          Set_AccepBidSelect={Set_AccepBidSelect}
          Set_tokenBidAmt={Set_tokenBidAmt}
          Set_NoOfToken={Set_NoOfToken}
          Set_ValidateError={Set_ValidateError}
          Set_TokenBalance={Set_TokenBalance}
          Set_YouWillPay={Set_YouWillPay}
          Set_YouWillPayFee={Set_YouWillPayFee}
          Set_YouWillGet={Set_YouWillGet}
          Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
          Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}
          AddressUserDetails={Wallet_Details.AddressUserDetails}
          MyItemAccountAddr={MyItemAccountAddr}
          tokenCounts={tokenCounts}
          item={item}
          tokenCounts_Detail={tokenCounts_Detail}
          MyTokenBalance={MyTokenBalance}
          Bids={Bids}
          AccepBidSelect={AccepBidSelect}
          tokenBidAmt={tokenBidAmt}
          NoOfToken={NoOfToken}
          ValidateError={ValidateError}
          TokenBalance={TokenBalance}
          YouWillPay={YouWillPay}
          YouWillPayFee={YouWillPayFee}
          YouWillGet={YouWillGet}
          BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
          BidApply_SignCallStatus={BidApply_SignCallStatus}
          AllowedQuantity={AllowedQuantity}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        />
        <PurchaseNowRef
          ref={PurchaseNowForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        />
        <WalletRef
          ref={WalletForwardRef}
        />
        <LikeRef

          ref={LikeForwardRef}
          setLikedTokenList={setLikedTokenList}
          MyItemAccountAddr={MyItemAccountAddr}

        />
        <CancelOrderRef
          ref={CancelOrderForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}

          setConvertVal={setConvertVal}
          convertVal={convertVal}

        />
        <ReportNowRef
          ref={ReportForwardRef}
        />
        <ShareNowRef
          ref={ShareForwardRef}
        />

        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<>
            <div className="light_logo"><Link to="/">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link></div>
            <div className="dark_logo"><Link to="/">
              <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
          </>}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 20,
            color: "white"
          }}
          {...rest}
        />

        <section className="navtabs pb-2 recentlyadded explore">
          <div className="container-fluid container-theme container-lg">
            <div className="flex_block_mob scrollheight align-items-center justify-content-center mb-5">
              <h1 className="title_text_white">Explore</h1>
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-12">
                  <div className="row">

                    <div className="col-lg-10 col-sm-10 col-12 col-md-10 explore_enf">

                      <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <img src={collect} className="filteradd" />
                            Category </div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 150 }} className="nto_scrol_div">
                                <ul className="explorebg">
                                  {/* {console.log("jfhdbhjufdsbgv", Categorylist)} */}
                                  {Categorylist.length > 0 && Categorylist.map((itempro) => {
                                    return (
                                      <li className="px-3">
                                        <div className="media">

                                          <div className="media-body">
                                            <Link to={`/explore/${itempro.name}`}>
                                              <div onClick={() => catChange(itempro.name)}>

                                                <p className="mt-0 banner_desc_user mb-0 not_banner_dessc"  >{itempro.name}</p>


                                              </div>
                                            </Link>
                                          </div>
                                        </div>

                                      </li>
                                    )
                                  })}

                                </ul>
                              </Scrollbars>



                            </div>
                          ]}
                        />
                      </div>


                      <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <img src={Collection} className="filteradd" />
                            Collections </div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 150 }} className="nto_scrol_div">
                                <ul className="explorebg">
                                  {hotcol.length > 0 &&
                                    hotcol.map((itemcoll, index) => {
                                      return (
                                        <li className="px-3" onClick={() =>
                                          catChange(itemcoll.customurl, 'collection')}>
                                          <div className="media">
                                            <div className="media-body">
                                              <div>
                                                <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >{itemcoll.collectionName}</p>

                                              </div>

                                            </div>
                                          </div>

                                        </li>

                                      )
                                    })}

                                </ul>
                                {/* <div className="optionbtn">
            <Button className="clearbtn">
            Clear
          </Button>
          <Button className="applybtn">
            Apply
          </Button>
            </div> */}
                              </Scrollbars>


                            </div>
                          ]}
                        />
                      </div>

                      <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <img src={sales} className="filteradd" />
                            Sales Type </div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 150 }} className="nto_scrol_div">
                                <ul className="explorebg">
                                  <li className="px-3" onClick={() =>
                                    catChange('FixedPrice')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Buy Now</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('TimedAuction')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Time Auctions</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('UnLimitedAuction')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Unlimited Auctions</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                </ul>
                                {/* <div className="optionbtn">
            <Button className="clearbtn">
            Clear
          </Button>
          <Button className="applybtn">
            Apply
          </Button>
            </div> */}
                              </Scrollbars>



                            </div>
                          ]}
                        />
                      </div>
                      <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <img src={range} className="filteradd" />
                            Price Range </div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 100 }} className="nto_scrol_div">
                                {/* <div className="d-flex p-2">
            <div  className="inputcolor">
            <input type="number" className="newsletter_inp" placeholder="Min"
                     />
            </div>
            <div  className="inputcolor">
            <input type="number" className="newsletter_inp" placeholder="Max"
                     />
            </div>
            </div> */}
                                <ul className="explorebg">
                                  {/* <li className="px-3">
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">All</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li> */}
                                  <li className="px-3"
                                    onClick={() =>
                                      catChange('LowtoHigh')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div >
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Price:Low to High</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('HightoLow')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div >
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Price:High to Low</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  {/* <li className="px-3">
              <div className="media">
                  <div className="media-body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Price:High to Low</p>
                    
                    </div>
                    
                  </div>
                </div>
               
              </li> */}
                                  {/* <li className="px-3">
              <div className="media">
                  <div className="media-body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Auction Ending Soon</p>
                    
                    </div>
                    
                  </div>
                </div>
               
              </li> */}
                                </ul>
                                {/* <div className="optionbtn">
            <Button className="clearbtn">
            Clear
          </Button>
          <Button className="applybtn">
            Apply
          </Button>
            </div> */}
                              </Scrollbars>



                            </div>
                          ]}
                        />
                      </div>
                      <div
                        className="menu_dropdown button dropdown_header_ul noti_ul">
                        <CustomDropdown
                          noLiPadding
                          buttonText={<div className="noti_online align-items-center buyerbtnn explore_btn">
                            <img src={filter} className="filteradd" />
                            Filter & Sort </div>}
                          dropdownList={[
                            <div className="buernotir">

                              <Scrollbars style={{ height: 150 }} className="nto_scrol_div">
                                <ul className="explorebg">
                                  {/* <li className="px-3">
              <div className="media">
                  <div className="media-body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">All</p>
                    
                    </div>
                    
                  </div>
                </div>
               
              </li> */}
                                  <li className="px-3" onClick={() =>
                                    catChange('Recently')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Recently</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('FixedPrice')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Buy Now</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('TimedAuction')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Time Auctions</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('UnLimitedAuction')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Unlimited Auctions</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3"
                                    onClick={() =>
                                      catChange('LowtoHigh')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div >
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Price:Low to High</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() =>
                                    catChange('HightoLow')}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div >
                                          <p className="mt-0 banner_desc_user mb-0 not_banner_dessc" >Price:High to Low</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  {/* <li className="px-3">
              <div className="media">
                  <div className="media-body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Price:High to Low</p>
                    
                    </div>
                    
                  </div>
                </div>
               
              </li>
              <li className="px-3">
              <div className="media">
         
                  <div className="media-body">
                    <div>
                    <p className="mt-0 banner_desc_user mb-0 not_banner_dessc">Auction Ending Soon</p>
                    
                    </div>
                    
                  </div>
                </div>
               
              </li> */}
                                </ul>
                              </Scrollbars>



                            </div>
                          ]}
                        />
                      </div>
                    </div>

                  </div>
                </div>

              </div>

              {/* <nav className="masonry_tab_nav items_tab_outer">
                <div className="nav nav-tabs masonry_tab primary_tab items_tab  items_tab_new pb-2 pl-2" id="nav-tab" role="tablist">
                  <a className="nav-link active" onClick={() => catChange('All')} id="collectibles-tab" data-toggle="tab" href="#collectibles" role="tab" aria-controls="collectibles" aria-selected="false">
                    New
                  </a>
                  {
                        Categorylist.map((item) => {
                          return (
                            <a className="nav-link" onClick={() => catChange(item.name)} data-tabName={item.name} id={item.name+"tab"} data-toggle="tab" role="tab" aria-controls="all" aria-selected="true">{item.name}</a>
                          )
                        })
                      }
                </div>
              </nav> */}
            </div>
            <div className="tab-content explore_tab_content mt-0" id="nav-tabContent">
              <div className="tab-pane fade show active" id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
                <div className="mt-3">
                  <div className=" m-0">
                    <div className="eploregrid">
                      {/* token card */}
                      {
                        (CatBasedTokenList
                          && CatName
                          && CatBasedTokenList[CatName] && CatBasedTokenList[CatName].list
                          && CatBasedTokenList[CatName].list.length > 0)
                          ? (CatBasedTokenList[CatName].list.map((item) => {
                            return (
                              (isEmpty(item.tokenowners_current) !== true )?
                              <div className=" mb-4">
                                {/* col-lg-3 col-md-6 col-sm-6 */}
                                <TokenCard
                                  item={item}
                                  Set_item={Set_item}
                                  LikedTokenList={LikedTokenList}
                                  hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                                  // UserAccountAddr={Wallet_Details.UserAccountAddr}
                                  // UserAccountBal={Wallet_Details.UserAccountBal}
                                  PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                  PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                  PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                  Set_Bids={Set_Bids}
                                  Bids={item.myBid}
                                  Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                  Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                  Set_MyTokenBalance={Set_MyTokenBalance}
                                  Set_MyTokenDetail={Set_MyTokenDetail}
                                  Set_AllowedQuantity={Set_AllowedQuantity}
                                  Set_YouWillPay={Set_YouWillPay}
                                  Set_YouWillPayFee={Set_YouWillPayFee}
                                  Set_YouWillGet={Set_YouWillGet}
                                  // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                                  Burn_Click={BurnForwardRef.current.Burn_Click}
                                  CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                  // WalletConnected={Wallet_Details.WalletConnected}
                                  SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                  ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                  setConvertVal={setConvertVal}
                                  convertVal={convertVal}
                                />
                              </div>
                              :("")
                            )
                          })) :
                          // <div className="proposal_panel_overall text-center">
                          <div className="text-center py-5 col-12 notfound">
                            <div className="text-center py-3  no_items_row">
                              <p className="not_found_text">Loading</p>
                              <p className="not_found_text_sub">Please wait a moment</p>
                              <div className="mt-3">
                                {/* <Button className="create_btn"><Link to="/">Browse Marketplace</Link></Button> */}

                              </div>
                            </div>
                          </div>
                      }



                    </div></div>
                </div>

              </div>

            </div>



          </div>
          {/* {
            (CatBasedTokenList && CatBasedTokenList.loader === false && CatBasedTokenList[CatName] && CatBasedTokenList[CatName].list.length >= 10 && CatBasedTokenList[CatName].onmore === true) ? ( */}
              <div className="text-center mt-4 w-100 ">
                {/* {(CatBasedTokenList.loader === true)?
                    <i className="fa fa-spinner spinner_icon spinner_red" aria-hidden="true" id="spin"></i>:('')} */}
                <div id="spinButs" className="overflow-hidden">
                  <Button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" onClick={onLoadMore} disabled={LoadDisable} style={{ display: "flex" }}>
                    Load More <span>
                      {/* <img src={require("../assets/images/delete-Ditmax UI/Cropping/Spinner-1s-64px.gif")} alt="Wallet" className="img-fluid" /> */}
                    </span>

                  </Button>
                </div>
              </div>
              {/* ) : ('')} */}
        </section>


        <div id="logo_overlay" className="logo_ovelay">
          {/* <div>
            <section className="section wallet_section walet_icon_sec py-4">
              <div className="container-fluid container-theme container-lg">
                <h2 className="mt-0">Supported Wallets</h2>
                <GridContainer className="justify-content-center mt-4">
              
                  <GridItem md={2} sm={4} className="text-center mb-3">
                    <img src={require("../assets/images/wallet_01.png")} alt="Wallet" className="img-fluid mb-3" />
                    <p className="mb-0">Metamask</p>
                  </GridItem>
                 
                  <GridItem md={2} sm={4} className="text-center mb-3">
                    <img src={require("../assets/images/wallet_05.png")} alt="Wallet" className="img-fluid mb-3" />
                    <p className="mb-0">TrustWallet</p>
                  </GridItem>
                </GridContainer>
              </div>
            </section>

          </div> */}
          <Footer />
        </div>
      </div>
    </div>
  );
}
