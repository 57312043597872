import React, { useEffect, useState, useRef } from "react";
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link, useParams ,useLocation} from "react-router-dom";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

import HeaderLinks from "components/Header/HeaderLinks.js";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// mysid
import TokenCard from '../views/separate/TokenCard'
import { useSelector } from "react-redux";
import {
  userNFTList, CollectiblesList_Home, getUserCollectiondata
} from '../actions/v1/token';
import { CancelOrderRef } from './separate/CancelOrderRef';
import { LikeRef } from './separate/LikeRef';
import { BurnRef } from './separate/BurnRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import ConnectWallet from './separate/Connect-Wallet';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { addUserCollection } from '../actions/v1/user'
import '@metamask/legacy-web3'
import { toast } from 'react-toastify';

import { getcmslistinhome, getpromotion } from '../actions/v1/report';
import isEmpty from "lib/isEmpty";
import config from 'lib/config';
toast.configure();
const dashboardRoutes = [];
const useStyles = makeStyles(styles);

export default function Ico(props) {


  const { ...rest } = props;


  const location = useLocation();
  const Wallet_Details = useSelector(state => state.wallet_connect_context);


  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var BurnForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  var PlaceABidForwardRef = useRef();
  const [topcreatorsection, settopcreatorsection] = React.useState({});
  const [LikedTokenList, setLikedTokenList] = useState([]);
  // const [UserAccountAddr, Set_UserAccountAddr] = useState('');
  // const [UserAccountBal, Set_UserAccountBal] = useState(0);
  // const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  // const [Accounts, Set_Accounts] = useState('');
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = useState('');
  const [HitItem, Set_HitItem] = useState({});
  const [Categorylist, setCategorylist] = useState([]);
  const [TokenList, setTokenList] = useState([]);
  const [CatName, setCatName] = useState('All');
  const [CatBasedTokenList, setCatBasedTokenList] = useState({ 'loader': false, 'All': { page: 1, list: [], onmore: true } });
  // const [Page, setPage] = useState(1);
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [item, Set_item] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [Bids, Set_Bids] = useState([]);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = useState('init');
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [Time_Auction_List, Set_Time_Auction_List] = useState([]);
  const [Recent_Nft_List, Set_Recent_Nft_List] = useState([]);
  // const [WalletConnected, Set_WalletConnected] = useState('false');
  // const [providerss,set_providers]=useState(null)
  const [Service_Fee, set_Service_Fee] = useState(0);
  const [convertVal, setConvertVal] = React.useState(0);
  // const[Wen_Bln,set_Wen_Bln]=useState(0);
  const [Promotion_List, Set_Promotion_list] = useState({})
  const [Creatorcount, setCreatorcount] = useState(0);
  const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = useState({})
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [getcmslistinhome1, setgetcmslistinhome1] = useState({})
  const [getcmslistinhome12, setgetcmslistinhome12] = useState({})
  const [CollectionList, Set_Collection_List] = useState([]);
  const [CollectionInfo, setCollectionInfo] = useState([]);
  var { param, customurl } = useParams();

  useEffect(() => {

    getInit();
    // if(getcmslistinhome1.length==0){

    // }
  }, [])
  async function getInit() {
    TokenListCall();
    CollectionProfile()

  }




  const onLoadMore = () => {

  }
  async function TokenListCall() {
    var payload = {
      currAddr: param,
      customurl: customurl,
      from: 'collection'
    }
    // console.log("TokenList call function", payload)
    var resp = await CollectiblesList_Home(payload);
    // console.log("After NFT", resp.data);
    if (resp && resp.data && resp.data.from === 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      // console.log("After NFT", resp.data);
      Set_Collection_List(resp.data.list)
    }
    else {
      Set_Collection_List([])
    }
  }
  async function CollectionProfile() {
    var payload = {
      userAddress: param,
      customurl: customurl
    }
  // console.log("fgjnhfgjm",payload);
    var collectionInfo = await getUserCollectiondata(payload);
    if (collectionInfo && collectionInfo.data && collectionInfo.data.data && collectionInfo.data.data.collections) {
      // console.log("Collection Informations", collectionInfo);
      setCollectionInfo(collectionInfo.data.data.collections)
    }
  }



  return (
    // WalletConnected=="false"?
    // <Loader/>:
    <div>
      {/* <div id="loader_div"><span className="spin_round"></span><img src="../assets/images/loader.png" className="logo_load" /></div> */}

      <div className="home_header">

        <PutOnSaleRef
          ref={PutOnSaleForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        />
        <BurnRef
          ref={BurnForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={providerss}

        />
        <PlaceAndAcceptBidRef
          ref={PlaceABidForwardRef}
          // Set_WalletConnected={Set_WalletConnected}
          // Set_UserAccountAddr={Set_UserAccountAddr}
          // Set_UserAccountBal={Set_UserAccountBal}
          // Set_AddressUserDetails={Set_AddressUserDetails}
          // Set_Accounts={Set_Accounts}
          Set_MyItemAccountAddr={Set_MyItemAccountAddr}
          Set_tokenCounts={Set_tokenCounts}
          Set_item={Set_item}
          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
          Set_MyTokenBalance={Set_MyTokenBalance}
          Set_Bids={Set_Bids}
          Set_AccepBidSelect={Set_AccepBidSelect}
          Set_tokenBidAmt={Set_tokenBidAmt}
          Set_NoOfToken={Set_NoOfToken}
          Set_ValidateError={Set_ValidateError}
          Set_TokenBalance={Set_TokenBalance}
          Set_YouWillPay={Set_YouWillPay}
          Set_YouWillPayFee={Set_YouWillPayFee}
          Set_YouWillGet={Set_YouWillGet}
          Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
          Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}

          // WalletConnected={WalletConnected}
          // UserAccountAddr={UserAccountAddr}
          // UserAccountBal={UserAccountBal}
          // AddressUserDetails={AddressUserDetails}
          // Accounts={Accounts}
          MyItemAccountAddr={MyItemAccountAddr}
          tokenCounts={tokenCounts}
          item={item}
          tokenCounts_Detail={tokenCounts_Detail}
          MyTokenBalance={MyTokenBalance}
          Bids={Bids}
          AccepBidSelect={AccepBidSelect}
          tokenBidAmt={tokenBidAmt}
          NoOfToken={NoOfToken}
          ValidateError={ValidateError}
          TokenBalance={TokenBalance}
          YouWillPay={YouWillPay}
          YouWillPayFee={YouWillPayFee}
          YouWillGet={YouWillGet}
          BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
          BidApply_SignCallStatus={BidApply_SignCallStatus}
          AllowedQuantity={AllowedQuantity}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // set_Wen_Bln={set_Wen_Bln}
          // Wen_Bln={Wen_Bln}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={providerss}
        />
        <PurchaseNowRef
          ref={PurchaseNowForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          // UserAccountAddr={UserAccountAddr}
          // UserAccountBal={UserAccountBal}
          // Accounts={Accounts}
          // GetUserBal={GetUserBal} 
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // set_Wen_Bln={set_Wen_Bln}
          // Wen_Bln={Wen_Bln}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={providerss}
        />
        <WalletRef
          ref={WalletForwardRef}
        // Set_UserAccountAddr={Set_UserAccountAddr}
        // Set_WalletConnected={Set_WalletConnected}
        // Set_UserAccountBal={Set_UserAccountBal}
        // set_providers={set_providers}
        // providerss={providerss}
        />
        <LikeRef

          // UserAccountAddr={UserAccountAddr}
          ref={LikeForwardRef}
          setLikedTokenList={setLikedTokenList}
          MyItemAccountAddr={MyItemAccountAddr}
        // WalletConnected={WalletConnected}
        // set_providers={set_providers}
        // providerss={providerss}
        />
        <CancelOrderRef
          ref={CancelOrderForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}


          // WalletConnected={WalletConnected}

          // UserAccountAddr={UserAccountAddr}
          // UserAccountBal={UserAccountBal}
          // Accounts={Accounts}
          // GetUserBal={GetUserBal}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={providerss}

        />
        <ReportNowRef
          // UserAccountAddr={UserAccountAddr}
          ref={ReportForwardRef}
        />
        <ShareNowRef
          ref={ShareForwardRef}
        />

        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<Link to="/">
            <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link>}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 20,
            color: "white"
          }}
          {...rest}
        />

        <section className="navtabs pb-2 explore">

          <div className="items_bg mt-5">
{/* 
            {console.log("fdgbhdghbdgfhbfgd", CollectionInfo)} */}
            {!isEmpty(CollectionInfo) && CollectionInfo[0].imageName ?
              <img src={`${config.Back_URL}/coverphoto/${param}/${CollectionInfo[0].coverimage}`} alt="profile" className="img-fluid" />
              :
              <img src={require("../assets/images/banner.jpg")} className="img-fluid" alt="logo" />
            }
          </div>
          <div className="container-fluid container-theme container-lg ico pb-5">
            <div className="d-flex align-items-center">

              <div className="d-block w-100 ico_border image_title text-center">
                <div className="title m-0">

                  <div className="d-block responsinge">
                    <div className="image user_collect_img_tab">
                      {/* {console.log("gfkijhgijfh", CollectionInfo)} */}
                      {!isEmpty(CollectionInfo) && CollectionInfo[0].imageName ?
                        <img src={`${config.Back_URL}/collectionLogo/${param}/${CollectionInfo[0].imageName}`} className="user_image" alt="DitmaxEye" />
                        :
                        <img src={require("../assets/images/Wen.png")} className="img-fluid" alt="logo" />
                      }
                    </div>
                    {CollectionInfo && CollectionInfo.length > 0 ?
                 
                    <>
                       {/* {console.log("dfgdsgefr6gefrgertfre",CollectionInfo)} */}
                      {Wallet_Details.UserAccountAddr == CollectionInfo[0].userAddress ?
                      <button className='btn newbtn'>
                        <span class="gradbtn grad-bordbtn">
                        
                          <Link className="" to={"/addCollectionss" + '/' + CollectionInfo[0].collectionName + "/" + "usercollection"}>Edit Collection</Link>
                        
                        </span>
                      </button>
                       :
                       
                       <button className='btn newbtn'>
                       <span class="gradbtn grad-bordbtn">
                       
                         <Link className=""to ={"/user" + '/' + param}>View Profile</Link>
                       
                       </span>
                     </button>
                      }
                      </>
                      :
                      <p></p>
                    }
                    <div className="para ml-3">
                      {CollectionInfo && CollectionInfo.length > 0 ?
                        <p className="linear">{CollectionInfo[0].collectionName}</p> :
                        <p className="linear">no name</p>}
                        <Link to ={"/user" + '/' + param} >
                      <p class="sub-linear">{param}</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="no_of_nft ml-auto mt-xl-4 mt-lg-4 mt-3">
                  <p className="heading">No. of NFT'S:&emsp;{CollectionList.length}</p>
                  {/* <p className="linear"></p> */}
                </div>
              </div>
            </div>
            <div className="tab-content explore_tab_content mt-0 pt-5" id="nav-tabContent">
              <div className="tab-pane fade show active" id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
                <div className="mt-3">
                  <div className=" m-0">
                    <div className="eploregrid">
                      {/* token card */}
                      {
                        (CollectionList
                          && CollectionList.length > 0)
                          ? (CollectionList.map((item) => {
                            return (
                              (isEmpty(item.tokenowners_current) !== true )?
                              <div className=" mb-4">
                                {/* col-lg-3 col-md-6 col-sm-6 */}
                         
                                <TokenCard
                                  item={item}
                                  Set_item={Set_item}
                                  LikedTokenList={LikedTokenList}
                                  hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                                  // UserAccountAddr={UserAccountAddr}
                                  // UserAccountBal={UserAccountBal}
                                  PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                  PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                  PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                  Set_Bids={Set_Bids}
                                  Bids={item.myBid}
                                  Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                  Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                  Set_MyTokenBalance={Set_MyTokenBalance}
                                  Set_MyTokenDetail={Set_MyTokenDetail}
                                  Set_AllowedQuantity={Set_AllowedQuantity}
                                  Set_YouWillPay={Set_YouWillPay}
                                  Set_YouWillPayFee={Set_YouWillPayFee}
                                  Set_YouWillGet={Set_YouWillGet}
                                  // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                                  Burn_Click={BurnForwardRef.current.Burn_Click}
                                  CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                  // WalletConnected={WalletConnected}
                                  SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                  ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                  setConvertVal={setConvertVal}
                                  convertVal={convertVal}
                                />
                               
                              </div>
                               : ("")
                            )
                          })) :
                          // <div className="proposal_panel_overall text-center">
                          <div className="text-center py-5 col-12 notfound">
                            <div className="text-center py-3  no_items_row">
                              <p className="not_found_text">No items found</p>
                              <Link to ={`/explore/All`}>
                              <p className="not_found_text_sub">Come back soon or maybe Explore more here</p>
                              </Link>
                              <div className="mt-3">
                                {/* <Button className="create_btn"><Link to="/">Browse Marketplace</Link></Button> */}

                              </div>
                            </div>
                          </div>
                      }



                    </div></div>
                </div>

              </div>

            </div>
          </div>
          <div className="ico-button m-auto text-center pb-4 ico_button">
            {/* <button class="btn newbtn" tabindex="0" type="button"><span class="gradbtn grad-bordbtn"><a href="#">View More</a></span></button> */}
          </div>
          {
            (CatBasedTokenList && CatBasedTokenList.loader === false && CatBasedTokenList[CatName] && CatBasedTokenList[CatName].list.length >= 10 && CatBasedTokenList[CatName].onmore === true) ? (
              <div className="text-center mt-4 w-100 ">
                {/* {(CatBasedTokenList.loader === true)?
                    <i className="fa fa-spinner spinner_icon spinner_red" aria-hidden="true" id="spin"></i>:('')} */}
                <div id="spinButs" className="overflow-hidden">
                  <Button className="create_btn create_newbtn mt-3 mx-auto btn-block" id="onmore" onClick={onLoadMore} style={{ display: "flex" }}>
                    Load More <span>
                      {/* <img src={require("../assets/images/delete-Ditmax UI/Cropping/Spinner-1s-64px.gif")} alt="Wallet" className="img-fluid" /> */}
                    </span>

                  </Button>
                </div>
              </div>) : ('')}
        </section>


        <div id="logo_overlay" className="logo_ovelay">

          <Footer />
        </div>
      </div>
    </div>
  );
}
