import React, { useEffect, useState, useRef } from "react";
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import $ from 'jquery'; 
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Dropdown from 'react-dropdown';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Scrollbars } from 'react-custom-scrollbars';
import HeaderLinks from "components/Header/HeaderLinks.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// mysid
import TokenCard from '../views/separate/TokenCard'
import { Button } from "@material-ui/core";

import axios from 'axios';
import Convert from '../views/separate/Convert'
import { Account_Connect, Account_disConnect } from '../../src/actions/redux/action'

import config from '../lib/config';
import {
  ParamAccountAddr_Detail_Get,
} from '../actions/v1/user';
import {
  CollectiblesList_Home,
  topCreatorsApi,
  getBuyerSeller,
  gallarycollection,
  Hotcollections
} from '../actions/v1/token';
import { CancelOrderRef } from './separate/CancelOrderRef';
import { LikeRef } from './separate/LikeRef';
import { BurnRef } from './separate/BurnRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import ConnectWallet from './separate/Connect-Wallet';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';

import ReactHtmlParser from 'react-html-parser';
import { useDispatch, useSelector } from "react-redux";

import '@metamask/legacy-web3'
import { toast } from 'react-toastify';

import { getcmslistinhome, getpromotion, Bannerpromotion, gall } from '../actions/v1/report'
import isEmpty from '../lib/isEmpty'
import { RFC_2822 } from "moment";
import Indexs from "App";
toast.configure();

const dashboardRoutes = [];
const useStyles = makeStyles(styles);



export default function Home(props) {
  const dispatch = useDispatch()
  // const history = useHistory();
  // const [expanded1, setExpanded1] = React.useState('panel9');
  // const [expanded3, setExpanded3] = React.useState('panel9');
  // const [expanded2, setExpanded2] = React.useState('panel9');
  // const [expanded4, setExpanded4] = React.useState('panel9');
  // const [expanded5, setExpanded5] = React.useState('panel9');

  // const [expanded6, setExpanded6] = React.useState('panel9');
  // const [expanded7, setExpanded7] = React.useState('panel9');
  // const [expanded8, setExpanded8] = React.useState('panel9');
  // const [expanded10, setExpanded10] = React.useState('panel9');
  const [expanded12, setExpanded12] = React.useState('panel9');
  const [expanded13, setExpanded13] = React.useState('panel9');
  const [expanded14, setExpanded14] = React.useState('panel9');
  const [expanded15, setExpanded15] = React.useState('panel9');
  const [expanded16, setExpanded16] = React.useState('panel9');
  // const handleChangeFaq = (panel1) => (event, isExpanded1) => {
  //   setExpanded1(isExpanded1 ? panel1 : false);
  // };
  // const handleChangeFaq2 = (panel2) => (event, isExpanded2) => {
  //   setExpanded2(isExpanded2 ? panel2 : false);
  // };
  // const handleChangeFaq3 = (panel3) => (event, isExpanded3) => {
  //   setExpanded3(isExpanded3 ? panel3 : false);
  // };
  // const handleChangeFaq4 = (panel4) => (event, isExpanded4) => {
  //   setExpanded4(isExpanded4 ? panel4 : false);
  // };
  // const handleChangeFaq5 = (panel5) => (event, isExpanded5) => {
  //   setExpanded5(isExpanded5 ? panel5 : false);
  // };
  // const handleChangeFaq6 = (panel6) => (event, isExpanded6) => {
  //   setExpanded6(isExpanded6 ? panel6 : false);
  // };
  // const handleChangeFaq7 = (panel7) => (event, isExpanded7) => {
  //   setExpanded7(isExpanded7 ? panel7 : false);
  // };
  // const handleChangeFaq8 = (panel8) => (event, isExpanded8) => {
  //   setExpanded8(isExpanded8 ? panel8 : false);
  // };
  // const handleChangeFaq10 = (panel10) => (event, isExpanded10) => {
  //   setExpanded10(isExpanded10 ? panel10 : false);
  // };
  const handleChangeFaq12 = (panel12, filter) => (event, isExpanded12) => {
    // console.log("Panel Checking", filter)
    setExpanded12(isExpanded12 ? panel12 : false);
    if (!isEmpty(filter)) {
      //('>>>>>filter',filter);

      getBuyerSellerFun(filter);
      setbuyersellerDrop(filter);

    }
  };


  // var settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1

  // };

  var state = {
    responsive: {
      0: {
        items: 1,
      },
      575: {
        items: 2,
      },
      767: {
        items: 3,
      },
      992: {
        items: 4,
      },
      1199: {
        items: 5,
      },
      1400: {
        items: 5
      }

    },

  };

  // const classes = useStyles();
  const { ...rest } = props;



  const Wallet_Details = useSelector(state => state.wallet_connect_context);


  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var BurnForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  var PlaceABidForwardRef = useRef();
  const [topcreatorsection, settopcreatorsection] = React.useState([]);
  const [LikedTokenList, setLikedTokenList] = useState([]);
  // const [UserAccountAddr, Set_UserAccountAddr] = useState('');
  // const [UserAccountBal, Set_UserAccountBal] = useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = useState('');
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = useState('');
  const [HitItem, Set_HitItem] = useState({});
  const [Categorylist, setCategorylist] = useState([]);
  const [TokenList, setTokenList] = useState([]);
  const [CatName, setCatName] = useState('All');
  const [CatBasedTokenList, setCatBasedTokenList] = useState({
    'loader': false, 'All': {
      page: 1, list: [],
      onmore: true
    }
  });

  //const [Page, setPage] = useState(1);
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [item, Set_item] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [Bids, Set_Bids] = useState([]);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = useState('init');
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [Time_Auction_List, Set_Time_Auction_List] = useState([]);
  const [Recent_Nft_List, Set_Recent_Nft_List] = useState([]);
  // const [WalletConnected, Set_WalletConnected] = useState('false');
  const [providerss, set_providers] = useState(null)
  const [Service_Fee, set_Service_Fee] = useState(0);
  const [convertVal, setConvertVal] = React.useState(0);
  // const [Wen_Bln, set_Wen_Bln] = useState(0);
  const [Promotion_List, Set_Promotion_list] = useState({})
  const [Promotion_Banner, Set_Promotion_Banner] = useState({})
  const [gallarycoll, Setgallarycoll] = useState({})
  const [hotcol, Sethotcoll] = useState({})
  const [Creatorcount, setCreatorcount] = useState(0);
  const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = useState({})
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});

  const [getcmslistinhome1, setgetcmslistinhome1] = useState({})
  const [getcmslistinhome12, setgetcmslistinhome12] = useState({})
  const [buyerSeller, setBuyerSeller] = useState([]);
  const [buyersellerDrop, setbuyersellerDrop] = useState('buyer');
  const [closemodel, setclosemodel] = useState(false);
  const [Time, setTime] = useState(7);
  const [LoadDisable, Set_LoadDisable] = useState(false);
  useEffect(() => {

    if (Wallet_Details.tokenAddress && Wallet_Details.tokenAddress.BiddingToken_USD)
      console.log("dxzhbvshdfbghkfsjdbghkjfsdbg", Wallet_Details.tokenAddress.BiddingToken_USD)

  }, [Wallet_Details.tokenAddress])

  useEffect(() => {
    Galarycoll();
    Hotcoll();
    getInit();
    // if(getcmslistinhome1.length==0){
      Timefun();
    // }
    TokenListCall();
    getBuyerSellerFun('buyer');

  }, [Time])

  useEffect(() => {
   
    if(!Wallet_Details.modelpopup)
    // alert(1)
        window.$("#agree_modal111").modal("show")

        {
          localStorage.setItem('agree_modal111','popup')
        }
  }, [Wallet_Details.WalletConnected, Wallet_Details.UserAccountAddr, localStorage.walletConnectType, config.providercon])

  const closefun = async () => {

    window.$("#agree_modal111").modal("hide")
    dispatch({
      type: Account_Connect, Account_Detail: {
        modelpopup:true
      }
    })
    localStorage.removeItem('agree_modal111')
  }
  const getcmslistinhomes = async () => {
    var reqdata = {
      load: 'home'
    }
    var convers = await getcmslistinhome(reqdata);
    if (convers && convers.data) {
      setgetcmslistinhome1(convers.data)
    }
  }
  const getcmslistinhomes1 = async () => {
    var reqdata = {
      load: 'tophome'
    }
    var convers = await getcmslistinhome(reqdata);
    if (convers && convers.data) {
      setgetcmslistinhome12(convers.data)
    }
  }
  const promotionData = async () => {
    var test = await getpromotion();
    // console.log("Dsajdashdjasgdaskdkagshdghasgkd", test)
    if (test && test.userValue) {
      // console.log("hfijwedifghi", test.userValue);
      Set_Promotion_list(test.userValue)
    }
  }

  const BannerPro = async () => {
    var test = await Bannerpromotion();
    // console.log("Dsajdashdjasgdaskdkagshdghasgkd", test)
    if (test && test.userValue) {
      // console.log("hfijwedifghi", test.userValue);
      Set_Promotion_Banner(test.userValue)
    }
  }

  const Galarycoll = async () => {
    var collectiongal = await gallarycollection();
    // console.log("gallarycollectioncomming", collectiongal.data.data.collections)
    Setgallarycoll(collectiongal.data.data.collections)
  }

  const Hotcoll = async () => {
    var Arg = {
      Timed: Time,
    };
    // console.log("skjdgasfabnkrfj",Arg);
    var collectiongal = await Hotcollections(Arg);
    // console.log("gallarycollectioncomming", collectiongal.data.data.collections)
    Sethotcoll(collectiongal.data.data.collections)
  }
  async function Timefun (timefunn) {
// console.log("nhkjdcbvdjshb",timefunn);
if(timefunn > 0 ){
setTime(timefunn)
}
  }

  $(window).scroll(function(){
    if ($(this).scrollTop()) {
       $('header').addClass('fixedTop');
    } else {
       $('header').removeClass('fixedTop');
    }
});


  async function getInit() {
  
    getcmslistinhomes();
    getcmslistinhomes1();
    promotionData();
    CategoryListCall();
    topCreatorsFUnc();
    BannerPro();
    timeAuctionFUnc();
    recentNftFUnc();

    // ;}

  }

  const topCreatorsFUnc = async () => {
    var topCraete = await topCreatorsApi();
    if (topCraete && topCraete.data) {
      // console.log("hjvbkjhbvkj,ubgjkhub", topCraete)
      settopcreatorsection(topCraete.data.data)
    }
  }
  const [responsiveone] = React.useState({
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    }

  })
  const getBuyerSellerFun = async (filter) => {
    var filterobj = {}
    //console.log('filterbuyerseller',filter);	
    if (!isEmpty(filter)) {
      if (filter === 'seller')
        filterobj.buyerSellerFilter = filter
      if (filter === 'buyer')
        filterobj.buyerSellerFilter = filter;
      //   if (filter === 'today')
      //     filterobj.buyerSellerTimeFilter = filter;
      //   if (filter === 'yesterday')
      //     filterobj.buyerSellerTimeFilter = filter;
    }
    const response = await getBuyerSeller(filterobj);
    //console.log("BuyerSeller........>>>>",filterobj);
    if (response && response.data && response.data.success) {
      setBuyerSeller(response.data.list);
    }
  }

  const [expanded1, setExpanded1] = React.useState('panel9');
  const [expanded3, setExpanded3] = React.useState('panel9');
  const [expanded2, setExpanded2] = React.useState('panel9');
  const [expanded4, setExpanded4] = React.useState('panel9');
  const [expanded5, setExpanded5] = React.useState('panel9');

  const [expanded6, setExpanded6] = React.useState('panel9');
  const [expanded7, setExpanded7] = React.useState('panel9');
  const [expanded8, setExpanded8] = React.useState('panel9');
  const [expanded10, setExpanded10] = React.useState('panel9');

  const handleChangeFaq = (panel1) => (event, isExpanded1) => {
    setExpanded1(isExpanded1 ? panel1 : false);
  };
  const handleChangeFaq2 = (panel2) => (event, isExpanded2) => {
    setExpanded2(isExpanded2 ? panel2 : false);
  };
  const handleChangeFaq3 = (panel3) => (event, isExpanded3) => {
    setExpanded3(isExpanded3 ? panel3 : false);
  };
  const handleChangeFaq4 = (panel4) => (event, isExpanded4) => {
    setExpanded4(isExpanded4 ? panel4 : false);
  };
  const handleChangeFaq5 = (panel5) => (event, isExpanded5) => {
    setExpanded5(isExpanded5 ? panel5 : false);
  };
  const handleChangeFaq6 = (panel6) => (event, isExpanded6) => {
    setExpanded6(isExpanded6 ? panel6 : false);
  };
  const handleChangeFaq7 = (panel7) => (event, isExpanded7) => {
    setExpanded7(isExpanded7 ? panel7 : false);
  };
  const handleChangeFaq8 = (panel8) => (event, isExpanded8) => {
    setExpanded8(isExpanded8 ? panel8 : false);
  };
  const handleChangeFaq10 = (panel10) => (event, isExpanded10) => {
    setExpanded10(isExpanded10 ? panel10 : false);
  };

  const timeAuctionFUnc = async () => {
    var currAddr = Wallet_Details.UserAccountAddr;

    var payload = {
      limit: 6, from: 'Time', currAddr: currAddr
    }
    var resp = await CollectiblesList_Home(payload);
    if (resp && resp.data && resp.data.from === 'time-auction-token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      Set_Time_Auction_List(resp.data.list)
    }
    else {
      Set_Time_Auction_List([])
    }
  }
  const recentNftFUnc = async () => {
    var currAddr = Wallet_Details.UserAccountAddr

    var payload = {
      limit: 10, from: 'recent', currAddr: currAddr
    }
    var resp = await CollectiblesList_Home(payload);
    if (resp && resp.data && resp.data.from === 'recent-token-collectibles-list-home' && resp.data.list && ((resp.data.list).length !== 0)) {
      // console.log("all come", resp.data)
      Set_Recent_Nft_List(resp.data.list)
    }
    else {
      Set_Recent_Nft_List([])
    }
  }
  const Get_MyItemAccountAddr_Details = async (payload) => {
    var Resp = await ParamAccountAddr_Detail_Get(payload);
    if (
      Resp
      && Resp.data
      && Resp.data.User
      && Resp.data.User.curraddress
    ) {
      Set_MyItemAccountAddr(Resp.data.User.curraddress);
      if (Resp.data.User) {
        Set_MyItemAccountAddr_Details(Resp.data.User);
      }
    }

  }

  async function CategoryListCall() {
    axios
      .get(`${config.vUrl}/token/category/list`)
      .then(response => {
        if (response && response.data && response.data.list) {
          setCategorylist(response.data.list);
        }
      })
      .catch(e => console.log(e))
  }

  // const onLoadMore = () => {
  //   CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page + 1;
  //   setCatBasedTokenList(CatBasedTokenList);

  //   TokenListCall({
  //     page: CatBasedTokenList[CatName].page + 1
  //   });
  // }

  async function catChange(name) {
    if (name !== CatName) {
      setCatName(name);
      if (typeof CatBasedTokenList[name] == 'undefined') {
        CatBasedTokenList[name] = { page: 1, list: [], onmore: true };
        setCatBasedTokenList(CatBasedTokenList);
        // Set_LoadDisable(CatBasedTokenList)
        TokenListCall({ CatName: name, page: 1 });
      }
    }
  }



  const onLoadMore = () => {
    // Set_LoadDisable(true)

    CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page + 1;
    setCatBasedTokenList(CatBasedTokenList);

    TokenListCall({
      page: CatBasedTokenList[CatName].page + 1

    });
    // Set_LoadDisable(false)

  }



  async function TokenListCall(data = {}) {

    var currAddr = Wallet_Details.UserAccountAddr
    var name = CatName;
    if (data.CatName) {
      name = data.CatName
    }
    var payload = {
      limit: 10,
      page: (CatBasedTokenList[name] && CatBasedTokenList[name].page) ? CatBasedTokenList[name].page : 1,
      currAddr: currAddr,
      CatName: name,
      from: 'Home',
    }
    Set_LoadDisable(true)
    // console.log("loadMore>>>", payload, CatBasedTokenList[name], CatBasedTokenList)
    CatBasedTokenList.loader = true;
    setCatBasedTokenList(CatBasedTokenList);
    var resp = await CollectiblesList_Home(payload);
    CatBasedTokenList.loader = false;
    setCatBasedTokenList(CatBasedTokenList);
    Set_LoadDisable(false)
    if (resp && resp.data && resp.data.from === 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      setTokenList(TokenList.concat(resp.data.list));
      setCreatorcount(resp.data.list.length);
      if (typeof CatBasedTokenList[name] == 'undefined') {
        CatBasedTokenList[name] = { page: 1, list: [] };
      }
      CatBasedTokenList[name].list = CatBasedTokenList[name].list.concat(resp.data.list);
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
    else {
      CatBasedTokenList[name].onmore = false;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }

  }


  async function AfterWalletConnected() {
    Get_MyItemAccountAddr_Details();
    LikeForwardRef && LikeForwardRef.current && LikeForwardRef.current.getLikesData();
  }




  return (
    // WalletConnected=="false"?
    // <Loader/>:
    <div>
      {/* <div id="loader_div"><span className="spin_round"></span><img src="../assets/images/loader.png" className="logo_load" /></div> */}

      <div className="home_header">
        {/* <ConnectWallet
          Set_UserAccountAddr={Set_UserAccountAddr}
          Set_UserAccountBal={Set_UserAccountBal}
          Set_WalletConnected={Set_WalletConnected}
          Set_AddressUserDetails={Set_AddressUserDetails}
          Set_Accounts={Set_Accounts}
          WalletConnected={WalletConnected}
          set_Service_Fee={set_Service_Fee}
          Service_Fee={Service_Fee}
          AfterWalletConnected={AfterWalletConnected}
          Wen_Bln={Wen_Bln}
          set_Wen_Bln={set_Wen_Bln}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
          set_providers={set_providers}
          providerss={providerss}
        /> */}

        <PutOnSaleRef
          ref={PutOnSaleForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          // GetUserBal={GetUserBal}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}

        />
        <BurnRef
          ref={BurnForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          // GetUserBal={GetUserBal}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}

        />
        <PlaceAndAcceptBidRef
          ref={PlaceABidForwardRef}
          // Set_WalletConnected={Set_WalletConnected}
          // Set_UserAccountAddr={Set_UserAccountAddr}
          // Set_UserAccountBal={Set_UserAccountBal}
          Set_AddressUserDetails={Set_AddressUserDetails}
          // Set_Accounts={Set_Accounts}
          Set_MyItemAccountAddr={Set_MyItemAccountAddr}
          Set_tokenCounts={Set_tokenCounts}
          Set_item={Set_item}
          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
          Set_MyTokenBalance={Set_MyTokenBalance}
          Set_Bids={Set_Bids}
          Set_AccepBidSelect={Set_AccepBidSelect}
          Set_tokenBidAmt={Set_tokenBidAmt}
          Set_NoOfToken={Set_NoOfToken}
          Set_ValidateError={Set_ValidateError}
          Set_TokenBalance={Set_TokenBalance}
          Set_YouWillPay={Set_YouWillPay}
          Set_YouWillPayFee={Set_YouWillPayFee}
          Set_YouWillGet={Set_YouWillGet}
          Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
          Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}

          // WalletConnected={Wallet_Details.WalletConnected}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          AddressUserDetails={Wallet_Details.AddressUserDetails}
          // Accounts={Wallet_Details.Accounts}
          MyItemAccountAddr={MyItemAccountAddr}
          tokenCounts={tokenCounts}
          item={item}
          tokenCounts_Detail={tokenCounts_Detail}
          MyTokenBalance={MyTokenBalance}
          Bids={Bids}
          AccepBidSelect={AccepBidSelect}
          tokenBidAmt={tokenBidAmt}
          NoOfToken={NoOfToken}
          ValidateError={ValidateError}
          TokenBalance={TokenBalance}
          YouWillPay={YouWillPay}
          YouWillPayFee={YouWillPayFee}
          YouWillGet={YouWillGet}
          BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
          BidApply_SignCallStatus={BidApply_SignCallStatus}
          AllowedQuantity={AllowedQuantity}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // set_Wen_Bln={set_Wen_Bln}
          // Wen_Bln={Wallet_Details.Wen_Bln}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}
        />
        <PurchaseNowRef
          ref={PurchaseNowForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          // GetUserBal={GetUserBal} 
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // set_Wen_Bln={set_Wen_Bln}
          // Wen_Bln={Wallet_Details.Wen_Bln}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}
        />
        <WalletRef
          ref={WalletForwardRef}
        // Set_UserAccountAddr={Set_UserAccountAddr}
        // Set_WalletConnected={Set_WalletConnected}
        // Set_UserAccountBal={Set_UserAccountBal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}
        />
        <LikeRef

          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          ref={LikeForwardRef}
          setLikedTokenList={setLikedTokenList}
        // MyItemAccountAddr={MyItemAccountAddr}
        // WalletConnected={Wallet_Details.WalletConnected}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}
        />
        <CancelOrderRef
          ref={CancelOrderForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}


          // WalletConnected={Wallet_Details.WalletConnected}

          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          // GetUserBal={GetUserBal}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}

        />
        <ReportNowRef
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          ref={ReportForwardRef}
        />
        <ShareNowRef
          ref={ShareForwardRef}
        />

        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={<>
            <div className="light_logo"><Link to="/">
              <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link></div>
            <div className="dark_logo"><Link to="/">
              <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
          </>}
          rightLinks={<HeaderLinks />}

          changeColorOnScroll={{
            height: 20,
            color: "white"
          }}
          {...rest}
        />



<div className="modal fade primary_modal" id="agree_modal111" tabindex="-1" role="dialog" aria-labelledby="agree_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="agree_modalLabel11">Welcome to XDCNFT</h5>
              <button type="button" className="close d-none" data-dismiss="modal" id="procedd_close11" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center">
              {/* <p className="text-center accept_ino_sed">
                loerpgfngfnhgdfngnhbgnhbgn hjhgkjmhk,mjn,mn,m  hjhgjhgnm
              </p> */}
              <p className="text-center text-dark click_here_text">XDCNFT uses a custom RPC, please ensure you have configured MetaMask or XDCPay correctly before starting. You can watch this video for details on how to get started.<a href={`${config.Back_URL}/nftImg/RPC_VIDEO.mp4`} target="_blank" className="mr-4 ml-3">Click here</a></p>
              <button onClick={() => closefun(closemodel)} className="ml-2 btn btn_outline_red m-auto h-auto">OK</button>
            </div>
          </div>
        </div>
      </div>


             {
          (Promotion_Banner && Promotion_Banner.length > 0) &&
        <section className="banner_head mt-5">
          <div className="container-fluid container-theme container-lg">
            <div className="row">

              {Promotion_Banner.length > 0 && Promotion_Banner.map((itempro) => {
                return (
                  <div className="col-lg-5 col-md-12 col-sm-12 col-12 mt-5 posAbsolute">
                    <Link to={'/collection/' + itempro.userAddress + '/' + itempro.customurl}>
                      <>

                        {/* <OwlCarousel items={1} className="owl-theme banner-owl 2"  
            loop  
            nav={false} 
            margin={20} autoplay ={true} items={1}  dots={true} touchDrag={false} mouseDrag={false} >  */}


                        {
                          itempro.imageName === "" &&
                          <img src={require("../assets/images/fish.jpg")} class="img-fluid img_radius bannerFirst img_hover manasory bannerheadd side_img_head1" alt="Shape" />
                        }
                        {
                          itempro.imageName !== "" &&
                          <img src={`${config.Back_URL}/collectionLogo/${itempro.userAddress}/${itempro.imageName}`} alt="Shape" width="80" height="80" className="img-fluid img_radius img_hover manasory bannerheadd side_img_head1" />
                        }

                        <div className="bannerTitle">
                          <h1>{itempro.collectionName}
                            {/* <p>{itempro.collectionName}</p> */}
                          </h1>
                        </div>
                        {/* <img src={require("../assets/images/fish.jpg")} class="img-fluid img_radius img_hover manasory bannerheadd" alt="Shape"/>
                    //   <img src={require("../assets/images/fish.jpg")} class="img-fluid img_radius img_hover manasory bannerheadd" alt="Shape"/> */}
                        {/* </OwlCarousel> */}
                      </>
                    </Link>
                  </div>
                )
              })}

              <div className="col-lg-7 col-md-12 col-sm-12 col-12 mt-5">

                <div className="bannergrid">
                  {/* {console.log("gtfhgitjh", Promotion_List)} */}
                  {Promotion_List.length > 0 && Promotion_List.map((itempro) => {
                    return (
                      <>
                        <Link to={'/collection/' + itempro.userAddress + '/' + itempro.customurl}>
                          <>

                            {
                              itempro.imageName === "" &&
                              <img src={require("../assets/images/fish.jpg")} class="img-fluid img_radius img_hover manasory bannerheadd side_img_head" alt="Shape" />
                            }
                            {
                              itempro.imageName !== "" &&

                              <img src={`${config.Back_URL}/collectionLogo/${itempro.userAddress}/${itempro.imageName}`} alt="Shape" width="80" height="80" className="img-fluid img_radius img_hover manasory bannerheadd side_img_head" />

                            }
                            <div className="bannerTitle">
                            <h4>{itempro.collectionName}</h4>
                          </div>
                          </>
                        </Link>
                      </>
                    )
                  })}
                  
                </div>

              </div>

            </div>
          </div>
        </section>
}

        {
          (gallarycoll && gallarycoll.length > 0) &&
        <section className="bid_section section bid_section_section_1 hotcollect">
          <div className="container-fluid container-theme container-lg">
            <h2 className="mb-5 title_text_white">Hot Collections</h2>

            <OwlCarousel
              className="owl-theme 1"
              // loop
              nav={true}
              margin={30} autoplay={false} responsive={responsiveone} dots={false}>

              {gallarycoll.length > 0 &&
                gallarycoll.map((itemgallary) => {
                  return (

                    <div className="hotcollect_body">
                      {/* {console.log("cjvidfhijfhvif", gallarycoll)} */}
                      <Link to={'/collection/' + itemgallary.userAddress + '/' + itemgallary.customurl}>

                      <>
                        {
                          itemgallary.coverimage === "" &&
                          <img src={require("../assets/images/banner.jpg")} class="hotcolectimg" alt="Shape" />
                        }
                        {
                          itemgallary.coverimage !== "" &&

                          <img src={`${config.Back_URL}/coverphoto/${itemgallary.userAddress}/${itemgallary.coverimage}`} alt="Shape" className="hotcolectimg" />

                        }

                        {/* <img src={require("../assets/images/fish.jpg")} class="hotcolectimg" alt="Shape" /> */}
                        <div className="profilehot">
                          {
                            itemgallary.imageName === "" &&
                            <img src={require("../assets/images/Wen.png")} class="img-fluid img-circle" alt="Shape" />
                          }
                          {
                            itemgallary.imageName !== "" &&

                            <img src={`${config.Back_URL}/collectionLogo/${itemgallary.userAddress}/${itemgallary.imageName}`} alt="Shape" className="img-fluid img-circle" />

                          }
                          {/* <img src={require("../assets/images/profile_img.png")} class="img-fluid img-circle" alt="Shape" /> */}
                        </div>
                        <h2>{itemgallary.collectionName}</h2>
                        {/* {itemgallary.total ?
                          <p>${Number(itemgallary.total.$numberDecimal).toFixed(4)}</p>
                          :
                          <p>$0</p>
                        } */}
                      </>
                      </Link>

                    </div>
                  )
                })}
            </OwlCarousel>

          </div>


        </section>
}
        {/* add class "ticket" for pink ticket image */}
        <section className="topcreators">
          <div className="container-fluid container-theme container-lg">
            <h2>Top Creators</h2>
            <div className="mt-5">
              <div className="rowone">
                {topcreatorsection.length > 0 && topcreatorsection.map((item) => {
                  // console.log("jfdxngjkdfngjkn", item)
                  return (
                    <div className="xenox">
                      <Link to={'/user/' + item.curraddress}>
                        <div className="prof-tick ">
                          {
                            item.image === "" &&
                            <img src={require("../assets/images/delete-Ditmax UI/Cropping/Wen.png")} alt="logo" className="img-fluid" />
                          }
                          {item.image !== "" &&

                            <img src={`${config.Back_URL}/images/${item._id}/${item.image}`} alt="logo" width="80" height="80" className="img-fluid" />
                          }
                        </div>
                      </Link>
                      <div>
                        {
                          item.name === "" && <p className="my-0 media_text"> {(item.curraddress).slice(0, 10).concat('.....')}</p>}
                        {
                          item.name !== "" && <p className="my-0 media_text"> {(item.name).slice(0, 10).concat('.....')}</p>}
                        <p className="mt-0 media_num mt-0">{item.count} items</p>
                      </div>
                    </div>

                  )
                })}

              </div>


            </div>
          </div>
        </section>

        {/* {
                          (Recent_Nft_List && Recent_Nft_List.length > 0)&&
                        
         <section className="liveauction carousel-nav">
          <div className="container-fluid container-theme container-lg">
            <div className="mt-3">
              <div className="">
                <div className="">
                  <div className="">
                    <div> <h3 className="section-head">Recent NFT</h3></div>
                    <div className=" masonry m-0">
                      <OwlCarousel items={4} className="owl-theme" nav={true} margin={5} autoplay={false}
                        responsive={state.responsive} dots={false}>
                         { (Recent_Nft_List.map((item) => {

                            return (
                              <TokenCard
                              item={item}
                          Set_item={Set_item}
                          LikedTokenList={LikedTokenList}
                        hitLike={LikeForwardRef.current&&LikeForwardRef.current.hitLike}  UserAccountAddr={UserAccountAddr}
                          UserAccountBal={UserAccountBal}
                          PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                          PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                          PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                          Set_Bids={Set_Bids}
                          Bids = {item.myBid}
                          Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                          Set_MyTokenBalance = {Set_MyTokenBalance}
                          Set_MyTokenDetail={Set_MyTokenDetail}
                          Set_AllowedQuantity={Set_AllowedQuantity}
                          Set_YouWillPay = {Set_YouWillPay}
                          Set_YouWillPayFee = {Set_YouWillPayFee}
                          Set_YouWillGet = {Set_YouWillGet}
                          // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                          Burn_Click={BurnForwardRef.current.Burn_Click}
                          CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                          WalletConnected={WalletConnected}
                          SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                          ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                          setConvertVal={setConvertVal}
                          convertVal={convertVal}
                           />
                            )
                          }))
                        }

                      </ OwlCarousel>
                    </div>

                  </div>

                </div>
              </div>


            </div>

          </div>
        </section>} */}
        {
          (Time_Auction_List && Time_Auction_List.length > 0) &&

          <section className="liveauction carousel-nav pb-3">
            <div className="container-fluid container-theme container-lg">

              <div className="mt-3">
                <div className="">
                  <div className="">
                    <div className="">
                      <div> <h2 className="section-head">Live Auctions</h2></div>
                      <div className=" masonry m-0">

                        <OwlCarousel items={5} className="owl-theme" nav={true} margin={10} autoplay={true}
                          responsive={state.responsive} dots={false}>

                          {(Time_Auction_List.map((item) => {
                            return (
                              (isEmpty(item.tokenowners_current) !== true )?
                              <TokenCard
                                item={item}
                                Set_item={Set_item}
                                LikedTokenList={LikedTokenList}
                                hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                                // UserAccountAddr={Wallet_Details.UserAccountAddr}
                                // UserAccountBal={Wallet_Details.UserAccountBal}
                                PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                Set_Bids={Set_Bids}
                                Bids={item.myBid}
                                Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                Set_MyTokenBalance={Set_MyTokenBalance}
                                Set_MyTokenDetail={Set_MyTokenDetail}
                                Set_AllowedQuantity={Set_AllowedQuantity}
                                Set_YouWillPay={Set_YouWillPay}
                                Set_YouWillPayFee={Set_YouWillPayFee}
                                Set_YouWillGet={Set_YouWillGet}
                                // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                                Burn_Click={BurnForwardRef.current.Burn_Click}
                                CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                // WalletConnected={Wallet_Details.WalletConnected}
                                SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                setConvertVal={setConvertVal}
                                convertVal={convertVal}
                              />
                              : ("")
                            )
                          }))
                          }

                        </ OwlCarousel>
                      </div>

                    </div>

                  </div>
                </div>


              </div>

            </div>
          </section>
        }
        {/* add class "ticket" for pink ticket image */}
        <section className="topcreators">
          <div className="container-fluid container-theme container-lg">
            {/* <div className="demo">
                <select class="option3">
                  <option>Buyers</option>
                  <option>Sellers</option>
                </select>
                </div> */}
                
            <Accordion expanded={expanded12 === 'panel12'} onChange={handleChangeFaq12('panel12')} className="panel_trans panel_acc_inline panel_acc_inline_dis">
              <AccordionSummary aria-controls="panel12bh-content" id="panel12bh-header" className="px-0">
                <button class="btn btn-secondary dropdown-toggle filter_btn select_btn m-auto">
                  <div className="explorerecenlyadded">

                    <span className="ml-2">{(buyersellerDrop === 'Buyer' || buyersellerDrop === 'buyer') ? 'Buyers' : 'Sellers'}<i class="fas fa-chevron-down"></i></span>

                  </div>
                </button>
              </AccordionSummary>
              <AccordionDetails className="px-0 border_width">
                <div className="accordian_para px-0 pb-0">
                  <div class="card cardexplore my-0 rad_2">
                    <div class="card-body px-2 pt-2 pb-0">
                      <ul className="colors_ul">
                        {buyersellerDrop === "buyer" &&
                          <li onClick={handleChangeFaq12('panel12', 'seller')}>
                            <span>seller</span>

                          </li>
                        }
                        {buyersellerDrop === "seller" &&
                          <li onClick={handleChangeFaq12('panel12', 'buyer')}>
                            <span >buyer</span>
                          </li>}


                      </ul>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <div className="mt-md-5 mt-3">
              <div className="rowone1">
                {buyerSeller.length > 0 && buyerSeller.map((item) => {
                  return (
                    <div className="xenox">
                      <div className="prof-tick">

                        <Link to={'/user/' + item.users.curraddress}>

                          <a href={item && item.users && item.users.customurl !== "" ? `${config.Front_URL}/${item.users.customurl}` : `${config.Front_URL}/user/${item.users.curraddress}`} title={`Owner : ${item.users.name}`}>
                            {/* <div className="img_tick_div">  */}
                            <div className="img_prgo_re">{
                              item.users.image === "" &&
                              <img src={require("../assets/images/delete-Ditmax UI/Cropping/Wen.png")} alt="logo" className="img-fluid" />

                            }
                              <span class="img_tick img_tick_lg"><img src={require("../assets/images/tick.png")} /></span>
                              {item.users.image !== "" &&
                                <img src={`${config.Back_URL}/images/${item.users._id}/${item.users.image}`} alt="User" className="img-fluid img_user_new tickuser" />}
                            </div>
                            {/* </div> */}
                          </a>

                        </Link>




                      </div>
                      <div>

                        {
                          item.users.name !== "" && <p className="xenoxpara"> {(item.users.name).slice(0, 10).concat('.....')}</p>}

                        {/* <p className="mt-0 media_num mt-0">{item.users.count} collections </p>  */}
                        {/* {console.log("dfhhgbdfgbfdgfdg", item.users.curraddress)} */}
                        {
                          item.users.name === "" && <p className="xenoxpara"> {(item.users.curraddress).slice(0, 10).concat('.....')}</p>}
                        {/* {console.log("fvbklfndlbvnfdklnmlokm", item.users.sellValue)} */}
                        {buyersellerDrop === "seller" &&

                          item.users.sellValue > 0 && <p className="xenoxcreate">$ {(item.users.sellValue).toFixed(4)}</p>

                        }
                        {/* {console.log("dfgfdgfgbrg",item.users.buyValue)} */}
                        {buyersellerDrop === "buyer" &&
                          item.users.buyValue > 0 && <p className="xenoxcreate">$ {(item.users.buyValue).toFixed(4)}</p>
                        }
                      </div>
                    </div>

                  )
                })}
              </div>



            </div>


          </div>
        </section>


        {
          (hotcol && hotcol.length > 0) &&
        <div className="main">
          <section className="bid_section section bid_section_section_1 hotbid_section">
            <div className="container-fluid container-theme container-lg">
<div className="d-flex justify-content-center customDrop">
<h2 class="section-head">Top Collections</h2>
            <CustomDropdown 
                          noLiPadding
                          buttonText={<div className="align-items-center buyersbtns customDropdown top-collection">
                       
                       {Time} Day</div>}
                          dropdownList={[
                            <div className="buernotir">

                              <div style={{ height: 200 }} className="nto_scrol_div">
                             
                                <ul className="explorebg btnbuyerss">
                                  <li className="px-3"  onClick={() => Timefun(1)}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 " >1 Day</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3"onClick={() => Timefun(7)}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">7 Days</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                  <li className="px-3" onClick={() => Timefun(30)}>
                                    <div className="media">
                                      <div className="media-body">
                                        <div>
                                          <p className="mt-0 ">30 Days</p>

                                        </div>

                                      </div>
                                    </div>

                                  </li>
                                </ul>
                               
                              </div>



                            </div>
                          ]}
                        />
                      <div className="see_all_button">
                      <Link to={'/collectionlist'} className="seealllister ml-3">See all</Link>
                      </div>
                        </div>
              {/* <Accordion expanded={expanded3 === 'panel3'} onChange={handleChangeFaq3('panel3')} className="panel_trans panel_acc_inline panel_acc_inline_dis felxfo">
                <AccordionSummary aria-controls="panel3bh-content" id="panel3bh-header" className="px-0">
                  <div className="felxfo">
                    <button class="btn btn-secondary dropdown-toggle filter_btn select_btn">
                      <div className="select_flex">
                        <span className="dayrwwoo">Top Collections  </span>
                        <span className="dayone ml-2">Day {Time}&nbsp;<i class="fas fa-chevron-down"></i>
                        </span>

                      </div>
                    </button>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="px-0 border_width">
                  <div className="accordian_para col-12 px-0 pb-0">
                    <div class="card card_bl_grey my-0 rad_2">
                      <div class="card-body px-2 pt-2 pb-0">
                        <ul className="colors_ul">
                          <a><li className="d-flex justify-content-between">
                            <span  onClick={() => Timefun(1)}>1Day</span>
                            // {/* <span><i class="fas fa-check"></i></span> 
                          </li></a>
                          <a><li>
                            <span onClick={() => Timefun(7)}>7Days</span>

                          </li></a>
                          <a><li>
                            <span  onClick={() => Timefun(30)}>30Days</span>

                          </li></a>

                        </ul>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion> */}

              <div>
                <div>
                  <ol className="gridtemplate">
                    {hotcol.length > 0 &&
                      hotcol.map((itemcoll, index) => {
                        return (
                          <Link to={'/collection/' + itemcoll.userAddress + '/' + itemcoll.customurl}>
                          <li className="orderflex">

                            <p>{index + 1}</p>
                            {/* <img src={require("../assets/images/skull.png")} class="rounded-circle" alt="Shape" /> */}

                            {
                              itemcoll.imageName === "" &&
                              <img src={require("../assets/images/Wen.png")} class="rounded-circle" alt="Shape" />
                            }
                            {
                              itemcoll.imageName !== "" &&

                              <img src={`${config.Back_URL}/collectionLogo/${itemcoll.userAddress}/${itemcoll.imageName}`} alt="Shape" className="rounded-circle" />

                            }
                            <div>
                              <p className="yachiclub">{(itemcoll.collectionName).slice(0,7)}</p>
                              {/* {itemcoll.total ?
                                <p>${Number(itemcoll.total).toFixed(4)}</p>
                                :
                                <p>$0</p>
                              } */}
                            
                            </div>
                           
                          </li>
                          </Link>
                        )
                      })}
                    {/* <li className="orderflex">
                      <p>2.</p>
                      <img src={require("../assets/images/skull.png")} class="rounded-circle" alt="Shape" />
                      <div>
                        <p className="yachiclub">MutanApe YachiClub</p>
                        <p>$7418,004</p>
                      </div>
                    </li>
                    <li className="orderflex">
                      <p>3.</p>
                      <img src={require("../assets/images/skull.png")} class="rounded-circle" alt="Shape" />
                      <div>
                        <p className="yachiclub">MutanApe YachiClub</p>
                        <p>$7418,004</p>
                      </div>
                    </li>
                    <li className="orderflex">
                      <p>4.</p>
                      <img src={require("../assets/images/skull.png")} class="rounded-circle" alt="Shape" />
                      <div>
                        <p className="yachiclub">MutanApe YachiClub</p>
                        <p>$7418,004</p>
                      </div>
                    </li>
                    <li className="orderflex">
                      <p>5.</p>
                      <img src={require("../assets/images/skull.png")} class="rounded-circle" alt="Shape" />
                      <div>
                        <p className="yachiclub">MutanApe YachiClub</p>
                        <p>$7418,004</p>
                      </div>
                    </li>
                    <li className="orderflex">
                      <p>6.</p>
                      <img src={require("../assets/images/skull.png")} class="rounded-circle" alt="Shape" />
                      <div>
                        <p className="yachiclub">MutanApe YachiClub</p>
                        <p>$7418,004</p>
                      </div>
                    </li>
                    <li className="orderflex">
                      <p>7.</p>
                      <img src={require("../assets/images/skull.png")} class="rounded-circle" alt="Shape" />
                      <div>
                        <p className="yachiclub">MutanApe YachiClub</p>
                        <p>$7418,004</p>
                      </div>
                    </li>
                    <li className="orderflex">
                      <p>8.</p>
                      <img src={require("../assets/images/skull.png")} class="rounded-circle" alt="Shape" />
                      <div>
                        <p className="yachiclub">MutanApe YachiClub</p>
                        <p>$7418,004</p>
                      </div>
                    </li>
                    <li className="orderflex">
                      <p>9.</p>
                      <img src={require("../assets/images/skull.png")} class="rounded-circle" alt="Shape" />
                      <div>
                        <p className="yachiclub">MutanApe YachiClub</p>
                        <p>$7418,004</p>
                      </div>
                    </li>
                    <li className="orderflex">
                      <p>10.</p>
                      <img src={require("../assets/images/skull.png")} class="rounded-circle" alt="Shape" />
                      <div>
                        <p className="yachiclub">MutanApe YachiClub</p>
                        <p>$7418,004</p>
                      </div>
                    </li>
                    <li className="orderflex">
                      <p>11.</p>
                      <img src={require("../assets/images/skull.png")} class="rounded-circle" alt="Shape" />
                      <div>
                        <p className="yachiclub">MutanApe YachiClub</p>
                        <p>$7418,004</p>
                      </div>
                    </li>
                    <li className="orderflex">
                      <p>12.</p>
                      <img src={require("../assets/images/skull.png")} class="rounded-circle" alt="Shape" />
                      <div>
                        <p className="yachiclub">MutanApe YachiClub</p>
                        <p>$7418,004</p>
                      </div>
                    </li>
                    <li className="orderflex">
                      <p>13.</p>
                      <img src={require("../assets/images/skull.png")} class="rounded-circle" alt="Shape" />
                      <div>
                        <p className="yachiclub">MutanApe YachiClub</p>
                        <p>$7418,004</p>
                      </div>
                    </li>
                    <li className="orderflex">
                      <p>14.</p>
                      <img src={require("../assets/images/skull.png")} class="rounded-circle" alt="Shape" />
                      <div>
                        <p className="yachiclub">MutanApe YachiClub</p>
                        <p>$7418,004</p>
                      </div>
                    </li>
                    <li className="orderflex">
                      <p>15.</p>
                      <img src={require("../assets/images/skull.png")} class="rounded-circle" alt="Shape" />
                      <div>
                        <p className="yachiclub">MutanApe YachiClub</p>
                        <p>$7418,004</p>
                      </div>
                    </li> */}

                  </ol>

                </div>
              </div>


            </div>
          </section>
          {/* Explore Section */}


          {/* Recent Collections Section */}



        </div>
         }
        <section className="liveauction carousel-nav pb-3 recentlyadded">
          <div className="container-fluid container-theme container-lg">

            <div className="mt-3">
              <div className="">
                <div className="">
                  <div className="">
                    <div> <h2 className="section-head">Recently Added NFTs</h2></div>
                    <div className=" masonry m-0">
                      <div className="eploregrid">
                        {/* token card */}
                        {/* {console.log("thytcatbasedtokenlist",CatBasedTokenList)} */}
                        {
                          (CatBasedTokenList
                            && CatName
                            && CatBasedTokenList[CatName] && CatBasedTokenList[CatName].list
                            && CatBasedTokenList[CatName].list.length > 0)
                            ? (CatBasedTokenList[CatName].list.map((item) => {
                              return (
                                (isEmpty(item.tokenowners_current) !== true )?
                                <div className=" mb-4">
                                  {/* col-lg-3 col-md-6 col-sm-6 */}
                                  <TokenCard
                                    item={item}
                                    Set_item={Set_item}
                                    LikedTokenList={LikedTokenList}
                                    hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                                    // UserAccountAddr={Wallet_Details.UserAccountAddr}
                                    // UserAccountBal={Wallet_Details.UserAccountBal}
                                    PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                    PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                    PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                    Set_Bids={Set_Bids}
                                    Bids={item.myBid}
                                    Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                    Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                    Set_MyTokenBalance={Set_MyTokenBalance}
                                    Set_MyTokenDetail={Set_MyTokenDetail}
                                    Set_AllowedQuantity={Set_AllowedQuantity}
                                    Set_YouWillPay={Set_YouWillPay}
                                    Set_YouWillPayFee={Set_YouWillPayFee}
                                    Set_YouWillGet={Set_YouWillGet}
                                    // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                                    Burn_Click={BurnForwardRef.current.Burn_Click}
                                    CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                    // WalletConnected={Wallet_Details.WalletConnected}
                                    SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                    ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                    setConvertVal={setConvertVal}
                                    convertVal={convertVal}
                                  />
                                </div>
                                : ("")
                              )
                            })) :
                            // <div className="proposal_panel_overall text-center">
                            <div className="text-center py-5 col-12 notfound">
                              <div className="text-center py-3  no_items_row">
                                <p className="not_found_text">Loading</p>
                                <p className="not_found_text_sub">Please wait a moment</p>
                                <div className="mt-3">
                                  {/* <Button className="create_btn"><Link to="/">Browse Marketplace</Link></Button> */}

                                </div>
                              </div>
                            </div>
                        }



                      </div>
                    </div>

                  </div>

                </div>
              </div>


            </div>

          </div>

          {/* {
            (CatBasedTokenList && CatBasedTokenList.loader === false && CatBasedTokenList[CatName] && CatBasedTokenList[CatName].list.length >= 10 && CatBasedTokenList[CatName].onmore === true) ? ( */}
              <div className="text-center mt-4 w-100 ">
                {/* {(CatBasedTokenList.loader === true)?
                    <i className="fa fa-spinner spinner_icon spinner_red" aria-hidden="true" id="spin"></i>:('')} */}
                <div id="spinButs" className="overflow-hidden">
                  <Button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" onClick={onLoadMore} disabled={LoadDisable} style={{ display: "flex" }}>
                    Load More <span>
                      {/* <img src={require("../assets/images/delete-Ditmax UI/Cropping/Spinner-1s-64px.gif")} alt="Wallet" className="img-fluid" /> */}
                    </span>

                  </Button>

                </div>
              </div>
              {/* ) : ('')} */}
        </section>


        <div id="logo_overlay" className="logo_ovelay">
          {/* <div>
            <section className="section wallet_section walet_icon_sec py-4">
              <div className="container-fluid container-theme container-lg">
                <h2 className="mt-0">Supported Wallets</h2>
                <GridContainer className="justify-content-center mt-4">
              
                  <GridItem md={2} sm={4} className="text-center mb-3">
                    <img src={require("../assets/images/wallet_01.png")} alt="Wallet" className="img-fluid mb-3" />
                    <p className="mb-0">Metamask</p>
                  </GridItem>
                 
                  <GridItem md={2} sm={4} className="text-center mb-3">
                    <img src={require("../assets/images/wallet_05.png")} alt="Wallet" className="img-fluid mb-3" />
                    <p className="mb-0">TrustWallet</p>
                  </GridItem>
                </GridContainer>
              </div>
            </section>

          </div> */}
          <Footer />
        </div>
      </div>
    </div>
  )
}
