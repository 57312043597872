import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
// myside
import CopyToClipboard from "react-copy-to-clipboard";
import moment from 'moment'
import {
  Link,
  useParams,
  useHistory,
  useLocation
} from "react-router-dom";
import isEmpty from "lib/isEmpty";
import {
  getprofile,
  getFollowers,
  followUnfollow,
  checkFollower,
  changeReceiptStatus_Action,
  ParamAccountAddr_Detail_Get,
  coverimagevalidations,
  coverImage,
} from '../actions/v1/user';
import Web3 from 'web3';
import config from '../lib/config';
import Avatars from './Avatar';
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton } from 'react-share'
import { toast } from 'react-toastify';
import {
  CollectiblesList_MyItems,
  getNFTDetails,
  InfopageRedirection,
  getAllCounts,
  getUserCollectiondata

} from '../actions/v1/token';
import { ActivityCall } from "../actions/v1/report";
import { LikeRef } from './separate/LikeRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import Banner from "../assets/images/banner.jpg";
import ConnectWallet from './separate/Connect-Wallet';
import TokenCard from './separate/TokenCard';
import { BurnRef } from './separate/BurnRef';
import { CancelOrderRef } from './separate/CancelOrderRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { notificationStatusChange } from '../actions/v1/report';



import { Account_Connect } from '../actions/redux/action'
import { useSelector, useDispatch } from 'react-redux'

toast.configure();
let toasterOption = config.toasterOption;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function Myitems(props) {
  const classes = useStyles();
  const location = useLocation();


  const Wallet_Details = useSelector(state => state.wallet_connect_context);

  const { ...rest } = props;
  const history = useHistory();
  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var BurnForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  var PlaceABidForwardRef = useRef();
  var { paramUsername, paramAddress } = useParams();
  if (typeof paramUsername == 'undefined') { paramUsername = ''; }
  if (typeof paramAddress == 'undefined') { paramAddress = ''; }
  const [ParamAccountCustomUrl, Set_ParamAccountCustomUrl] = React.useState(paramUsername);
  const [ParamAccountAddr, Set_ParamAccountAddr] = React.useState(paramAddress);
  const [UserNotFound, Set_UserNotFound] = React.useState(false);
  // const [FollowingUserList, Set_FollowingUserList] = React.useState([]);

  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [showingLoader, setshowingLoader] = React.useState(false);
  const [chooseimage, setchooseimage] = React.useState(false);
  const [validateError, setvalidateError] = React.useState({});
  const [followButton, setFollowButton] = useState('Follow')
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState('');
  const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = React.useState({});
  const [profile, setProfile] = useState({});
  const [coverimage, setcoverphoto] = React.useState(Banner);
  const [HitItem, Set_HitItem] = useState({});
  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  const [OnSale_Count, Set_OnSale_Count] = useState(0);
  const [OnSale_List, Set_OnSale_List] = useState([]);
  const [OnSale_Page, Set_OnSale_Page] = useState(1);
  const [followList, setFollowList] = useState([]);
  const [followingList, setFollowingList] = useState([]);
  const [Collectibles_Count, Set_Collectibles_Count] = useState(0);
  const [Collectibles_List, Set_Collectibles_List] = useState([]);
  const [Collectibles_List_Page, Set_Collectibles_List_Page] = useState(1);
  const [Created_Count, Set_Created_Count] = useState(0);
  const [Created_List, Set_Created_List] = useState([]);
  const [Created_List_Page, Set_Created_List_Page] = useState(1);
  const [Owned_Count, Set_Owned_Count] = useState(0);
  const [Owned_List, Set_Owned_List] = useState([]);

  const [Liked_Count, Set_Liked_Count] = useState(0);
  const [Liked_List, Set_Liked_List] = useState([]);
  const [Liked_List_Page, Set_Liked_List_Page] = useState(1);
  const [LoadmoreStatus, Set_Loadmore] = useState(false);
  const [notFount, Set_NotFount] = useState(false);

  // const [Burned_Count, Set_Burned_Count] = useState(0);
  // const [Burned_List, Set_Burned_List] = useState([]);

  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [item, Set_item] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [Bids, Set_Bids] = useState([]);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = useState('init');
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [Activity_Item, Set_Activity_Item] = useState([])
  const [convertVal, setConvertVal] = React.useState(0);
  const [userCollection, setUserCollections] = useState({});
  const [UserCollectionCount, setUserCollectionCount] = useState(0)
  const [LoadDisable, Set_LoadDisable] = useState(false);
  const [AlldataCount, setAllDataCount] = useState({})


  //loadmore
  const [CatBasedTokenList, setCatBasedTokenList] = useState({ 'loader': false, 'All': { page: 1, list: [], onmore: true } });

  async function CorrectDataGet(Resp, Target) {
    var RetData = { count: 0, list: [] }
    if (
      Resp
      && Resp.data
      && Resp.data.Target
      && Resp.data.list
      && Resp.data.list.length > 0
      && Resp.data.list[0]
    ) {
      if (Resp.data.Target === 'Count' && Resp.data.list[0].count) {
        RetData.count = Resp.data.list[0].count;
      }
      else if (Resp.data.Target === 'List' && Resp.data.list[0]) {
        RetData.list = Resp.data.list;
      }
    }
    // if (
    //   Resp
    //   && Resp.data
    //   && Resp.data.Target
    //   && Resp.data.changeStatusList) {
    //   changeReceiptStatus_Call(Resp.data.changeStatusList);
    // }
    return RetData;
  }

  async function changeReceiptStatus_Call(list) {
    var web3 = new Web3(Wallet_Details.providerss);
    list.map(async (item) => {
      if (item && typeof item.checkAdd != "undefined" && item.checkAdd.hashValue) {
        try {
          var data = await web3.eth.getTransactionReceipt(item.checkAdd.hashValue);
          var hashValue = item.checkAdd.hashValue;
          if (data == null) {
          } else {
            if (data.status === '0x0') {
            } else {
              var payload = {
                status: 'true',
                hashValue: hashValue
              }
              await changeReceiptStatus_Action(payload);
            }
          }
        }
        catch (err) {
        }
      }
    })
  }
  const copyText = (a, b) => {
    toast.success('copied', toasterOption);

  }
  async function Tab_Click(TabName, pageno) {

    Set_NotFount(false)
    if (pageno > 0) {
      await Tab_Data_Call('List', TabName, true, pageno);
    }
    else {
      Set_Loadmore(false)
      await Tab_Data_Call('List', TabName, true, 1);
    }
    //await Tab_Data_Call('Count', TabName, true);
    if (TabName === 'collectibles') {
      Set_Collectibles_List_Page(Collectibles_List_Page + 1)
    }
    else if (TabName === 'onsale') {
      Set_OnSale_Page(OnSale_Page + 1)
    }
    else if (TabName === 'created') {
      Set_Created_List_Page(Created_List_Page + 1)
    }
    else if (TabName === 'liked') {
      Set_Liked_List_Page(Liked_List_Page + 1)
    }

  }

  // async function LoadMore(TabName,pageno){
  //   await Tab_Data_Call('List', TabName, 'true', pageno);
  // }

  async function Tab_Data_Call(Target, TabName, init, pageno) {
    if (MyItemAccountAddr !== "") {
      var pagelimit = 10;
      var ReqData = {
        Addr: MyItemAccountAddr,
        MyItemAccountAddr: MyItemAccountAddr,
        ParamAccountAddr: ParamAccountAddr,
        UserAccountAddr: Wallet_Details.UserAccountAddr,
        Target: Target,
        TabName: TabName,
        init: init,
        limit: pagelimit,
        from: 'My-Items',
        page: pageno
      };
      Set_LoadDisable(true)
      var Resp = {};
      Resp = await CollectiblesList_MyItems(ReqData);
      //console.log("====++++",Resp);
      var RespNew = await CorrectDataGet(Resp);
      //console.log("=====",RespNew);

      if (
        (Target === 'Count' && typeof RespNew.count != 'undefined')
        ||
        (Target === 'List' && RespNew.list)
      ) {
        Set_LoadDisable(false)
        if (TabName === 'collectibles') {
          if (Target === 'Count') { Set_Collectibles_Count(RespNew.count); }
          if (Target === 'List') {
            if (pagelimit == RespNew.list.length) {
              Set_Loadmore(true)
            }
            if (pageno == 1) {
              if (RespNew.list.length == 0) { Set_NotFount(true) }
              Set_Collectibles_List(RespNew.list)
            }
            else {
              Set_Collectibles_List(Collectibles_List.concat(RespNew.list));
            }

          }
        }
        else if (TabName === 'onsale') {
          if (Target === 'Count') { Set_OnSale_Count(RespNew.count); }
          if (Target === 'List') {
            if (pagelimit == RespNew.list.length) {
              Set_Loadmore(true)
            }
            if (pageno == 1) {
              if (RespNew.list.length == 0) { Set_NotFount(true) }
              Set_OnSale_List(RespNew.list);
            }
            else {
              Set_OnSale_List(OnSale_List.concat(RespNew.list));
            }

          }
        }
        else if (TabName === 'created') {
          ////////////console.log("check vala", RespNew.count)
          ////////////console.log("check vala", RespNew.list)
          if (Target === 'Count') { Set_Created_Count(RespNew.count); }
          if (Target === 'List') {
            if (pagelimit == RespNew.list.length) {
              Set_Loadmore(true)
            }
            if (pageno == 1) {
              if (RespNew.list.length == 0) { Set_NotFount(true) }
              Set_Created_List(RespNew.list);
            }
            else {
              Set_Created_List(Created_List.concat(RespNew.list));
            }

          }
        }
        // else if (TabName === 'owned') {
        //   if (Target === 'Count') { Set_Owned_Count(RespNew.count); }
        //   if (Target === 'List') { Set_Owned_List(RespNew.list); }
        // }
        else if (TabName === 'liked') {
          if (Target === 'Count') { Set_Liked_Count(RespNew.count); }
          if (Target === 'List') {
            if (pagelimit == RespNew.list.length) {
              Set_Loadmore(true)
            }
            if (pageno == 1) {
              if (RespNew.list.length == 0) { Set_NotFount(true) }
              Set_Liked_List(RespNew.list);
            }
            else {
              Set_Liked_List(Liked_List.concat(RespNew.list));

            }

          }
        }
      }
    }
    return true;
  }

  useEffect(() => {
    if (Wallet_Details.UserAccountAddr !== "" || ParamAccountCustomUrl !== '' || paramAddress !== '') {
      getInit();

    }
    // (async()=>{

    //   if(String(location&&location.pathname).includes('/info/')===true){
    //     //console.log("Locations",location)
    //     var redata={
    //       tokenCounts:String(location&&location.pathname).split('/info/').pop()
    //     }
    //   var inforedirect=await InfopageRedirection(redata)
    //   if(inforedirect&&inforedirect.data&&inforedirect.data.data&&inforedirect.data.data.data){
    //    // console.log("inforedirect-1",inforedirect.data.data.data)
    //     var sendpush=inforedirect.data.data.data
    //     history.push('/info/'+sendpush.tokenOwner+'/'+sendpush.contractAddress+'/'+sendpush.tokenCounts)

    //   }
    //   }
    // })()
  }, [Wallet_Details.UserAccountAddr]);
  const Get_MyItemAccountAddr_Details = async (payload) => {
    //////console.log("pqowpqowpqp",JSON.stringify(payload))
    if (Wallet_Details.UserAccountAddr !== "" || paramAddress !== "" || ParamAccountCustomUrl !== "") {
      var Resp = await ParamAccountAddr_Detail_Get(payload);

      //////console.log("pqowpqowpqp",JSON.stringify(Resp))
      if (
        Resp
        && Resp.data
        && Resp.data.User
        && Resp.data.User.curraddress
      ) {
        Set_MyItemAccountAddr(Resp.data.User.curraddress);
        getAllCount(Resp.data.User.curraddress);
        if (Resp.data.User) {
          Set_MyItemAccountAddr_Details(Resp.data.User);
          getProfileData(Resp.data.User.curraddress);
        }
      }
      else {
        toast.warning('User not found', toasterOption);
        Set_UserNotFound(true);
      }
    }
  }
  const getAllCount = async (addr) => {
    var payload = {}
    //if (MyItemAccountAddr !== "") {
    payload.currAddr = addr;
    var countdata = await getAllCounts(payload);
    if (countdata && countdata.data && countdata.data.sucess) {
      setAllDataCount(countdata.data)
      // console.log("All Counts", countdata.data)
    }
    //}
  }
  const getInit = async () => {
    var currAddr = Wallet_Details.UserAccountAddr;
    var payload = {}
    if (ParamAccountAddr !== "" || ParamAccountCustomUrl !== "") {
      if (ParamAccountAddr && ParamAccountAddr.toString() === currAddr.toString()) {
        Set_MyItemAccountAddr(ParamAccountAddr);

        payload.addr = ParamAccountAddr
      }
      else {
        if (ParamAccountAddr !== "") {

          payload.addr = ParamAccountAddr;
        }
        else if (ParamAccountCustomUrl !== "") {
          payload.customurl = ParamAccountCustomUrl;
        }
      }
    }
    else {
      Set_MyItemAccountAddr(currAddr);
      payload.addr = currAddr
    }
    await Get_MyItemAccountAddr_Details(payload);

    window.$('#AfterWalletConnected_two').click();
  }
  //  check

  const getProfileData = async (addrchk) => {
    // var addrchk = UserAccountAddr
    const currAddr = isEmpty(paramAddress) ? addrchk : paramAddress;
    let reqData = {
      currAddr
    }
    var data = await getprofile(reqData);
    setProfile(data.userValue);
    //////console.log("sasasasasasas",data.userValue)
    if (data.userValue) {
      if (data.userValue.coverimage !== "") {
        let coverimage = `${config.Back_URL}/images/coverimages/${data.userValue._id}/${data.userValue.coverimage}`;
        setcoverphoto(coverimage);
      }
    }
  }

  async function AfterWalletConnected_two() {

    // LikeForwardRef&&LikeForwardRef.current.getLikesData();
    //await ActivityTab('mine');
    await checkFollowUser();
    await getfollowers1();//
    await getfollowing(); //
    //await Tab_Data_Call('Count', 'onsale', 'true'); 
    await getuserCollections();
    await Tab_Data_Call('List', 'onsale', 'true', OnSale_Page);
    Set_OnSale_Page(OnSale_Page + 1)
    // await Tab_Data_Call('Count', 'created', 'true');
    // await Tab_Data_Call('Count', 'collectibles', 'true');
    // await Tab_Data_Call('Count', 'owned', 'true');
    // await Tab_Data_Call('Count', 'liked', 'true');

    //await getOtherNFT('argument');
  }


  //   const getOtherNFT = async (data) => {
  //     var currAddr = Wallet_Details.UserAccountAddr;

  //     if(currAddr){
  //       var req = {
  //         useraddress: currAddr.toLowerCase(),
  //         refresh : true,
  //         senddata:data
  //       }
  //   }
  //   else{
  //     var req = {
  //       useraddress: currAddr.toLowerCase(),
  //       refresh : false,
  //         senddata:data
  //     }
  //   }
  //   // console.log("get User Account@123", req)
  //   var getNFTJson = await getNFTDetails(req);
  //   // console.log("getNFTJson",getNFTJson)
  //   if(getNFTJson&&getNFTJson.data&&getNFTJson.data.soci&&getNFTJson.data.soci.result){
  //     toast.success('Meta data refreshed,Please wait few moment')
  //   }
  //   //await Tab_Click_Collectibles('count')
  // }
  const handleFile = async (e) => {

    var reader = new FileReader()
    var file = e.target.files[0];
    var url = reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      setcoverphoto(reader.result)
    }.bind(this);


    let addr = MyItemAccountAddr;
    var reqdata = {
      file,
      currAddr: addr,
    }

    const { error } = await coverimagevalidations(reqdata)

    if (error !== undefined) {
      setshowingLoader(true)
      if (isEmpty(error.errors)) {
        setchooseimage(true)

        setvalidateError('')
        var coverimg = await coverImage(reqdata)
        if (coverimg && coverimg.userValue !== undefined) {
          window.$('#edit_cover_modal').modal('hide');
          getProfileData();
          setshowingLoader(false)
          setchooseimage(false)
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          setcoverphoto(Banner)
        }
      } else {
        setchooseimage(false)
        setshowingLoader(false)
        setvalidateError(error.errors)

      }
    } else {
      if (isEmpty(error)) {
        setshowingLoader(true)
        setchooseimage(true)
        setvalidateError('')
        var coverimg = await coverImage(reqdata)
        if (coverimg && coverimg.userValue !== undefined) {
          document.getElementById('edit_cover_modal_close').click()
          setshowingLoader(false)
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          setcoverphoto(Banner)
        }

      } else {
        setshowingLoader(false)
        setchooseimage(false)
        setvalidateError(error.errors)
      }
    }
  }
  const followFun = async (followerAddress) => {
    const currAddr = MyItemAccountAddr_Details.curraddress;
    let reqData = {
      curraddress: currAddr,
      followeraddress: followerAddress
    }
    var data = await followUnfollow(reqData);
    ////////console.log("okjkjkkpopo",data)
    if (data && data.follower && data.follower.success === true) {
      if (data.follower.message)
        toast.success(data.follower.message, toasterOption)
    }
    checkFollowUser();

  }
  const checkFollowUser = async () => {
    const currAddr = MyItemAccountAddr_Details.curraddress;
    let reqData = {
      curraddress: currAddr,
      followeraddress: Wallet_Details.UserAccountAddr
    }
    var data = await checkFollower(reqData);
    if (data && data.follower && data.follower.success === true) {
      if (data.follower.message) {
        setFollowButton(data.follower.message)
        ////////////console.log("<<<<<dd>>>>>",data)
      }
    }
  }
  const getfollowers1 = async () => {
    const currAddr = MyItemAccountAddr;
    let reqData = {

      curraddress: currAddr,
      tab: "follower"
    }
    var data = await getFollowers(reqData);
    // ////////////console.log("opopopopopop",(data.follower.list).length)

    if (data && data.follower && (data.follower.list).length !== 0) {
      setFollowList(data.follower.list);
    }
  }
  const getuserCollections = async () => {
    var reqData = {
      userAddress: MyItemAccountAddr
    }
    var userdata = await getUserCollectiondata(reqData)
    console.log("User Datasfdsefc", userdata.data.data.collections)
    if (userdata && userdata.data && userdata.data.data && userdata.data.data.collections) {
      setUserCollections(userdata.data.data.collections)
    }
  }

  const getfollowing = async () => {
    // var addrchk = getCurrAddr
    const currAddr = MyItemAccountAddr;
    let reqData = {
      curraddress: currAddr,
      tab: "following"
    }
    var data = await getFollowers(reqData);
    //////////console.log("follower lisisisisisit",data.follower);
    if (data && data.follower && (data.follower.list).length !== 0) {
      setFollowingList(data.follower.list);
    }
    else {
      setFollowingList([])
    }
  }
  //  ////////////console.log("checkkkk",MyItemAccountAddr_Details.curraddress)
  const statusChangeactivity = async (data) => {
    // ////////alert('vanthutan',UserAccountAddr)
    var reqdata = {
      currAddr: (Wallet_Details.UserAccountAddr).toLowerCase(),
      tokenCounts: data.tokenCounts,
      _id: data._id
    }
    var noti = await notificationStatusChange(reqdata)
    //////////////console.log("qweiqwueiqwueiouqeuqw", noti)
  }

  const ActivityTab = async (data) => {
    //////////console.log("soci1",data)
    var reqdata = {
      tabName: data,
      currAddr: MyItemAccountAddr,
      limit: config.limitMax,
      page: 1,
    }
    //////////console.log("soci1",reqdata)

    var activitys = await ActivityCall(reqdata)
    if (activitys && activitys.data && activitys.data.list && (activitys.data.list).length > 0) {
      // console.log("activity details", activitys.data.list)
      Set_Activity_Item(activitys.data.list)
      // Set_load_more(activitys.data)
    }
    else {
      Set_Activity_Item([])
    }

  }

  //   async function Tab_Click_Collectibles(data) {
  //     await getNftCollection(data);
  // }

  // const getNftCollection = async (data) => {
  //     const currAddr = MyItemAccountAddr
  //     let reqData = {
  //             curraddress: currAddr,
  //             senddata:data,
  //             limit:config.My_item_Limit
  //     }
  //     var data = await getNftCollectionData(reqData);
  //     console.log("--=--=gfhgfhgvhgh",data);
  //     if (data && data.respData&& data.respData.data&& data.respData.data.list) {
  //       setUserCollection(data.respData.data.list);
  //       //  console.log("NFTLength",data.respData.data.list)
  //     } 
  //     else if (data && data.respData&& data.respData.data&&data.respData.data.data) {
  //       console.log("--=--=gfhgfhgvhgh",data);
  //               setUserCollectionCount(data.respData.data.data);
  //     }
  // }


  return (
    <div className="inner_header">

      {/* <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        set_Service_Fee={set_Service_Fee}
        Service_Fee={Service_Fee}
        AfterWalletConnected={AfterWalletConnected}
        Wen_Bln={Wen_Bln}
        set_Wen_Bln={set_Wen_Bln}
        setConvertVal={setConvertVal}
        convertVal={convertVal}
        set_providers={set_providers}
        providerss={providerss}
      /> */}

      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}

        setConvertVal={setConvertVal}
        convertVal={convertVal}


      />
      <BurnRef
        ref={BurnForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        setConvertVal={setConvertVal}
        convertVal={convertVal}

      //GetUserBal={GetUserBal}
      // set_providers={set_providers}
      // providerss={Wallet_Details.providerss}
      />
      <PlaceAndAcceptBidRef
        ref={PlaceABidForwardRef}

        Set_AddressUserDetails={Set_AddressUserDetails}

        Set_MyItemAccountAddr={Set_MyItemAccountAddr}
        Set_tokenCounts={Set_tokenCounts}
        Set_item={Set_item}
        Set_tokenCounts_Detail={Set_tokenCounts_Detail}
        Set_MyTokenBalance={Set_MyTokenBalance}
        Set_Bids={Set_Bids}
        Set_AccepBidSelect={Set_AccepBidSelect}
        Set_tokenBidAmt={Set_tokenBidAmt}
        Set_NoOfToken={Set_NoOfToken}
        Set_ValidateError={Set_ValidateError}
        Set_TokenBalance={Set_TokenBalance}
        Set_YouWillPay={Set_YouWillPay}
        Set_YouWillPayFee={Set_YouWillPayFee}
        Set_YouWillGet={Set_YouWillGet}
        Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}

        MyItemAccountAddr={MyItemAccountAddr}
        tokenCounts={tokenCounts}
        item={item}
        tokenCounts_Detail={tokenCounts_Detail}
        MyTokenBalance={MyTokenBalance}
        Bids={Bids}
        AccepBidSelect={AccepBidSelect}
        tokenBidAmt={tokenBidAmt}
        NoOfToken={NoOfToken}
        ValidateError={ValidateError}
        TokenBalance={TokenBalance}
        YouWillPay={YouWillPay}
        YouWillPayFee={YouWillPayFee}
        YouWillGet={YouWillGet}
        BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
        BidApply_SignCallStatus={BidApply_SignCallStatus}
        AllowedQuantity={AllowedQuantity}
        setConvertVal={setConvertVal}
        convertVal={convertVal}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}

      />
      <WalletRef
        ref={WalletForwardRef}



      />
      <LikeRef

        ref={LikeForwardRef}


        setLikedTokenList={setLikedTokenList}
        MyItemAccountAddr={MyItemAccountAddr}
      />
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}

        setConvertVal={setConvertVal}
        convertVal={convertVal}

      />
      <ReportNowRef

        ref={ReportForwardRef}
      />
      <ShareNowRef
        ref={ShareForwardRef}
      />
      <div id="AfterWalletConnected_two" onClick={() => AfterWalletConnected_two()}></div>
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><Link to="/">
            <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link></div>
          <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
        </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader items_header"}>
        <div>
          <GridContainer className="mx-0">
            <GridItem xs={12} sm={12} md={12} className="px-0">
              <div className="items_bg">
                <img src={coverimage} alt="profile" className="img-fluid" />
                <div className="container edit_cover_container">
                  {
                    Wallet_Details.UserAccountAddr === MyItemAccountAddr_Details.curraddress &&
                    <p className="edit_cover_text" data-toggle="modal" data-target="#edit_cover_modal">Edit cover</p>
                  }
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div>
          <GridContainer className="mx-0">
            <GridItem xs={12} sm={12} md={12} className="px-0">
              <div className="items_bg_pink">
                <div className="item_prof">
                  <div className="item_prof_img">
                    {MyItemAccountAddr_Details && MyItemAccountAddr_Details.image !== "" &&
                      <img src={`${config.Back_URL}/images/${MyItemAccountAddr_Details._id}/${MyItemAccountAddr_Details.image}`} alt="profile" className="img-fluid items_profile" />}
                    {
                      MyItemAccountAddr_Details && MyItemAccountAddr_Details.image === "" &&
                      <Avatars item="img-fluid items_profile" />
                      //  <Avatars classValue="img-fluid items_profile"/>
                    }

                  </div>

                </div>
                <p className="text-center">
                  <span className="address_text">{MyItemAccountAddr_Details && MyItemAccountAddr_Details.curraddress}</span>
                  <span>
                    <CopyToClipboard text={MyItemAccountAddr_Details.curraddress} onCopy={() => copyText('invite link', MyItemAccountAddr_Details.curraddress)}>

                      <i className="fas fa-sticky-note notes_fa cur_pointer"></i>
                    </CopyToClipboard>

                  </span>
                </p>
                {(MyItemAccountAddr_Details && MyItemAccountAddr_Details.name) && <p className="text-center bio_desc">
                  {MyItemAccountAddr_Details.name}
                </p>}
                <p className="text-center bio_desc">
                  {MyItemAccountAddr_Details && MyItemAccountAddr_Details.bio}
                </p>

                <div className="mt-3  ">

                  <p className="mb-1 text-center sociallinks">
                    {(MyItemAccountAddr_Details.instagramcheck === true && MyItemAccountAddr_Details.instagram && MyItemAccountAddr_Details.instagram !== "") ?
                      <a href={`https://www.instagram.com/${MyItemAccountAddr_Details.instagram.split('/')[MyItemAccountAddr_Details.instagram.split('/').length - 1]}`} target="_blank">
                        <span> <i className="fab fa-instagram notes_fa mr-2"></i>

                          {MyItemAccountAddr_Details.instagram}
                        </span></a> : null}
                    {(MyItemAccountAddr_Details.twittercheck === true && MyItemAccountAddr_Details.twitter && MyItemAccountAddr_Details.twitter !== "") ?
                      <a href={`https://twitter.com/${MyItemAccountAddr_Details.twitter.split('/')[MyItemAccountAddr_Details.twitter.split('/').length - 1]}`} target="_blank">
                        <span className="pl-3">
                          <i className="fab fa-twitter notes_fa mr-2"></i>
                          {MyItemAccountAddr_Details.twitter}</span> </a> : null}

                    {(MyItemAccountAddr_Details.facebookcheck === true && MyItemAccountAddr_Details.facebook && MyItemAccountAddr_Details.facebook !== "") ?
                      <a href={`https://facebook.com/${MyItemAccountAddr_Details.facebook.split('/')[MyItemAccountAddr_Details.facebook.split('/').length - 1]}`} target="_blank">
                        <span> <i className="fab fa-facebook-f notes_fa mr-2"></i>
                          {MyItemAccountAddr_Details.facebook}</span> </a> : null}
                    {(MyItemAccountAddr_Details.youtubecheck === true && MyItemAccountAddr_Details.youtube && MyItemAccountAddr_Details.youtube !== "") ?
                      <a href={`https://www.youtube.com/${MyItemAccountAddr_Details.youtube.split('/')[MyItemAccountAddr_Details.youtube.split('/').length - 1]}`} target="_blank">
                        <span className="pl-3">
                          <i className="fab fa-youtube  notes_fa mr-2"></i>
                          {MyItemAccountAddr_Details.youtube}</span> </a> : null
                    }
                  </p>
                </div>


                <div className="mt-3">
                  {
                    (MyItemAccountAddr_Details.curraddress === Wallet_Details.UserAccountAddr) ?
                      <button className='btn newbtn'>
                        <span class="gradbtn grad-bordbtn">
                          <Link className="" to="/edit-profile">Edit Profile</Link>
                        </span>
                      </button>

                      :
                      <button className='btn newbtn' onClick={() => followFun(Wallet_Details.UserAccountAddr)}>

                        <span class="gradbtn grad-bordbtn">   {followButton}</span>

                      </button>
                  }
                  <span className="bg_red_icon cur_poinet" data-toggle="modal" data-target="#share_modal1">
                    <i className="fas fa-share-alt"></i>
                  </span>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container-fluid container-theme">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <nav className="masonry_tab_nav mt-4 mb-5 items_tab_outer">
                <div className="nav nav-tabs masonry_tab primary_tab items_tab justify-content-center items_tab_new pb-2 pl-2" id="nav-tab" role="tablist">
                  <a className="nav-link active" id="onsale-tab" data-toggle="tab" href="#onsale" role="tab" aria-controls="onsale" aria-selected="true" onClick={() => Tab_Click('onsale', 0)}>
                    <div className="tab_head">OnSale</div>
                    <div className="tab_count">{AlldataCount && AlldataCount.onsaleCount}</div>
                  </a>
                  <a className="nav-link" id="collectibles-tab" data-toggle="tab" href="#collectibles" role="tab" aria-controls="collectibles" aria-selected="false" onClick={() => Tab_Click('collectibles', 0)}>
                    <div className="tab_head">Collectibles</div>
                    <div className="tab_count">{AlldataCount && AlldataCount.collectibleCount}</div>
                  </a>
                  <a className="nav-link" id="created-tab" data-toggle="tab" href="#created" role="tab" aria-controls="created" aria-selected="false" onClick={() => Tab_Click('created', 0)}>
                    <div className="tab_head">Created</div>
                    <div className="tab_count">{AlldataCount && AlldataCount.createdCount}</div>
                  </a>
                  <a className="nav-link" id="liked-tab" data-toggle="tab" href="#liked" role="tab" aria-controls="liked" aria-selected="false" onClick={() => Tab_Click('liked', 0)}>
                    <div className="tab_head">Liked</div>
                    <div className="tab_count">{AlldataCount && AlldataCount.likeCount}</div>
                  </a>
                  {/* <a className="nav-link" id="activity-tab" data-toggle="tab" href="#activity" role="tab" aria-controls="activity" aria-selected="false">
                    <div className="tab_head">Activity</div>
                    <div className="tab_count">{Activity_Item && Activity_Item.length}</div>
                  </a> */}
                  <a className="nav-link" id="following-tab" data-toggle="tab" href="#following" role="tab" aria-controls="following" aria-selected="false" onClick={getfollowing}>
                    <div className="tab_head">Following</div>
                    <div className="tab_count">{AlldataCount && AlldataCount.followingCount}</div>
                  </a>
                  <a className="nav-link" id="followers-tab" data-toggle="tab" href="#followers" role="tab" aria-controls="followers" aria-selected="false" onClick={getfollowers1}>
                    <div className="tab_head">Followers</div>
                    <div className="tab_count">{AlldataCount && AlldataCount.followersCount}</div>
                  </a>
                  <a className="nav-link" id="UserCollection-tab" data-toggle="tab" href="#userCollection" role="tab" aria-controls="userCollection" aria-selected="false" onClick={getuserCollections}>
                    <div className="tab_head">Collections</div>
                    <div className="tab_count">{userCollection && userCollection.length === 0 ? 0 : userCollection.length}</div>
                  </a>
                  {/* {
                    MyItemAccountAddr== UserAccountAddr&&
                                    <a className="nav-link" id="NewCollectibles_tab" data-toggle="tab" href="#NewCollectibles" role="tab" aria-controls="NewCollectibles" aria-selected="false" onClick={() => Tab_Click_Collectibles('list')}>
                    <div className="tab_head">User Collectibles</div>
                    <div className="tab_count">{UserCollectionCount}</div>
                  </a>} */}
                  {/* <a className="nav-link" id="Burned-tab" data-toggle="tab" href="#Burned" role="tab" aria-controls="Burned" aria-selected="false" onClick={() => BurnFunction('click')}>
<div className="tab_head">Burned</div>
<div className="tab_count">{Burned_Count}</div>
</a> */}
                </div>
              </nav>

              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="onsale" role="tabpanel" aria-labelledby="onsale-tab">
                  <div className="proposal_panel_overall">
                    {(OnSale_List.length === 0) ? (


                      <div className="text-center py-5">
                        {notFount === true ? <>
                          <p className="not_found_text">Item Not Found</p>
                          <p className="not_found_text_sub">OnSale NFT Not Found</p>
                        </> :
                          <>
                            <p className="not_found_text">Loading</p>
                            <p className="not_found_text_sub">Please wait a moment</p>
                          </>}

                        <div className="mt-3">
                        </div>
                      </div>) :


                      (
                        <div className=" masonry  m-0 ma_no_gap myitemcards">
                          <>
                            {
                              OnSale_List.map((item) => {

                                return (
                                  (isEmpty(item.tokenowners_current) !== true) ?

                                    <div className="">

                                      <TokenCard
                                        item={item}
                                        Set_item={Set_item}
                                        LikedTokenList={LikedTokenList}
                                        hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                                        // UserAccountAddr={Wallet_Details.UserAccountAddr}
                                        // UserAccountBal={Wallet_Details.UserAccountBal}
                                        PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                        PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                        PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                        Set_Bids={Set_Bids}
                                        Bids={item.myBid}
                                        Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                        Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                        Set_MyTokenBalance={Set_MyTokenBalance}
                                        Set_MyTokenDetail={Set_MyTokenDetail}
                                        Set_AllowedQuantity={Set_AllowedQuantity}
                                        Set_YouWillPay={Set_YouWillPay}
                                        Set_YouWillPayFee={Set_YouWillPayFee}
                                        Set_YouWillGet={Set_YouWillGet}
                                        // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                                        Burn_Click={BurnForwardRef.current.Burn_Click}
                                        CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}

                                        SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                        ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                        setConvertVal={setConvertVal}
                                        convertVal={convertVal}

                                      />
                                    </div>
                                    : ("")
                                )
                              })}
                          </>

                        </div>

                      )}
                    {LoadmoreStatus == true &&

                      <div className="text-center mt-4 w-100 ">

                        <div id="spinButs" className="overflow-hidden">
                          <Button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" onClick={() => { Tab_Click('onsale', OnSale_Page) }} disabled={LoadDisable} style={{ display: "flex" }}>
                            Load More <span>

                            </span>

                          </Button>

                        </div>
                      </div>

                    }
                  </div>

                </div>

                <div className="tab-pane fade" id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
                  <div className="proposal_panel_overall">
                    {(Collectibles_List.length === 0) ? (

                      <div className="text-center py-5">
                        {notFount === true ? <>
                          <p className="not_found_text">Item Not Found</p>
                          <p className="not_found_text_sub">Collectibles NFT Not Found</p>
                        </> :
                          <>
                            <p className="not_found_text">Loading</p>
                            <p className="not_found_text_sub">Please wait a moment</p>
                          </>}
                        <div className="mt-3">
                          {/* <Button className="create_btn"><Link to="/">Browse Marketplace</Link></Button> */}

                        </div>
                      </div>) :


                      (<div className="masonry myitemcards m-0 ma_no_gap">
                        {
                          Collectibles_List.map((item) => {

                            return (
                              (isEmpty(item.tokenowners_current) !== true) ?
                                <div className="item ">

                                  <TokenCard
                                    item={item}
                                    Set_item={Set_item}
                                    LikedTokenList={LikedTokenList}
                                    hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                                    // UserAccountAddr={Wallet_Details.UserAccountAddr}
                                    // UserAccountBal={Wallet_Details.UserAccountBal}
                                    PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                    PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                    PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                    Set_Bids={Set_Bids}
                                    Bids={item.myBid}
                                    Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                    Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                    Set_MyTokenBalance={Set_MyTokenBalance}
                                    Set_MyTokenDetail={Set_MyTokenDetail}
                                    Set_AllowedQuantity={Set_AllowedQuantity}
                                    Set_YouWillPay={Set_YouWillPay}
                                    Set_YouWillPayFee={Set_YouWillPayFee}
                                    Set_YouWillGet={Set_YouWillGet}
                                    // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                                    Burn_Click={BurnForwardRef.current.Burn_Click}
                                    CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                    // WalletConnected={Wallet_Details.WalletConnected}
                                    SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                    ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                    setConvertVal={setConvertVal}
                                    convertVal={convertVal}

                                  />
                                </div>
                                : ("")
                            )
                          })}</div>
                      )}
                    {LoadmoreStatus == true &&



                      <div className="text-center mt-4 w-100 ">

                        <div id="spinButs" className="overflow-hidden">
                          <Button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" onClick={() => { Tab_Click('collectibles', Collectibles_List_Page) }} disabled={LoadDisable} style={{ display: "flex" }}>
                            Load More <span>

                            </span>

                          </Button>

                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="tab-pane fade" id="created" role="tabpanel" aria-labelledby="created-tab">
                  <div className="proposal_panel_overall">
                    {(Created_List.length === 0) ? (

                      <div className="text-center py-5">
                        {notFount === true ? <>
                          <p className="not_found_text">Item Not Found</p>
                          <p className="not_found_text_sub">Created NFT Not Found</p>
                        </> :
                          <>
                            <p className="not_found_text">Loading</p>
                            <p className="not_found_text_sub">Please wait a moment</p>
                          </>}
                        <div className="mt-3">
                          {/* <Button className="create_btn"><Link to="/">Browse Marketplace</Link></Button> */}

                        </div>
                      </div>) :


                      (<div className=" masonry myitemcards m-0 ma_no_gap">
                        {
                          Created_List.map((item) => {

                            return (
                              (isEmpty(item.tokenowners_current) !== true) ?
                                <div className="item ">

                                  <TokenCard
                                    item={item}
                                    Set_item={Set_item}
                                    LikedTokenList={LikedTokenList}
                                    hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                                    //  UserAccountAddr={Wallet_Details.UserAccountAddr}
                                    // UserAccountBal={Wallet_Details.UserAccountBal}
                                    PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                    PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                    PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                    Set_Bids={Set_Bids}
                                    Bids={item.myBid}
                                    Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                    Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                    Set_MyTokenBalance={Set_MyTokenBalance}
                                    Set_MyTokenDetail={Set_MyTokenDetail}
                                    Set_AllowedQuantity={Set_AllowedQuantity}
                                    Set_YouWillPay={Set_YouWillPay}
                                    Set_YouWillPayFee={Set_YouWillPayFee}
                                    Set_YouWillGet={Set_YouWillGet}
                                    // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                                    Burn_Click={BurnForwardRef.current.Burn_Click}
                                    CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                    // WalletConnected={Wallet_Details.WalletConnected}
                                    SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                    ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                    setConvertVal={setConvertVal}
                                    convertVal={convertVal}

                                  />
                                </div>
                                : ("")
                            )
                          })}</div>
                      )}
                    {LoadmoreStatus == true &&

                      <div className="text-center mt-4 w-100 ">

                        <div id="spinButs" className="overflow-hidden">
                          <Button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" onClick={() => { Tab_Click('created', Created_List_Page) }} disabled={LoadDisable} style={{ display: "flex" }}>
                            Load More <span>

                            </span>

                          </Button>

                        </div>
                      </div>
                    }

                  </div>
                </div>

                <div className="tab-pane fade" id="liked" role="tabpanel" aria-labelledby="liked-tab">
                  <div className="proposal_panel_overall">
                    {(Liked_List.length === 0) ? (

                      <div className="text-center py-5">
                        {notFount === true ? <>
                          <p className="not_found_text">Item Not Found</p>
                          <p className="not_found_text_sub">Liked NFT Not Found</p>
                        </> :
                          <>
                            <p className="not_found_text">Loading</p>
                            <p className="not_found_text_sub">Please wait a moment</p>
                          </>}
                        <div className="mt-3">
                          {/* <Button className="create_btn"><Link to="/">Browse Marketplace</Link></Button> */}

                        </div>
                      </div>) :


                      (<div className=" myitemcards masonry m-0 ma_no_gap">
                        {
                          Liked_List.map((item) => {

                            return (
                              (isEmpty(item.tokenowners_current) !== true )?


                              <div className="item ">

                                <TokenCard
                                  item={item}
                                  Set_item={Set_item}
                                  LikedTokenList={LikedTokenList}
                                  hitLike={LikeForwardRef.current && LikeForwardRef.current.hitLike}
                                  // UserAccountAddr={Wallet_Details.UserAccountAddr}
                                  // UserAccountBal={Wallet_Details.UserAccountBal}
                                  PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                  PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                  PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                  Set_Bids={Set_Bids}
                                  Bids={item.myBid}
                                  Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                  Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                  Set_MyTokenBalance={Set_MyTokenBalance}
                                  Set_MyTokenDetail={Set_MyTokenDetail}
                                  Set_AllowedQuantity={Set_AllowedQuantity}
                                  Set_YouWillPay={Set_YouWillPay}
                                  Set_YouWillPayFee={Set_YouWillPayFee}
                                  Set_YouWillGet={Set_YouWillGet}
                                  // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                                  Burn_Click={BurnForwardRef.current.Burn_Click}
                                  CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                  // WalletConnected={Wallet_Details.WalletConnected}
                                  SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                  ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                  setConvertVal={setConvertVal}
                                  convertVal={convertVal}

                                />
                              </div>
   : ("")


                            )
                          })}
                      </div>
                      )}
                    {LoadmoreStatus == true &&
                      <div className="text-center mt-4 w-100 ">

                        <div id="spinButs" className="overflow-hidden">
                          <Button className="btn newbtn mr-4 text-center text-light m-auto d-block mb-button" id="onmore" onClick={() => { Tab_Click('liked', Liked_List_Page) }} disabled={LoadDisable} style={{ display: "flex" }}>
                            Load More <span>

                            </span>

                          </Button>

                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="tab-pane fade" id="activity" role="tabpanel" aria-labelledby="activity-tab">
                  <div className="proposal_panel_overall">
                    {(Activity_Item.length === 0) ?

                      <div className="text-center py-5">
                        <p className="not_found_text">Loading</p>
                        <p className="not_found_text_sub">Please wait a moment</p>
                        <div className="mt-3">
                          {/* <Button className="create_btn"><Link to="/">Browse Marketplace</Link></Button> */}

                        </div>
                      </div> :
                      <div className="followers_overall py-1" >

                        <div className="row" >{
                          Activity_Item.map((item) => {
                            return (

                              <div className="col-12 col-md-6 col-xl-4 mb-4" onClick={() => statusChangeactivity(item)}>
                                <div className="card my-0 h-100 acti_card">
                                  <div className="card-body px-3">
                                    <div className="media follow_media icon_img">
                                      <div className="icon-img-div">
                                        <div className="">
                                          {/* <i className="fas fa-share-alt"></i> */}
                                        </div>
                                        <div className="img_media_new  mr-3">
                                          {
                                            item
                                            && item.fromField
                                            && item.fromField.image
                                            && item.fromField.image !== ""
                                            && <img onClick={() => history.push(item.fromField.customurl !== "" ? `/:${item.fromField.customurl}` : `/user/${item.to}`)} src={`${config.Back_URL}/images/${item.fromField._id}/${item.fromField.image}`} alt="User" className="img-fluid" />
                                          }
                                          {
                                            (item.fromField === undefined
                                              || item.fromField.image === undefined
                                              || item.fromField.image === "")
                                            &&
                                            <div className="img-fluid">
                                              <Avatars onClick={() => history.push(item.fromField.customurl !== "" ? `/:${item.fromField.customurl}` : `/user/${item.to}`)} item="img-fluid" /></div>
                                          }


                                        </div>
                                      </div>
                                      <div className="media-body flex_body">
                                        <div>
                                          {item.to && <div onClick={() => history.push(`/user/${item.to}`)}>
                                            <p className="my-0 media_text" title={(item.touserField && item.touserField.name !== "") ? item.touserField.name : item.to}>{(item.touserField && item.touserField.name !== "") ? item.touserField.name : item.to && item.to !== "" && (String(item.to).slice(0, config.My_item_Limit).concat('...'))}</p></div>}
                                          {item.activity && <div onClick={() => history.push(`/info/${item.tokenOwner}/${item.contractAddress}/${item.tokenCounts}`)}><p className="mt-0 media_num mt-0">
                                            <b>{item.tokens_current && item.tokens_current.tokenName !== "" && item.tokens_current.tokenName}</b> {item.activity}</p></div>}
                                          {item.from && <div onClick={() => history.push(`/user/${item.from}`)}><p className="my-0 media_text" title={(item.userField && item.userField.name !== "") ? item.userField.name : (item.from)}>{(item.userField && item.userField.name !== "") ? item.userField.name : item.from !== "" && String(item.from).slice(0, config.My_item_Limit).concat('...')}</p></div>}



                                        </div>

                                      </div>
                                    </div>
                                    <div className="ml-2">
                                      <p className="mt-0 media_num mb-0 dur_text">		{moment(item.created).format('MM/DD/YYYY, h:mm a')}
                                      </p>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    }</div>
                </div>
                <div className="tab-pane fade" id="following" role="tabpanel" aria-labelledby="following-tab">
                  {followingList && followingList.length === 0 ?
                    <div className="text-center py-5">
                      <p className="not_found_text">Loading</p>
                      <p className="not_found_text_sub">Please wait a moment</p>
                      <div className="mt-3">
                        {/* <Button className="create_btn"><Link to="/">Browse Marketplace</Link></Button> */}

                      </div>
                    </div> :
                    <div className="followers_overall py-1">
                      <div className="row">
                        {followingList && followingList.map((User) => {
                          //////////console.log("opopopopoppo",User)
                          return (
                            <div className="col-12 col-md-6 col-xl-4 mb-4">
                              <div className="card my-0">
                                <div className="card-body">
                                  <div className="media follow_media">
                                    <div className="img_media_new  mr-3">

                                      {User && User.user && !isEmpty(User.user) && User.user.image && User.user.image !== "" ?
                                        <Link to={(User.user.customurl && User.user.customurl !== "") ? '/' + User.user.customurl : '/user/' + User.user.curraddress}>
                                          <img src={`${config.Back_URL}/images/${User.user._id}/${User.user.image}`} alt="User" className="img-fluid" onClick={User.user.customurl !== "" ? `/${User.user.customurl}` : `/user/${User.user.curraddress}`} />
                                        </Link>
                                        :
                                        <Link to={(User.user.customurl && User.user.customurl !== "") ? '/' + User.user.customurl : '/user/' + User.user.curraddress}>

                                          <div className="img-fluid">
                                            <Avatars item="img-fluid" /></div>

                                        </Link>
                                      }{
                                        User && User.user && isEmpty(User.user) &&


                                        <div className="img-fluid">
                                          <Link to={'/user/' + User.followerAddress}>
                                            <Avatars item="img-fluid" />  </Link>
                                        </div>

                                      }
                                    </div>
                                    <div className="media-body flex_body"
                                    >
                                      <div>
                                        {

                                          User && User.user && !isEmpty(User.user) && <>
                                            <p className="mt-0 media_num font_14 mb-0">You Started Following</p>
                                            <Link to={(User.user.customurl && User.user.customurl !== "") ? '/' + User.user.customurl : '/user/' + User.user.curraddress}>

                                              <p className="my-0 media_text" title={'User :' + (User.user.name !== "" ? User.user.name : User.user.curraddress)}>{(User.user.name !== "" ? User.user.name : User.user.curraddress !== "" && String(User.user.curraddress).slice(0, 10).concat("..."))}</p>
                                            </Link>
                                          </>

                                        }
                                        {
                                          User && User.user && isEmpty(User.user) && <>

                                            <p className="mt-0 media_num font_14 mb-0">You Started Following  </p>
                                            <Link to={'/user/' + User.followerAddress}>
                                              <p className="my-0 media_text" title={'User :' + User.followerAddress}>{(User.followerAddress).slice(0, 10).concat("...")}</p>

                                            </Link> </>}
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          )
                        })}

                      </div>

                    </div>}
                </div>
                <div className="tab-pane fade" id="followers" role="tabpanel" aria-labelledby="followers-tab">
                  <div className="proposal_panel_overall">
                    {followList && followList.length === 0 ?
                      <div className="text-center py-5">
                        <p className="not_found_text">Loading</p>
                        <p className="not_found_text_sub">Please wait a moment</p>
                        <div className="mt-3">
                          {/* <Button className="create_btn"><Link to="/">Browse Marketplace</Link></Button> */}

                        </div>
                      </div> :
                      <div className="followers_overall py-1">
                        <div className="row">
                          {followList && followList.map((User) => {
                            //  ////console.log("opopopopoppo",User)
                            return (
                              <div className="col-12 col-md-6 col-xl-4 mb-4">
                                <div className="card my-0">
                                  <div className="card-body">
                                    <div className="media follow_media">
                                      <div className="img_media_new  mr-3">

                                        {User && User.user && !isEmpty(User.user) && User.user.image && User.user.image !== "" ?
                                          <Link to={(User.user.customurl && User.user.customurl !== "") ? '/' + User.user.customurl : '/user/' + User.user.curraddress}>
                                            <img src={`${config.Back_URL}/images/${User.user._id}/${User.user.image}`} alt="User" className="img-fluid" onClick={User.user.customurl !== "" ? `/${User.user.customurl}` : `/user/${User.user.curraddress}`} />
                                          </Link>
                                          :
                                          <Link href={(User.user.customurl && User.user.customurl !== "") ? '/' + User.user.customurl : '/user/' + User.user.curraddress}>

                                            <div className="img-fluid">
                                              <Avatars item="img-fluid" /></div>

                                          </Link>
                                        }{
                                          User && User.user && isEmpty(User.user) &&


                                          <div className="img-fluid">
                                            <Link to={'/user/' + User.userAddress}>
                                              <Avatars item="img-fluid" />  </Link>
                                          </div>

                                        }
                                      </div>
                                      <div className="media-body flex_body"
                                      >
                                        <div>
                                          {

                                            User && User.user && !isEmpty(User.user) && <>
                                              <p className="mt-0 media_num font_14 mb-0">You Started Following</p>
                                              <Link to={(User.user.customurl && User.user.customurl !== "") ? '/' + User.user.customurl : '/user/' + User.user.curraddress}>

                                                <p className="my-0 media_text" title={'User :' + (User.user.name !== "" ? User.user.name : User.user.curraddress)}>{(User.user.name !== "" ? User.user.name : User.user.curraddress !== "" && String(User.user.curraddress).slice(0, 10).concat("..."))}</p>
                                              </Link>
                                            </>

                                          }
                                          {
                                            User && User.user && isEmpty(User.user) && <>

                                              <p className="mt-0 media_num font_14 mb-0">You Started Following  </p>
                                              <Link to={'/user/' + User.userAddress}>
                                                <p className="my-0 media_text" title={'User :' + User.userAddress}>{(User.userAddress).slice(0, 10).concat("...")}</p>

                                              </Link> </>}
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            )
                          })}

                        </div>

                      </div>}

                  </div>
                </div>

                <div className="tab-pane fade" id="NewCollectibles" role="tabpanel" aria-labelledby="NewCollectibles-tab">
                  <div className="proposal_panel_overall">
                    {(UserCollectionCount === 0) ? (

                      <div className="text-center py-5">
                        <p className="not_found_text">Loading</p>
                        <p className="not_found_text_sub">Please wait a moment</p>
                        <div className="mt-3">
                        </div>
                      </div>) :
                      (<div className=" masonry myitemcards m-0 ma_no_gap">
                        {
                          ((userCollection.nft) || []).map((item, index) => {

                            return (

                              <div className="item ">
                                <div className="item itemd_heih_adj">
                                  <div className="card_inner_item">
                                    <div className="d-flex justify-content-between">
                                      <div className="d-flex creators_details mb-2">

                                        {
                                          <Link toref={`/user/${(item.owner_of)}`} title={`Owner : ${(item.owner_of)}`}>
                                            {MyItemAccountAddr_Details && MyItemAccountAddr_Details.image !== "" &&
                                              <img src={`${config.Back_URL}/images/${MyItemAccountAddr_Details._id}/${MyItemAccountAddr_Details.image}`} alt="profile" className="img-fluid align-self-center" />}
                                            {
                                              MyItemAccountAddr_Details && MyItemAccountAddr_Details.image === "" &&
                                              <Avatars item="img-fluid align-self-center" />
                                            }
                                          </Link>
                                        }
                                      </div>
                                      <div className="pl-2">
                                        <div className="dropdown ">
                                          <button className="drop-down" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            ....
                                          </button>
                                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                            <div className="menu_itm dropdown-item" id={"BurnCollectible" + item.tokenCounts} data-toggle="modal" data-target="#share_modal" onClick={() => {
                                              var item = {
                                                tokenName: userCollection.meta[index].name,
                                                contractAddress: userCollection.nft[index].token_address,
                                                tokenCounts: Number(userCollection.nft[index].token_id),

                                              }
                                              var tokenowners_current = {
                                                tokenCounts: Number(userCollection.nft[index].token_id),
                                                tokenOwner: userCollection.nft[index].owner_of,
                                                contractAddress: userCollection.nft[index].token_address,


                                              }
                                              ShareForwardRef.current.ShareSocial_Click(item, tokenowners_current)
                                            }}>
                                              <span>Share</span>
                                            </div>
                                          </div>




                                        </div>
                                      </div>

                                    </div>
                                    <div className="remaintime">
                                      <div className="item_inner_img  my_itens_sec_img">
                                        <Link to={"/info/" + userCollection.nft[index].owner_of + "/" + userCollection.nft[index].token_address + "/" + userCollection.nft[index].token_id}>
                                          <>
                                            {
                                              (((userCollection.meta[index].image).includes('ipfs://') === true || (userCollection.meta[index].image).includes('ipfs/') === true || (userCollection.meta[index].image).includes('ipfs:/') === true)
                                                ? (

                                                  <object type="image/webp" data={"https://ipfs.io/ipfs/" + (((userCollection.meta[index].image).split('ipfs://').pop()).split('ipfs/').pop()).split('ipfs:/').pop()}
                                                  >
                                                  </object>

                                                )
                                                :

                                                (userCollection.meta[index].image !== "" &&

                                                  <object type="image/webp" data={userCollection.meta[index].image}
                                                  >
                                                  </object>
                                                ))}


                                          </>
                                        </Link>
                                      </div>
                                    </div>
                                    <Link to={"/info/" + item.owner_of + "/" + item.token_address + "/" + item.token_id}>
                                      <h2>{userCollection.meta[index].name}</h2></Link>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <h3 className="mb-0" >{userCollection.nft[index].amount} of {userCollection.nft[index].amount}</h3>
                                      {/* <div className="masonry_likes" onClick={() => {
                                          var reqdata={tokenCounts:item.token_id,tokenOwner:item.owner_of}
                                          // LikeForwardRef.current &&
                                           LikeForwardRef.current.hitLike(reqdata)}}>
                                          <span className={item.tokenCounts + '-likecount'}>{item.likecount}</span>
                                           {
                                            (LikedTokenList.findIndex(tokenCounts => (tokenCounts.tokenCounts === item.token_id)) > -1) ?
                                              (<i className="fas fa-heart ml-2 liked"></i>) :
                                              (<i className="far fa-heart ml-2"></i>)
                                          }
                                        </div> */}
                                    </div>

                                  </div></div>
                              </div>

                            )
                          })}</div>
                      )}

                  </div>
                </div>

                <div className="tab-pane fade" id="userCollection" role="tabpanel" aria-labelledby="UserCollection-tab">
                  <div className="proposal_panel_overall">
                    {/* {console.log("fgbfdgbfdbgfd", userCollection)} */}
                    {userCollection && userCollection.length === 0 ?
                      <div className="text-center py-5">
                        <p className="not_found_text">No items found</p>
                        <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                        <div className="mt-3">
                          {/* <Button className="create_btn"><Link to="/">Browse Marketplace</Link></Button> */}
                        </div>
                      </div> :
                      <div className="followers_overall py-1">
                        <div className="row">
                          {userCollection && userCollection.length > 0 && userCollection.map((User) => {
                            //  ////console.log("opopopopoppo",User)
                            return (
                              <div className="col-12 col-md-6 col-xl-4 mb-4">
                                <div className="card my-0">
                                  <div className="card-body">
                                    <div className="media follow_media">
                                      <div className="img_media_new  mr-3">

                                        {User && User && User.imageName !== "" ?
                                          <Link to={`/collection/${(User.userAddress)}/${(User.customurl)}`} title={`Owner : ${(User.userAddress)}`}>

                                            <img src={`${config.Back_URL}/collectionLogo/${User.userAddress}/${User.imageName}`} alt="User" className="img-fluid" />
                                          </Link>
                                          :
                                          <Link to={`/collection/${(User.userAddress)}/${(User.customurl)}`} title={`Owner : ${(User.userAddress)}`}>

                                            <div className="img-fluid">
                                              <Avatars item="img-fluid" /></div>

                                          </Link>
                                        }
                                      </div>
                                      <div className="media-body flex_body">
                                        <div>
                                          {
                                            User && !isEmpty(User) && <>
                                              <Link to={`/collection/${(User.userAddress)}/${(User.customurl)}`} title={`Owner : ${(User.userAddress)}`}>
                                                <p className="mt-0 media_num font_14 mb-0">{(User.collectionName !== "" ? User.collectionName : User.collectionName !== "" && String(User.collectionName).slice(0, 10).concat("..."))}</p>


                                                <p className="my-0 media_text" >{(User.collectionType)}</p>
                                              </Link>
                                            </>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            )
                          })}

                        </div>

                      </div>}

                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
      {/* edit_cover Modal */}
      <div className="modal fade primary_modal" id="edit_cover_modal" tabIndex="-1" role="dialog" aria-labelledby="edit_cover_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="edit_cover_modalLabel_1">Update cover</h5>
              <h5 className="modal-title" id="edit_cover_modalLabel_2">Follow Steps</h5>

              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="update_cover_div_1" id="update_cover_div_1">
                <p className="mt-0 approve_desc text-center mb-4">Upload new cover for your profile page. We recommended to upload images in 1140×260 resolution and supported format PNG,GIF,WEPG,JPG</p>
                <form className="text-center">
                  <Button className="file_btn btn primary_btn" disabled={chooseimage}>Choose image
                    <input className="inp_file"
                      type="file"
                      name="coverimage"
                      id="coverphoto"
                      onChange={(e) => handleFile(e)}
                    />
                  </Button>


                </form>
                <div className="text-center mb-2">
                  {
                    validateError.file && <span className="text-danger">{validateError.file}</span>
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* end edit_cover modal */}
      <div className="modal fade primary_modal" id="share_modal1" tabIndex="-1"
        role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="share_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-module">
              <div className="modal-header text-center">
                <h5 className="modal-title" id="share_modalLabel">Share this NFT</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>

              </div>
              <div className="modal-body px-0">
                <div className="row justify-content-center mx-0">
                  <div className="col-12 col-sm-6 col-lg-3 px-1">
                    <div className="text-center icon_div">

                      <TwitterShareButton

                        title={`${(MyItemAccountAddr_Details.name !== "") ? MyItemAccountAddr_Details.name : MyItemAccountAddr_Details.curraddress}` + " " + `NFT`}
                        url={`${config.Front_URL}/user/${MyItemAccountAddr_Details.curraddress}` + " " + `${config.shareTag[0]}` + " " + `${config.shareTag[1]}` + " " + `${(config.shareTag[2])}`}

                      // via={`${config.Front_URL}`}
                      >
                        <i className="fab fa-twitter"></i>
                        <p>Twitter</p>


                      </TwitterShareButton>

                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 px-1">
                    <div className="text-center icon_div">

                      <TelegramShareButton
                        title={`${(MyItemAccountAddr_Details.name !== "") ? MyItemAccountAddr_Details.name : MyItemAccountAddr_Details.curraddress}` + " " + `NFT`}
                        url={`${config.Front_URL}/user/${MyItemAccountAddr_Details.curraddress}` + " " + `${config.shareTag[0]}` + " " + `${config.shareTag[1]}` + " " + `${(config.shareTag[2])}`}
                      >

                        <i className="fab fa-telegram-plane"></i>
                        <p>Telegram</p>

                      </TelegramShareButton>

                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 px-1">
                    <div className="text-center icon_div">
                      <FacebookShareButton
                        quote={`${(MyItemAccountAddr_Details.name !== "") ? MyItemAccountAddr_Details.name : MyItemAccountAddr_Details.curraddress}` + " " + `NFT`}
                        //  title={`${(MyItemAccountAddr_Details.name  != "") ? MyItemAccountAddr_Details.name : MyItemAccountAddr_Details.curraddress}` " "+ `NFT`}
                        url={`${config.Front_URL}/user/${MyItemAccountAddr_Details.curraddress}` + " " + `${config.shareTag[0]}` + " " + `${config.shareTag[1]}` + " " + `${(config.shareTag[2])}`}

                      // via={`${config.Front_URL}`}
                      >
                        <i className="fab fa-facebook-f"></i>
                        <p>Facebook</p>
                      </FacebookShareButton>

                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 px-1">
                    <div className="text-center icon_div">
                      <WhatsappShareButton
                        title={`${(MyItemAccountAddr_Details.name !== "") ? MyItemAccountAddr_Details.name : MyItemAccountAddr_Details.curraddress}` + " " + `NFT`}
                        url={`${config.Front_URL}/user/${MyItemAccountAddr_Details.curraddress}` + " " + `${config.shareTag[0]}` + " " + `${config.shareTag[1]}` + " " + `${(config.shareTag[2])}`}
                      >
                        <i className="fab fa-whatsapp"></i>
                        <p>Whatsapp</p>
                      </WhatsappShareButton>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
