import DETH_ABI from '../ABI/DETH_ABI.json';
import Loder from '../assets/images/loader.png'
import '@metamask/legacy-web3'
import AudioImg from '../assets/images/AudioImg.jpg'
import axios from 'axios';
let fee1 ='';
var chainIds=1;
const singleType = 721;
const multipleType = 1155;
let currencySymbol = 'XDC';
let tokenSymbol = 'BLKZ';
let maxLength=13;
var WenlamboConvertVal=0
let PurchaseTransferType = 'currency';
var tokenAddr = { 
    BLKZ :"0x4826634fe187ed3ffb64d77c5c1a67ba49cc03de".toLowerCase()  
}
var tokenABI = {
    BLKZ: DETH_ABI, 
}
let BNBPROVIDER="";
let Front_URL = '';
let Back_URL = '';
let Users_URL = '';
let v1Url = '';
let limit = '8';
var My_item_Limit=6;
let Lod=Loder;
let limitMax = 3000;
let decimalvalues = 1000000000000000000;
let toFixed = 6;
var tokenFee=0;
let IPFS_IMG = "https://xdcnft.infura-ipfs.io/ipfs";
let IPFS_Infura = "https://xdcnft.infura-ipfs.io/ipfs";
var nameFormat = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
var trade   ="";
var singleContract ="";
var multipleContract = "";
var chainId ="";
var networkVersion ="";
var shareTag=[]
var toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false, 
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    limit:1
}

var providercon=null;
var buyerfee=0;
var sellerfee=0; 
// live

let EnvName = 'production';
let fee=fee1;
var currAdrress='';

if (EnvName === "production") {
    Front_URL = 'https://app.xdcnft.com';
    Users_URL = 'https://app.xdcnft.com/user';
    Back_URL = 'https://admin.xdcnft.com';
    v1Url = 'https://admin.xdcnft.com/v1';
    BNBPROVIDER             =   "https://erpc.xinfin.network";
    networkVersion      =   '50';
    chainId             =   '0x32';
    var exchangeAddress     =   "";
    singleContract   =  "0x2385bD067826f0Ac6aD4727cd07D22b84E7aF7E8".toLowerCase();
    multipleContract    =  "0x89A8CcE3F9a84Ac434A6d5a8D92aCA45b8c3729b".toLowerCase();
}
else if (EnvName === "demo") {  
    Front_URL = 'http://54.242.155.153';
    Users_URL = 'http://54.242.155.153/user';
    // Front_URL               =   'http://localhost:3000';
    // Users_URL               =   'http://localhost:3000/user';
    Back_URL = 'http://54.242.155.153:2053';
    v1Url = 'http://54.242.155.153:2053/v1';
    BNBPROVIDER             =   "https://erpc.xinfin.network";
    networkVersion      =   '50';
    chainId             =   '0x32';
    var exchangeAddress     =   "";
    singleContract   =  "0x2385bD067826f0Ac6aD4727cd07D22b84E7aF7E8".toLowerCase();
    multipleContract    =  "0x89A8CcE3F9a84Ac434A6d5a8D92aCA45b8c3729b".toLowerCase(); 
}
else if(EnvName === "testlive"){
    Front_URL = 'https://app.xdcnft.com';
    Users_URL = 'https://app.xdcnft.com/user';
    Back_URL = 'https://admin.xdcnft.com';
    v1Url = 'https://admin.xdcnft.com/v1';
BNBPROVIDER             =   "https://erpc.apothem.network";
networkVersion      =   '50';
chainId             =   '0x61';
var exchangeAddress     =   "";

singleContract      =   "0x2385bD067826f0Ac6aD4727cd07D22b84E7aF7E8".toLowerCase();
 multipleContract    =   "0x89A8CcE3F9a84Ac434A6d5a8D92aCA45b8c3729b".toLowerCase();
 trade               =   ("0x6b85C4861427cB262E61c54a2b47E130c6051478").toLowerCase();

}
else if(EnvName === "demoLive"){ 

}
else {
    Front_URL               =   'http://localhost:3000';
    Users_URL               =   'http://localhost:3000/user';
    Back_URL                =   'http://localhost:2002';
    v1Url                   =   'http://localhost:2002/v1';
    // Back_URL = 'http://54.242.155.153:2053';
    // v1Url = 'http://54.242.155.153:2053/v1';
    // BNBPROVIDER             =   "https://erpc.apothem.network";
    BNBPROVIDER             =   "https://erpc.xinfin.network";
    networkVersion      =   '50';
    chainId             =   '0x32';
    var exchangeAddress     = "";
    singleContract      =   "0x2385bD067826f0Ac6aD4727cd07D22b84E7aF7E8".toLowerCase();
     multipleContract    =   "0x89A8CcE3F9a84Ac434A6d5a8D92aCA45b8c3729b".toLowerCase();
     trade               =   ("0x6b85C4861427cB262E61c54a2b47E130c6051478").toLowerCase();
}

async function tag(){
    var id="0000"
    await axios.get(v1Url+'/admin/panel/getnfttag/'+id)
    .then((data)=>{
        if(data&&data.data&&data.data.userValue){
            (data.data.userValue).map((item)=>{
                (shareTag).push(item.nfttag)
                return item   
        })}
    })
}
tag();
       
var My_item_Page        =   1;
let key = {
    Front_URL           :   Front_URL,
    Back_URL            :   Back_URL,
    v1Url               :   v1Url,
    vUrl                :   v1Url,
    fee                 :   fee,
    decimalvalues       :   decimalvalues,
    toFixed             :   toFixed,
    networkVersion      :   networkVersion,
    currencySymbol      :   currencySymbol,
    tokenSymbol         :   tokenSymbol,
    toasterOption       :   toasterOption,
    limit               :   limit,
    limitMax            :   limitMax,
    exchangeAddress     :   exchangeAddress,
    singleContract      :   singleContract,
    multipleContract    :   multipleContract,
    // old_singleContract  :   old_singleContract,
    // old_multipleContract:   old_multipleContract,
    tokenAddr           :   tokenAddr,
    singleType          :   singleType,
    multipleType        :   multipleType,
    IPFS_IMG            :   IPFS_IMG,
    BNBPROVIDER         :   BNBPROVIDER,
    tokenABI            :   tokenABI,
    PurchaseTransferType:   PurchaseTransferType,
    maxLength           :   maxLength,
    Users_URL           :   Users_URL,
    Lod                 :   Lod,
    chainId             :   chainId,
    WenlamboConvertVal  :   WenlamboConvertVal,
    currAdrress         :    currAdrress,
    tokenFee            :   tokenFee,
    sellerfee           :   sellerfee,
    buyerfee            :   buyerfee,
    shareTag            :   shareTag,
    providercon         :   providercon,
    AudioImg            :   AudioImg,
    nameFormat          :   nameFormat,
    chainIds            :   chainIds,
    trade               :   trade,
    My_item_Limit       :   My_item_Limit,
    My_item_Page        :   My_item_Page,
    IPFS_Infura         :   IPFS_Infura,
};

export default key;