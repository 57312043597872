import React, {
    forwardRef,
    useImperativeHandle,useState,useEffect
} from 'react';

import { Button } from '@material-ui/core';
import Web3 from 'web3';

import config from '../../lib/config';

import { BurnField,checkOtherPlatformDetais1155} from '../../actions/v1/token';
import { toast } from 'react-toastify';
import SINGLE from '../../ABI/SINGLE.json'
import MULTIPLE from '../../ABI/MULTIPLE.json'
import {getReceipt} from '../../actions/v1/getReceiptFunc';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

toast.configure();
let toasterOption = config.toasterOption;

export const BurnRef = forwardRef((props, ref) => {

    const history = useHistory()

    const Wallet_Details = useSelector(state=>state.wallet_connect_context);
    // const [BuyerName, Set_BuyerName] = React.useState('');
    // const [blns, Set_blns] = React.useState('');
    // const [dethBln, Set_dethBln] = React.useState('');
    // const [bidProfile1, Set_bidProfile1] = React.useState([]);
    const [burnLoading, setBurnLoading] = useState('empty');
	
    // const [FormSubmitLoading, Set_FormSubmitLoading] = useState('init');
    // const [CoinName, setCoinNames] = useState('');
    // const [CoinName_Initial, set_CoinName_Initial] = useState('');
    
    const [ValidateError, Set_ValidateError] = useState({});
    // const [YouWillGet, Set_YouWillGet] = useState(0);
    const[Items,Set_Items]=useState({})
    ////////console.log('props.item.tokenPrice', props.item);

    // const [TokenPrice, Set_TokenPrice] = useState(0);
    // const [TokenPrice_Initial, Set_TokenPrice_Initial] = React.useState(0);
    const [noofitems,setnoofitem]=useState(0)
        const[MyTokenDetail,set_MyTokenDetail]=useState({})

  
    useImperativeHandle(
        ref,
        () => ({
            async Burn_Click(item, MyTokenDetail) {
                ////////console.log("putonsale1",item,MyTokenDetail,UserAccountAddr)
                if (Wallet_Details.UserAccountAddr!=="") {
                    var web3=new Web3(Wallet_Details.providerss)
                    var balance = await checkOtherPlatformDetais1155(item,MyTokenDetail,item.type,web3);
                     console.log('sdvbalsdvancev>>>vdfvdfv>>>>dsv>>',balance)
                    if (balance === 0) {
                        toast.warning("You won't buy at this moment please refresh you data",toasterOption);
                        setTimeout(() => {
                        history.push("/")
                        }, 1000);
                        return false;
                    }
                    else{

                    props.Set_HitItem(item);
                    Set_Items(item)
                    setnoofitem(MyTokenDetail.balance)
                    set_MyTokenDetail(MyTokenDetail)
                     Set_ValidateError({});
                    window.$('#burn_token_modal').modal('show');
                }}
                else {
                    window.$('#connect_modal').modal('show');
                }
            }
        }),
    )
    // const history = useHistory();

   
    async function FormSubmit(data,MyTokenDetail) {
        var receipt=null;
            var handle=null;
        if (Wallet_Details.UserAccountBal < 0) {
			toast.error('Enter vaid balance');
			return false;
		}
		if(Wallet_Details.providerss) {
			var web3 = new Web3(Wallet_Details.providerss)
			if(
				web3
			) {
                setBurnLoading('processing');
                var CoursetroContract=null;
                var contractCall=null;
                try{
                if(Items.type===721){
				var CoursetroContract = new web3.eth.Contract(
					SINGLE,
					Items.contractAddress
				);
                 await CoursetroContract.methods
                .burn(
                   
                   data.tokenCounts
               )
               .send({ from: Wallet_Details.UserAccountAddr })
               .on('transactionHash',async (transactionHash) => {
                handle = setInterval(async () => {
                    receipt = await getReceipt(web3, transactionHash)
                    clr1();
                  }, 8000)
            })
            }
                else{
                    var CoursetroContract = new web3.eth.Contract(
                        MULTIPLE,
                        Items.contractAddress
                    );  
                await CoursetroContract.methods
                .burn(
                    Wallet_Details.UserAccountAddr,
                   data.tokenCounts,noofitems
               )
               .send({ from: Wallet_Details.UserAccountAddr })
                
                .on('transactionHash',async (transactionHash) => {
                    handle = setInterval(async () => {
                        receipt = await getReceipt(web3, transactionHash)
                        clr1();
                      }, 8000)
                })
            }
        }
        catch(error) {
            setBurnLoading('try');
            console.log('error : ', error);
            toast.error('Order not placed', toasterOption)
        }
			
                async function clr1(){
       
                    if(receipt!=null){
                        clearInterval(handle);
                         if(receipt.status===true){   
				
					////////console.log('result : ', result);
          var postData = {
						tokenOwner: Wallet_Details.UserAccountAddr,
						tokenCounts: data.tokenCounts,
						blockHash: receipt.blockHash,
						transactionHash: receipt.transactionHash,
                        contractAddress:data.contractAddress,
                        type:data.type,
                        balance:noofitems,
                        currAddr:Wallet_Details.UserAccountAddr,
                        quant:MyTokenDetail.balance
          
          
					}
                    setBurnLoading('done');
          ////////console.log('postDatafrominfo',postData);
          var updateBurnField = await BurnField(postData)
          if(updateBurnField){
            toast.success('Burned successfully',toasterOption)
            document.getElementById('closeburn').click() 
            // history.push('/')
            history.push("/")
          }
        }
    }
				
			
      
    }
  }
    }
    }
    var {
        // item,
        UserAccountAddr,
        // UserAccountBal,
        // Service_Fee
    } = props;

    useEffect(() => {
        Set_ValidateError({});
    }, []);

  
    const inputChange = (e) => {
        if (e.target && e.target.value)
    // ////////console.log("lalalalalalallala",MyTokenDetail)
    if(MyTokenDetail.balance>=e.target.value){
    setnoofitem(e.target.value)
  	setBurnLoading('init');}
    else if(e.target.value === 0){
      setBurnLoading('zero');
    }
    else if(e.target.value === "" ){
      setBurnLoading('empty');
    }
    else if(e.target.value === undefined ){
      setBurnLoading('empty');
    }
    else{
      setBurnLoading('errors');
    }

    }

    return (
   
	<div className="modal fade primary_modal" id="burn_token_modal" tabIndex="-1" role="dialog" aria-labelledby="burn_token_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div className="modal-content">
        <div className="modal-header text-center">
        <h5 className="modal-title" id="burn_token_modalLabel">Burn token</h5>
        <button type="button" id="closeburn" className="close" data-dismiss="modal" aria-label="Close" 
          disabled = { (burnLoading==='processing') }

        >
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body px-0">
            <form className="bid_form">
    <div className="px-4">
                    <p className="checkout_text_light_white text-center" style={{color:'black',fontWeight:'bold'}}>{MyTokenDetail.balance} Tokens Available</p>
                </div> 
                <div className="px-4">
                    <p className="checkout_text_light_white">Are you sure to burn this token? This action cannot be undone. Token will be transfered to zero address</p>
                </div>  
                <div className="px-4 mt-4 pt-2">               
                    <input 
                        id = "burn" 
                        name="burn" 
                        className="form-control"
                        onChange = {(e) => { inputChange(e)}}
                    />
                    <div className="text-center mt-3">
                        <Button 
                            className="burn_btn_red primary_btn btn-block" 
                            onClick={(() => FormSubmit(Items, MyTokenDetail))}
                            disabled = { (burnLoading === 'done' || burnLoading==='processing' || burnLoading === 'zero'||burnLoading === 'errors'||burnLoading === 'empty') }
                            >
              {burnLoading === 'processing' && <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                {burnLoading === 'init' && 'Continue'}
                                {burnLoading === 'processing' && 'In-progress...'}
                                {burnLoading === 'done' && 'Done'}
                                {burnLoading === 'try' && 'Try Again'}
              {burnLoading === 'errors' && 'Check Balance'}
              {burnLoading === 'zero' && "Qty can't be Zero"}
              {burnLoading === 'empty' && "Qty can't be Empty"}
              
                            </Button>
                        <Button className="btn_outline_grey cancel_btn btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    </div>
</div>
)
})

