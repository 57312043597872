import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button} from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";

import Select from "react-select";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";




const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function CreateSingle(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const options = [
    { value: 'WENLAMBO', label: 'WENLAMBO' },
  { value: 'BTC', label: 'BTC' },
  ];

  // const options1 = [
  //   { value: 'WETH', label: 'WETH' },
  // { value: 'BTC', label: 'BTC' },
  // ];

  // const options2 = [
  //   { value: 'Right after listing', label: 'Right after listing' },
  // { value: '1 day', label: '1 day' },
  // ];

  // const options3 = [
  //   { value: '1 day', label: '1 day' },
  // { value: '2 days', label: '2 days' },
  // ];
  const handleChange =()=>{
    // this.setState({ selectedOption });
    // ////console.log(`Option selected:`, selectedOption);
  };
  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><Link to="/">
            <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/></Link></div>
            <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid"/></Link></div>
            </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <Link to="/">
                    <i className="fas fa-arrow-left mr-3 arrow_back"></i>
                </Link>
                <h3 className="section-head mb-0 sec_he_sm">Create Multiple Collectible</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-5">
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <label className="primary_label">Preview</label>
              <div className="single_collectible masonry mx-0">
              <div className="item">
                      <div className="card_inner_item item_multiple">
                        <div className="d-flex justify-content-between">
                        <div className="d-flex creators_details">
                          <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                          <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                          <img src={require("../assets/images/small-profile.png")} alt="User" className="img-fluid align-self-center" />
                          </div>
                          <div>
                          {/* <div className="dropdown">
                            <button className="drop-down" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            ....
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <a className="dropdown-item" data-toggle="modal" data-target="#buy_modal">Purchase now</a>
                              <a className="dropdown-item" data-toggle="modal" data-target="#place_bid_modal">Place a bid</a>
                              <a className="dropdown-item" data-toggle="modal" data-target="#share_modal">Share</a>
                            </div>
                          </div> */}
                          </div>
                        </div>
                        <div className="remaintime mt-3">
                            <div className="item_inner_img">
                                <img src={require("../assets/images/masonary_04.png")}  alt="Collections" className="img-fluid" />
                              </div>
                            <div className="timeleft">
                              <span>03h 56mm 06s left</span>
                            </div>
                        </div>
                      
                          <h2>Ants Net - Awesome Group.</h2>
                         
                          
                        <div className="d-flex justify-content-between align-items-end">
                          <div>
                            <h3 className="my-2 "><span className="text-gray">Highest bid 1/1</span> </h3>
                          <button className="btn btn_purple_sm bg-theme" data-toggle="modal" data-target="#place_bid_multiple_modal">2.3B WENLAMBO($100)</button>
                          </div>
                          {/* <div className="masonry_likes">
                          <span>27</span>
                            <i className="far fa-heart ml-2 liked"></i>
                          
                          </div> */}
                        </div>
                      </div>
                      </div>
                     
              </div>
            </GridItem>
            <GridItem xs={12} sm={8} md={8}>
              <form className="formCls">
                <div className="form-row">
                  <div className="form-group col-md-12">
                   <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Upload file</label>
                        <p className="form_note">PNG, GIF, WEBP, MP4 or MP3. Max 30mb.</p>
                      </div>
                      <div className="file_btn btn primary_btn">Upload
                        <input className="inp_file" type="file" name="file"/>
                      </div>
                   </div>
                  </div>
                  <div className="form-group col-md-12">
                  <div className="">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Choose Collection</label>
                      </div>
                      <div className="row connect_row mt-3">
                      <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                        <div className="create_box create_sing_bx"  data-toggle="modal" data-target="#choose_collection_modal">
                        
                        <img src={require("../assets/images/price_svg.svg")} alt="Fixed Price" className="img-fluid" />

                          <p>Create ERC-721</p>
                        </div>
                      </div>
                     
                      <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                        <div className="create_box create_sing_bx">
                        <img src={require("../assets/images/loader.png")} alt="Unlimited Auction" className="img-fluid" />

                          <p>WENLAMBO</p>
                        </div>
                      </div>
                    </div>

                      
                    
                   </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start grid_toggle">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Put on Sale</label>
                        <p className="form_note">You’ll receive bids on this item</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox"/>
                        <span className="slider"></span>
                      </label>
                    </div>
                    <div className="row connect_row mt-3 putonsale_sec">
                      <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                        <div className="create_box create_sing_bx">
                         {/* <i className="fas fa-tag"></i> */}
                        <img src={require("../assets/images/price_svg.svg")} alt="Fixed Price" className="img-fluid" />

                          <p>Fixed Price</p>
                        </div>
                      </div>
                      {/* <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                        <div className="create_box create_sing_bx">
                        <img src={require("../assets/images/timed_svg.svg")} alt="Timed Auction" className="img-fluid" />
                          <p>Timed Auction</p>
                        </div>
                      </div> */}
                      <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                        <div className="create_box create_sing_bx">
                        <img src={require("../assets/images/unlimited_svg.svg")} alt="Unlimited Auction" className="img-fluid" />

                          <p>Unlimited Auction</p>
                        </div>
                      </div>
                    </div>


                    <div className="row mx-0 mt-3 fixed_price_sec">
                    <label className="primary_label" htmlFor="price_new">Price</label>

<div className="form-row w-100">
<div className="form-group col-md-6">
<div className="input-group input_grp_style_1">

<input type="text" id="bid" className="form-control" placeholder="Enter your bid" aria-label="bid" aria-describedby="basic-addon2" />
<div className="input-group-append">
<span className="input-group-text pl-0 min_h_45_px" id="basic-addon2">USD</span>
</div>
</div>
</div>
<div className="form-group col-md-6">
<div className="input-group input_grp_style_1">

<input type="text" id="bid" className="form-control" value="0.01B" placeholder="Enter your bid" aria-label="bid" aria-describedby="basic-addon2" disabled/>
<div className="input-group-append">
<span className="input-group-text pl-0 min_h_45_px" id="basic-addon2">WENLAMBO</span>
</div>
</div>
</div>
</div>

{/* <div className="input-group mb-1">
<input type="text" id="price_new" className="form-control selct_form_input_h" placeholder="Enter price for one piece" aria-label="Recipient's username" aria-describedby="basic-addon2" />
<div className="input-group-append">
<Select
    className="yes1 form-control primary_inp select1 selxet_app"

    id="tokenCategory"
    name="tokenCategory"
    onChange={handleChange}
    options={options}
    label="Single select"
    formControlProps={{
      fullWidth: true
    }}
  />
</div>

</div> */}
<p className="form_note">Service fee <span className="font_we_700_note_txt">2.5%</span><br/> You will receive <span className="font_we_700_note_txt">0B WENLAMBO($100)</span></p>

                    </div>
                  


                  </div>
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start grid_toggle">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Unlock Once Purchased</label>
                        <p className="form_note">Content will be unlocked after successful transaction</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox"/>
                        <span className="slider"></span>
                      </label>
                    </div>
                    <div className="form-group unlock_sec d-none">
                    <input type="text" className="form-control primary_inp mb-1" id="digitalkey" placeholder="Digital key, code to redeem or link to a file.."/>
                    <p className="form_note">Tip: Markdown syntax is supported</p>
                  </div>
                  </div>
                </div>
                {/* <div className="form-row">
                  <div className="form-group col-md-6">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Instant Sale Price</label>
                        <p className="form_note">Enter the price for which the item<br/> will be instantly sold</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox"/>
                        <span className="slider"></span>
                      </label>
                    </div>
                  </div>
                </div> */}
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Name</label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="e.g. Redeemable"/>
                    {/* <p className="error_text">Name is required</p> */}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Description <span className="text-muted">(Optional)</span></label>
                    <input type="text" className="form-control primary_inp" id="desccription" placeholder="e.g. After purchasing..."/>
                    {/* <p className="error_text">Description is required</p> */}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="royalties">Royalties</label>
                    <div className="inp_grp">
                      <input type="text" className="form-control primary_inp" id="royalties" placeholder="0.00"/>
                      <p className="inp_append">%</p>
                    </div>
                    {/* <p className="error_text">Royalties is required</p> */}
                  </div>
                  <div className="form-group col-md-6">
                  <label className="primary_label" htmlFor="category">Category</label>
                  <Select
                        className="yes1 form-control primary_inp select1 selxet_app"

                        id="tokenCategory"
                        name="tokenCategory"
                        onChange={handleChange}
                        options={options}
                        label="Single select"
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    {/* <input type="text" className="form-control primary_inp" id="category" placeholder="e.g. catogory"/> */}
                    {/* <p className="error_text">Properties is required</p> */}
                  
                  </div>
                </div>
                <div className="form-row">
                <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="qty">Quantity</label>
                      <input type="text" className="form-control primary_inp" id="qty" placeholder="1"/>
                    {/* <p className="error_text">Royalties is required</p> */}
                  </div>
                <div className="form-group col-md-6">
                <label className="primary_label" htmlFor="properties">Properties <span className="text-muted">(Optional)</span></label>
                    <input type="text" className="form-control primary_inp" id="properties" placeholder="e.g. size"/>
                    {/* <p className="error_text">Properties is required</p> */}
                  </div>
                </div>
                <div>
                  {/* <Button className="create_btn">Create item</Button> */}
              <Button className="create_btn" data-toggle="modal" data-target="#create_item_modal">Create item</Button>

                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
        {/* Choose Collection Modal */}
        <div className="modal fade primary_modal" id="choose_collection_modal" tabIndex="-1" role="dialog" aria-labelledby="choose_collection_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="choose_collection_modalLabel">Collection</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex align-items-center flex_block_mob">
                <div className="hex center">
                  <div className="hex__shape">
                    <div className="hex__shape">
                      <div className="hex__shape">
                        <img src={require("../assets/images/img_01.png")} alt="logo" className="img-fluid"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="font_14 font_600 line_20 mb-1 text_brown mb-3">We recommend an image of at least 400x400. Gifs work too.</p>
                  <div className="file_btn primary_btn d-inline-block btn_small">Choose File
                    <input className="inp_file" type="file" name="file"/>
                  </div>
                </div>
              </div>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="name">Display Name <span className="text-muted">(30 available)</span></label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="Enter token name"/>
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="desccription">Symbol <span className="text-muted">(required)</span></label>
                    <input type="text" className="form-control primary_inp" id="desccription" placeholder="Enter token symbol"/>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="name">Description <span className="text-muted">(Optional)</span></label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="Spread some words about token collection"/>                  
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="desccription">Short url</label>
                    <input type="text" className="form-control primary_inp" id="desccription" defaultValue="Fayre.io/"/>
                  </div>
                </div>
                <div className="text-center">
                  <Button className="create_btn btn-block">Create Collection</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* ens choose collection modal */}
       {/* create_item Modal */}
 <div className="modal fade primary_modal" id="create_item_modal" tabIndex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
 <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
   <div className="modal-content">
     <div className="modal-header text-center">
       <h5 className="modal-title" id="create_item_modalLabel">Follow Steps</h5>
       <button type="button" className="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">&times;</span>
       </button>
     </div>
     <div className="modal-body">                         
       <form> 
       <div className="media approve_media">
       <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
       {/* <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
       <div className="media-body">
         <p className="mt-0 approve_text">Approve</p> 
         <p className="mt-0 approve_desc">Approve performing transactions with your wallet</p>
       </div>
     </div>
       <div className="text-center my-3">
       <Button className="btn_outline_red btn-block">Done</Button>                
       </div>
       <div className="media approve_media">
       <i className="fas fa-check mr-3" aria-hidden="true"></i>
       <div className="media-body">
         <p className="mt-0 approve_text">Upload files & Mint token</p> 
         <p className="mt-0 approve_desc">Call contract method</p>
       </div>
     </div>
       <div className="text-center my-3">
       <Button className="create_btn btn-block">Start</Button>                
       </div>

       <div className="media approve_media">
       <i className="fas fa-check mr-3" aria-hidden="true"></i>
       <div className="media-body">
         <p className="mt-0 approve_text">Sign sell order</p> 
         <p className="mt-0 approve_desc">Sign sell order using tour wallet</p>
       </div>
     </div>
       <div className="text-center my-3">
       <Button className="create_btn btn-block" disabled>Start</Button>                
       </div>

       <div className="media approve_media">
       <i className="fas fa-check mr-3" aria-hidden="true"></i>
       <div className="media-body">
         <p className="mt-0 approve_text">Sign lock order</p> 
         <p className="mt-0 approve_desc">Sign lock order using tour wallet</p>
       </div>
     </div>
       <div className="text-center mt-3">
       <Button className="create_btn btn-block" disabled>Start</Button>                
       </div>
       </form>
     </div>
   </div>
 </div>
</div>
{/* end create_item modal */}


  {/* learn Modal */}
  <div className="modal fade primary_modal" id="learn_modal" tabIndex="-1" role="dialog" aria-labelledby="learn_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
 <div className="modal-dialog modal-dialog-centered modal-md" role="document">
   <div className="modal-content">
     <div className="modal-header text-center">
       <h5 className="modal-title" id="learn_modalLabel">How timed auction work</h5>
       <button type="button" className="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">&times;</span>
       </button>
     </div>
     <div className="modal-body">                         
      <p>When you put your item on timed auction, you choose currency, minimum bid, starting and ending dates of your auction.</p>

      <p>The bidder can only place a bid which satisfies the following conditions:</p>
      <ol>
        <li>
        It is at least minimum bid set for the auction
        </li>
        <li>
        It is at least 5% higher than the current highest bid or it is at least 2.3B WENLAMBO($100) higher than the current highest bid

        </li>
      </ol>

<p>Note that some bids may disappear with time if the bidder withdraws their balance. At the same time, some bids may reappear if the bidder has topped up their balance again.</p>

<p>Auction cannot be cancelled after any valid bid was made. Any bid placed in the last 10 minutes extends the auction by 10 minutes.</p>

<p>In the 48 hours after the auction ends you will only be able to accept the highest available bid placed during the auction. As with regular bids, you will need to pay some gas to accept it.Note that you can always decrease the price of your listing for free, without making a transaction or paying gas. You can view all your items here.</p>

    
   </div>
 </div>
</div>
</div>
{/* end learn modal */}

 {/* calendar Modal */}
 <div className="modal fade primary_modal" id="calendar_modal" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
 <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
   <div className="modal-content">
     <div className="modal-header text-center">
       <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>
       <button type="button" className="close" data-dismiss="modal" aria-label="Close">
         <span aria-hidden="true">&times;</span>
       </button>
     </div>
     <div className="modal-body">  
     <div className="pb-3">
                           
     <Datetime input={false} />
     </div>  
   </div>
 </div>
</div>
</div>
{/* end calendar modal */}
    </div>
  );
}
