import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useHistory, useParams } from "react-router-dom";
import Web3 from 'web3';
import Profile from 'assets/images/no_profile2.png'
import Select from 'react-select'
import { addUserCollection, CollectionValidationAction } from '../actions/v1/user'
import {
  getUserCollectiondata
} from '../actions/v1/token';
// myside
import {
  editprofile,
  getprofile,
} from '../actions/v1/user';

import '@metamask/legacy-web3';
import config from '../lib/config';
import isEmpty from '../lib/isEmpty';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";

import ConnectWallet from '../views/separate/Connect-Wallet'
toast.configure();
let toasterOption = config.toasterOption;
const dashboardRoutes = [];
const useStyles = makeStyles(styles);
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const initialFormValue = {
  'userAddress': "",
  'collectionName': "",
  'customurl': "",
  'collectionType': '',
  'profileImg': '',
  'coverImg': ''
}
export default function EditProfile(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const Wallet_Details = useSelector(state => state.wallet_connect_context);
  var { name, form } = useParams();

  const [disablebtn, setDisablebtn] = useState(0)
  const [validateError, setValidateError] = useState({});
  const [CategoryOption, setCategoryOption] = useState([{ value: 'SINGLE', label: 'Single' }, { value: 'MULTIPLE', label: 'Multple' }]);
  // const [CollectionAddress,setCollectionAddress] = useState('')
  // const [CollectionName,setCollectionName] = useState('')
  const [NFTType, setNFTType] = useState('')
  const [onchangeimg, setOnchangeimg] = useState('')
  const [coverimg, setcoverimg] = useState('')
  const [imageVal, setImageVal] = useState('')
  const [coverval, setcoverval] = useState('')
  const [backvalerr, setbackvalerr] = useState('')
  const [formValue, setFormValue] = useState(initialFormValue);
  const [userAddress, setuserAddress] = useState(Wallet_Details.UserAccountAddr)
  const history = useHistory();


  const {
    collectionName,
    customurl,
    collectionType,
    profileImg,
    coverImg
  } = formValue

  const onChange = (e) => {
    setDisablebtn(0)
    //e.preventDefault();
    //setCollectionAddress(e.target.value)
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }
    setFormValue(formData)
  }
  // const onChangeCollectionName = (e) => {
  //   e.preventDefault();
  //   console.log("Collection Name",e.target.value)
  //   setCollectionName(e.target.value)
  // }
  useEffect(() => {




    CollectionProfile();

  }, [Wallet_Details.UserAccountAddr])

  const selectChange = (e) => {

    if (e && e.label && e.value) {
      const { id, value } = e
      let formData = { ...formValue, ...{ ['collectionType']: value } }
      console.log("dhtsdrth", e)
      setFormValue(formData)

      // setNFTType(e.value)

    }
  }
  // const Validate = async (data) => {
  //   // console.log("fkdsbnvkfjsbnvk", data);
  //   var validateError = {};
  //   let urlRegex = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/;
  //   let imageSize = 5000000;
  //   // if ((data.imageVal) === '') {
  //   //   validateError.image = "image is Required"
  //   // }
  //   // if (onchangeimg === "") {
  //   //   console.log("epwopeopwope", data.imageVal, onchangeimg, onchangeimg.split('.').pop())
  //   //   if (data.imageVal != "" || data.imageVal != undefined) {
  //   //     // if (imageSize < (data.imageVal).size) {
  //   //     //   validateError.image = "File size must be below 5mb"
  //   //     // }
  //   //     if (!(/\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|WEBP|gif|GIF)$/i).test((data.imageVal).name)) {
  //   //       validateError.image = "file is invalid. only allowed JPG,PNG,WEBP,gif";
  //   //     }
  //   //   }

  //   // }

  //   // if (coverimg === "" ) {
  //   //   console.log("epwopeoerhtergpwope", data.imageVal, coverimg, coverimg.split('.').pop())
  //   //   if (data.coverval != "" || data.coverval != undefined) {
  //   //     if (imageSize < (data.coverval).size) {
  //   //       validateError.image = "File size must be below 5mb"
  //   //     }
  //   //     if (!(/\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|WEBP|gif|GIF)$/i).test((data.coverval).name)) {
  //   //       validateError.image = "file is invalid. only allowed JPG,PNG,WEBP,gif";
  //   //     }
  //   //   }

  //   // }
  //   // console.log("sdxgdsrh3sdr", data.formValue.collectionName)
  //   if ((data.formValue.collectionName) === '') {
  //     validateError.names = "Collection Name is Required"
  //   }
  //   if ((data.formValue.customurl) === '') {
  //     validateError.customurl = "Custom url is Required"
  //   }

  //   if (data.formValue.collectionType === '') {
  //     validateError.Category = "Category is Required"
  //   }
  //   // if (data.collectionAddress === '') {
  //   //   validateError.Category = "Category is Required"
  //   // }
  //   setValidateError(validateError)
  //   return validateError;
  // }

  const Validate = async (data) => {

    var validateError = {};

    let imageSize = 5000000;
    // var namesvalidate = /^\s*\s*$/;
    let urlRegex = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/;


    if (onchangeimg != "") {
      // console.log("epwopeopwope", data.formValue.coverImg, onchangeimg, onchangeimg.split('.').pop())
      if (data.formValue.profileImg != "" || data.formValue.profileImg != undefined) {
        if (imageSize < (data.formValue.profileImg).size) {
          validateError.image = "File size must be below 5mb"
        }
        if (!(/\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|WEBP|gif|GIF)$/i).test((data.formValue.profileImg).name)) {
          validateError.image = "file is invalid. only allowed JPG,PNG,WEBP,gif";
        }
      }

    }

    if (coverimg != "") {
      console.log("epwopeoerhtergpwope", data.formValue.coverImg, coverimg, coverimg.split('.').pop())
      if (data.formValue.coverImg != "" || data.formValue.coverImg != undefined) {
        if (imageSize < (data.formValue.coverImg).size) {
          validateError.image = "File size must be below 5mb"
        }
        if (!(/\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|WEBP|gif|GIF)$/i).test((data.formValue.coverImg).name)) {
          validateError.image = "file is invalid. only allowed JPG,PNG,WEBP,gif";
        }
      }

    }

    if ((data.formValue.collectionName) === '') {
      validateError.names = "Collection Name is Required"
    }
    // if (data.formValue.collectionName !== "") {
    //   if (namesvalidate.test(data.formValue.collectionName)) {
    //     validateError.names = "Cannot allowed smiley"
    //   }
    // }
    if (data.formValue.customurl === '') {
      validateError.customurl = "Custom url is Required"
    }

    if (data.formValue.customurl !== "") {
      if (urlRegex.test(data.formValue.customurl)) {
        validateError.customurl = "invalid custom url"
      }
    }

    if (data.formValue.collectionType === '') {
      validateError.Category = "Category is Required"
    }

    console.log("validateError", validateError);
    setValidateError(validateError)
    return validateError;
  }


  async function CollectionProfile() {



    // console.log("fsdbghdf")
    let formData = {};
    var payload = {
      collectionName: name,
      from: "addCollection"
    }
    // console.log("zdgzdhg", payload)
    var collectionInfo = await getUserCollectiondata(payload);
    // console.log("aeryhstrfj", collectionInfo)
    if (collectionInfo && collectionInfo.data && collectionInfo.data.data && collectionInfo.data.data.collections) {

      console.log("Collection Informations", collectionInfo.data.data.collections);
      var collectionData = collectionInfo.data.data.collections
      collectionData.map((item) => {
        // console.log("ADHFgsdfh", item)

        // formData['collectionAddress'] = item.userAddress;
        formData['collectionName'] = item.collectionName;
        formData['customurl'] = item.customurl;
        formData['collectionType'] = item.collectionType;
        //formData['profileImg'] = item.imageName;
        //formData['coverImg'] = item.coverimage; 
        formData['userAddress'] = Wallet_Details.UserAccountAddr;


        // setcoverimg(item.coverimage)
        setNFTType(item.collectionType)
        console.log("zdfbsdfbdsz", item.imageName === null, item.coverimage === '');
        if (item.imageName != '') {
          var profileimage = config.Back_URL + '/collectionLogo/' + item.userAddress + '/' + item.imageName;
          setOnchangeimg(profileimage)
        }
        if (item.coverimage != '') {

          var coverimage = config.Back_URL + '/coverphoto/' + item.userAddress + '/' + item.coverimage;
          setcoverimg(coverimage)
        }
        setFormValue(formData)

      })
      // console.log("fdsbhsfgnh", formData.collectionName)
    }
  }




  const handleFormSubmit = async (e) => {



    // console.log("formValue", formValue)

    // var reqdata = {
    //   collectionAddress : CollectionAddress,
    //   collectionType : NFTType,
    //   collectionName : CollectionName,
    //   userAddress : Wallet_Details.UserAccountAddr
    // }
    // setDisablebtn(2)
    const userAddress = Wallet_Details.UserAccountAddr
    const collectionType = NFTType
    let reqdata = {
      formValue,
      userAddress,
    }

    Validate(reqdata)
    var validate = await Validate(reqdata)
    console.log("DFhsdhtrbdaf", validate.length > 0)

    if (isEmpty(validate) == false) {
      setDisablebtn(1)
      toast.error("please fill the required field", toasterOption);
    }


    else if (form != "usercollection") {
      // setDisablebtn(3)
      // console.log("DFhbdaf", form)
      const resp = await CollectionValidationAction(reqdata)
      // console.log("zdfhgbdfh", resp)
      //  if (resp.data) {
      var errors = resp.data;
      var errorsSize = Object.keys(errors).length;
      // console.log("drhtfj", errorsSize)
      if (errorsSize != 0 || errorsSize > 0) {
        setDisablebtn(1)
        // console.log("fgnbfeuhgbuhfbg", errors);
        setValidateError(errors);

      }

      else {
        setDisablebtn(3)
        // console.log("SDgasdrgardg", reqdata)

        var responsedata = await addUserCollection(reqdata)
        if (responsedata) {
          setValidateError("");

          toast.success("Collection Added successfully", toasterOption)
          history.push("/create")

        }
      }

      // }
    }
    else if (form == "usercollection") {
      setDisablebtn(3)
      // console.log("formval chk from my side>>>>>>>>>", reqdata);

      var responsedata = await addUserCollection(reqdata)
      if (responsedata) {
        setValidateError("");
        // setDisablebtn(3)
        toast.success("collection Updated successfully", toasterOption)
        history.push("/create")
      }
    }


  }

  const handleFile1 = (event) => {
    // setDisablebtn(0)
    event.preventDefault();
    var reader = new FileReader()
    const { id, files } = event.target;
    // setDisablebtn(0)
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      setcoverval(file)
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        if (reader.result) {
          setcoverimg(reader.result);

          // setImageVal1(file.name)

        }
      }
    }
    console.log("Dfbhrsfrtgjh11", id, files[0])
    let formData = { ...formValue, ...{ [id]: files[0] } };
    setFormValue(formData);
    console.log("Dfbhrsfrtgjh22", formData)
  }



  const handleFile = (event) => {
    setDisablebtn(0)
    event.preventDefault();
    var reader = new FileReader()
    const { id, files } = event.target;
    setDisablebtn(0)
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      setImageVal(file)
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        if (reader.result) {
          setOnchangeimg(reader.result);
          // setImageVal1(file.name)

        }
      }
    }
    console.log("Dfbhrsfrtgjh", id, files[0], formValue)
    let formData = { ...formValue, ...{ [id]: files[0] } };
    // console.log("Dfbhrsfrtgjh22", formData)
    setFormValue(formData);
  }
  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/">
          <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <h3 className="section-head mb-0">Add Collections</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-5">
          <GridContainer>
            <GridItem xs={12} sm={3} md={3}>
              <h2 className="user_profileim">Profile</h2>
              <div className="holder">
                <div className="profile_edit_panel">
                  {onchangeimg === '' &&
                    <img src={Profile} alt="logo" id="imgPreview" className="img-fluid" />
                  }
                  {onchangeimg != '' &&
                    <img src={onchangeimg ? onchangeimg : null} alt={onchangeimg ? onchangeimg.name : null} id="imgPreview" className="img-fluid" />
                  }
                  {/* <img src={require("../assets/images/profile_img.png")} alt="logo" id="imgPreview" className="img-fluid"/> */}

                  <div className="profile_edit_icon">
                    <i className="fas fa-pencil-alt"></i>
                  </div>
                  <input
                    type="file"
                    name="photo"
                    id="profileImg"
                    required="true"
                    className="photo"
                    // value={onchangeimg}
                    onChange={(e) => handleFile(e)}
                  />
                  {validateError.image && <span className="text-danger">{validateError.image}</span>}
                </div>


              </div>
              <div className="mt-3">
                <h2 className="user_profileim">Cover Image</h2>
                <div className="profile_banner">
                  {coverimg === '' &&
                    <img src={Profile} alt="logo" id="imgPreview" className="img-fluid" />
                  }
                  {coverimg != '' &&
                    <img src={coverimg ? coverimg : null} alt={coverimg ? coverimg.name : null} id="imgPreview" className="img-fluid" />
                  }

                  {/* <img src={require("../assets/images/profile_img.png")} alt="logo" id="imgPreview" className="img-fluid"/> */}

                  <div className="profile_edit_banner">
                    <i className="fas fa-pencil-alt"></i>
                  </div>

                  <input
                    type="file"
                    name="photo"
                    id="coverImg"
                    className="photo"
                    // value={onchangeimg}
                    onChange={(e) => handleFile1(e)}
                  />
                  {validateError.image && <span className="text-danger">{validateError.image}</span>}
                </div>


              </div>
            </GridItem>
            <GridItem xs={12} sm={9} md={9}>
              <form>
                <div className="form-row">
                  {
                    (form != "usercollection") &&
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="name">Collection Name</label>
                      <input type="text"
                        // value={collectionName}
                        className="form-control primary_inp"
                        id="collectionName"
                        placeholder="Enter your Collection Name"
                        onChange={onChange} />
                      {validateError.names && <span className="text-danger">{validateError.names}</span>}
                      {validateError.collectionName && <span className="text-danger">{validateError.collectionName}</span>}
                      {/* {backvalerr} */}
                    </div>
                  }
                  {
                    (form != "usercollection") &&
                    <div className="form-group col-md-6">

                      <label className="primary_label" htmlFor="collectionType">Category</label>
                      <Select
                        // inputProps={{ id: 'collectionType' }}
                        className="yes1 form-control primary_inp select1 selxet_app"
                        // id="collectionType"
                        // value={NFTType}
                        //  id='collectionType' {...props}
                        name="collectionType"
                        onChange={selectChange}
                        maxMenuHeight={220}
                        options={CategoryOption}
                        label="Select or type name"
                        placeholder="Select or type name"
                      />
                      {validateError.Category && <span className="text-danger"><br />{validateError.Category}</span>}

                    </div>
                  }

                  {
                    (form != "usercollection") &&

                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="customurl">Custom Url</label>
                      <input type="text"
                        value={customurl}
                        className="form-control primary_inp"
                        id="customurl"
                        placeholder="Enter your Custom Url"
                        onChange={onChange} />
                      {validateError.customurl && <span className="text-danger">{validateError.customurl}</span>}

                      {/* {backvalerr} */}
                    </div>
                  }


                  <GridItem xs={12} sm={3} md={3}>


                  </GridItem>




                </div>
                <div className="mt-3">
                  {
                    (form != "usercollection") &&
                    <Button className="create_btn" onClick={handleFormSubmit}>Add Collections</Button>
                  }
                  {
                    (form == "usercollection") &&
                    <Button className="create_btn" onClick={handleFormSubmit}>Update Collection</Button>
                  }
                  {/* {disablebtn === 0 &&
                  
                  } */}
                  {/* {
                    disablebtn === 1 &&
                    <Button className="create_btn" disabled="true">Form Error</Button>
                  }
                  {
                    disablebtn === 2 &&
                    <Button className="create_btn" onClick={handleFormSubmit}>Try Again</Button>
                  }
                  {
                    disablebtn === 3 &&
                    <Button className="create_btn" disabled={true}>Processing</Button>
                  }  */}
                  {/* <Button className="create_btn">Update Profile</Button> */}
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
