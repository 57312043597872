import React, {
  useEffect,
  useState,
  useRef
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useParams } from "react-router-dom";
import Countdown from "react-countdown";
// myside
import config from '../lib/config';
import isEmpty from "../lib/isEmpty";
import moment from 'moment';
import Convert from 'views/separate/Convert'
import Loader from './Loader'

import {
  TokenCounts_Get_Detail_Action,
  setTokenCounts_Get_Detail_Action,
  History
} from '../actions/v1/token';
import ConnectWallet from './separate/Connect-Wallet';
import { WalletRef } from './separate/WalletRef';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { PurchaseNowRef } from "./separate/PurchaseNowRef";
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { LikeRef } from './separate/LikeRef';
import { CancelOrderRef } from './separate/CancelOrderRef';
import { BurnRef } from './separate/BurnRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import { TransferRef } from './separate/TransferRef';
import ReactMarkdown from 'react-markdown';
import { useSelector } from "react-redux";


import Convert1 from '../views/separate/Convert1'
import { toast } from 'react-toastify';
import Avatars from "./Avatar";
toast.configure();
let toasterOption = config.toasterOption;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}



export default function Info(props) {
  const classes = useStyles();
  // const history = useHistory();
  const { ...rest } = props;
  const Wallet_Details = useSelector(state => state.wallet_connect_context);
  function hideDetail() {
    document.getElementById("image_div").classList.toggle('expand_img');
    document.getElementById("img_des").classList.toggle('show_des');
    document.getElementById("detai_div").classList.toggle('hide_detail');
    document.getElementById("arrow_icon").classList.toggle('fa-shrink');

  }

  // function hideDetailowner() {
  //   document.getElementById("image_div_owner").classList.toggle('expand_img');
  //   document.getElementById("img_des_owner").classList.toggle('show_des');
  //   document.getElementById("detai_div_owner").classList.toggle('hide_detail');
  //   document.getElementById("arrow_icon_owner").classList.toggle('fa-shrink');
  // }

  const LikeForwardRef = useRef();
  const PlaceABidForwardRef = useRef();
  const PutOnSaleForwardRef = useRef();
  const PurchaseNowForwardRef = useRef();
  const CancelOrderForwardRef = useRef();
  const WalletForwardRef = useRef();
  // const location = useLocation();
  var BurnForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  const TransferForwardRef = useRef();
  //const [locationname, Setlocationname] = useState(location.pathname)
  async function BidApply_ApproveCall() {
    PlaceABidForwardRef.current.BidApply_ApproveCall();
  }
  async function BidApply_SignCall() {
    PlaceABidForwardRef.current.BidApply_ApproveCall();
  }
  var { owneraddress, collectionaddress, tokenidval } = useParams();

  // wallet related : common state
  const [AllowedQuantity, Set_AllowedQuantity] = useState(0);
  const [tokenUsers, setTokenUsers] = useState({})
  // const [WalletConnected, Set_WalletConnected] = React.useState('false');
  // const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  // const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  // const [Accounts, Set_Accounts] = React.useState('');
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState('');
  const [tokenCounts, Set_tokenCounts] = useState(tokenidval);
  const [item, Set_item] = useState({});
  const [his, sethis] = useState([])
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState(0);
  //const [noofitems, setnoofitem] = useState();
  const [Bids, Set_Bids] = useState({});
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  // const [MultipleWei, Set_MultipleWei] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = React.useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = React.useState('init');
  const [HitItem, Set_HitItem] = useState({});
  //const [BuyOwnerDetail, Set_BuyOwnerDetail] = useState({});
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [convertVal, setConvertVal] = React.useState(0);
  // const [providerss, set_providers] = useState(null)

  const [OwnersDetailFirst, Set_OwnersDetailFirst] = useState({});
  const [showingLoader, setshowingLoader] = React.useState(false);
  const [onwer_price, set_onwer_price] = useState({})
  const [Service_Fee, set_Service_Fee] = useState(0);
  const [Wen_Bln, set_Wen_Bln] = useState(0);
  const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = useState({})
  const [Zero_Price_Detail, Set_Zero_Price_Detail] = useState([])
  const [token_owner_detail_first, set_token_owner_detail_first] = useState({})
  const [swap_fee, set_swap_fee] = useState(0);

  useEffect(() => {

    (async () => {
      var curAddr = Wallet_Details.UserAccountAddr
      var payload = {
        curAddr: curAddr,
        tokenCounts: String(tokenidval),
        paramAddress: String(owneraddress).toLowerCase(),
        contractAddress: String(collectionaddress).toLowerCase()
      };
      // if (Wallet_Details.UserAccountAddr !== "") {

      //   var tokenentry = await setTokenCounts_Get_Detail_Action(payload)
      //   if (tokenentry && tokenentry.data && tokenentry.data.RespType === 'success') {
      //   }
      // }
      await TokenCounts_Get_Detail_Call(payload);

      var dataHistory = await History({ tokenCounts: String(tokenidval) });
      // console.log("dataHistory", dataHistory.data);
      sethis(dataHistory.data)
    })();

  }, [Wallet_Details.UserAccountAddr])

  const AfterWalletConnected = async () => {
    Set_TokenBalance(Wallet_Details.Wen_Bln);
    // var curAddr = UserAccountAddr
    // var payload = {
    //   curAddr: curAddr,
    //   tokenCounts: String(tokenidval),
    //   paramAddress: String(owneraddress).toLowerCase(),
    //   contractAddress: String(collectionaddress).toLowerCase()
    // };

  }

  const checkerror = (e, item) => {
    e.target.src = `${config.IPFS_IMG}/${item.ipfsimage}`
  }


  const TokenCounts_Get_Detail_Call = async (payload) => {
    var curAddr = payload.curAddr;
    setshowingLoader(true)
    var Resp;

    // if (payload.refresh === "refresh") {
    //   toast.success("You are Refresh the meta data, Please wait few minutes", toasterOption)
    //   await setTokenCounts_Get_Detail_Action(payload)
    // }
    Resp = await TokenCounts_Get_Detail_Action(payload)

    setTimeout(() => {
      setshowingLoader(false)
    }, 3000);

    if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {

      var TokenResp = Resp.data.Detail.Resp;
      if (
        TokenResp
        && TokenResp.Token
        && TokenResp.Token[0]
        && TokenResp.Token[0].tokenowners_current
      ) {
        var sum = 0
        // var aso=  (TokenResp.Token[0].tokenowners_current).map((item)=>{
        //   sum += item.balance 
        //   Set_AllowedQuantity(sum)
        //   console.log("Set_AllowedQuantity",item,sum)

        //   })
        sum = (TokenResp.Token[0].tokenowners_current).reduce((a, b) => a + (b["balance"] || 0), 0);
        Set_AllowedQuantity(sum)
        // console.log("Set_AllowedQuantity", TokenResp.Token[0].tokenowners_current, sum)

        for (let i = 0; i < (TokenResp.Token[0].tokenowners_current).length; i++) {
          const element = TokenResp.Token[0].tokenowners_current[i];
          set_onwer_price(element)

          ////console.log("eowieoiwieoiwoeiwe",sum,element.balance,TokenResp.Token[0])

          if (element.tokenPrice > 0 && element.tokenOwner !== curAddr) {
            Set_BuyOwnerDetailFirst(element);

            break;
          }
          if (element.tokenOwner !== curAddr) {
            Set_Zero_Price_Detail(element);

            break;
          }
          if (element.tokenPrice > 0 && element.tokenOwner === curAddr) {
            Set_OwnersDetailFirst(element);

            break;
          }
        }
      }
      Set_tokenCounts_Detail(TokenResp);
      //console.log("slasjakljkasjdsajdasdlasj",TokenResp.Bids)
      if (TokenResp.Bids) {
        Set_Bids(TokenResp.Bids);
      }

      var IndexVal = -1;
      var tokenOwnInf = {};
      if (TokenResp.Token[0] && TokenResp.Token[0].tokenowners_all && curAddr) {
        var tokenowners_all = TokenResp.Token[0].tokenowners_all;
        IndexVal = tokenowners_all.findIndex(val => (val.tokenOwner.toString() === curAddr.toString() && val.balance > 0));
      }

      // //////////console...log("check all val")

      if (IndexVal > -1) {

        Set_MyTokenBalance(tokenowners_all[IndexVal].balance);
        Set_MyTokenDetail(tokenowners_all[IndexVal])
        var addrs = TokenResp.Token[0].tokenOwnerInfo.curraddress[IndexVal]
        tokenOwnInf.curraddress = addrs;
        tokenOwnInf.name = TokenResp.Token[0].tokenOwnerInfo.name[IndexVal]
        set_token_owner_detail_first(tokenOwnInf)
        //console.log("check all val122",TokenResp.Token[0].tokenOwnerInfo,TokenResp.Token[0].tokenOwnerInfo.curraddress[IndexVal])
      }
      else {
        Set_MyTokenDetail({});
        Set_MyTokenBalance(0);
      }

      if (TokenResp.Token && TokenResp.Token[0]) {
        Set_item(TokenResp.Token[0]);
        setTokenUsers(TokenResp.Tusers);
        // //////////console...log("tokenvaluesss",TokenResp.Token[0])
      }

    }

  }
  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }
  const showingloader_true = async () => {
    // ////alert('jhvhj')
    // setshowingLoader(true)
  }
  const showingloader_false = async () => {
    // setshowingLoader(false)
  }
  var renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {

      return <span>Waiting for Owner To Accept</span>
    } else {
      return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
    }
  };

  return (
    showingLoader === true ?
      <Loader />
      :
      <div className="inner_header">
        {/* <ConnectWallet
          Set_UserAccountAddr={Set_UserAccountAddr}
          Set_UserAccountBal={Set_UserAccountBal}
          Set_WalletConnected={Set_WalletConnected}
          Set_AddressUserDetails={Set_AddressUserDetails}
          Set_Accounts={Set_Accounts}
          WalletConnected={WalletConnected}
          set_Service_Fee={set_Service_Fee}
          Service_Fee={Service_Fee}
          AfterWalletConnected={AfterWalletConnected}
          Wen_Bln={Wen_Bln}
          set_Wen_Bln={set_Wen_Bln}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
          set_providers={set_providers}
          providerss={providerss}
        /> */}

        <PutOnSaleRef
          ref={PutOnSaleForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          GetUserBal={GetUserBal}
          againCall={TokenCounts_Get_Detail_Call}
          showingloadertrue={showingloader_true}
          showingloaderfalse={showingloader_false}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}
        />
        <BurnRef
          ref={BurnForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          GetUserBal={GetUserBal}
          againCall={TokenCounts_Get_Detail_Call}
          showingloadertrue={showingloader_true}
          showingloaderfalse={showingloader_false}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}
        />
        <PlaceAndAcceptBidRef
          ref={PlaceABidForwardRef}
          // Set_WalletConnected={Set_WalletConnected}
          // Set_UserAccountAddr={Set_UserAccountAddr}
          // Set_UserAccountBal={Set_UserAccountBal}
          Set_AddressUserDetails={Set_AddressUserDetails}
          // Set_Accounts={Set_Accounts}
          Set_MyItemAccountAddr={Set_MyItemAccountAddr}
          Set_tokenCounts={Set_tokenCounts}
          Set_item={Set_item}
          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
          Set_MyTokenBalance={Set_MyTokenBalance}
          Set_Bids={Set_Bids}
          Set_AccepBidSelect={Set_AccepBidSelect}
          Set_tokenBidAmt={Set_tokenBidAmt}
          Set_NoOfToken={Set_NoOfToken}
          Set_ValidateError={Set_ValidateError}
          Set_TokenBalance={Set_TokenBalance}
          Set_YouWillPay={Set_YouWillPay}
          Set_YouWillPayFee={Set_YouWillPayFee}
          Set_YouWillGet={Set_YouWillGet}
          Set_BidApply_ApproveCallStatus={Set_BidApply_ApproveCallStatus}
          Set_BidApply_SignCallStatus={Set_BidApply_SignCallStatus}

          // WalletConnected={Wallet_Details.WalletConnected}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          AddressUserDetails={Wallet_Details.AddressUserDetails}
          // Accounts={Wallet_Details.Accounts}
          MyItemAccountAddr={MyItemAccountAddr}
          tokenCounts={tokenCounts}
          item={item}
          tokenCounts_Detail={tokenCounts_Detail}
          MyTokenBalance={MyTokenBalance}
          Bids={Bids}
          AccepBidSelect={AccepBidSelect}
          tokenBidAmt={tokenBidAmt}
          NoOfToken={NoOfToken}
          ValidateError={ValidateError}
          TokenBalance={TokenBalance}
          YouWillPay={YouWillPay}
          YouWillPayFee={YouWillPayFee}
          YouWillGet={YouWillGet}
          BidApply_ApproveCallStatus={BidApply_ApproveCallStatus}
          BidApply_SignCallStatus={BidApply_SignCallStatus}
          AllowedQuantity={AllowedQuantity}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          // set_Wen_Bln={set_Wen_Bln}
          // Wen_Bln={Wallet_Details.Wen_Bln}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          againCall={TokenCounts_Get_Detail_Call}
          showingloadertrue={showingloader_true}
          showingloaderfalse={showingloader_false}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}
        />
        <PurchaseNowRef
          ref={PurchaseNowForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          GetUserBal={GetUserBal}
          Service_Fee={Service_Fee}
          set_Service_Fee={set_Service_Fee}
          set_Wen_Bln={set_Wen_Bln}
          Wen_Bln={Wallet_Details.Wen_Bln}
          MyItemAccountAddr_Details={MyItemAccountAddr_Details}
          againCall={TokenCounts_Get_Detail_Call}
          showingloadertrue={showingloader_true}
          showingloaderfalse={showingloader_false}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}
        />
        <WalletRef
          ref={WalletForwardRef}
        // Set_UserAccountAddr={Set_UserAccountAddr}
        // Set_WalletConnected={Set_WalletConnected}
        // Set_UserAccountBal={Set_UserAccountBal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}
        />
        <LikeRef

          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          ref={LikeForwardRef}
          // WalletConnected={Wallet_Details.WalletConnected}
          // set_providers={set_providers}
          // providerss={Wallet_Details.providerss}
          setLikedTokenList={setLikedTokenList}
          MyItemAccountAddr={MyItemAccountAddr}
        />
        <CancelOrderRef
          ref={CancelOrderForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          // WalletConnected={Wallet_Details.WalletConnected}

          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          GetUserBal={GetUserBal}
          againCall={TokenCounts_Get_Detail_Call}
          showingloadertrue={showingloader_true}
          showingloaderfalse={showingloader_false}
          setConvertVal={setConvertVal}
          convertVal={convertVal}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}
        />
        <ReportNowRef
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          ref={ReportForwardRef}
        />
        <ShareNowRef
          ref={ShareForwardRef}
        />
        <TransferRef
          ref={TransferForwardRef}
          Set_HitItem={Set_HitItem}
          item={HitItem}
          // UserAccountAddr={Wallet_Details.UserAccountAddr}
          // UserAccountBal={Wallet_Details.UserAccountBal}
          // Accounts={Wallet_Details.Accounts}
          GetUserBal={GetUserBal}
          swap_fee={swap_fee}
          set_swap_fee={set_swap_fee}
          againCall={TokenCounts_Get_Detail_Call}
          showingloadertrue={showingloader_true}
          showingloaderfalse={showingloader_false}
        // set_providers={set_providers}
        // providerss={Wallet_Details.providerss}
        />
        <>
          <Header
            fixed
            color="transparent"
            routes={dashboardRoutes}
            brand={<>
              <div className="light_logo"><Link to="/">
                <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link></div>
              <div className="dark_logo"><Link to="/">
                <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
            </>}
            rightLinks={<HeaderLinks />}
            changeColorOnScroll={{
              height: 50,
              color: "dark"
            }}
            {...rest}
          />
          <ScrollToTopOnMount />
          <div className={classes.pageHeader + " inner_pageheader info_header info"}>
            {/* info row */}
            <div className="row info_row mx-0 buyer_div">
              <div className="col-12 col-lg-8" id="image_div">
{/* {console.log("testedddddfor quantity",item,MyTokenDetail)} */}
                <div className="flex_center">
                  <div className="float-right arrow_expand" onClick={hideDetail}>
                    <i className="fas fa-arrows-alt" aria-hidden="true" id="arrow_icon"></i>
                  </div>
                  <div className="clearfix"></div>
                  <div className="mid-cont">
                    {
                      (item &&
                        item.image &&
                        item.image !== "" && (
                          (String(item.image).split('.').pop() === "mp4") ||
                          (String(item.image).split('.').pop() === "webm") ||
                          (String(item.image).split('.').pop() === "WEBM") ||
                          (String(item.image).split('.').pop() === "ogv") ||
                          (String(item.image).split('.').pop() === "OGV"))) &&
                      <>{
                        MyTokenDetail.tokenOwner !== Wallet_Details.UserAccountAddr ?

                          <video
                            id="my-video"
                            className="img-fluid"
                            muted
                            controlsList="nodownload"
                            playsInline loop
                            controls
                            autoPlay
                            // preload="auto"
                            alt="video"
                          >
                            <source src={item.additionalImage !== "" ? `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}` : `${config.IPFS_IMG}/${item.ipfsimage}`} type="video/mp4" />
                          </video>
                          :
                          <video
                            id="my-video"
                            className="img-fluid"
                            muted
                            playsInline loop
                            controls
                            autoPlay
                            controlsList={onwer_price.tokenOwner !== Wallet_Details.UserAccountAddr && "nodownload"}
                            // preload="auto"
                            alt="video"

                          >
                            <source src={item.additionalImage !== "" ? `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}` : `${config.IPFS_IMG}/${item.ipfsimage}`} type="video/mp4" />
                          </video>}</>
                    }
                    {
                      item && item.image && item.image !== ""
                      && ((String(item.image).split('.').pop() === "mp3" || String(item.image).split('.').pop() === "aac" || String(item.image).split('.').pop() === "AAC" || String(item.image).split('.').pop() === "FLAC" || String(item.image).split('.').pop() === "flac")) &&
                      <>
                        <img src={config.AudioImg} className="img-fluid" alt="text" />

                        {
                          MyTokenDetail.tokenOwner !== Wallet_Details.UserAccountAddr ?
                            <>


                              <audio
                                controls
                                controlsList="nodownload"
                                muted
                                alt='audio'
                                id="audio_play"
                                playsInline loop
                                type="audio/mp3"
                                autostart="off"

                                src={item.additionalImage !== "" ? `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}` : `${config.IPFS_IMG}/${item.ipfsimage}`}
                              >
                              </audio>
                              {/* <img src={AudioImg} onClick="document.getElementById('audio_play').play(); "  className="img-fluid"/> */}
                              {/* <p>sadasd</p> */}
                            </>
                            :
                            <>
                              <audio controls
                                muted
                                alt='audio'
                                className="img-fluid"
                                playsInline loop
                                type="audio/mp3"
                                autostart="off"
                                id="audio_play"
                                src={item.additionalImage !== "" ? `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}` : `${config.IPFS_IMG}/${item.ipfsimage}`}
                              >
                              </audio>
                            </>
                        }
                      </>
                    }

                    {
                      (
                        item && item.image && item.image !== "" &&
                        ((
                          String(item.image).split('.').pop() === "webp"
                          || String(item.image).split('.').pop() === "WEBP"
                          || String(item.image).split('.').pop() === "gif"
                          || String(item.image).split('.').pop() === "jpg"
                          || String(item.image).split('.').pop() === "GIF"
                          || String(item.image).split('.').pop() === "JPG"
                          || String(item.image).split('.').pop() === "JPEG"
                          || String(item.image).split('.').pop() === "jpeg"
                          || String(item.image).split('.').pop() === "png"
                          || String(item.image).split('.').pop() === "PNG"))) &&
                      // <img src={item.ipfsimage !== "" ? `${config.IPFS_IMG}/${item.ipfsimage}` : `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}`}
                      //   onError={(e) => checkerror(e, item)}
                      //   alt="Collections" className="img-fluid " />
                      <img src={item.additionalImage !== "" ? `${config.Back_URL}/nftImg/${item.tokenCreator}/${item.additionalImage}` : `${config.IPFS_IMG}/${item.ipfsimage}` }
                        onError={(e) => checkerror(e, item)}
                        alt="Collections" className="img-fluid"/>
                    }
                  </div>

                  {/* <img src={require("../assets/images/img_info.png")} alt="Collections" className="img-fluid" /> */}
                  <div className="img_des" id="img_des">
                    <p className="info_title">{item && item.tokenName}</p>
                    <h3 className="info_h3">by<span className="px-2">{item && (isEmpty(item.usersinfo) ? item.tokenOwner : item.usersinfo.name)}</span>on<span className="pl-2">NFT</span></h3>

                  </div>
                </div>

              </div>
              <div className="col-12 col-lg-4 bg_pink" id="detai_div">
                <div className="">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <span className="info_title">{item && item.tokenName}</span>
                    </div>
                    
                    <div className="masonry_likes">
                      {
                        (collectionaddress !== "0x1419c94d6560b81f16486a4c57c7c66f1253cf20".toLowerCase()
                          && collectionaddress !== "0x8D43916d784759B46255c0F47e3b67E1c8375e40".toLowerCase()
                          && collectionaddress !== "0x008505ac19add467B1a6177A27D8D383A078dA26".toLowerCase()
                          && collectionaddress !== "0x1938f408543A19EF6d6d60C3b8A806eBD4E69236".toLowerCase()) ?

                          //  MyTokenDetail.tokenOwner==UserAccountAddr&&
                          <i className="fas fa-redo pr-3 refresh_icon" aria-hidden="true" onClick={() => {
                            var payload = {
                              curAddr: Wallet_Details.UserAccountAddr,
                              tokenCounts: tokenidval,
                              paramAddress: String(owneraddress).toLowerCase(),
                              contractAddress: String(collectionaddress).toLowerCase(),
                              refresh: "refresh"
                            };
                            TokenCounts_Get_Detail_Call(payload);
                          }}></i>
                          : ''
                      }
                      {
                        (LikedTokenList.findIndex(tokenCounts => (tokenCounts.tokenCounts === item.tokenCounts)) > -1)
                          ? (<i className="fas fa-heart mr-2 liked" onClick={() => LikeForwardRef.current.hitLike(item)} style={{ cursor: 'pointer' }}></i>)
                          : (<i className="far fa-heart mr-2" onClick={() => LikeForwardRef.current.hitLike(item)} style={{ cursor: 'pointer' }}></i>)
                      }
                      {/* <i className="fas fa-heart mr-2"></i> */}
                      <span className={item && item.tokenCounts + '-likecount mr-2' + "badge badge_pink mr-2"}>{item && item.likecount}</span>
                      <div className="dropdown dd_info_inline">
                        <button className="drop-down" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          ....
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                          {
                            MyTokenDetail && MyTokenDetail.balance > 0 &&
                            (((String(MyTokenDetail.contractAddress).toLowerCase()) === (String(config.singleContract).toLowerCase())) || ((String(MyTokenDetail.contractAddress).toLowerCase()) === (String(config.multipleContract).toLowerCase()))) &&
                            <span className="dropdown-item" data-toggle="modal" data-target="#" onClick={() => BurnForwardRef.current.Burn_Click(item, MyTokenDetail)}>Burn Token</span>

                          }
                          {
                            MyTokenDetail && MyTokenDetail.balance > 0 &&
                            (((String(MyTokenDetail.contractAddress).toLowerCase()) === (String(config.singleContract).toLowerCase())) || ((String(MyTokenDetail.contractAddress).toLowerCase()) === (String(config.multipleContract).toLowerCase())))

                            && item
                            && item.tokenowners_current.clocktimetime == null
                            && item.tokenowners_current.endclocktime == null &&
                            <span className="dropdown-item" data-toggle="modal" data-target="#"
                              onClick={() => TransferForwardRef.current.Transfer_Click(item, MyTokenDetail)}>Transfer Token
                            </span>
                          }
                          {
                            MyTokenDetail &&
                            MyTokenDetail && MyTokenDetail.balance > 0 && (MyTokenDetail.tokenPrice === 0 || MyTokenDetail.tokenPrice == null)
                            
                            && ((MyTokenDetail.clocktime != null && MyTokenDetail.endclocktime != null) || (MyTokenDetail.clocktime == null && MyTokenDetail.endclocktime == null)) &&
                            // ?
                            (new Date(MyTokenDetail.endclocktime).getTime() < Date.now()) &&
                            <span className="dropdown-item" data-toggle="modal" data-target="#" onClick={() => PutOnSaleForwardRef.current.PutOnSale_Click(item, token_owner_detail_first, MyTokenDetail)}>
                              Put On Sale</span>

                          }
                          {
                            MyTokenDetail && MyTokenDetail.balance > 0 && (MyTokenDetail.tokenPrice > 0) && 
                            <>
                             <>
                              <span className="dropdown-item" data-toggle="modal" data-target="#" onClick={() => PutOnSaleForwardRef.current.PutOnSale_Click(item, token_owner_detail_first, MyTokenDetail)}>Lower Price</span>

                            </>
                              <>
                                <span className="dropdown-item" data-toggle="modal" data-target="#" onClick={() => CancelOrderForwardRef.current.CancelOrder_Click(item, token_owner_detail_first, MyTokenDetail)}>Cancel Order</span>

                              </>
                              </>
                          }
                          {/* <a className="dropdown-item" data-toggle="modal" data-target="#buy_modal">Purchase now</a>
<a className="dropdown-item" data-toggle="modal" data-target="#place_bid_modal">Place a bid</a> */}
                          <span className="dropdown-item" data-toggle="modal" data-target="#report_page_modal"
                            onClick={() => ReportForwardRef.current.SubmitReport_Click(item, onwer_price)}
                          >Report</span>
                          <span className="dropdown-item" data-toggle="modal" data-target="#share_modal"
                            onClick={() => ShareForwardRef.current.ShareSocial_Click(item, onwer_price)}
                          >Share</span>
                        </div>
                      </div>
                    </div>

                  </div>
                  <h3 className="info_h3"><span className="pr-2">
                    {
                      item
                        && item.tokenBid === true
                        && item.tokenowners_current.clocktime == null
                        && item.tokenowners_current.endclocktime == null
                        ?
                        (onwer_price &&
                          (
                            onwer_price.tokenPrice
                              && onwer_price.tokenPrice != null
                              && onwer_price.tokenPrice !== 0 ? <>
                              <Convert
                                item={onwer_price.tokenPrice}
                                coinName={onwer_price.CoinName}
                                convertVal={1}
                              />
                              {' ' + onwer_price.CoinName}
                              {/* ($ <Convert1
                                item={onwer_price.tokenPrice}
                                convertVal={(((String(onwer_price.CoinName).toLowerCase()) === (String(config.tokenSymbol).toLowerCase()))) ? config.WenlamboConvertVal : Wallet_Details.currency_convertion}
                              />) */}
                              ($ {onwer_price.CoinName == "BLKZ" ? Wallet_Details.tokenAddress.BiddingToken_USD * onwer_price.tokenPrice : Wallet_Details.tokenAddress.XDC_Currency_USD * onwer_price.tokenPrice})
                            </>
                              : 'For Sale'

                          ))
                        :
                        <>
                          {onwer_price.minimumBid + ' ' + config.tokenSymbol}
                          <Convert
                            item={onwer_price.minimumBid}
                            coinName={'WENLAMBO'}
                            convertVal={1}
                          />
                          {/* ($ <Convert1
                            item={onwer_price.minimumBid}
                            convertVal={config.WenlamboConvertVal}
                          />) */}
                          ($ {onwer_price.CoinName == "BLKZ" ? Wallet_Details.tokenAddress.BiddingToken_USD * onwer_price.tokenPrice : Wallet_Details.tokenAddress.XDC_Currency_USD * onwer_price.tokenPrice})

                        </>

                    }</span>
                    {onwer_price.balance} <> </> of {item.tokenQuantity}</h3>
                  {/* {console.log("onwer_price.clocktime", onwer_price.clocktime)} */}
                  {
                    (item
                      && onwer_price.clocktime
                      && onwer_price.endclocktime
                      && onwer_price.clocktime != null
                      && onwer_price.endclocktime != null)
                    &&

                    <div className="text-center">
                      <badge className="badge badge-dark badge-timer my-3 badge_red">
                        {
                          (new Date(onwer_price.endclocktime) > Date.now()) ?
                            <>
                              <Countdown
                                // date={Date.now()+100000000000}
                                date={new Date(onwer_price.endclocktime)}
                                autoStart={true}
                                onStart={() => new Date(onwer_price.clocktime)}
                                renderer={renderer}
                              >
                              </Countdown>
                              <i className="fas fa-fire ml-2"></i>
                            </>
                            : ("Auction Ended")
                        }
                      </badge>
                    </div>
                    }
                  <a href={`${config.IPFS_Infura}/${item.ipfsimage}`} target="_blank"><button type="submit" className="btn btn_ipfs">View On IPFS</button></a>      
                  <p className="info_des">{item.tokenDesc}</p>
                  {MyTokenDetail &&
                    MyTokenDetail.tokenOwner
                    &&
                    MyTokenDetail.tokenOwner === Wallet_Details.UserAccountAddr
                    &&
                    item.unlockcontent !== ''
                    && <p className="info_des">
                      {/* <a href={item.unlockcontent}> */}
                      {/* {item.unlockcontent}  */}
                      <ReactMarkdown children={item.unlockcontent} />
                      {/* </a> */}
                    </p>}
                  <nav className="masonry_tab_nav items_tab_outer mt-4 mb-3">
                    <div className="nav nav-tabs masonry_tab primary_tab items_tab d-block items_tab_new pb-2 pl-2" id="nav-tab" role="tablist">
                      <a className="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true"><div className="tab_head ">Info</div></a>
                      <a className="nav-link" id="owners-tab" data-toggle="tab" href="#owners" role="tab" aria-controls="active" aria-selected="false"><div className="tab_head">Owners</div></a>
                      <a className="nav-link" id="bid-tab" data-toggle="tab" href="#bid" role="tab" aria-controls="bid" aria-selected="false"><div className="tab_head">Bid</div></a>
                      <a className="nav-link" id="history-tab" data-toggle="tab" href="#history" role="tab" aria-controls="history" aria-selected="false"><div className="tab_head">history</div></a>

                    </div>
                  </nav>
                  <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
                      <div className="proposal_panel_overall">
                        <div className="inner_div_info">

                          {
                            item
                            && item.tokenowners_current
                            &&
                            <div className="media follow_media info_media">
                              <div className="info_media_img_div mr-3">

                                {item.tokenCreatorInfo.image[0] !== '' ?
                                  <Link to={item && item.tokenCreatorInfo && (item.tokenCreatorInfo.customurl[0] !== "" ? `/${item.tokenCreatorInfo.customurl[0]}` : `/user/${item.tokenCreator}`)} title={`Creator : ${item.tokenCreatorInfo.name[0] !== "" ? item.tokenCreatorInfo.name[0] : item.tokenCreator}`}>

                                    <img src={`${config.Back_URL}/images/${item.tokenCreatorInfo._id[0]}/${item.tokenCreatorInfo.image[0]}`} alt="Owner" className="img-fluid" />
                                  </Link>
                                  :
                                  <Link to={`/user/${item.tokenCreator}`} title={`Owner : ${item.tokenCreator}`}>

                                    <Avatars item="img-fluid"></Avatars>
                                  </Link>
                                }
                              </div>
                              <div className="media-body flex_body">
                                <div>
                                  <p className="mt-0 media_num">Creator</p>
                                  <p className="mt-0 media_text  mb-0" title={item.tokenCreatorInfo.name[0] !== "" ? item.tokenCreatorInfo.name[0] : item.tokenCreator}>{item.tokenCreatorInfo.name[0] !== "" ? item.tokenCreatorInfo.name[0] : String(item.tokenCreator).slice(0, 8).concat('...')}</p>

                                </div>
                                <div className="ml-2 ml-cus">
                                  <div className="card owner_card my-0 border-0">
                                    <div className="card-body p-2">
                                      <div className="flex_txt">
                                        <div className="media_num">{item.tokenRoyality}%</div>
                                        {/* <div className="prce_grey"> */}
                                        <p className="mb-0 price_1 ml-1"> Royalty to the Creator</p>
                                        {/* </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          }
                        </div>

                      </div>
                    </div>
                    <div className="tab-pane fade" id="owners" role="tabpanel" aria-labelledby="owners-tab">
                      <div className="proposal_panel_overall">
                        <div className="inner_div_info">
                          {tokenUsers && tokenUsers.length > 0 && tokenUsers.map((itemCur, i) => {
                            return (<div className="media follow_media info_media">
                              <div className="info_media_img_div mr-3">
                                {(itemCur && itemCur.tusers.image !== '') ?
                                  <Link to={itemCur
                                    && itemCur.tusers
                                    && itemCur.tusers.customurl !== ""
                                    ? `/${itemCur.tusers.customurl}`
                                    : `/user/${itemCur.tusers.curraddress}`} title={`Owner : ${itemCur.tusers.name}`}>
                                    <img src={`${config.Back_URL}/images/${itemCur.tusers._id}/${itemCur.tusers.image}`} alt="Owner" className="img-fluid" />
                                  </Link>
                                  :
                                  <Link to={`/user/${itemCur.tusers.curraddress}`} title={`Owner : ${itemCur.tusers.curraddress}`}>
                                    <Avatars item="img-fluid" />
                                    {/* <Avatars classValue="img-fluid img_user_new"/> */}
                                  </Link>
                                }

                                {/* <img src={require("../assets/images/collections_01.png")} alt="User" className="img-fluid" /> */}
                              </div>
                              <div className="media-body flex_body">
                                <div>
                                  <p className="mt-0 media_num">Owned by</p>
                                  <p className="mt-0 media_text  mb-0">
                                    {
                                      (itemCur.tusers.name !== ''
                                        ? itemCur.tusers.name
                                        :
                                        <span title={itemCur.tokenOwner}>{(itemCur.tokenOwner).slice(0, 8).concat('....')}</span>
                                      )
                                    }
                                  </p>
                                  {/* {
itemCur.tokenOwner==UserAccountAddr
&& <p className="mt-0 media_text mb-0">
{itemCur.balance}/{itemCur.quantity}  

{itemCur.quantity > 0 && 'each'}</p>} */}
                                  {
                                    itemCur.tokenPrice > 0
                                    && <p className="mt-0 media_text mb-0">
                                      {itemCur.balance}/{itemCur.quantity} on sale for
                                      <Convert
                                        item={itemCur.tokenPrice}
                                        coinName={itemCur.CoinName}
                                        convertVal={1}
                                      />
                                      {itemCur.CoinName}
                                      {/* ($ <Convert1
                                        item={itemCur.tokenPrice}
                                        convertVal={(String(itemCur.CoinName).toLowerCase() === String(config.tokenSymbol).toLowerCase()) ? config.WenlamboConvertVal : Wallet_Details.currency_convertion}
                                      />) */}
                                      ($ {onwer_price.CoinName == "BLKZ" ? Wallet_Details.tokenAddress.BiddingToken_USD * onwer_price.tokenPrice : Wallet_Details.tokenAddress.XDC_Currency_USD * onwer_price.tokenPrice})
                                      {itemCur.quantity > 0 && 'each'}</p>}
                                  {itemCur.tokenPrice <= 0
                                    && <p className="mt-0 media_text mb-0">
                                      {itemCur.balance}/{itemCur.quantity}  For Sale</p>}
                                  {
                                    itemCur.tokenPrice > 0
                                    && itemCur.balance > 0
                                    && itemCur.tokenOwner !== Wallet_Details.UserAccountAddr &&
                                    <Button className="btn_outline_red ml-2 mb-2" onClick={() => PurchaseNowForwardRef.current.PurchaseNow_Click(item, itemCur)} >Buy Now</Button>
                                  }
                                  {/* {
(itemCur.tokenPrice == 0 || itemCur.tokenPrice == null) && itemCur.balance > 0 && itemCur.tokenOwner != UserAccountAddr &&
((item
&& item.tokenBid == true
&& item.tokenowners_current.clocktime != null
&& item.tokenowners_current.endclocktime != null
&& new Date(item.endclocktime) < Date.now()) ?
(<p className="btn_outline_red ml-2 mb-2">Waiting for owner to Accept</p>)
:

(Bids
&& Bids.myBid
&& !Bids.myBid.status
?
<Button className="btn_outline_red ml-2 mb-2" onClick={() => PlaceABidForwardRef.current.PlaceABid_Click(item, itemCur, Bids)}>
Bid now
</Button>
:
(Bids
&& Bids.myBid
&& Bids.myBid.status
&& (Bids.myBid.status == 'pending' ?
<Button className="btn_outline_red ml-2 mb-2" onClick={() => PlaceABidForwardRef.current.PlaceABid_Click(item, itemCur, Bids)}>
Edit a bid
</Button>
:
Bids
&& Bids.myBid
&& Bids.myBid.status
&& Bids.myBid.status == 'partiallyCompleted'
&&
<Button className="btn_outline_red ml-2 mb-2" onClick={() => PlaceABidForwardRef.current.CancelBid_Select(Bids.myBid)}>
Cancel a bid
</Button>))))
} */}
                                </div>

                              </div>
                            </div>)
                          })}
                        </div>

                      </div>
                    </div>



                    <div className="tab-pane fade" id="bid" role="tabpanel" aria-labelledby="bid-tab">
                      <div className="proposal_panel_overall">
                        <div className="inner_div_info">
                          {
                            Bids && Bids.pending && Bids.pending.length > 0 && Bids.pending.map((curBid) => {
                              return (
                                <div className="media follow_media info_media">
                                  <div className="info_media_img_div mr-3">
                                    {curBid.bidUsers && <div className="img_prgo_re">
                                      <Link href={curBid.bidUsers !== undefined && (curBid.bidUsers.customurl !== "" ? `/${curBid.bidUsers.customurl}` : `/user/${curBid.tokenBidAddress}`)}>
                                        {
                                          curBid.bidUsers.image !== "" && <img src={`/images/${curBid.bidUsers._id}/${curBid.bidUsers.image}`} alt="User" className="img-fluid" />

                                        }
                                        {
                                          curBid.bidUsers.image === "" &&

                                          <Avatars item="img-fluid" />

                                        }
                                      </Link>
                                    </div>}
                                    {/* <div className="img_prgo_re mr-3">
<img src={require("../assets/images/collections_01.png")} alt="User" className="img-fluid" /></div> */}
                                  </div>
                                  <div className="media-body flex_body">
                                    <div>
                                      <p className="mt-0 media_num mt-0 word_break_all">
                                        <Convert
                                          item={curBid.tokenBidAmt}
                                          coinName={'WENLAMBO'}
                                          convertVal={1}
                                        />
                                        {config.tokenSymbol}
                                        {/* ($ <Convert1
                                          item={curBid.tokenBidAmt}
                                          convertVal={config.WenlamboConvertVal}
                                        />) */}
                                        ($ {onwer_price.CoinName == "BLKZ" ? Wallet_Details.tokenAddress.BiddingToken_USD * curBid.tokenBidAmt : Wallet_Details.tokenAddress.XDC_Currency_USD * curBid.tokenBidAmt})


                                        by   <span title={curBid.bidUsers !== undefined && (curBid.bidUsers.name !== "" ? curBid.bidUsers.name : curBid.tokenBidAddress)}>{curBid.bidUsers !== undefined && (curBid.bidUsers.name !== "" ? curBid.bidUsers.name : <span className="word_brak_txt">{curBid.tokenBidAddress}</span>)}</span> {AllowedQuantity > 0 && (<span>for {curBid.pending}/{curBid.NoOfToken} edition</span>)}</p>
                                      <p className="my-0 media_text">{moment(curBid.timestamp).format('MMMM Do YYYY, h:mm a')}</p>

                                      {Wallet_Details.UserAccountAddr
                                        && Wallet_Details.UserAccountAddr !== curBid.tokenBidAddress
                                        && item
                                        && item.tokenowners_current
                                        && item.tokenowners_current.findIndex(e => e.tokenOwner === Wallet_Details.UserAccountAddr) > -1
                                        &&
                                        <div className="ml-2 ml-cus">
                                          {item
                                            && item.tokenBid === true
                                            && item.tokenowners_current[0].clocktime != null
                                            && item.tokenowners_current[0].endclocktime != null
                                            && (new Date(item.tokenowners_current[0].endclocktime).getTime() > Date.now()) ?
                                            (<Button className="primary_btn mb-2">You Can't Accept The Bid Until Auction Complete</Button>) :

                                            <Button className="primary_btn mb-2" onClick={() => PlaceABidForwardRef.current.AcceptBid_Select(item, curBid)}>Accept</Button>

                                          }    </div>
                                      }

                                      {Wallet_Details.UserAccountAddr
                                        && Wallet_Details.UserAccountAddr === curBid.tokenBidAddress
                                        && item
                                        && item.tokenBid === true
                                        // && item.tokenowners_current.clocktime == null
                                        // && item.tokenowners_current.endclocktime == null
                                        &&
                                        <Button className="create_btn  ml-1 mb-2" onClick={() => PlaceABidForwardRef.current.CancelBid_Select(curBid)}>Cancel</Button>
                                      }


                                    </div>


                                  </div>

                                </div>

                              )
                            })}</div>

                      </div>
                    </div>



                    <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="hostory-tab">
                      <div className="proposal_panel_overall">
                        <div className="inner_div_info">
                          {
                            his.map((hisitem) => {
                              // console.log("history map", hisitem);
                              return (
                                <div className="media follow_media info_media">
                                  <div className="info_media_img_div mr-3">

                                    {hisitem.OwnersHistory.image !== '' ?
                                      <Link to={hisitem && hisitem.OwnersHistory && (hisitem.OwnersHistory.customurl !== "" ? `/${hisitem.OwnersHistory.customurl}` : `/user/${hisitem.tokenOwner}`)} title={`Creator : ${hisitem.OwnersHistory.name !== "" ? hisitem.OwnersHistory.name : hisitem.tokenOwner}`}>

                                        <img src={`${config.Back_URL}/images/${hisitem.OwnersHistory._id}/${hisitem.OwnersHistory.image}`} alt="Owner" className="img-fluid" />
                                      </Link>
                                      :
                                      <Link to={`/user/${hisitem.tokenOwner}`} title={`Owner : ${hisitem.tokenOwner}`}>

                                        <Avatars item="img-fluid"></Avatars>
                                      </Link>
                                    }
                                  </div>
                                  <div className="media-body flex_body">
                                    <div>
                                      {/* <p className="mt-0 media_num">{hisitem.balance === 0 ? `${hisitem.balance} of ${item.tokenName} owned by`:`${hisitem.quantity} of ${item.tokenName} Own by`}</p> */}
                                      <p className="mt-0 media_num">{hisitem.balance === 0 ? `Previous owned by`:`Owned by`}</p> 

                                      <p className="mt-0 media_text  mb-0" title={hisitem.OwnersHistory.name !== "" ? hisitem.OwnersHistory.name : hisitem.tokenOwner}>{hisitem.OwnersHistory.name !== "" ? hisitem.OwnersHistory.name : String(hisitem.tokenOwner).slice(0, 8).concat('...')}</p>

                                    </div>
                                  </div>
                                  
                                </div>

                              )
                            })}
                        </div>





                      </div>
                    </div>






                  </div>
                  <hr />
                  <div className="card info_big_card mb-0">
                    <div className="card-body">
                      {(Bids.highestBid && Bids.highestBid.tokenBidAmt > 0) &&
                        <>
                          <div className="media follow_media">
                            <div className="info_media_img_div mr-3">

                              {Bids.highestBid.bidUsers && Bids.highestBid.bidUsers.image !== ""
                                &&
                                <img src={`${config.Back_URL}/images/${Bids.highestBid.bidUsers._id}/${Bids.highestBid.bidUsers.image}`} alt="User" className="img-fluid" />}

                              {
                                Bids.highestBid.bidUsers && Bids.highestBid.bidUsers.image === "" &&

                                <Avatars item="img-fluid" />}


                            </div>
                            <div className="media-body flex_body">
                              <div>
                                <p className="mt-0 media_text_big_1">Highest bid by <span className="text_blk" title={(Bids.highestBid.bidBy && Bids.highestBid.bidBy.name) ? Bids.highestBid.bidBy.name : (Bids.highestBid.tokenBidAddress)}>
                                  {(Bids.highestBid && Bids.highestBid.bidUsers && Bids.highestBid.bidUsers.name) ? Bids.highestBid.bidUsers.name : (Bids.highestBid.tokenBidAddress).slice(0, 8).concat('....')}
                                </span></p>
                                <p className="mt-0 mb-0 media_text_big_2">
                                  <Convert
                                    item={Bids && Bids.highestBid && Bids.highestBid.tokenBidAmt}
                                    convertVal={1}
                                    coinName={'WENLAMBO'}
                                  />
                                  {" " + config.tokenSymbol}
                                  {/* ($ <Convert1
                                    item={Bids && Bids.highestBid && Bids.highestBid.tokenBidAmt}
                                    convertVal={config.WenlamboConvertVal}
                                  />) */}

                                  ($ {Wallet_Details.tokenAddress.BiddingToken_USD * Bids.highestBid.tokenBidAmt})
                                  <span className="text_blk">


                                  </span></p>
                              </div>


                            </div>
                          </div></>}

                      <div className="mt-3 text-center">

                        {/* <Button className="create_btn mb-2" data-toggle="modal" data-target="#buy_modal">Buy Now</Button> */}
                        {/* {console.log("buyerownerdetails for put onsale",BuyOwnerDetailFirst)} */}
                        {
                          BuyOwnerDetailFirst
                          && BuyOwnerDetailFirst.tokenOwner !== Wallet_Details.UserAccountAddr
                          && BuyOwnerDetailFirst.tokenPrice > 0
                         
                          && <Button className="btn_outline_red mb-2" onClick={() => PurchaseNowForwardRef.current.PurchaseNow_Click(item, BuyOwnerDetailFirst)} >Buy Now</Button>}
                        {MyTokenDetail && MyTokenDetail.tokenOwner === Wallet_Details.UserAccountAddr && MyTokenDetail.tokenPrice > 0 
                          ? <Button className="btn_outline_red mb-2" onClick={() => CancelOrderForwardRef.current.CancelOrder_Click(item, token_owner_detail_first, MyTokenDetail)}>Cancel Order</Button>
                          :
                          MyTokenDetail
                          && MyTokenDetail.tokenOwner
                          && item
                          && item.tokenBid === true
                          && item.tokenowners_current.clocktime != null
                          && item.tokenowners_current.endclocktime != null
                          &&
                          ((new Date(item.endclocktime)) > (Date.now())) &&
                          <Button className="create_btn mb-2">
                            Auction Not Complete Yet
                          </Button>}

                        {/* {MyTokenDetail
&& MyTokenDetail.tokenOwner
&& MyTokenDetail.type==721
&& item
&& item.tokenBid == true
&& item.tokenowners_current.clocktime != null
&& item.tokenowners_current.endclocktime != null &&
// &&(Bids === undefined)&&
((new Date(item.endclocktime)) < (Date.now())) &&
<Button className="create_btn mb-2 mt-2 pt-2 ml-2 mb-2" 
onClick={() => PutOnSaleForwardRef.current.PutOnSale_Click(item,token_owner_detail_first, MyTokenDetail)}>
Put on sale</Button>
} */}
   {/* {console.log("MyTokenDetail for putonsale",MyTokenDetail)} */}

                        {
                          (MyTokenDetail && MyTokenDetail.balance > 0 && (MyTokenDetail.tokenPrice === 0 || MyTokenDetail.tokenPrice === null)
                            // && MyTokenDetail.type==721
                            //&& item
                            //&& item.tokenowners_current)
                          )
                       
                          
                          && MyTokenDetail
                          // && (MyTokenDetail.putonsalequantity === 0) && (MyTokenDetail.balance > 0)
                          && MyTokenDetail && MyTokenDetail.balance > 0 && (MyTokenDetail.tokenPrice === 0 || MyTokenDetail.tokenPrice == null)
                          && ((MyTokenDetail.clocktime != null && MyTokenDetail.endclocktime != null) || (MyTokenDetail.clocktime == null && MyTokenDetail.endclocktime === null)) &&
                          // ?
                          (new Date(MyTokenDetail.endclocktime).getTime() < Date.now()) &&
                       
                          <Button className="create_btn mb-2 ml-1"
                            onClick={() => PutOnSaleForwardRef.current.PutOnSale_Click(item, token_owner_detail_first, MyTokenDetail)}>
                            Put on Sale
                          </Button>

                          // :
                          // <Button className="create_btn mb-2 ml-1"
                          //   onClick={() => PutOnSaleForwardRef.current.PutOnSale_Click(item, token_owner_detail_first, MyTokenDetail)}>
                          //   two
                          // </Button>
                        }

                        {/* <Button className="btn_outline_red ml-2 mb-2" data-toggle="modal" data-target="#place_bid_modal">Bid</Button> */}
                        {
                          (item
                            && item.tokenBid === true
                            && item.tokenowners_current[0].clocktime != null
                            && item.tokenowners_current[0].endclocktime != null
                            && (new Date(item.tokenowners_current[0].endclocktime) < Date.now()) ?
                            (
                              !isEmpty(Bids.highestBid) ?
                                (Bids && !isEmpty(Bids.highestBid) &&
                                  Wallet_Details.UserAccountAddr
                                  && Wallet_Details.UserAccountAddr !== Bids.highestBid.tokenBidAddress
                                  && item
                                  && item.tokenowners_current
                                  && item.tokenowners_current.findIndex(e => e.tokenOwner === Wallet_Details.UserAccountAddr) > -1
                                  ?
                                  <Button className="btn_outline_red ml-2 mb-2" onClick={() => PlaceABidForwardRef.current.AcceptBid_Select(item, Bids.highestBid)}>
                                    Accept
                                  </Button>
                                  :
                                  <Button className="btn_outline_red ml-2 mb-2 waiting_for_owner">
                                    Waiting for owner to accept the bid
                                  </Button>)
                                // <p className="btn_outline_red ml-2 mb-2">Waiting for owner to Accept</p>                     
                                :
                                <p className="btn_outline_red ml-2 mb-2 timed-aution-ended">Timed auction ended</p>
                            )
                            :
                            // MyTokenBalance>0 ?
                            (AllowedQuantity > MyTokenBalance ?
                              Bids
                                && Bids.myBid
                                && !Bids.myBid.status
                                ?
                                <Button className="btn_outline_red ml-2 mb-2" onClick={() => PlaceABidForwardRef.current.PlaceABid_Click(item, onwer_price, Bids)}>
                                  Bid now
                                </Button>
                                :
                                Bids
                                && Bids.myBid
                                && Bids.myBid.status
                                && (Bids.myBid.status === 'pending' ?
                                  <Button className="btn_outline_red ml-2 mb-2" onClick={() => PlaceABidForwardRef.current.PlaceABid_Click(item, onwer_price, Bids)}>
                                    Edit a bid
                                  </Button>
                                  :
                                  Bids
                                  && Bids.myBid
                                  && Bids.myBid.status
                                  && Bids.myBid.status === 'partiallyCompleted'
                                  &&
                                  <Button className="btn_outline_red ml-2 mb-2" onClick={() => PlaceABidForwardRef.current.CancelBid_Select(Bids.myBid)}>
                                    Cancel a bid
                                  </Button>)
                              : ''))

                        }

                      </div>
                      {(onwer_price.tokenPrice > 0) &&
                        <p className="mt-0 media_text_big_1 text-center">Service fee {Wallet_Details.Service_Fee_buyer / config.decimalvalues}%,
                          <Convert
                            item={Number(onwer_price.tokenPrice)}
                            coinName={onwer_price.CoinName}
                            convertVal={1}
                          />
                          {onwer_price.CoinName}

                          <>
                            {/* ($  <Convert1
                            item={Number(onwer_price.tokenPrice)}
                            convertVal={(String(onwer_price.CoinName).toLowerCase() === String(config.tokenSymbol).toLowerCase()) ? config.WenlamboConvertVal : Wallet_Details.currency_convertion}
                          />) */}
                            ($ {onwer_price.CoinName == "BLKZ" ? Wallet_Details.tokenAddress.BiddingToken_USD * onwer_price.tokenPrice : Wallet_Details.tokenAddress.XDC_Currency_USD * onwer_price.tokenPrice})
                          </>
                        </p>}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            {/* end info row */}


          </div>
          <Footer />
        </>
      </div>
  );
}
