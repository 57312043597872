
import React from "react";
import {useHistory} from "react-router-dom";
import config from '../../lib/config';
import Avatars from "views/Avatar";
import moment from 'moment'
export default function ActivityCard(props) {
var history=useHistory();
    var {
    item
    } = props;

    return (
      <div className="col-12 col-md-6 col-xl-4 mb-4">
      <div className="card my-0 h-100 acti_card">
        <div className="card-body px-3">
        <div className="media follow_media icon_img">
        <div className="icon-img-div">
      {/* <div className="bg_red_icon bg_red_icon_sm">
                   <i className="fas fa-share-alt"></i> 
                  </div> */}
                  <div className="img_media_new  mr-3">
                  {
										item
										&& item.toField
										&& item.toField.image
										&& item.toField.image !== ""
										&&<img onClick={() => history.push(item.toField.customurl!==""? `/:${item.toField.customurl}`:`/user/${item.to}`)} src={`${config.Back_URL}/images/${item.toField._id}/${item.toField.image}`} alt="User" className="img-fluid" />
									}
									{
										(item.toField === undefined
										|| item.toField.image === undefined
										|| item.toField.image === "")
										&&
                    <div className="img-fluid">
                    <Avatars onClick={() => history.push(item.toField.customurl!==""? `/:${item.toField.customurl}`:`/user/${item.to}`)} item="img-fluid" /></div>
									}
                  {/* <img src={require("../assets/images/collections_02.png")} alt="User" className="img-fluid" /> */}
                  </div>
      </div>
          <div className="media-body flex_body">
          {item.to && <div>
            <p className="my-0 media_text"  title={"User :  "+((item.toField && item.toField.name !== "") ? item.toField.name : String(item.to))} onClick={() => history.push(item.toField && item.toField.customurl !== "" ? `/${item.toField.customurl}` : `/user/${item.to}`)}>{(item.toField && item.toField.name !== "") ? item.toField.name : String(item.to).slice(0,6).concat('...')}</p>
          <p className="mt-0 media_num mt-0">
          <span onClick={() => history.push( `/info/${item.tokenOwner}/${item.contractAddress}/${item.tokenCounts}`)}></span>
         <b>{item.tokens_current&&item.tokens_current.tokenName}</b>   <span>{item.activity}  </span>
            <span className="media_text pl-1 px-1 ml-1"  onClick={() => history.push(item.toField && item.toField.customurl !== "" ? `/${item.toField.customurl}` : `/user/${item.to}`)} className="user_noti_colr" title={"User : "+((item.toField && item.toField.name !== "") ? item.toField.name : String(item.to))}>
            {(item.toField && item.toField.name !== "") ?  item.toField.name :  String(item.to).slice(0,6).concat('...')}</span>
            {/* <> for{item&&item.tokenowners_current&& <span className="media_text px-1">{item.tokenowners_current.tokenOwner == config.currAdrress &&  item.tokenowners_current.tokenPrice} {item.tokenowners_current.currencySymbol} </span> } each </> */}
           
          <> from <span className="media_text pl-1"  onClick={() => history.push(item.fromField && item.fromField.customurl !== "" ? `/${item.fromField.customurl}` : `/user/${item.from}`)} title={"Token Owner : "+((item.fromField && item.fromField.name !== "") ? item.fromField.name : String(item.from))}>
          {(item.fromField && item.fromField.name !== "") ?  item.fromField.name :  String(item.from).slice(0,6).concat('...')}
            </span></>
             </p>  </div>}
         
          </div>
          </div>
          <div className="ml-2">
          <p className="mt-0 media_num mb-0 dur_text">	{moment(item.created).format('DD/MM/YYYY, h:mm a')}</p>
      </div>
         
        </div>
      </div>
    </div>
          
          
    )
}
